import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { EyeClosedIcon, EyeIcon } from '@moia-dev/pace-icons'
import { Button, Callout, LoadingIndicator, Text } from '@moia-dev/pace-web'
import { useSetCognitoUser } from '@backoffice-frontend/authentication'
import { challengePassword, isAuthenticated } from '@backoffice-frontend/common'
import { FormTextfield } from '@backoffice-frontend/forms'
import { MoiaGrid, useTheme } from '@backoffice-frontend/patterns'
import { usePaceValidators } from '@backoffice-frontend/validators'
import { PublicAppViewContentLayout } from '../components/PublicAppViewContentLayout'

export const PasswordChallengeView = ({ isMobile }: { isMobile: boolean }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { handleSubmit, control, formState, setError, watch } = useForm()
  const theme = useTheme()
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false)

  const { required } = usePaceValidators()
  const newPasswordRepeat = watch('newPasswordRepeat')

  const handleValidationError = async (error: { code: string }) => {
    if (error.code === 'LimitExceededException') {
      setError('root.serverError', {
        message: t('loginView_resetPasswordLimitExceededError'),
      })
    } else if (error.code === 'InvalidPasswordException') {
      setError('newPassword', {
        type: 'validate',
        message: t('common_passwordPolicy'),
      })
    } else {
      setError('root.serverError', {
        message: t('loginView_validationErrorMessage'),
      })
    }
  }

  const setCognitoUser = useSetCognitoUser()

  const onSubmit = async ({ newPassword = '' }) => {
    if (newPasswordRepeat && newPasswordRepeat !== newPassword) {
      setError('newPasswordRepeat', {
        type: 'validate',
        message: t('common_newPasswordMismatch_error'),
      })
      return
    }
    try {
      await challengePassword(newPassword)
      const user = await isAuthenticated()
      if (user) {
        setCognitoUser?.(user)
        navigate('/')
      }
      throw Error('no current user')
    } catch (e) {
      //@ts-expect-error there is a default
      handleValidationError(e)
      console.info(e)
    }
  }

  return (
    <PublicAppViewContentLayout
      title={t('loginView_resetPassword_header')}
      isMobile={isMobile}
    >
      {formState.errors.root?.serverError && (
        <div
          css={theme => ({
            marginTop: isMobile ? theme.space.Space2 : theme.space.Space6,
          })}
        >
          <Callout variant="neutral">
            {formState.errors.root.serverError.message}
          </Callout>
        </div>
      )}
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          marginTop: isMobile ? theme.space.Space2 : theme.space.Space6,
        }}
      >
        <Text size="sm" css={{ paddingBottom: `${theme.space.Space2}` }}>
          <Text weight="bold">{t('common_passwordPolicy_header')}</Text>
          <Text
            css={{
              whiteSpace: 'pre-line',
              display: 'block',
              paddingLeft: theme.space.Space2,
            }}
          >
            {t('common_passwordPolicy')}
          </Text>
        </Text>
        <MoiaGrid mt={1} gridGap={isMobile ? 1 : 3}>
          <FormTextfield
            control={control}
            name="newPassword"
            type={showPassword ? 'text' : 'password'}
            label={t('loginView_newPassword_label')}
            rules={{
              required,
            }}
            removeSpaces
            trailing={
              showPassword ? (
                <EyeIcon onClick={() => setShowPassword(false)} />
              ) : (
                <EyeClosedIcon onClick={() => setShowPassword(true)} />
              )
            }
          />
          <FormTextfield
            control={control}
            name="newPasswordRepeat"
            type={showPasswordRepeat ? 'text' : 'password'}
            label={t('loginView_newPasswordRepeat_label')}
            rules={{
              required,
            }}
            removeSpaces
            trailing={
              showPasswordRepeat ? (
                <EyeIcon onClick={() => setShowPasswordRepeat(false)} />
              ) : (
                <EyeClosedIcon onClick={() => setShowPasswordRepeat(true)} />
              )
            }
          />
          <MoiaGrid
            gridTemplateColumns="max-content max-content"
            justifyContent="end"
            gridGap={1}
          >
            <Button
              type="button"
              onClick={() => navigate('/login')}
              label={t('common_back_button')}
            />
            <Button
              type="submit"
              disabled={!formState.isValid}
              color="primary"
              icon={formState.isSubmitting ? <LoadingIndicator /> : undefined}
              label={t('common_submit_button')}
            />
          </MoiaGrid>
        </MoiaGrid>
      </form>
    </PublicAppViewContentLayout>
  )
}
