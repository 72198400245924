/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type QueryCustomerHeaderQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input']
}>

export type QueryCustomerHeaderQuery = {
  __typename?: 'Query'
  customer: {
    __typename?: 'CustomerResponse'
    customer: {
      __typename?: 'Customer'
      wheelchairAccess: boolean
      hvvPartnershipValidUntil: any | null
    } | null
  }
}

export const QueryCustomerHeaderDocument = gql`
    query queryCustomerHeader($id: ID!) {
  customer(id: $id) {
    customer {
      wheelchairAccess
      hvvPartnershipValidUntil
    }
  }
}
    `

/**
 * __useQueryCustomerHeaderQuery__
 *
 * To run a query within a React component, call `useQueryCustomerHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCustomerHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCustomerHeaderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQueryCustomerHeaderQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryCustomerHeaderQuery,
    QueryCustomerHeaderQueryVariables
  > &
    (
      | { variables: QueryCustomerHeaderQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    QueryCustomerHeaderQuery,
    QueryCustomerHeaderQueryVariables
  >(QueryCustomerHeaderDocument, options)
}
export function useQueryCustomerHeaderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryCustomerHeaderQuery,
    QueryCustomerHeaderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    QueryCustomerHeaderQuery,
    QueryCustomerHeaderQueryVariables
  >(QueryCustomerHeaderDocument, options)
}
export function useQueryCustomerHeaderSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        QueryCustomerHeaderQuery,
        QueryCustomerHeaderQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    QueryCustomerHeaderQuery,
    QueryCustomerHeaderQueryVariables
  >(QueryCustomerHeaderDocument, options)
}
export type QueryCustomerHeaderQueryHookResult = ReturnType<
  typeof useQueryCustomerHeaderQuery
>
export type QueryCustomerHeaderLazyQueryHookResult = ReturnType<
  typeof useQueryCustomerHeaderLazyQuery
>
export type QueryCustomerHeaderSuspenseQueryHookResult = ReturnType<
  typeof useQueryCustomerHeaderSuspenseQuery
>
export type QueryCustomerHeaderQueryResult = Apollo.QueryResult<
  QueryCustomerHeaderQuery,
  QueryCustomerHeaderQueryVariables
>
