/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
export type VehicleGroupFragment = {
  __typename?: 'VehicleGroup'
  id: string
  groupType: Types.VehicleGroupType
  boxProvider: Types.BoxProvider
  engineType: Types.VehicleEngineType
  displayName: string
  minStateOfCharge: number
  tenant: string
  seats: number
  wheelchairSeats: number
  sdsProvider: Types.SdsProvider
  serviceAreaUUIDs: Array<string>
  isWheelchairAccessible: boolean
  childSeatSpecs: Array<{
    __typename?: 'ChildSeatSpec'
    seatNumber: number
    isBoosterSeat: boolean
    isChildSeat: boolean
    isAvailable: boolean
  }>
}

export const VehicleGroupFragmentDoc = gql`
    fragment VehicleGroup on VehicleGroup {
  id
  groupType
  boxProvider
  engineType
  displayName
  minStateOfCharge
  tenant
  seats
  childSeatSpecs {
    seatNumber
    isBoosterSeat
    isChildSeat
    isAvailable
  }
  wheelchairSeats
  sdsProvider
  serviceAreaUUIDs
  isWheelchairAccessible
}
    `
