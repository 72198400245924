import * as Sentry from '@sentry/browser'
import type { ErrorInfo, ReactNode } from 'react'
import { Suspense } from 'react'
import type { ErrorBoundaryProps } from 'react-error-boundary'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { MoiaViewBox } from '../basics/MoiaViewBox'
import { ContentInfo } from '../feedback/ContentInfo'
import { LoadingIndicator } from '../feedback/LoadingIndicator'
import { MoiaCard, MoiaCardContent } from './Cards/MoiaCard'

export const SuspenseWrapper = ({
  loadingFallback,
  errorFallback,
  children,
  onError,
}: {
  children: ReactNode
  loadingFallback?: ReactNode
  errorFallback?: ErrorBoundaryProps['fallback']
  onError?: (error: Error, info: ErrorInfo) => void
}) => {
  return (
    <ErrorBoundary fallback={errorFallback ?? null} onError={onError}>
      <Suspense fallback={loadingFallback}>{children}</Suspense>
    </ErrorBoundary>
  )
}

export const SuspenseLoadingWrapper = ({
  children,
}: {
  children: ReactNode
}) => {
  const { t } = useTranslation()
  return (
    <SuspenseWrapper
      loadingFallback={<LoadingIndicator loading />}
      onError={(error, info) =>
        Sentry.captureException(error, {
          extra: { componentStack: info.componentStack },
          tags: { isSuspenseLoadingWrapper: true },
        })
      }
      errorFallback={
        <MoiaViewBox>
          <MoiaCard>
            <MoiaCardContent>
              <ContentInfo
                title={t('patterns_areaErrorFallback_title')}
                infoText={t('patterns_areaErrorFallback_caption')}
                variant="alert"
              />
            </MoiaCardContent>
          </MoiaCard>
        </MoiaViewBox>
      }
    >
      {children}
    </SuspenseWrapper>
  )
}
