/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type ServiceAreaByIdWithLocationAttributesQueryVariables = Types.Exact<{
  serviceAreaId: Types.Scalars['ID']['input']
}>

export type ServiceAreaByIdWithLocationAttributesQuery = {
  __typename?: 'Query'
  serviceAreaById: {
    __typename?: 'ServiceArea'
    id: string
    vehicleOperationMode: Types.VehicleOperationMode
    locationAttributes: {
      __typename?: 'LocationAttributes'
      topLeft: { __typename?: 'Location'; latitude: number; longitude: number }
      defaultLocation: {
        __typename?: 'Location'
        latitude: number
        longitude: number
      }
      bottomRight: {
        __typename?: 'Location'
        latitude: number
        longitude: number
      }
    }
  } | null
}

export const ServiceAreaByIdWithLocationAttributesDocument = gql`
    query serviceAreaByIdWithLocationAttributes($serviceAreaId: ID!) {
  serviceAreaById(id: $serviceAreaId) {
    id
    vehicleOperationMode
    locationAttributes {
      topLeft {
        latitude
        longitude
      }
      defaultLocation {
        latitude
        longitude
      }
      bottomRight {
        latitude
        longitude
      }
    }
  }
}
    `

/**
 * __useServiceAreaByIdWithLocationAttributesQuery__
 *
 * To run a query within a React component, call `useServiceAreaByIdWithLocationAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceAreaByIdWithLocationAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceAreaByIdWithLocationAttributesQuery({
 *   variables: {
 *      serviceAreaId: // value for 'serviceAreaId'
 *   },
 * });
 */
export function useServiceAreaByIdWithLocationAttributesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ServiceAreaByIdWithLocationAttributesQuery,
    ServiceAreaByIdWithLocationAttributesQueryVariables
  > &
    (
      | {
          variables: ServiceAreaByIdWithLocationAttributesQueryVariables
          skip?: boolean
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ServiceAreaByIdWithLocationAttributesQuery,
    ServiceAreaByIdWithLocationAttributesQueryVariables
  >(ServiceAreaByIdWithLocationAttributesDocument, options)
}
export function useServiceAreaByIdWithLocationAttributesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ServiceAreaByIdWithLocationAttributesQuery,
    ServiceAreaByIdWithLocationAttributesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ServiceAreaByIdWithLocationAttributesQuery,
    ServiceAreaByIdWithLocationAttributesQueryVariables
  >(ServiceAreaByIdWithLocationAttributesDocument, options)
}
export function useServiceAreaByIdWithLocationAttributesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ServiceAreaByIdWithLocationAttributesQuery,
        ServiceAreaByIdWithLocationAttributesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ServiceAreaByIdWithLocationAttributesQuery,
    ServiceAreaByIdWithLocationAttributesQueryVariables
  >(ServiceAreaByIdWithLocationAttributesDocument, options)
}
export type ServiceAreaByIdWithLocationAttributesQueryHookResult = ReturnType<
  typeof useServiceAreaByIdWithLocationAttributesQuery
>
export type ServiceAreaByIdWithLocationAttributesLazyQueryHookResult =
  ReturnType<typeof useServiceAreaByIdWithLocationAttributesLazyQuery>
export type ServiceAreaByIdWithLocationAttributesSuspenseQueryHookResult =
  ReturnType<typeof useServiceAreaByIdWithLocationAttributesSuspenseQuery>
export type ServiceAreaByIdWithLocationAttributesQueryResult =
  Apollo.QueryResult<
    ServiceAreaByIdWithLocationAttributesQuery,
    ServiceAreaByIdWithLocationAttributesQueryVariables
  >
