/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
export type HubViewVehicleFragmentFragment = {
  __typename?: 'Vehicle'
  id: string
  label: number | null
  state: Types.VehicleState
  engineType: Types.VehicleEngineType | null
  fuelLevel: number | null
  highVoltageBatteryLevel: number | null
  chargingMode: Types.ChargingMode | null
}

export const HubViewVehicleFragmentFragmentDoc = gql`
    fragment HubViewVehicleFragment on Vehicle {
  id
  label
  state
  engineType
  fuelLevel
  highVoltageBatteryLevel
  chargingMode
}
    `
