/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type WorkOrdersTableQueryVariables = Types.Exact<{
  baseHub?: Types.InputMaybe<Types.Scalars['ID']['input']>
  currentLocation?: Types.InputMaybe<Types.Scalars['String']['input']>
  pagination: Types.PaginationInput
  search?: Types.InputMaybe<Types.Scalars['String']['input']>
  serviceAreaUUID: Types.Scalars['ID']['input']
  sorting?: Types.InputMaybe<Types.WorkOrderSorting>
  status?: Types.InputMaybe<Types.WorkOrderStatus>
  vehicleID?: Types.InputMaybe<Types.Scalars['ID']['input']>
  fromDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>
  toDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>
  serviceItem?: Types.InputMaybe<Types.WorkOrderServiceItem>
}>

export type WorkOrdersTableQuery = {
  __typename?: 'Query'
  workOrders: {
    __typename?: 'WorkOrdersQueryResponse'
    total: number
    workOrders: Array<{
      __typename?: 'WorkOrder'
      id: string
      orderNumber: number
      createdAt: string
      status: Types.WorkOrderStatus
      serviceItem: Types.WorkOrderServiceItem | null
      statusChangedAt: string | null
      vehicle: {
        __typename?: 'Vehicle'
        id: string
        label: number | null
        isWheelchairAccessible: boolean | null
        isSelfDriving: boolean
        serviceAreaUUID: string
        baseHub: { __typename?: 'Hub'; displayName: string } | null
        currentlyLocatedInHub: { __typename?: 'Hub'; id: string } | null
      } | null
    }>
  }
}

export const WorkOrdersTableDocument = gql`
    query WorkOrdersTable($baseHub: ID, $currentLocation: String, $pagination: PaginationInput!, $search: String, $serviceAreaUUID: ID!, $sorting: WorkOrderSorting, $status: WorkOrderStatus, $vehicleID: ID, $fromDate: DateTime, $toDate: DateTime, $serviceItem: WorkOrderServiceItem) {
  workOrders(
    input: {baseHub: $baseHub, currentLocation: $currentLocation, pagination: $pagination, search: $search, serviceAreaUUID: $serviceAreaUUID, sorting: $sorting, status: $status, vehicleID: $vehicleID, after: $fromDate, before: $toDate, serviceItem: $serviceItem}
  ) {
    total
    workOrders {
      id
      orderNumber
      createdAt
      status
      serviceItem
      statusChangedAt
      vehicle {
        id
        label
        isWheelchairAccessible
        isSelfDriving
        baseHub {
          displayName
        }
        currentlyLocatedInHub {
          id
        }
        serviceAreaUUID
      }
    }
  }
}
    `

/**
 * __useWorkOrdersTableQuery__
 *
 * To run a query within a React component, call `useWorkOrdersTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkOrdersTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkOrdersTableQuery({
 *   variables: {
 *      baseHub: // value for 'baseHub'
 *      currentLocation: // value for 'currentLocation'
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *      serviceAreaUUID: // value for 'serviceAreaUUID'
 *      sorting: // value for 'sorting'
 *      status: // value for 'status'
 *      vehicleID: // value for 'vehicleID'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      serviceItem: // value for 'serviceItem'
 *   },
 * });
 */
export function useWorkOrdersTableQuery(
  baseOptions: Apollo.QueryHookOptions<
    WorkOrdersTableQuery,
    WorkOrdersTableQueryVariables
  > &
    (
      | { variables: WorkOrdersTableQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<WorkOrdersTableQuery, WorkOrdersTableQueryVariables>(
    WorkOrdersTableDocument,
    options,
  )
}
export function useWorkOrdersTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkOrdersTableQuery,
    WorkOrdersTableQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    WorkOrdersTableQuery,
    WorkOrdersTableQueryVariables
  >(WorkOrdersTableDocument, options)
}
export function useWorkOrdersTableSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        WorkOrdersTableQuery,
        WorkOrdersTableQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    WorkOrdersTableQuery,
    WorkOrdersTableQueryVariables
  >(WorkOrdersTableDocument, options)
}
export type WorkOrdersTableQueryHookResult = ReturnType<
  typeof useWorkOrdersTableQuery
>
export type WorkOrdersTableLazyQueryHookResult = ReturnType<
  typeof useWorkOrdersTableLazyQuery
>
export type WorkOrdersTableSuspenseQueryHookResult = ReturnType<
  typeof useWorkOrdersTableSuspenseQuery
>
export type WorkOrdersTableQueryResult = Apollo.QueryResult<
  WorkOrdersTableQuery,
  WorkOrdersTableQueryVariables
>
