/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import { WaypointAttendanceModalWaypointFragmentDoc } from '../WaypointAttendanceModal.hook'
import { EvaluateCabinSafetyCheckStatusFragmentDoc } from '../../utils/evaluateCabinSafetyCheckStatus/evaluateCabinSafetyCheckStatus.hook'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type UpcomingWaypointsQueryVariables = Types.Exact<{
  vehicleId: Types.Scalars['ID']['input']
}>

export type UpcomingWaypointsQuery = {
  __typename?: 'Query'
  vehicle: {
    __typename?: 'Vehicle'
    id: string
    upcomingWaypoints: Array<{
      __typename?: 'FccWaypoint'
      id: string
      adState: Types.FccWaypointState | null
      actions: Array<
        | {
            __typename: 'FccWaypointDeliveryAction'
            estimatedAt: string
            id: string
            trip: { __typename?: 'Trip'; id: string } | null
            customer: {
              __typename?: 'Customer'
              firstName: string | null
              lastName: string | null
              extendedWalkingTime: boolean
              visualImpairment: boolean | null
            } | null
            seats: {
              __typename?: 'FccWaypointSeatInfo'
              seats: number
              wheelchairs: number
              childSeats: number
              boosterSeats: number
            }
          }
        | { __typename: 'FccWaypointHubReturnAction'; estimatedAt: string }
        | {
            __typename: 'FccWaypointPickupAction'
            adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
            estimatedAt: string
            id: string
            trip: { __typename?: 'Trip'; id: string } | null
            customer: {
              __typename?: 'Customer'
              firstName: string | null
              lastName: string | null
              extendedWalkingTime: boolean
              visualImpairment: boolean | null
            } | null
            seats: {
              __typename?: 'FccWaypointSeatInfo'
              seats: number
              wheelchairs: number
              childSeats: number
              boosterSeats: number
            }
          }
        | { __typename: 'FccWaypointReallocationAction'; estimatedAt: string }
      >
      stop: {
        __typename?: 'Stop'
        routingLocation: {
          __typename?: 'Location'
          latitude: number
          longitude: number
        }
      } | null
    }> | null
  }
}

export const UpcomingWaypointsDocument = gql`
    query UpcomingWaypoints($vehicleId: ID!) {
  vehicle: vehicleByID(id: $vehicleId) {
    id
    upcomingWaypoints: fccUpcomingWaypoints {
      ...WaypointAttendanceModalWaypoint
      ...EvaluateCabinSafetyCheckStatus
    }
  }
}
    ${WaypointAttendanceModalWaypointFragmentDoc}
${EvaluateCabinSafetyCheckStatusFragmentDoc}`

/**
 * __useUpcomingWaypointsQuery__
 *
 * To run a query within a React component, call `useUpcomingWaypointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingWaypointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingWaypointsQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useUpcomingWaypointsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UpcomingWaypointsQuery,
    UpcomingWaypointsQueryVariables
  > &
    (
      | { variables: UpcomingWaypointsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UpcomingWaypointsQuery,
    UpcomingWaypointsQueryVariables
  >(UpcomingWaypointsDocument, options)
}
export function useUpcomingWaypointsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UpcomingWaypointsQuery,
    UpcomingWaypointsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UpcomingWaypointsQuery,
    UpcomingWaypointsQueryVariables
  >(UpcomingWaypointsDocument, options)
}
export function useUpcomingWaypointsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        UpcomingWaypointsQuery,
        UpcomingWaypointsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    UpcomingWaypointsQuery,
    UpcomingWaypointsQueryVariables
  >(UpcomingWaypointsDocument, options)
}
export type UpcomingWaypointsQueryHookResult = ReturnType<
  typeof useUpcomingWaypointsQuery
>
export type UpcomingWaypointsLazyQueryHookResult = ReturnType<
  typeof useUpcomingWaypointsLazyQuery
>
export type UpcomingWaypointsSuspenseQueryHookResult = ReturnType<
  typeof useUpcomingWaypointsSuspenseQuery
>
export type UpcomingWaypointsQueryResult = Apollo.QueryResult<
  UpcomingWaypointsQuery,
  UpcomingWaypointsQueryVariables
>
