/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type RioAttachmentDownloadUrlQueryVariables = Types.Exact<{
  reportId: Types.Scalars['ID']['input']
  attachmentIds:
    | Array<Types.Scalars['String']['input']>
    | Types.Scalars['String']['input']
}>

export type RioAttachmentDownloadUrlQuery = {
  __typename?: 'Query'
  rioAttachmentDownloadUrl: {
    __typename?: 'RioAttachmentDownloadUrlResponse'
    urls: Array<{
      __typename?: 'RioAttachmentDownloadUrl'
      attachmentId: string
      url: string
    }> | null
  }
}

export const RioAttachmentDownloadUrlDocument = gql`
    query RioAttachmentDownloadUrl($reportId: ID!, $attachmentIds: [String!]!) {
  rioAttachmentDownloadUrl(
    input: {reportId: $reportId, attachmentIds: $attachmentIds}
  ) {
    urls {
      attachmentId
      url
    }
  }
}
    `

/**
 * __useRioAttachmentDownloadUrlQuery__
 *
 * To run a query within a React component, call `useRioAttachmentDownloadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useRioAttachmentDownloadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRioAttachmentDownloadUrlQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *      attachmentIds: // value for 'attachmentIds'
 *   },
 * });
 */
export function useRioAttachmentDownloadUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    RioAttachmentDownloadUrlQuery,
    RioAttachmentDownloadUrlQueryVariables
  > &
    (
      | { variables: RioAttachmentDownloadUrlQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    RioAttachmentDownloadUrlQuery,
    RioAttachmentDownloadUrlQueryVariables
  >(RioAttachmentDownloadUrlDocument, options)
}
export function useRioAttachmentDownloadUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RioAttachmentDownloadUrlQuery,
    RioAttachmentDownloadUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    RioAttachmentDownloadUrlQuery,
    RioAttachmentDownloadUrlQueryVariables
  >(RioAttachmentDownloadUrlDocument, options)
}
export function useRioAttachmentDownloadUrlSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RioAttachmentDownloadUrlQuery,
        RioAttachmentDownloadUrlQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    RioAttachmentDownloadUrlQuery,
    RioAttachmentDownloadUrlQueryVariables
  >(RioAttachmentDownloadUrlDocument, options)
}
export type RioAttachmentDownloadUrlQueryHookResult = ReturnType<
  typeof useRioAttachmentDownloadUrlQuery
>
export type RioAttachmentDownloadUrlLazyQueryHookResult = ReturnType<
  typeof useRioAttachmentDownloadUrlLazyQuery
>
export type RioAttachmentDownloadUrlSuspenseQueryHookResult = ReturnType<
  typeof useRioAttachmentDownloadUrlSuspenseQuery
>
export type RioAttachmentDownloadUrlQueryResult = Apollo.QueryResult<
  RioAttachmentDownloadUrlQuery,
  RioAttachmentDownloadUrlQueryVariables
>
