/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type DeactivateVehicleMutationVariables = Types.Exact<{
  input: Types.DeactivateVehicleInput
}>

export type DeactivateVehicleMutation = {
  __typename?: 'Mutation'
  deactivateVehicle: {
    __typename?: 'StateChangeResponse'
    state: Types.VehicleState
    success: boolean
  }
}

export const DeactivateVehicleDocument = gql`
    mutation deactivateVehicle($input: DeactivateVehicleInput!) {
  deactivateVehicle(input: $input) {
    state
    success
  }
}
    `
export type DeactivateVehicleMutationFn = Apollo.MutationFunction<
  DeactivateVehicleMutation,
  DeactivateVehicleMutationVariables
>

/**
 * __useDeactivateVehicleMutation__
 *
 * To run a mutation, you first call `useDeactivateVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateVehicleMutation, { data, loading, error }] = useDeactivateVehicleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeactivateVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeactivateVehicleMutation,
    DeactivateVehicleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeactivateVehicleMutation,
    DeactivateVehicleMutationVariables
  >(DeactivateVehicleDocument, options)
}
export type DeactivateVehicleMutationHookResult = ReturnType<
  typeof useDeactivateVehicleMutation
>
export type DeactivateVehicleMutationResult =
  Apollo.MutationResult<DeactivateVehicleMutation>
export type DeactivateVehicleMutationOptions = Apollo.BaseMutationOptions<
  DeactivateVehicleMutation,
  DeactivateVehicleMutationVariables
>
