/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type ActivateAcMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input']
}>

export type ActivateAcMutation = {
  __typename?: 'Mutation'
  activateClimateControl: {
    __typename?: 'ActivateClimateControlResponse'
    vehicle: {
      __typename?: 'Vehicle'
      climateControlState: Types.ClimateControlState | null
      interiorTemperature: number | null
    }
  }
}

export const ActivateAcDocument = gql`
    mutation activateAC($id: ID!) {
  activateClimateControl(input: {vehicleID: $id}) {
    vehicle {
      climateControlState
      interiorTemperature
    }
  }
}
    `
export type ActivateAcMutationFn = Apollo.MutationFunction<
  ActivateAcMutation,
  ActivateAcMutationVariables
>

/**
 * __useActivateAcMutation__
 *
 * To run a mutation, you first call `useActivateAcMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateAcMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateAcMutation, { data, loading, error }] = useActivateAcMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateAcMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateAcMutation,
    ActivateAcMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ActivateAcMutation, ActivateAcMutationVariables>(
    ActivateAcDocument,
    options,
  )
}
export type ActivateAcMutationHookResult = ReturnType<
  typeof useActivateAcMutation
>
export type ActivateAcMutationResult = Apollo.MutationResult<ActivateAcMutation>
export type ActivateAcMutationOptions = Apollo.BaseMutationOptions<
  ActivateAcMutation,
  ActivateAcMutationVariables
>
