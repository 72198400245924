/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type BackofficeUserFleetsByEmailQueryVariables = Types.Exact<{
  email: Types.Scalars['String']['input']
}>

export type BackofficeUserFleetsByEmailQuery = {
  __typename?: 'Query'
  backofficeUserFleetsByEmail: Array<{
    __typename?: 'BackofficeUserManagementFleetShort'
    id: string
    name: string
  }> | null
}

export const BackofficeUserFleetsByEmailDocument = gql`
    query backofficeUserFleetsByEmail($email: String!) {
  backofficeUserFleetsByEmail(email: $email) {
    id
    name
  }
}
    `

/**
 * __useBackofficeUserFleetsByEmailQuery__
 *
 * To run a query within a React component, call `useBackofficeUserFleetsByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useBackofficeUserFleetsByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBackofficeUserFleetsByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useBackofficeUserFleetsByEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    BackofficeUserFleetsByEmailQuery,
    BackofficeUserFleetsByEmailQueryVariables
  > &
    (
      | { variables: BackofficeUserFleetsByEmailQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    BackofficeUserFleetsByEmailQuery,
    BackofficeUserFleetsByEmailQueryVariables
  >(BackofficeUserFleetsByEmailDocument, options)
}
export function useBackofficeUserFleetsByEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BackofficeUserFleetsByEmailQuery,
    BackofficeUserFleetsByEmailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    BackofficeUserFleetsByEmailQuery,
    BackofficeUserFleetsByEmailQueryVariables
  >(BackofficeUserFleetsByEmailDocument, options)
}
export function useBackofficeUserFleetsByEmailSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BackofficeUserFleetsByEmailQuery,
        BackofficeUserFleetsByEmailQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    BackofficeUserFleetsByEmailQuery,
    BackofficeUserFleetsByEmailQueryVariables
  >(BackofficeUserFleetsByEmailDocument, options)
}
export type BackofficeUserFleetsByEmailQueryHookResult = ReturnType<
  typeof useBackofficeUserFleetsByEmailQuery
>
export type BackofficeUserFleetsByEmailLazyQueryHookResult = ReturnType<
  typeof useBackofficeUserFleetsByEmailLazyQuery
>
export type BackofficeUserFleetsByEmailSuspenseQueryHookResult = ReturnType<
  typeof useBackofficeUserFleetsByEmailSuspenseQuery
>
export type BackofficeUserFleetsByEmailQueryResult = Apollo.QueryResult<
  BackofficeUserFleetsByEmailQuery,
  BackofficeUserFleetsByEmailQueryVariables
>
