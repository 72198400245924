import { useEffect, useState } from 'react'
import { ImageNotFound } from './ImageNotFound'

type ImageViewProps = {
  imageURL: string
}
export const ImageView = ({ imageURL }: ImageViewProps) => {
  const [isBroken, setIsBroken] = useState(false)

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setIsBroken(false)
  }, [imageURL])

  return (
    <>
      {!isBroken ? (
        <img
          css={{
            margin: 'auto auto',
            maxWidth: '100%',
            borderRadius: '2px',
          }}
          alt={imageURL}
          src={imageURL}
          onError={() => setIsBroken(prev => !prev)}
        />
      ) : (
        <ImageNotFound />
      )}
    </>
  )
}
