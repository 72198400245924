/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type VehicleStateChangeDialogQueryVariables = Types.Exact<{
  vehicleId: Types.Scalars['ID']['input']
}>

export type VehicleStateChangeDialogQuery = {
  __typename?: 'Query'
  vehicle: {
    __typename?: 'Vehicle'
    id: string
    state: Types.VehicleState
    currentlyOpen: boolean
    vin: string | null
    label: number | null
    licensePlate: string | null
    lastVehicleStateChange: {
      __typename?: 'VehicleStateChange'
      vehicleStateChangeReason: Types.VehicleStateChangeReason | null
      changeReasonFreeText: string | null
      createdAt: string | null
      expectedActivationAt: string | null
      createdByUser: {
        __typename?: 'BackofficeUser'
        firstName: string
        lastName: string
      } | null
    } | null
  }
}

export const VehicleStateChangeDialogDocument = gql`
    query VehicleStateChangeDialog($vehicleId: ID!) {
  vehicle: vehicleByID(id: $vehicleId) {
    id
    state
    currentlyOpen
    vin
    label
    licensePlate
    lastVehicleStateChange {
      vehicleStateChangeReason
      changeReasonFreeText
      createdAt
      createdByUser {
        firstName
        lastName
      }
      expectedActivationAt
    }
  }
}
    `

/**
 * __useVehicleStateChangeDialogQuery__
 *
 * To run a query within a React component, call `useVehicleStateChangeDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleStateChangeDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleStateChangeDialogQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useVehicleStateChangeDialogQuery(
  baseOptions: Apollo.QueryHookOptions<
    VehicleStateChangeDialogQuery,
    VehicleStateChangeDialogQueryVariables
  > &
    (
      | { variables: VehicleStateChangeDialogQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    VehicleStateChangeDialogQuery,
    VehicleStateChangeDialogQueryVariables
  >(VehicleStateChangeDialogDocument, options)
}
export function useVehicleStateChangeDialogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VehicleStateChangeDialogQuery,
    VehicleStateChangeDialogQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    VehicleStateChangeDialogQuery,
    VehicleStateChangeDialogQueryVariables
  >(VehicleStateChangeDialogDocument, options)
}
export function useVehicleStateChangeDialogSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        VehicleStateChangeDialogQuery,
        VehicleStateChangeDialogQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    VehicleStateChangeDialogQuery,
    VehicleStateChangeDialogQueryVariables
  >(VehicleStateChangeDialogDocument, options)
}
export type VehicleStateChangeDialogQueryHookResult = ReturnType<
  typeof useVehicleStateChangeDialogQuery
>
export type VehicleStateChangeDialogLazyQueryHookResult = ReturnType<
  typeof useVehicleStateChangeDialogLazyQuery
>
export type VehicleStateChangeDialogSuspenseQueryHookResult = ReturnType<
  typeof useVehicleStateChangeDialogSuspenseQuery
>
export type VehicleStateChangeDialogQueryResult = Apollo.QueryResult<
  VehicleStateChangeDialogQuery,
  VehicleStateChangeDialogQueryVariables
>
