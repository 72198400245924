// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts

import { lazy } from 'react'

import { DsBossAreaId, DsBossAreaRoutes } from '@backoffice-frontend/common'

import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { BoumRight } from '@moia-dev/moia-token-claims'

import { DsBossAreaNavigation } from './DsBossAreaNavigation'

const LazyDsBossComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./DsBossArea')),
)

const DsBossComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyDsBossComponent />
  </SuspenseLoadingWrapper>
)

export const DsBossArea = {
  id: DsBossAreaId,
  requiredRight: BoumRight.AREA_DS_BOSS,
  NavigationComponent: DsBossAreaNavigation,
  route: {
    path: DsBossAreaRoutes.route,
    component: DsBossComponent,
  },
}
