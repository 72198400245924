/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import { UpdateShiftBreakFragmentFragmentDoc } from './updateShiftBreakFragment.hook'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type ShiftOverrideTypeMutationVariables = Types.Exact<{
  shiftId: Types.Scalars['ID']['input']
  shiftType: Types.ShiftType
}>

export type ShiftOverrideTypeMutation = {
  __typename?: 'Mutation'
  shiftOverrideType: {
    __typename?: 'ShiftOverrideTypeResponse'
    shift: {
      __typename?: 'Shift'
      id: string
      shiftType: Types.ShiftType
      shiftTypeOverrideDetails: {
        __typename?: 'ShiftTypeOverrideDetails'
        overriddenAt: string
      } | null
      breaks: Array<{
        __typename?: 'ShiftBreak'
        earliestStart: string
        latestEnd: string
        duration: string
      }>
    }
  }
}

export const ShiftOverrideTypeDocument = gql`
    mutation shiftOverrideType($shiftId: ID!, $shiftType: ShiftType!) {
  shiftOverrideType(input: {shiftId: $shiftId, shiftType: $shiftType}) {
    shift {
      id
      shiftType
      shiftTypeOverrideDetails {
        overriddenAt
      }
      breaks {
        ...updateShiftBreakFragment
      }
    }
  }
}
    ${UpdateShiftBreakFragmentFragmentDoc}`
export type ShiftOverrideTypeMutationFn = Apollo.MutationFunction<
  ShiftOverrideTypeMutation,
  ShiftOverrideTypeMutationVariables
>

/**
 * __useShiftOverrideTypeMutation__
 *
 * To run a mutation, you first call `useShiftOverrideTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShiftOverrideTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shiftOverrideTypeMutation, { data, loading, error }] = useShiftOverrideTypeMutation({
 *   variables: {
 *      shiftId: // value for 'shiftId'
 *      shiftType: // value for 'shiftType'
 *   },
 * });
 */
export function useShiftOverrideTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ShiftOverrideTypeMutation,
    ShiftOverrideTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ShiftOverrideTypeMutation,
    ShiftOverrideTypeMutationVariables
  >(ShiftOverrideTypeDocument, options)
}
export type ShiftOverrideTypeMutationHookResult = ReturnType<
  typeof useShiftOverrideTypeMutation
>
export type ShiftOverrideTypeMutationResult =
  Apollo.MutationResult<ShiftOverrideTypeMutation>
export type ShiftOverrideTypeMutationOptions = Apollo.BaseMutationOptions<
  ShiftOverrideTypeMutation,
  ShiftOverrideTypeMutationVariables
>
