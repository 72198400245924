/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import {
  AlertAssignmentIconTaskFragmentDoc,
  AlertAssignmentIconAdAlertFragmentDoc,
} from '../AlertAssignmentIcon.hook'
export type AlertLabelTask_HubServiceNotification_Fragment = {
  __typename?: 'HubServiceNotification'
  id: string
  status: Types.TaskNotificationStatus
  notificationType: Types.TaskNotificationType
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type AlertLabelTask_IvcTaskNotification_Fragment = {
  __typename?: 'IvcTaskNotification'
  id: string
  status: Types.TaskNotificationStatus
  notificationType: Types.TaskNotificationType
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type AlertLabelTask_PassengerAuthentificationFallbackRequestedNotification_Fragment =
  {
    __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
    id: string
    status: Types.TaskNotificationStatus
    notificationType: Types.TaskNotificationType
    assigned: {
      __typename?: 'TaskStateChangeDetails'
      backofficeUserId: string | null
    } | null
  }

export type AlertLabelTask_PassengerUnbuckled_Fragment = {
  __typename?: 'PassengerUnbuckled'
  id: string
  status: Types.TaskNotificationStatus
  notificationType: Types.TaskNotificationType
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type AlertLabelTask_RoadBlockageTaskNotification_Fragment = {
  __typename?: 'RoadBlockageTaskNotification'
  id: string
  status: Types.TaskNotificationStatus
  notificationType: Types.TaskNotificationType
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type AlertLabelTask_ShiftDeviationNotification_Fragment = {
  __typename?: 'ShiftDeviationNotification'
  id: string
  status: Types.TaskNotificationStatus
  notificationType: Types.TaskNotificationType
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type AlertLabelTask_StopAccessibilityDownvotedByDrivers_Fragment = {
  __typename?: 'StopAccessibilityDownvotedByDrivers'
  id: string
  status: Types.TaskNotificationStatus
  notificationType: Types.TaskNotificationType
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type AlertLabelTask_StopActivationFailedNotification_Fragment = {
  __typename?: 'StopActivationFailedNotification'
  id: string
  status: Types.TaskNotificationStatus
  notificationType: Types.TaskNotificationType
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type AlertLabelTask_StopAreaMissingActiveStopsNotification_Fragment = {
  __typename?: 'StopAreaMissingActiveStopsNotification'
  id: string
  status: Types.TaskNotificationStatus
  notificationType: Types.TaskNotificationType
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type AlertLabelTask_StopDownVotedByDrivers_Fragment = {
  __typename?: 'StopDownVotedByDrivers'
  id: string
  status: Types.TaskNotificationStatus
  notificationType: Types.TaskNotificationType
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type AlertLabelTask_StopPotentiallyUnreachable_Fragment = {
  __typename?: 'StopPotentiallyUnreachable'
  id: string
  status: Types.TaskNotificationStatus
  notificationType: Types.TaskNotificationType
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type AlertLabelTask_StopValidationFailed_Fragment = {
  __typename?: 'StopValidationFailed'
  id: string
  status: Types.TaskNotificationStatus
  notificationType: Types.TaskNotificationType
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type AlertLabelTask_TimeManagementNotification_Fragment = {
  __typename?: 'TimeManagementNotification'
  id: string
  status: Types.TaskNotificationStatus
  notificationType: Types.TaskNotificationType
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type AlertLabelTask_TripPrebookingNoAssignment_Fragment = {
  __typename?: 'TripPrebookingNoAssignment'
  id: string
  status: Types.TaskNotificationStatus
  notificationType: Types.TaskNotificationType
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type AlertLabelTask_VehicleAvailabilityNotification_Fragment = {
  __typename?: 'VehicleAvailabilityNotification'
  id: string
  status: Types.TaskNotificationStatus
  notificationType: Types.TaskNotificationType
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type AlertLabelTask_VehicleIncidentNotification_Fragment = {
  __typename?: 'VehicleIncidentNotification'
  id: string
  status: Types.TaskNotificationStatus
  notificationType: Types.TaskNotificationType
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type AlertLabelTask_VehicleSurveillanceNotification_Fragment = {
  __typename?: 'VehicleSurveillanceNotification'
  id: string
  status: Types.TaskNotificationStatus
  notificationType: Types.TaskNotificationType
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type AlertLabelTask_VehicleWaypointAttendance_Fragment = {
  __typename?: 'VehicleWaypointAttendance'
  id: string
  status: Types.TaskNotificationStatus
  notificationType: Types.TaskNotificationType
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type AlertLabelTaskFragment =
  | AlertLabelTask_HubServiceNotification_Fragment
  | AlertLabelTask_IvcTaskNotification_Fragment
  | AlertLabelTask_PassengerAuthentificationFallbackRequestedNotification_Fragment
  | AlertLabelTask_PassengerUnbuckled_Fragment
  | AlertLabelTask_RoadBlockageTaskNotification_Fragment
  | AlertLabelTask_ShiftDeviationNotification_Fragment
  | AlertLabelTask_StopAccessibilityDownvotedByDrivers_Fragment
  | AlertLabelTask_StopActivationFailedNotification_Fragment
  | AlertLabelTask_StopAreaMissingActiveStopsNotification_Fragment
  | AlertLabelTask_StopDownVotedByDrivers_Fragment
  | AlertLabelTask_StopPotentiallyUnreachable_Fragment
  | AlertLabelTask_StopValidationFailed_Fragment
  | AlertLabelTask_TimeManagementNotification_Fragment
  | AlertLabelTask_TripPrebookingNoAssignment_Fragment
  | AlertLabelTask_VehicleAvailabilityNotification_Fragment
  | AlertLabelTask_VehicleIncidentNotification_Fragment
  | AlertLabelTask_VehicleSurveillanceNotification_Fragment
  | AlertLabelTask_VehicleWaypointAttendance_Fragment

export type AlertLabelAdAlertFragment = {
  __typename?: 'FccAdAlert'
  id: string
  status: Types.FccAdAlertStatus
  type: Types.FccAdAlertType
  assignee: { __typename?: 'BackofficeUser'; id: string } | null
}

export const AlertLabelTaskFragmentDoc = gql`
    fragment AlertLabelTask on TaskNotification {
  ...AlertAssignmentIconTask
}
    ${AlertAssignmentIconTaskFragmentDoc}`
export const AlertLabelAdAlertFragmentDoc = gql`
    fragment AlertLabelAdAlert on FccAdAlert {
  ...AlertAssignmentIconAdAlert
}
    ${AlertAssignmentIconAdAlertFragmentDoc}`
