import Popper from '@mui/material/Popper'
import { type ReactNode } from 'react'
import type { FieldArrayRenderProps } from 'react-final-form-arrays'
import { MoiaGrid } from '../../basics/MoiaGrid'
import { MoiaChip } from '../../interactions/MoiaChip/MoiaChip'
import { Divider } from '../../patterns/Divider'
import type { AutocompleteProps } from '../MoiaAutocomplete'
import { MoiaAutocomplete } from '../MoiaAutocomplete'
import { MoiaCheckbox } from '../MoiaCheckbox'
import { MoiaTextField } from '../MoiaTextField/MoiaTextField'

export type DialogPropsAutoComplete<T> = {
  showDialog: boolean
  toggleDialog: () => void
  selectItems?: T[]
  fields: FieldArrayRenderProps<T, HTMLElement>['fields']
}

type ChipsComboBoxProps<T> = {
  label: string
  error?: boolean
  helperText?: string
  required?: boolean
  selectItems?: T[]
  menuItem?: ReactNode
  renderTagLabel?: (item: T) => ReactNode
  renderOptionState?: (item: T) => ReactNode
} & Omit<
  AutocompleteProps<T, true, false, false>,
  'renderTags' | 'PopperComponent' | 'renderInput' | 'multiple'
>

export function ChipsComboBox<T>({
  menuItem,
  label,
  required,
  selectItems,
  error,
  helperText,
  onChange,
  renderTagLabel,
  renderOptionState,
  getOptionLabel = value => `${value}`,
  ...props
}: ChipsComboBoxProps<T>) {
  // biome-ignore lint/suspicious/noExplicitAny: we just want to pass along the event
  const removeTag = (e: any, itemToRemove: T) => {
    const nextValue =
      props?.value?.filter(item => {
        return item !== itemToRemove
      }) ?? []
    onChange?.(e, nextValue, 'removeOption')
  }

  return (
    <MoiaGrid>
      <MoiaAutocomplete
        {...props}
        multiple
        onChange={onChange}
        // do not render any tags inside the input
        renderTags={() => ''}
        getOptionLabel={getOptionLabel}
        renderOption={(props, option, { selected }) => (
          <li
            css={theme => ({
              // default active state should be highlighted via checkbox and not by background color https://www.figma.com/file/Q3zTF0LuJjQJ3QIOALcct1/LIB-BO-%E2%80%93-Core?type=design&node-id=372-24658&mode=design&t=rUD8IhbBRw9Z49p0-0
              '&.MuiAutocomplete-option[aria-selected="true"]': {
                background: `${theme.semantic.ColorSurfaceDefault}`,
              },
              display: 'flex',
              gap: theme.spacing(0.5),
            })}
            {...props}
          >
            <MoiaCheckbox checked={selected} />
            {getOptionLabel(option)}
            {renderOptionState?.(option)}
          </li>
        )}
        renderInput={({ inputProps, ...params }) => (
          <MoiaTextField
            error={error}
            helperText={helperText}
            inputProps={{
              ...inputProps,
            }}
            label={label}
            {...params}
            required={required}
          />
        )}
        PopperComponent={props => {
          return (
            <Popper
              onMouseDown={e => e.preventDefault()}
              {...props}
              css={{
                position: 'relative',
                '& .MuiPaper-root': {
                  marginBottom: '30px',
                },
              }}
            >
              <>
                {props.children}
                {menuItem && (
                  <div
                    css={{
                      position: 'absolute',
                      width: '100%',
                      bottom: '0',
                    }}
                  >
                    <Divider />
                    {menuItem}
                  </div>
                )}
              </>
            </Popper>
          )
        }}
      />
      <div
        css={theme => ({
          display: 'flex',
          flexWrap: 'wrap',
          columnGap: theme.spacing(0.5),
          rowGap: theme.spacing(1),
        })}
      >
        {props?.value?.map(item => (
          <MoiaChip
            onDelete={e => removeTag(e, item)}
            key={getOptionLabel(item)}
            color="secondary"
            label={renderTagLabel?.(item) ?? getOptionLabel(item)}
          />
        ))}
      </div>
    </MoiaGrid>
  )
}
type FormChipsComboBoxProps<Item> = ChipsComboBoxProps<Item> &
  FieldArrayRenderProps<Item, HTMLElement>
export function FormChipsComboBox<T>({
  meta,
  fields,
  ...props
}: FormChipsComboBoxProps<T>) {
  const value = fields.value ? fields.value : []
  return (
    <ChipsComboBox
      {...props}
      value={value}
      onChange={(_, value) => {
        while (fields.pop()) {
          // Empty the field values
        }
        if (Array.isArray(value)) {
          value?.forEach(item => {
            fields.push(item)
          })
        } else if (value !== null) {
          // `value` can be of type T
          fields.push(value as T)
        }
      }}
    />
  )
}
