/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import { GetPassengersWaypointFragmentDoc } from '../utils/passenger-filters.hook'
export type AdOnBoardPassengersVehicleFragment = {
  __typename?: 'Vehicle'
  id: string
  fccUpcomingWaypoints: Array<{
    __typename?: 'FccWaypoint'
    actions: Array<
      | {
          __typename: 'FccWaypointDeliveryAction'
          id: string
          estimatedAt: string
          trip: { __typename?: 'Trip'; id: string } | null
          customer: {
            __typename?: 'Customer'
            firstName: string | null
            lastName: string | null
            extendedWalkingTime: boolean
            visualImpairment: boolean | null
          } | null
          seats: {
            __typename?: 'FccWaypointSeatInfo'
            seats: number
            wheelchairs: number
            childSeats: number
            boosterSeats: number
          }
        }
      | { __typename?: 'FccWaypointHubReturnAction' }
      | {
          __typename: 'FccWaypointPickupAction'
          id: string
          estimatedAt: string
          adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
          trip: { __typename?: 'Trip'; id: string } | null
          customer: {
            __typename?: 'Customer'
            firstName: string | null
            lastName: string | null
            extendedWalkingTime: boolean
            visualImpairment: boolean | null
          } | null
          seats: {
            __typename?: 'FccWaypointSeatInfo'
            seats: number
            wheelchairs: number
            childSeats: number
            boosterSeats: number
          }
        }
      | { __typename?: 'FccWaypointReallocationAction' }
    >
  }> | null
}

export const AdOnBoardPassengersVehicleFragmentDoc = gql`
    fragment AdOnBoardPassengersVehicle on Vehicle {
  id
  fccUpcomingWaypoints {
    ...GetPassengersWaypoint
  }
}
    ${GetPassengersWaypointFragmentDoc}`
