/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type DeleteVehicleGroupMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input']
}>

export type DeleteVehicleGroupMutation = {
  __typename?: 'Mutation'
  deleteVehicleGroup: boolean
}

export const DeleteVehicleGroupDocument = gql`
    mutation deleteVehicleGroup($id: ID!) {
  deleteVehicleGroup(id: $id)
}
    `
export type DeleteVehicleGroupMutationFn = Apollo.MutationFunction<
  DeleteVehicleGroupMutation,
  DeleteVehicleGroupMutationVariables
>

/**
 * __useDeleteVehicleGroupMutation__
 *
 * To run a mutation, you first call `useDeleteVehicleGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVehicleGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVehicleGroupMutation, { data, loading, error }] = useDeleteVehicleGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVehicleGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteVehicleGroupMutation,
    DeleteVehicleGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteVehicleGroupMutation,
    DeleteVehicleGroupMutationVariables
  >(DeleteVehicleGroupDocument, options)
}
export type DeleteVehicleGroupMutationHookResult = ReturnType<
  typeof useDeleteVehicleGroupMutation
>
export type DeleteVehicleGroupMutationResult =
  Apollo.MutationResult<DeleteVehicleGroupMutation>
export type DeleteVehicleGroupMutationOptions = Apollo.BaseMutationOptions<
  DeleteVehicleGroupMutation,
  DeleteVehicleGroupMutationVariables
>
