/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type BackofficeUpdateCreateMutationVariables = Types.Exact<{
  input: Types.BackofficeUpdateCreate
}>

export type BackofficeUpdateCreateMutation = {
  __typename?: 'Mutation'
  backofficeUpdateCreate: {
    __typename?: 'BackofficeUpdate'
    id: string
    titleDe: string
    titleEn: string
    type: Types.BackofficeUpdateType
    notesDe: string
    notesEn: string
    images: Array<string | null> | null
    videoDe: string | null
    videoEn: string | null
  }
}

export const BackofficeUpdateCreateDocument = gql`
    mutation backofficeUpdateCreate($input: BackofficeUpdateCreate!) {
  backofficeUpdateCreate(input: $input) {
    id
    titleDe
    titleEn
    type
    notesDe
    notesEn
    images
    videoDe
    videoEn
  }
}
    `
export type BackofficeUpdateCreateMutationFn = Apollo.MutationFunction<
  BackofficeUpdateCreateMutation,
  BackofficeUpdateCreateMutationVariables
>

/**
 * __useBackofficeUpdateCreateMutation__
 *
 * To run a mutation, you first call `useBackofficeUpdateCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackofficeUpdateCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backofficeUpdateCreateMutation, { data, loading, error }] = useBackofficeUpdateCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBackofficeUpdateCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BackofficeUpdateCreateMutation,
    BackofficeUpdateCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BackofficeUpdateCreateMutation,
    BackofficeUpdateCreateMutationVariables
  >(BackofficeUpdateCreateDocument, options)
}
export type BackofficeUpdateCreateMutationHookResult = ReturnType<
  typeof useBackofficeUpdateCreateMutation
>
export type BackofficeUpdateCreateMutationResult =
  Apollo.MutationResult<BackofficeUpdateCreateMutation>
export type BackofficeUpdateCreateMutationOptions = Apollo.BaseMutationOptions<
  BackofficeUpdateCreateMutation,
  BackofficeUpdateCreateMutationVariables
>
