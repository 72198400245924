/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type BackofficeUpdateUpdateMutationVariables = Types.Exact<{
  input: Types.BackofficeUpdateUpdate
}>

export type BackofficeUpdateUpdateMutation = {
  __typename?: 'Mutation'
  backofficeUpdateUpdate: {
    __typename?: 'BackofficeUpdate'
    id: string
    creationDate: string
    rights: Array<string | null>
    titleDe: string
    titleEn: string
    type: Types.BackofficeUpdateType
    notesDe: string
    notesEn: string
    images: Array<string | null> | null
    videoDe: string | null
    videoEn: string | null
  }
}

export const BackofficeUpdateUpdateDocument = gql`
    mutation backofficeUpdateUpdate($input: BackofficeUpdateUpdate!) {
  backofficeUpdateUpdate(input: $input) {
    id
    creationDate
    rights
    titleDe
    titleEn
    type
    notesDe
    notesEn
    images
    videoDe
    videoEn
  }
}
    `
export type BackofficeUpdateUpdateMutationFn = Apollo.MutationFunction<
  BackofficeUpdateUpdateMutation,
  BackofficeUpdateUpdateMutationVariables
>

/**
 * __useBackofficeUpdateUpdateMutation__
 *
 * To run a mutation, you first call `useBackofficeUpdateUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackofficeUpdateUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backofficeUpdateUpdateMutation, { data, loading, error }] = useBackofficeUpdateUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBackofficeUpdateUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BackofficeUpdateUpdateMutation,
    BackofficeUpdateUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BackofficeUpdateUpdateMutation,
    BackofficeUpdateUpdateMutationVariables
  >(BackofficeUpdateUpdateDocument, options)
}
export type BackofficeUpdateUpdateMutationHookResult = ReturnType<
  typeof useBackofficeUpdateUpdateMutation
>
export type BackofficeUpdateUpdateMutationResult =
  Apollo.MutationResult<BackofficeUpdateUpdateMutation>
export type BackofficeUpdateUpdateMutationOptions = Apollo.BaseMutationOptions<
  BackofficeUpdateUpdateMutation,
  BackofficeUpdateUpdateMutationVariables
>
