// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts

import { lazy } from 'react'

import { PricingAreaId, PricingAreaRoutes } from '@backoffice-frontend/common'

import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { BoumRight } from '@moia-dev/moia-token-claims'

import { PricingAreaNavigation } from './PricingAreaNavigation'

const LazyPricingComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./PricingArea')),
)

const PricingComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyPricingComponent />
  </SuspenseLoadingWrapper>
)

export const PricingArea = {
  id: PricingAreaId,
  requiredRight: BoumRight.AREA_PRICING,
  NavigationComponent: PricingAreaNavigation,
  route: {
    path: PricingAreaRoutes.route,
    component: PricingComponent,
  },
}
