import type { TFunction } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import { Language } from '@backoffice-frontend/common'
import { BackofficeStatusType } from '@backoffice-frontend/graphql'

export const useGetTranslatedType = (type?: BackofficeStatusType) => {
  const { t } = useTranslation()
  return getTranslatedType(t, type)
}

export const getTranslatedType = (
  t: TFunction,
  type?: BackofficeStatusType,
) => {
  switch (type) {
    case BackofficeStatusType.Open:
      return t('status_statusTypeOpen')
    case BackofficeStatusType.InReview:
      return t('status_statusTypeInReview')
    case BackofficeStatusType.Resolved:
      return t('status_statusTypeResolved')
    default:
      return type ?? ''
  }
}

export const useGetTranslatedText = ({
  de,
  en,
}: {
  de: string
  en: string
}) => {
  const { i18n } = useTranslation()

  return i18n.language === Language.de ? de : en
}

export const TranslatedType = ({
  type,
}: {
  type: BackofficeStatusType | undefined
}) => {
  const translatedType = useGetTranslatedType(type)
  return <>{translatedType}</>
}
