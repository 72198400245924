import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button, LoadingIndicator } from '@moia-dev/pace-web'
import { FormTextfield } from '@backoffice-frontend/forms'
import { MoiaGrid, useTheme } from '@backoffice-frontend/patterns'
import { usePaceValidators } from '@backoffice-frontend/validators'

export type ForgotPasswordValues = {
  email: string
}
export const ForgotPasswordForm = ({
  onSubmit,
  isMobile,
}: {
  onSubmit: (values: ForgotPasswordValues) => Promise<void>
  isMobile: boolean
}) => {
  const { t } = useTranslation()
  const { handleSubmit, control, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
    },
  })
  const { required } = usePaceValidators()
  const theme = useTheme()
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ marginTop: isMobile ? theme.space.Space2 : theme.space.Space6 }}
    >
      <FormTextfield
        control={control}
        type="email"
        name="email"
        label={t('loginView_forgotPasswordEmail_label')}
        rules={{ required }}
        forceLowerCase
        size={isMobile ? 'compact' : 'default'}
      />
      <MoiaGrid
        justifyContent="end"
        gridTemplateColumns="max-content max-content"
        gridGap={1}
        mt={isMobile ? 1 : 3}
      >
        <Button
          label={t('common_back_button')}
          variant="secondary"
          size="compact"
          onClick={() => window.history.back()}
        />
        <Button
          disabled={!formState.isValid || formState.isSubmitting}
          label={t('loginView_forgotPasswordSendCode_button')}
          type="submit"
          variant="primary"
          size="compact"
          icon={formState.isSubmitting ? <LoadingIndicator /> : undefined}
        />
      </MoiaGrid>
    </form>
  )
}
