/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
export type EvaluateCabinSafetyCheckStatusFragment = {
  __typename?: 'FccWaypoint'
  id: string
  adState: Types.FccWaypointState | null
  actions: Array<
    | { __typename: 'FccWaypointDeliveryAction' }
    | { __typename: 'FccWaypointHubReturnAction' }
    | {
        __typename: 'FccWaypointPickupAction'
        adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
      }
    | { __typename: 'FccWaypointReallocationAction' }
  >
}

export const EvaluateCabinSafetyCheckStatusFragmentDoc = gql`
    fragment EvaluateCabinSafetyCheckStatus on FccWaypoint {
  id
  adState
  actions {
    __typename
    ... on FccWaypointPickupAction {
      adPassengerAuthenticationState
    }
  }
}
    `
