/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type PaginatedTripsByVehicleIdSortedByPickupQueryVariables =
  Types.Exact<{
    id: Types.Scalars['ID']['input']
    dateTimeInterval?: Types.InputMaybe<Types.DateTimeIntervalInput>
    first?: Types.InputMaybe<Types.Scalars['Int']['input']>
    after?: Types.InputMaybe<Types.Scalars['String']['input']>
  }>

export type PaginatedTripsByVehicleIdSortedByPickupQuery = {
  __typename?: 'Query'
  paginatedTripsByVehicleIdSortedByPickup: {
    __typename?: 'TripConnection'
    pageInfo: {
      __typename?: 'PageInfo'
      endCursor: string | null
      hasNextPage: boolean
    }
    edges: Array<{
      __typename?: 'TripEdge'
      cursor: string
      node: {
        __typename?: 'Trip'
        id: string
        state: Types.TripExecutionStatus
        tenant: any
        customerId: string
        orderTime: string | null
        createdAt: string
        updatedAt: string
        pickedUpAt: string | null
        earliestPickupTimeAtCreation: string | null
        deliveredAt: string | null
        fixedVehicleId: string | null
        serviceAreaId: string
        express: boolean
        drivingSystem: Types.TripDrivingSystem
        prebooked: boolean
        adults: number
        children: number
        customer: {
          __typename?: 'Customer'
          firstName: string | null
          lastName: string | null
        } | null
        schedule: {
          __typename?: 'TripSchedule'
          pickup: string
          delivery: string
          pickupPunctuality: Types.TripPunctuality
          deliveryPunctuality: Types.TripPunctuality
        } | null
        serviceArea: {
          __typename?: 'ServiceArea'
          displayNames: Partial<Record<'de' | 'en', string>>
        } | null
        origin: { __typename?: 'TripNamedLocation'; primaryAddress: string }
        destination: {
          __typename?: 'TripNamedLocation'
          primaryAddress: string
        }
        pickupStop: {
          __typename?: 'TripStop'
          stopId: string
          stop: {
            __typename?: 'Stop'
            names: Partial<Record<'de' | 'en', string>>
            address: {
              __typename?: 'StopAddress'
              city: string | null
              houseNumber: string | null
              postalCode: string | null
              street: string | null
            }
            routingLocation: {
              __typename?: 'Location'
              latitude: number
              longitude: number
            }
          } | null
        } | null
        deliveryStop: {
          __typename?: 'TripStop'
          stopId: string
          stop: {
            __typename?: 'Stop'
            names: Partial<Record<'de' | 'en', string>>
            address: {
              __typename?: 'StopAddress'
              city: string | null
              houseNumber: string | null
              postalCode: string | null
              street: string | null
            }
            routingLocation: {
              __typename?: 'Location'
              latitude: number
              longitude: number
            }
          } | null
        } | null
      }
    }>
  } | null
}

export const PaginatedTripsByVehicleIdSortedByPickupDocument = gql`
    query paginatedTripsByVehicleIdSortedByPickup($id: ID!, $dateTimeInterval: DateTimeIntervalInput, $first: Int, $after: String) {
  paginatedTripsByVehicleIdSortedByPickup(
    id: $id
    dateTimeInterval: $dateTimeInterval
    first: $first
    after: $after
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        state
        tenant
        customerId
        customer {
          firstName
          lastName
        }
        orderTime
        createdAt
        updatedAt
        pickedUpAt
        earliestPickupTimeAtCreation
        deliveredAt
        fixedVehicleId
        schedule {
          pickup
          delivery
          pickupPunctuality
          deliveryPunctuality
        }
        serviceAreaId
        serviceArea {
          displayNames
        }
        express
        drivingSystem
        prebooked
        origin {
          primaryAddress
        }
        destination {
          primaryAddress
        }
        pickupStop {
          stop {
            address {
              city
              houseNumber
              postalCode
              street
            }
            routingLocation {
              latitude
              longitude
            }
            names
          }
          stopId
        }
        deliveryStop {
          stop {
            address {
              city
              houseNumber
              postalCode
              street
            }
            routingLocation {
              latitude
              longitude
            }
            names
          }
          stopId
        }
        adults
        children
      }
      cursor
    }
  }
}
    `

/**
 * __usePaginatedTripsByVehicleIdSortedByPickupQuery__
 *
 * To run a query within a React component, call `usePaginatedTripsByVehicleIdSortedByPickupQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedTripsByVehicleIdSortedByPickupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedTripsByVehicleIdSortedByPickupQuery({
 *   variables: {
 *      id: // value for 'id'
 *      dateTimeInterval: // value for 'dateTimeInterval'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function usePaginatedTripsByVehicleIdSortedByPickupQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaginatedTripsByVehicleIdSortedByPickupQuery,
    PaginatedTripsByVehicleIdSortedByPickupQueryVariables
  > &
    (
      | {
          variables: PaginatedTripsByVehicleIdSortedByPickupQueryVariables
          skip?: boolean
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    PaginatedTripsByVehicleIdSortedByPickupQuery,
    PaginatedTripsByVehicleIdSortedByPickupQueryVariables
  >(PaginatedTripsByVehicleIdSortedByPickupDocument, options)
}
export function usePaginatedTripsByVehicleIdSortedByPickupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedTripsByVehicleIdSortedByPickupQuery,
    PaginatedTripsByVehicleIdSortedByPickupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PaginatedTripsByVehicleIdSortedByPickupQuery,
    PaginatedTripsByVehicleIdSortedByPickupQueryVariables
  >(PaginatedTripsByVehicleIdSortedByPickupDocument, options)
}
export function usePaginatedTripsByVehicleIdSortedByPickupSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PaginatedTripsByVehicleIdSortedByPickupQuery,
        PaginatedTripsByVehicleIdSortedByPickupQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    PaginatedTripsByVehicleIdSortedByPickupQuery,
    PaginatedTripsByVehicleIdSortedByPickupQueryVariables
  >(PaginatedTripsByVehicleIdSortedByPickupDocument, options)
}
export type PaginatedTripsByVehicleIdSortedByPickupQueryHookResult = ReturnType<
  typeof usePaginatedTripsByVehicleIdSortedByPickupQuery
>
export type PaginatedTripsByVehicleIdSortedByPickupLazyQueryHookResult =
  ReturnType<typeof usePaginatedTripsByVehicleIdSortedByPickupLazyQuery>
export type PaginatedTripsByVehicleIdSortedByPickupSuspenseQueryHookResult =
  ReturnType<typeof usePaginatedTripsByVehicleIdSortedByPickupSuspenseQuery>
export type PaginatedTripsByVehicleIdSortedByPickupQueryResult =
  Apollo.QueryResult<
    PaginatedTripsByVehicleIdSortedByPickupQuery,
    PaginatedTripsByVehicleIdSortedByPickupQueryVariables
  >
