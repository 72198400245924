// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts

import { lazy } from 'react'

import {
  SafetyDriverGuidanceAreaId,
  SafetyDriverGuidanceAreaRoutes,
} from '@backoffice-frontend/common'

import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { BoumRight } from '@moia-dev/moia-token-claims'

import { SafetyDriverGuidanceAreaNavigation } from './SafetyDriverGuidanceAreaNavigation'

const LazySafetyDriverGuidanceComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./SafetyDriverGuidanceArea')),
)

const SafetyDriverGuidanceComponent = () => (
  <SuspenseLoadingWrapper>
    <LazySafetyDriverGuidanceComponent />
  </SuspenseLoadingWrapper>
)

export const SafetyDriverGuidanceArea = {
  id: SafetyDriverGuidanceAreaId,
  requiredRight: BoumRight.AREA_SAFETY_DRIVER_INTERFACE,
  NavigationComponent: SafetyDriverGuidanceAreaNavigation,
  route: {
    path: SafetyDriverGuidanceAreaRoutes.route,
    component: SafetyDriverGuidanceComponent,
  },
}
