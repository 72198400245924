/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type FlashVehicleMutationVariables = Types.Exact<{
  vehicleID: Types.Scalars['ID']['input']
}>

export type FlashVehicleMutation = {
  __typename?: 'Mutation'
  flashVehicle: boolean
}

export const FlashVehicleDocument = gql`
    mutation flashVehicle($vehicleID: ID!) {
  flashVehicle(vehicleID: $vehicleID)
}
    `
export type FlashVehicleMutationFn = Apollo.MutationFunction<
  FlashVehicleMutation,
  FlashVehicleMutationVariables
>

/**
 * __useFlashVehicleMutation__
 *
 * To run a mutation, you first call `useFlashVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFlashVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [flashVehicleMutation, { data, loading, error }] = useFlashVehicleMutation({
 *   variables: {
 *      vehicleID: // value for 'vehicleID'
 *   },
 * });
 */
export function useFlashVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FlashVehicleMutation,
    FlashVehicleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    FlashVehicleMutation,
    FlashVehicleMutationVariables
  >(FlashVehicleDocument, options)
}
export type FlashVehicleMutationHookResult = ReturnType<
  typeof useFlashVehicleMutation
>
export type FlashVehicleMutationResult =
  Apollo.MutationResult<FlashVehicleMutation>
export type FlashVehicleMutationOptions = Apollo.BaseMutationOptions<
  FlashVehicleMutation,
  FlashVehicleMutationVariables
>
