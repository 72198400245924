// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts

import { lazy } from 'react'

import {
  CustomerFeatureAllowlistingAreaId,
  CustomerFeatureAllowlistingAreaRoutes,
} from '@backoffice-frontend/common'

import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { BoumRight } from '@moia-dev/moia-token-claims'

import { CustomerFeatureAllowlistingAreaNavigation } from './CustomerFeatureAllowlistingAreaNavigation'

const LazyCustomerFeatureAllowlistingComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./CustomerFeatureAllowlistingArea')),
)

const CustomerFeatureAllowlistingComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyCustomerFeatureAllowlistingComponent />
  </SuspenseLoadingWrapper>
)

export const CustomerFeatureAllowlistingArea = {
  id: CustomerFeatureAllowlistingAreaId,
  requiredRight: BoumRight.AREA_CUSTOMER_FEATURE_ALLOWLISTING,
  NavigationComponent: CustomerFeatureAllowlistingAreaNavigation,
  route: {
    path: CustomerFeatureAllowlistingAreaRoutes.route,
    component: CustomerFeatureAllowlistingComponent,
  },
}
