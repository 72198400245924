/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type BackofficeStatusesQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type BackofficeStatusesQuery = {
  __typename?: 'Query'
  backofficeStatuses: {
    __typename?: 'BackofficeStatusesResponse'
    total: number
    statuses: Array<{
      __typename?: 'BackofficeStatus'
      id: string
      creationDate: string
      type: Types.BackofficeStatusType
      title: string
    } | null>
  }
}

export const BackofficeStatusesDocument = gql`
    query backofficeStatuses {
  backofficeStatuses {
    total
    statuses {
      id
      creationDate
      type
      title
    }
  }
}
    `

/**
 * __useBackofficeStatusesQuery__
 *
 * To run a query within a React component, call `useBackofficeStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBackofficeStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBackofficeStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useBackofficeStatusesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BackofficeStatusesQuery,
    BackofficeStatusesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    BackofficeStatusesQuery,
    BackofficeStatusesQueryVariables
  >(BackofficeStatusesDocument, options)
}
export function useBackofficeStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BackofficeStatusesQuery,
    BackofficeStatusesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    BackofficeStatusesQuery,
    BackofficeStatusesQueryVariables
  >(BackofficeStatusesDocument, options)
}
export function useBackofficeStatusesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BackofficeStatusesQuery,
        BackofficeStatusesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    BackofficeStatusesQuery,
    BackofficeStatusesQueryVariables
  >(BackofficeStatusesDocument, options)
}
export type BackofficeStatusesQueryHookResult = ReturnType<
  typeof useBackofficeStatusesQuery
>
export type BackofficeStatusesLazyQueryHookResult = ReturnType<
  typeof useBackofficeStatusesLazyQuery
>
export type BackofficeStatusesSuspenseQueryHookResult = ReturnType<
  typeof useBackofficeStatusesSuspenseQuery
>
export type BackofficeStatusesQueryResult = Apollo.QueryResult<
  BackofficeStatusesQuery,
  BackofficeStatusesQueryVariables
>
