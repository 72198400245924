/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type TakeOverOpenAdAlertMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input']
}>

export type TakeOverOpenAdAlertMutation = {
  __typename?: 'Mutation'
  takeOverOpenAlert: { __typename?: 'FccAdAlert'; id: string }
}

export const TakeOverOpenAdAlertDocument = gql`
    mutation takeOverOpenAdAlert($id: ID!) {
  takeOverOpenAlert(id: $id) {
    id
  }
}
    `
export type TakeOverOpenAdAlertMutationFn = Apollo.MutationFunction<
  TakeOverOpenAdAlertMutation,
  TakeOverOpenAdAlertMutationVariables
>

/**
 * __useTakeOverOpenAdAlertMutation__
 *
 * To run a mutation, you first call `useTakeOverOpenAdAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTakeOverOpenAdAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [takeOverOpenAdAlertMutation, { data, loading, error }] = useTakeOverOpenAdAlertMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTakeOverOpenAdAlertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TakeOverOpenAdAlertMutation,
    TakeOverOpenAdAlertMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TakeOverOpenAdAlertMutation,
    TakeOverOpenAdAlertMutationVariables
  >(TakeOverOpenAdAlertDocument, options)
}
export type TakeOverOpenAdAlertMutationHookResult = ReturnType<
  typeof useTakeOverOpenAdAlertMutation
>
export type TakeOverOpenAdAlertMutationResult =
  Apollo.MutationResult<TakeOverOpenAdAlertMutation>
export type TakeOverOpenAdAlertMutationOptions = Apollo.BaseMutationOptions<
  TakeOverOpenAdAlertMutation,
  TakeOverOpenAdAlertMutationVariables
>
