/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type BackofficeUpdatesByRightsQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type BackofficeUpdatesByRightsQuery = {
  __typename?: 'Query'
  backofficeUpdatesByRights: {
    __typename?: 'BackofficeUpdateByRightsResponse'
    total: number
    updates: Array<{
      __typename?: 'BackofficeUpdate'
      id: string
      creationDate: string
      type: Types.BackofficeUpdateType
      titleDe: string
      titleEn: string
      images: Array<string | null> | null
      videoEn: string | null
      videoDe: string | null
    } | null>
  }
}

export const BackofficeUpdatesByRightsDocument = gql`
    query backofficeUpdatesByRights {
  backofficeUpdatesByRights {
    total
    updates {
      id
      creationDate
      type
      titleDe
      titleEn
      images
      videoEn
      videoDe
    }
  }
}
    `

/**
 * __useBackofficeUpdatesByRightsQuery__
 *
 * To run a query within a React component, call `useBackofficeUpdatesByRightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBackofficeUpdatesByRightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBackofficeUpdatesByRightsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBackofficeUpdatesByRightsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BackofficeUpdatesByRightsQuery,
    BackofficeUpdatesByRightsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    BackofficeUpdatesByRightsQuery,
    BackofficeUpdatesByRightsQueryVariables
  >(BackofficeUpdatesByRightsDocument, options)
}
export function useBackofficeUpdatesByRightsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BackofficeUpdatesByRightsQuery,
    BackofficeUpdatesByRightsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    BackofficeUpdatesByRightsQuery,
    BackofficeUpdatesByRightsQueryVariables
  >(BackofficeUpdatesByRightsDocument, options)
}
export function useBackofficeUpdatesByRightsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BackofficeUpdatesByRightsQuery,
        BackofficeUpdatesByRightsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    BackofficeUpdatesByRightsQuery,
    BackofficeUpdatesByRightsQueryVariables
  >(BackofficeUpdatesByRightsDocument, options)
}
export type BackofficeUpdatesByRightsQueryHookResult = ReturnType<
  typeof useBackofficeUpdatesByRightsQuery
>
export type BackofficeUpdatesByRightsLazyQueryHookResult = ReturnType<
  typeof useBackofficeUpdatesByRightsLazyQuery
>
export type BackofficeUpdatesByRightsSuspenseQueryHookResult = ReturnType<
  typeof useBackofficeUpdatesByRightsSuspenseQuery
>
export type BackofficeUpdatesByRightsQueryResult = Apollo.QueryResult<
  BackofficeUpdatesByRightsQuery,
  BackofficeUpdatesByRightsQueryVariables
>
