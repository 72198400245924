import { alpha } from '@mui/material'
import clsx from 'clsx'
import * as React from 'react'
import type { Theme } from '../basics/theme'
import type { MoiaButtonProps } from '../inputs/MoiaButton'
import { ButtonHoverStyles, MoiaButton } from '../inputs/MoiaButton'
import type { MoiaLinkProps } from '../interactions/MoiaLink'
import { MoiaLink } from '../interactions/MoiaLink'

export const revealOnHover = 'revealOnHover'

export const tableActionRowStyle = (theme: Theme) =>
  ({
    cursor: 'default',
    '& .revealOnHover': {
      opacity: 0,
    },
    '& .hideOnHover': {
      display: 'block',
    },
    '&:hover': {
      '& .revealOnHover': {
        opacity: 1,
      },
      '& .hideOnHover': {
        display: 'none',
      },
      '& .tableAction': {
        ...ButtonHoverStyles,
      },
      '& .tableDropdown': {
        '& .MuiSvgIcon-root': {
          opacity: 1,
        },
      },
      '& td': {
        backgroundColor: alpha(theme.semantic.ColorSurfaceHovered, 0.5),
      },
    },
  }) as const

export const contentSizeCellStyle = {}

type TableActionProps = {
  leading?: React.ReactNode
  trailing?: React.ReactNode
  disabled?: boolean
}

export type MoiaTableLinkProps = TableActionProps &
  MoiaLinkProps & {
    // biome-ignore lint/suspicious/noExplicitAny: causing typeissues in BaseGoogleMapsCoordinatesLink which is doing way to many things I'd try to get split that component and use a simple voidfunction here
    onClick?(e: any): void
  }

export function MoiaTableLink({
  children,
  leading,
  trailing,
  ...props
}: MoiaTableLinkProps) {
  return (
    <MoiaLink bold noUnderline icon={leading && leading} {...props}>
      {children && (
        <span
          css={theme => ({
            display: 'grid',
            gridAutoFlow: 'column',
            columnGap: theme.spacing(1),
            alignItems: 'center',
            alignContent: 'center',
            paddingRight: trailing ? '4px' : undefined,
            whiteSpace: 'nowrap',
          })}
        >
          {children}
        </span>
      )}

      {trailing && (
        <span
          css={{
            '& svg': {
              width: '20px',
            },
          }}
        >
          {trailing}
        </span>
      )}
    </MoiaLink>
  )
}

export const MoiaTableButton = ({
  className,
  color,
  ...props
}: MoiaButtonProps) => {
  return (
    <MoiaButton
      {...props}
      // https://moiafamily.slack.com/archives/CSQLEH17E/p1673530372848339
      color={color === 'secondary' ? undefined : color}
      variant="inline"
      className={clsx(className, 'tableAction')}
    />
  )
}
