import { transformRoutes } from '@backoffice-frontend/common'

const baseRoute = '/trips'

export const BaseTripAreaRoutes = {
  route: baseRoute,
  Display: {
    route: '/:tripId',
  },
}

export const TripAreaRoutes = transformRoutes(BaseTripAreaRoutes)
