/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type RioAddressFromLocationQueryVariables = Types.Exact<{
  latitude: Types.Scalars['Float']['input']
  longitude: Types.Scalars['Float']['input']
  language: Types.RioLanguage
}>

export type RioAddressFromLocationQuery = {
  __typename?: 'Query'
  rioAddressFromLocation: {
    __typename?: 'RioAddressFromLocationResponse'
    address: string
  }
}

export const RioAddressFromLocationDocument = gql`
    query RioAddressFromLocation($latitude: Float!, $longitude: Float!, $language: RioLanguage!) {
  rioAddressFromLocation(
    input: {latitude: $latitude, longitude: $longitude, language: $language}
  ) {
    address
  }
}
    `

/**
 * __useRioAddressFromLocationQuery__
 *
 * To run a query within a React component, call `useRioAddressFromLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useRioAddressFromLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRioAddressFromLocationQuery({
 *   variables: {
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useRioAddressFromLocationQuery(
  baseOptions: Apollo.QueryHookOptions<
    RioAddressFromLocationQuery,
    RioAddressFromLocationQueryVariables
  > &
    (
      | { variables: RioAddressFromLocationQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    RioAddressFromLocationQuery,
    RioAddressFromLocationQueryVariables
  >(RioAddressFromLocationDocument, options)
}
export function useRioAddressFromLocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RioAddressFromLocationQuery,
    RioAddressFromLocationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    RioAddressFromLocationQuery,
    RioAddressFromLocationQueryVariables
  >(RioAddressFromLocationDocument, options)
}
export function useRioAddressFromLocationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RioAddressFromLocationQuery,
        RioAddressFromLocationQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    RioAddressFromLocationQuery,
    RioAddressFromLocationQueryVariables
  >(RioAddressFromLocationDocument, options)
}
export type RioAddressFromLocationQueryHookResult = ReturnType<
  typeof useRioAddressFromLocationQuery
>
export type RioAddressFromLocationLazyQueryHookResult = ReturnType<
  typeof useRioAddressFromLocationLazyQuery
>
export type RioAddressFromLocationSuspenseQueryHookResult = ReturnType<
  typeof useRioAddressFromLocationSuspenseQuery
>
export type RioAddressFromLocationQueryResult = Apollo.QueryResult<
  RioAddressFromLocationQuery,
  RioAddressFromLocationQueryVariables
>
