import type H from '@here/maps-api-for-javascript'
import { useMemo } from 'react'
import type { Location } from '@backoffice-frontend/graphql'

/**
 * parseLocation is a helper function that turns a `Location` into a `H.geo.IPoint`.
 * It helps as a translation layer between the GraphQL `Location` type and the HERE Maps `H.geo.IPoint` type.
 */
const parseLocation = (location: Location): H.geo.IPoint => ({
  lat: location.latitude,
  lng: location.longitude,
})

/**
 * useHereLocation is a low-level hook that turns a `Location` into a memoized `H.geo.IPoint`.
 * The memoization is important because it ensures the map only updates when the location actually changes.
 * @param location A `Location` object that contains a `latitude` and `longitude`.
 * @returns The memoized `H.geo.IPoint` that represents the location.
 */
export const useHereLocation = (location?: Location) => {
  // biome-ignore lint/correctness/useExhaustiveDependencies: ensuring the value is memoized only when the location actually changes
  const mapLocation = useMemo(
    () => location && parseLocation(location),
    [JSON.stringify(location)],
  )

  return mapLocation
}

/**
 * useHereLocations is a low-level hook that turns a `Location`s into memoized `H.geo.IPoint`s.
 * It is similar to `useHereLocation` but supports the use case of parsing multiple locations.
 * Since hooks cannot be called conditionally, `useHereLocation` cannot be used for dynamic arrays.
 * The memoization is important because it ensures the map only updates when the location actually changes.
 * @param locations A list of `Location` objects that contain a `latitude` and `longitude`.
 * @returns The memoized list of `H.geo.IPoint`s that represent the locations.
 */
export const useHereLocations = (locations?: Location[]) => {
  // biome-ignore lint/correctness/useExhaustiveDependencies: ensuring the value is memoized only when the location actually changes
  const mapLocations = useMemo(
    () => locations?.map(parseLocation),
    [JSON.stringify(locations)],
  )

  return mapLocations
}
