/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type FccMostCriticalVehicleSubscriptionVariables = Types.Exact<{
  backofficeUserId: Types.Scalars['ID']['input']
  serviceAreaUuid: Types.Scalars['ID']['input']
}>

export type FccMostCriticalVehicleSubscription = {
  __typename?: 'Subscription'
  fccMostCriticalVehicle: {
    __typename?: 'FccMostCriticalVehicleAssignment'
    backofficeUserId: string
    serviceAreaUuid: string
    vehicle: {
      __typename?: 'FccMostCriticalVehicle'
      id: string
      dismissed: boolean
      handledByBackofficeUserId: string | null
    } | null
  } | null
}

export const FccMostCriticalVehicleDocument = gql`
    subscription fccMostCriticalVehicle($backofficeUserId: ID!, $serviceAreaUuid: ID!) {
  fccMostCriticalVehicle(
    backofficeUserId: $backofficeUserId
    serviceAreaUuid: $serviceAreaUuid
  ) {
    vehicle {
      id
      dismissed
      handledByBackofficeUserId
    }
    backofficeUserId
    serviceAreaUuid
    vehicle {
      id
    }
  }
}
    `

/**
 * __useFccMostCriticalVehicleSubscription__
 *
 * To run a query within a React component, call `useFccMostCriticalVehicleSubscription` and pass it any options that fit your needs.
 * When your component renders, `useFccMostCriticalVehicleSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFccMostCriticalVehicleSubscription({
 *   variables: {
 *      backofficeUserId: // value for 'backofficeUserId'
 *      serviceAreaUuid: // value for 'serviceAreaUuid'
 *   },
 * });
 */
export function useFccMostCriticalVehicleSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    FccMostCriticalVehicleSubscription,
    FccMostCriticalVehicleSubscriptionVariables
  > &
    (
      | {
          variables: FccMostCriticalVehicleSubscriptionVariables
          skip?: boolean
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    FccMostCriticalVehicleSubscription,
    FccMostCriticalVehicleSubscriptionVariables
  >(FccMostCriticalVehicleDocument, options)
}
export type FccMostCriticalVehicleSubscriptionHookResult = ReturnType<
  typeof useFccMostCriticalVehicleSubscription
>
export type FccMostCriticalVehicleSubscriptionResult =
  Apollo.SubscriptionResult<FccMostCriticalVehicleSubscription>
