/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type ArchiveVehicleMutationVariables = Types.Exact<{
  input: Types.ArchiveVehicleInput
}>

export type ArchiveVehicleMutation = {
  __typename?: 'Mutation'
  archiveVehicle: {
    __typename?: 'StateChangeResponse'
    state: Types.VehicleState
    success: boolean
  }
}

export const ArchiveVehicleDocument = gql`
    mutation archiveVehicle($input: ArchiveVehicleInput!) {
  archiveVehicle(input: $input) {
    state
    success
  }
}
    `
export type ArchiveVehicleMutationFn = Apollo.MutationFunction<
  ArchiveVehicleMutation,
  ArchiveVehicleMutationVariables
>

/**
 * __useArchiveVehicleMutation__
 *
 * To run a mutation, you first call `useArchiveVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveVehicleMutation, { data, loading, error }] = useArchiveVehicleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveVehicleMutation,
    ArchiveVehicleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ArchiveVehicleMutation,
    ArchiveVehicleMutationVariables
  >(ArchiveVehicleDocument, options)
}
export type ArchiveVehicleMutationHookResult = ReturnType<
  typeof useArchiveVehicleMutation
>
export type ArchiveVehicleMutationResult =
  Apollo.MutationResult<ArchiveVehicleMutation>
export type ArchiveVehicleMutationOptions = Apollo.BaseMutationOptions<
  ArchiveVehicleMutation,
  ArchiveVehicleMutationVariables
>
