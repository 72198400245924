import { RouteIcon } from '@moia-dev/pace-icons'
import { NavigationItem } from '@backoffice-frontend/patterns'
import type { AreaNavigationProps } from '@backoffice-frontend/patterns'
import { TripAreaRoutes } from '@backoffice-frontend/trip-api'

export const TripAreaNavigation = ({
  onClick,
  open,
  label,
}: AreaNavigationProps) => {
  return (
    <NavigationItem
      onClick={onClick}
      open={open}
      label={label}
      to={TripAreaRoutes.route}
      Icon={RouteIcon}
    />
  )
}
