/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type FleetMapAreasQueryVariables = Types.Exact<{
  serviceAreaID?: Types.InputMaybe<Types.Scalars['ID']['input']>
}>

export type FleetMapAreasQuery = {
  __typename?: 'Query'
  areas: Array<{
    __typename?: 'Area'
    id: string
    name: string | null
    geometry: string
    type: Types.AreaType
    details:
      | { __typename?: 'CooperationDetails' }
      | {
          __typename?: 'HubDetails'
          hubStop: {
            __typename?: 'NavigationStop'
            id: string
            active: boolean
            routingLocation: {
              __typename?: 'Location'
              latitude: number
              longitude: number
            }
          } | null
        }
      | {
          __typename?: 'InFieldBreakDetails'
          stop: {
            __typename?: 'NavigationStop'
            id: string
            active: boolean
            routingLocation: {
              __typename?: 'Location'
              latitude: number
              longitude: number
            }
          } | null
        }
      | {
          __typename?: 'ShortBreakDetails'
          stop: {
            __typename?: 'NavigationStop'
            id: string
            active: boolean
            routingLocation: {
              __typename?: 'Location'
              latitude: number
              longitude: number
            }
          } | null
        }
      | null
  }>
}

export type NavigationStopFragment = {
  __typename?: 'NavigationStop'
  id: string
  active: boolean
  routingLocation: {
    __typename?: 'Location'
    latitude: number
    longitude: number
  }
}

export const NavigationStopFragmentDoc = gql`
    fragment NavigationStop on NavigationStop {
  id
  active
  routingLocation {
    latitude
    longitude
  }
}
    `
export const FleetMapAreasDocument = gql`
    query fleetMapAreas($serviceAreaID: ID) {
  areas(serviceAreaID: $serviceAreaID, areaTypes: [Hub, InFieldBreak, ShortBreak]) {
    id
    name
    geometry
    type
    details {
      ... on HubDetails {
        hubStop {
          ...NavigationStop
        }
      }
      ... on InFieldBreakDetails {
        stop {
          ...NavigationStop
        }
      }
      ... on ShortBreakDetails {
        stop {
          ...NavigationStop
        }
      }
    }
  }
}
    ${NavigationStopFragmentDoc}`

/**
 * __useFleetMapAreasQuery__
 *
 * To run a query within a React component, call `useFleetMapAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useFleetMapAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFleetMapAreasQuery({
 *   variables: {
 *      serviceAreaID: // value for 'serviceAreaID'
 *   },
 * });
 */
export function useFleetMapAreasQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FleetMapAreasQuery,
    FleetMapAreasQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FleetMapAreasQuery, FleetMapAreasQueryVariables>(
    FleetMapAreasDocument,
    options,
  )
}
export function useFleetMapAreasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FleetMapAreasQuery,
    FleetMapAreasQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FleetMapAreasQuery, FleetMapAreasQueryVariables>(
    FleetMapAreasDocument,
    options,
  )
}
export function useFleetMapAreasSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FleetMapAreasQuery,
        FleetMapAreasQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    FleetMapAreasQuery,
    FleetMapAreasQueryVariables
  >(FleetMapAreasDocument, options)
}
export type FleetMapAreasQueryHookResult = ReturnType<
  typeof useFleetMapAreasQuery
>
export type FleetMapAreasLazyQueryHookResult = ReturnType<
  typeof useFleetMapAreasLazyQuery
>
export type FleetMapAreasSuspenseQueryHookResult = ReturnType<
  typeof useFleetMapAreasSuspenseQuery
>
export type FleetMapAreasQueryResult = Apollo.QueryResult<
  FleetMapAreasQuery,
  FleetMapAreasQueryVariables
>
