/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import { GetPassengersWaypointFragmentDoc } from '../../../../../ad-passenger-list/src/lib/utils/passenger-filters.hook'
export type BoardingPassengersWaypointFragment = {
  __typename?: 'FccWaypoint'
  id: string
  actions: Array<
    | {
        __typename: 'FccWaypointDeliveryAction'
        id: string
        estimatedAt: string
        trip: { __typename?: 'Trip'; id: string } | null
        customer: {
          __typename?: 'Customer'
          firstName: string | null
          lastName: string | null
          extendedWalkingTime: boolean
          visualImpairment: boolean | null
        } | null
        seats: {
          __typename?: 'FccWaypointSeatInfo'
          seats: number
          wheelchairs: number
          childSeats: number
          boosterSeats: number
        }
      }
    | { __typename?: 'FccWaypointHubReturnAction' }
    | {
        __typename: 'FccWaypointPickupAction'
        id: string
        estimatedAt: string
        adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
        trip: { __typename?: 'Trip'; id: string } | null
        customer: {
          __typename?: 'Customer'
          firstName: string | null
          lastName: string | null
          extendedWalkingTime: boolean
          visualImpairment: boolean | null
        } | null
        seats: {
          __typename?: 'FccWaypointSeatInfo'
          seats: number
          wheelchairs: number
          childSeats: number
          boosterSeats: number
        }
      }
    | { __typename?: 'FccWaypointReallocationAction' }
  >
}

export const BoardingPassengersWaypointFragmentDoc = gql`
    fragment BoardingPassengersWaypoint on FccWaypoint {
  id
  ...GetPassengersWaypoint
}
    ${GetPassengersWaypointFragmentDoc}`
