/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
export type UpdateShiftBreakFragmentFragment = {
  __typename?: 'ShiftBreak'
  earliestStart: string
  latestEnd: string
  duration: string
}

export const UpdateShiftBreakFragmentFragmentDoc = gql`
    fragment updateShiftBreakFragment on ShiftBreak {
  earliestStart
  latestEnd
  duration
}
    `
