import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button, LoadingIndicator } from '@moia-dev/pace-web'
import type { MOIACognitoUser } from '@backoffice-frontend/common'
import { confirmSignIn, MfaErrorStates } from '@backoffice-frontend/common'
import { FormTextfield } from '@backoffice-frontend/forms'
import {
  MoiaGrid,
  useMoiaErrorNotification,
} from '@backoffice-frontend/patterns'
import { GetSupportChannelByEmail } from '../components/GetSupportChannelByEmail'
import { useIsSystemUser } from './useIsSystemUser'

type MfaCodeType = {
  mfaCode: string
}

export const MultiFactorAuthenticationForm = ({
  authenticatedUserRef,
  onRedirect,
  email,
  isMobile,
}: {
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  authenticatedUserRef: any
  email: string
  onRedirect: (user: MOIACognitoUser) => void
  isMobile: boolean
}) => {
  const [failedAttempts, setFailedAttempts] = useState(1)
  const [submitting, setSubmitting] = useState(false)
  const { enqueueMoiaErrorNotification } = useMoiaErrorNotification()
  const MAX_FAILED_ATTEMPTS = 3
  const { t } = useTranslation()

  useIsSystemUser()

  const getErrorStringFromCode = (error: unknown) => {
    // @ts-expect-error unknown cast
    const code = error?.code
    if (
      code === MfaErrorStates.CodeMismatchException &&
      failedAttempts < MAX_FAILED_ATTEMPTS
    ) {
      return t('loginView_mfaCodeInvalid')
    }
    if (
      code === MfaErrorStates.CodeMismatchException &&
      failedAttempts >= MAX_FAILED_ATTEMPTS
    ) {
      return t('loginView_mfaCodeInvalidMaxAttempts')
    }
    if (code === MfaErrorStates.NotAuthorizedException) {
      return t('loginView_mfaSessionExpired')
    }

    return error
  }
  const { handleSubmit, control } = useForm<MfaCodeType>({
    mode: 'onChange',
    defaultValues: {
      mfaCode: '',
    },
  })

  const handleMfaSubmit = async (data: MfaCodeType) => {
    setSubmitting(true)
    try {
      const user = await confirmSignIn({
        code: data.mfaCode,
        user: authenticatedUserRef.current,
      })
      onRedirect(user)
    } catch (error) {
      setSubmitting(false)
      setFailedAttempts(prevFailedAttempts => prevFailedAttempts + 1)
      const message = getErrorStringFromCode(error)
      enqueueMoiaErrorNotification(message)
    }
  }
  return (
    <form onSubmit={handleSubmit(handleMfaSubmit)}>
      <div>
        <FormTextfield
          control={control}
          autoFocus
          caption={t('loginView_mfaCode_caption')}
          name="mfaCode"
          label={t('loginView_mfaCode_label')}
          size={isMobile ? 'compact' : 'default'}
        />
        <MoiaGrid
          alignItems="center"
          gridTemplateColumns="1fr min-content"
          justifyContent="flex-end"
          mt={4}
        >
          <p>
            {t('loginView_mfaCode_hint')}{' '}
            <GetSupportChannelByEmail email={email} />
          </p>
          <Button
            variant="primary"
            icon={submitting ? <LoadingIndicator /> : undefined}
            type="submit"
            size="compact"
            label={t('loginView_login_button')}
          />
        </MoiaGrid>
      </div>
    </form>
  )
}
