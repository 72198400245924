/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import { AdOnBoardPassengersVehicleFragmentDoc } from '../../../../ad-passenger-list/src/lib/AdOnBoardPassengers/AdOnBoardPassengers.hook'
import { AlertsModalSkeletonVehicleFragmentDoc } from '../components/AlertsModalSkeleton/AlertsModalSkeleton.hook'
import {
  AlertVehicleMapVehicleFragmentDoc,
  AlertVehicleMapWaypointFragmentDoc,
} from '../AlertVehicleMap/AlertVehicleMap.hook'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type ServiceCancellationModalQueryVariables = Types.Exact<{
  vehicleId: Types.Scalars['ID']['input']
  taskNotificationTypes:
    | Array<Types.TaskNotificationType>
    | Types.TaskNotificationType
  taskNotificationStatus:
    | Array<Types.TaskNotificationStatus>
    | Types.TaskNotificationStatus
}>

export type ServiceCancellationModalQuery = {
  __typename?: 'Query'
  vehicle: {
    __typename?: 'Vehicle'
    id: string
    isWheelchairAccessible: boolean | null
    isSelfDriving: boolean
    label: number | null
    fccUpcomingWaypoints: Array<{
      __typename?: 'FccWaypoint'
      id: string
      stop: {
        __typename?: 'Stop'
        routingLocation: {
          __typename?: 'Location'
          latitude: number
          longitude: number
        }
      } | null
      actions: Array<
        | {
            __typename: 'FccWaypointDeliveryAction'
            estimatedAt: string
            id: string
            trip: { __typename?: 'Trip'; id: string } | null
            customer: {
              __typename?: 'Customer'
              firstName: string | null
              lastName: string | null
              extendedWalkingTime: boolean
              visualImpairment: boolean | null
            } | null
            seats: {
              __typename?: 'FccWaypointSeatInfo'
              seats: number
              wheelchairs: number
              childSeats: number
              boosterSeats: number
            }
          }
        | { __typename?: 'FccWaypointHubReturnAction'; estimatedAt: string }
        | {
            __typename: 'FccWaypointPickupAction'
            estimatedAt: string
            id: string
            adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
            trip: { __typename?: 'Trip'; id: string } | null
            customer: {
              __typename?: 'Customer'
              firstName: string | null
              lastName: string | null
              extendedWalkingTime: boolean
              visualImpairment: boolean | null
            } | null
            seats: {
              __typename?: 'FccWaypointSeatInfo'
              seats: number
              wheelchairs: number
              childSeats: number
              boosterSeats: number
            }
          }
        | { __typename?: 'FccWaypointReallocationAction'; estimatedAt: string }
      >
    }> | null
    latestADTelemetry: {
      __typename?: 'ADTelemetry'
      speed: {
        __typename?: 'ADTelemetryFloatWithTimestamp'
        value: number
        timestamp: string
      } | null
      rightSlidingDoorOpen: {
        __typename?: 'ADTelemetryBooleanWithTimestamp'
        value: boolean
        timestamp: string
      } | null
    } | null
    latestLocation: {
      __typename?: 'VehicleLocation'
      heading: number | null
      location: {
        __typename?: 'Location'
        latitude: number
        longitude: number
      } | null
    } | null
    taskNotifications: {
      __typename?: 'TaskNotificationList'
      tasks: Array<
        | {
            __typename: 'HubServiceNotification'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'IvcTaskNotification'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'PassengerAuthentificationFallbackRequestedNotification'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'PassengerUnbuckled'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'RoadBlockageTaskNotification'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'ShiftDeviationNotification'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'StopAccessibilityDownvotedByDrivers'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'StopActivationFailedNotification'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'StopAreaMissingActiveStopsNotification'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'StopDownVotedByDrivers'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'StopPotentiallyUnreachable'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'StopValidationFailed'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'TimeManagementNotification'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'TripPrebookingNoAssignment'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'VehicleAvailabilityNotification'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'VehicleIncidentNotification'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'VehicleSurveillanceNotification'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'VehicleWaypointAttendance'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
      >
    } | null
    fccCurrentAdAlerts: {
      __typename?: 'FccAdAlerts'
      alerts: Array<{
        __typename: 'FccAdAlert'
        id: string
        type: Types.FccAdAlertType
        createdAt: string
      }>
    } | null
    pooling: { __typename?: 'Pooling'; enabled: boolean } | null
  }
}

export type ServiceCancellationActionCardVehicleFragment = {
  __typename?: 'Vehicle'
  id: string
  latestADTelemetry: {
    __typename?: 'ADTelemetry'
    speed: {
      __typename?: 'ADTelemetryFloatWithTimestamp'
      value: number
    } | null
  } | null
}

export type ServiceCancellationModalVehicleFragment = {
  __typename?: 'Vehicle'
  id: string
  isWheelchairAccessible: boolean | null
  isSelfDriving: boolean
  label: number | null
  fccUpcomingWaypoints: Array<{
    __typename?: 'FccWaypoint'
    id: string
    stop: {
      __typename?: 'Stop'
      routingLocation: {
        __typename?: 'Location'
        latitude: number
        longitude: number
      }
    } | null
    actions: Array<
      | {
          __typename: 'FccWaypointDeliveryAction'
          estimatedAt: string
          id: string
          trip: { __typename?: 'Trip'; id: string } | null
          customer: {
            __typename?: 'Customer'
            firstName: string | null
            lastName: string | null
            extendedWalkingTime: boolean
            visualImpairment: boolean | null
          } | null
          seats: {
            __typename?: 'FccWaypointSeatInfo'
            seats: number
            wheelchairs: number
            childSeats: number
            boosterSeats: number
          }
        }
      | { __typename?: 'FccWaypointHubReturnAction'; estimatedAt: string }
      | {
          __typename: 'FccWaypointPickupAction'
          estimatedAt: string
          id: string
          adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
          trip: { __typename?: 'Trip'; id: string } | null
          customer: {
            __typename?: 'Customer'
            firstName: string | null
            lastName: string | null
            extendedWalkingTime: boolean
            visualImpairment: boolean | null
          } | null
          seats: {
            __typename?: 'FccWaypointSeatInfo'
            seats: number
            wheelchairs: number
            childSeats: number
            boosterSeats: number
          }
        }
      | { __typename?: 'FccWaypointReallocationAction'; estimatedAt: string }
    >
  }> | null
  latestADTelemetry: {
    __typename?: 'ADTelemetry'
    speed: {
      __typename?: 'ADTelemetryFloatWithTimestamp'
      value: number
      timestamp: string
    } | null
    rightSlidingDoorOpen: {
      __typename?: 'ADTelemetryBooleanWithTimestamp'
      value: boolean
      timestamp: string
    } | null
  } | null
  latestLocation: {
    __typename?: 'VehicleLocation'
    heading: number | null
    location: {
      __typename?: 'Location'
      latitude: number
      longitude: number
    } | null
  } | null
  taskNotifications: {
    __typename?: 'TaskNotificationList'
    tasks: Array<
      | {
          __typename: 'HubServiceNotification'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'IvcTaskNotification'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'PassengerAuthentificationFallbackRequestedNotification'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'PassengerUnbuckled'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'RoadBlockageTaskNotification'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'ShiftDeviationNotification'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'StopAccessibilityDownvotedByDrivers'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'StopActivationFailedNotification'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'StopAreaMissingActiveStopsNotification'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'StopDownVotedByDrivers'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'StopPotentiallyUnreachable'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'StopValidationFailed'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'TimeManagementNotification'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'TripPrebookingNoAssignment'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'VehicleAvailabilityNotification'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'VehicleIncidentNotification'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'VehicleSurveillanceNotification'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'VehicleWaypointAttendance'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
    >
  } | null
  fccCurrentAdAlerts: {
    __typename?: 'FccAdAlerts'
    alerts: Array<{
      __typename: 'FccAdAlert'
      id: string
      type: Types.FccAdAlertType
      createdAt: string
    }>
  } | null
  pooling: { __typename?: 'Pooling'; enabled: boolean } | null
}

export type ServiceCancellationStateSubscriptionVariables = Types.Exact<{
  vehicleId: Types.Scalars['String']['input']
}>

export type ServiceCancellationStateSubscription = {
  __typename?: 'Subscription'
  fccServiceCancellationState: {
    __typename?: 'FccServiceCancellationState'
    vehicleId: string
    state: Types.FccServiceCancellationStateEnum | null
    cancellationError: {
      __typename?: 'FccServiceCancellationError'
      code: Types.FccServiceCancellationErrorCode
    } | null
  }
}

export const ServiceCancellationActionCardVehicleFragmentDoc = gql`
    fragment ServiceCancellationActionCardVehicle on Vehicle {
  id
  latestADTelemetry {
    speed {
      value
    }
  }
}
    `
export const ServiceCancellationModalVehicleFragmentDoc = gql`
    fragment ServiceCancellationModalVehicle on Vehicle {
  id
  ...ServiceCancellationActionCardVehicle
  ...AdOnBoardPassengersVehicle
  ...AlertsModalSkeletonVehicle
  ...AlertVehicleMapVehicle
  fccUpcomingWaypoints {
    ...AlertVehicleMapWaypoint
  }
}
    ${ServiceCancellationActionCardVehicleFragmentDoc}
${AdOnBoardPassengersVehicleFragmentDoc}
${AlertsModalSkeletonVehicleFragmentDoc}
${AlertVehicleMapVehicleFragmentDoc}
${AlertVehicleMapWaypointFragmentDoc}`
export const ServiceCancellationModalDocument = gql`
    query ServiceCancellationModal($vehicleId: ID!, $taskNotificationTypes: [TaskNotificationType!]!, $taskNotificationStatus: [TaskNotificationStatus!]!) {
  vehicle: vehicleByID(id: $vehicleId) {
    ...ServiceCancellationModalVehicle
  }
}
    ${ServiceCancellationModalVehicleFragmentDoc}`

/**
 * __useServiceCancellationModalQuery__
 *
 * To run a query within a React component, call `useServiceCancellationModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceCancellationModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceCancellationModalQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      taskNotificationTypes: // value for 'taskNotificationTypes'
 *      taskNotificationStatus: // value for 'taskNotificationStatus'
 *   },
 * });
 */
export function useServiceCancellationModalQuery(
  baseOptions: Apollo.QueryHookOptions<
    ServiceCancellationModalQuery,
    ServiceCancellationModalQueryVariables
  > &
    (
      | { variables: ServiceCancellationModalQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ServiceCancellationModalQuery,
    ServiceCancellationModalQueryVariables
  >(ServiceCancellationModalDocument, options)
}
export function useServiceCancellationModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ServiceCancellationModalQuery,
    ServiceCancellationModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ServiceCancellationModalQuery,
    ServiceCancellationModalQueryVariables
  >(ServiceCancellationModalDocument, options)
}
export function useServiceCancellationModalSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ServiceCancellationModalQuery,
        ServiceCancellationModalQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ServiceCancellationModalQuery,
    ServiceCancellationModalQueryVariables
  >(ServiceCancellationModalDocument, options)
}
export type ServiceCancellationModalQueryHookResult = ReturnType<
  typeof useServiceCancellationModalQuery
>
export type ServiceCancellationModalLazyQueryHookResult = ReturnType<
  typeof useServiceCancellationModalLazyQuery
>
export type ServiceCancellationModalSuspenseQueryHookResult = ReturnType<
  typeof useServiceCancellationModalSuspenseQuery
>
export type ServiceCancellationModalQueryResult = Apollo.QueryResult<
  ServiceCancellationModalQuery,
  ServiceCancellationModalQueryVariables
>
export const ServiceCancellationStateDocument = gql`
    subscription ServiceCancellationState($vehicleId: String!) {
  fccServiceCancellationState(vehicleId: $vehicleId) {
    vehicleId
    state
    cancellationError {
      code
    }
  }
}
    `

/**
 * __useServiceCancellationStateSubscription__
 *
 * To run a query within a React component, call `useServiceCancellationStateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useServiceCancellationStateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceCancellationStateSubscription({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useServiceCancellationStateSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    ServiceCancellationStateSubscription,
    ServiceCancellationStateSubscriptionVariables
  > &
    (
      | {
          variables: ServiceCancellationStateSubscriptionVariables
          skip?: boolean
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    ServiceCancellationStateSubscription,
    ServiceCancellationStateSubscriptionVariables
  >(ServiceCancellationStateDocument, options)
}
export type ServiceCancellationStateSubscriptionHookResult = ReturnType<
  typeof useServiceCancellationStateSubscription
>
export type ServiceCancellationStateSubscriptionResult =
  Apollo.SubscriptionResult<ServiceCancellationStateSubscription>
