import type { TextFieldProps } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import * as React from 'react'
import { ChevronDownIcon } from '../../basics/Icons'
import { CircularProgress } from '../../feedback/CircularProgress'
import { getFinalFormError } from '../FormControls/FormControlErrorHandler'

export type MoiaDropdownFlexProps = Omit<
  TextFieldProps,
  'onChange' | 'error'
> & {
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  onChange?(value: any): void
  loading?: boolean
  error?: React.ReactNode | string
  emptyOption?: string
  emptyPlaceholder?: string
  children?: React.ReactNode
}

export const MoiaDropdownFlex = ({
  children,
  disabled,
  emptyOption,
  emptyPlaceholder,
  error,
  helperText,
  InputProps,
  loading,
  onChange,
  SelectProps,
  value,
  variant,
  ...otherProps
}: MoiaDropdownFlexProps) => {
  const { errorHelperText } = getFinalFormError({
    error,
    touched: Boolean(error),
  })

  return (
    <TextField
      fullWidth
      disabled={disabled}
      role="listbox"
      value={value}
      error={Boolean(error)}
      helperText={errorHelperText ?? helperText}
      onChange={e => onChange?.(e.target.value)}
      InputLabelProps={{ shrink: emptyOption ? true : undefined }}
      InputProps={{
        endAdornment: loading && (
          <InputAdornment position="end">
            <CircularProgress size={24} />
          </InputAdornment>
        ),
        ...InputProps,
      }}
      SelectProps={{
        displayEmpty: !!emptyOption || !!emptyPlaceholder,
        IconComponent: ChevronDownIcon,
        ...SelectProps,
      }}
      {...otherProps}
      select
    >
      {emptyOption && <MenuItem value="">{emptyOption}</MenuItem>}
      {children}
    </TextField>
  )
}
