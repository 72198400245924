// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts

import { lazy } from 'react'

import {
  CouponAreaId,
  CouponAreaRoutes,
  CouponAreaDataTestIds,
} from '@backoffice-frontend/common'

import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { BoumRight } from '@moia-dev/moia-token-claims'

import { CouponAreaNavigation } from './CouponAreaNavigation'

const LazyCouponComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./CouponArea')),
)

const CouponComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyCouponComponent />
  </SuspenseLoadingWrapper>
)

export const CouponArea = {
  id: CouponAreaId,
  dataTestIds: CouponAreaDataTestIds,
  requiredRight: BoumRight.AREA_COUPON,
  NavigationComponent: CouponAreaNavigation,
  route: {
    path: CouponAreaRoutes.route,
    component: CouponComponent,
  },
}
