/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
export type BackofficeUserFragment = {
  __typename?: 'BackofficeUser'
  id: string
  firstName: string
  lastName: string
}

export const BackofficeUserFragmentDoc = gql`
    fragment backofficeUser on BackofficeUser {
  id
  firstName
  lastName
}
    `
