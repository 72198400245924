/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type BackofficeUserByEmailQueryVariables = Types.Exact<{
  email: Types.Scalars['String']['input']
}>

export type BackofficeUserByEmailQuery = {
  __typename?: 'Query'
  backofficeUserByEmail: {
    __typename?: 'BackofficeUser'
    id: string
    firstName: string
    lastName: string
    username: string
    email: string
    createDate: string
    lastModifiedDate: string
    groupsApprover: Array<string>
    operatorTenants: Array<string>
    customerTenants: Array<string>
    userTenants: Array<string>
    timedGroups: Array<{
      __typename?: 'BackofficeUserTimeGroup'
      expires: string | null
      groupName: string
    }>
    timedServiceAreas: Array<{
      __typename?: 'BackofficeUserTimeArea'
      expires: string | null
      serviceArea: string
    }>
  }
}

export const BackofficeUserByEmailDocument = gql`
    query backofficeUserByEmail($email: String!) {
  backofficeUserByEmail(email: $email) {
    id
    firstName
    lastName
    username
    email
    createDate
    lastModifiedDate
    groupsApprover
    operatorTenants
    customerTenants
    userTenants
    timedGroups {
      expires
      groupName
    }
    timedServiceAreas {
      expires
      serviceArea
    }
  }
}
    `

/**
 * __useBackofficeUserByEmailQuery__
 *
 * To run a query within a React component, call `useBackofficeUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useBackofficeUserByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBackofficeUserByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useBackofficeUserByEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    BackofficeUserByEmailQuery,
    BackofficeUserByEmailQueryVariables
  > &
    (
      | { variables: BackofficeUserByEmailQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    BackofficeUserByEmailQuery,
    BackofficeUserByEmailQueryVariables
  >(BackofficeUserByEmailDocument, options)
}
export function useBackofficeUserByEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BackofficeUserByEmailQuery,
    BackofficeUserByEmailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    BackofficeUserByEmailQuery,
    BackofficeUserByEmailQueryVariables
  >(BackofficeUserByEmailDocument, options)
}
export function useBackofficeUserByEmailSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BackofficeUserByEmailQuery,
        BackofficeUserByEmailQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    BackofficeUserByEmailQuery,
    BackofficeUserByEmailQueryVariables
  >(BackofficeUserByEmailDocument, options)
}
export type BackofficeUserByEmailQueryHookResult = ReturnType<
  typeof useBackofficeUserByEmailQuery
>
export type BackofficeUserByEmailLazyQueryHookResult = ReturnType<
  typeof useBackofficeUserByEmailLazyQuery
>
export type BackofficeUserByEmailSuspenseQueryHookResult = ReturnType<
  typeof useBackofficeUserByEmailSuspenseQuery
>
export type BackofficeUserByEmailQueryResult = Apollo.QueryResult<
  BackofficeUserByEmailQuery,
  BackofficeUserByEmailQueryVariables
>
