/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type VehicleByPhoneNumberQueryVariables = Types.Exact<{
  phoneNumber: Types.Scalars['String']['input']
}>

export type VehicleByPhoneNumberQuery = {
  __typename?: 'Query'
  vehicleByPhoneNumber: {
    __typename?: 'Vehicle'
    id: string
    label: number | null
  } | null
}

export const VehicleByPhoneNumberDocument = gql`
    query VehicleByPhoneNumber($phoneNumber: String!) {
  vehicleByPhoneNumber(phoneNumber: $phoneNumber) {
    id
    label
  }
}
    `

/**
 * __useVehicleByPhoneNumberQuery__
 *
 * To run a query within a React component, call `useVehicleByPhoneNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleByPhoneNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleByPhoneNumberQuery({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useVehicleByPhoneNumberQuery(
  baseOptions: Apollo.QueryHookOptions<
    VehicleByPhoneNumberQuery,
    VehicleByPhoneNumberQueryVariables
  > &
    (
      | { variables: VehicleByPhoneNumberQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    VehicleByPhoneNumberQuery,
    VehicleByPhoneNumberQueryVariables
  >(VehicleByPhoneNumberDocument, options)
}
export function useVehicleByPhoneNumberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VehicleByPhoneNumberQuery,
    VehicleByPhoneNumberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    VehicleByPhoneNumberQuery,
    VehicleByPhoneNumberQueryVariables
  >(VehicleByPhoneNumberDocument, options)
}
export function useVehicleByPhoneNumberSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        VehicleByPhoneNumberQuery,
        VehicleByPhoneNumberQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    VehicleByPhoneNumberQuery,
    VehicleByPhoneNumberQueryVariables
  >(VehicleByPhoneNumberDocument, options)
}
export type VehicleByPhoneNumberQueryHookResult = ReturnType<
  typeof useVehicleByPhoneNumberQuery
>
export type VehicleByPhoneNumberLazyQueryHookResult = ReturnType<
  typeof useVehicleByPhoneNumberLazyQuery
>
export type VehicleByPhoneNumberSuspenseQueryHookResult = ReturnType<
  typeof useVehicleByPhoneNumberSuspenseQuery
>
export type VehicleByPhoneNumberQueryResult = Apollo.QueryResult<
  VehicleByPhoneNumberQuery,
  VehicleByPhoneNumberQueryVariables
>
