/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type CloseVehicleForEmployeeInEmergencyMutationVariables = Types.Exact<{
  input: Types.CloseVehicleInput
}>

export type CloseVehicleForEmployeeInEmergencyMutation = {
  __typename?: 'Mutation'
  closeVehicleForEmployeeInEmergency: {
    __typename?: 'VehicleInteractionResponse'
    interactionID: string | null
    errors: Array<Types.VehicleInteractionError>
  }
}

export const CloseVehicleForEmployeeInEmergencyDocument = gql`
    mutation closeVehicleForEmployeeInEmergency($input: CloseVehicleInput!) {
  closeVehicleForEmployeeInEmergency(input: $input) {
    interactionID
    errors
  }
}
    `
export type CloseVehicleForEmployeeInEmergencyMutationFn =
  Apollo.MutationFunction<
    CloseVehicleForEmployeeInEmergencyMutation,
    CloseVehicleForEmployeeInEmergencyMutationVariables
  >

/**
 * __useCloseVehicleForEmployeeInEmergencyMutation__
 *
 * To run a mutation, you first call `useCloseVehicleForEmployeeInEmergencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseVehicleForEmployeeInEmergencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeVehicleForEmployeeInEmergencyMutation, { data, loading, error }] = useCloseVehicleForEmployeeInEmergencyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloseVehicleForEmployeeInEmergencyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloseVehicleForEmployeeInEmergencyMutation,
    CloseVehicleForEmployeeInEmergencyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CloseVehicleForEmployeeInEmergencyMutation,
    CloseVehicleForEmployeeInEmergencyMutationVariables
  >(CloseVehicleForEmployeeInEmergencyDocument, options)
}
export type CloseVehicleForEmployeeInEmergencyMutationHookResult = ReturnType<
  typeof useCloseVehicleForEmployeeInEmergencyMutation
>
export type CloseVehicleForEmployeeInEmergencyMutationResult =
  Apollo.MutationResult<CloseVehicleForEmployeeInEmergencyMutation>
export type CloseVehicleForEmployeeInEmergencyMutationOptions =
  Apollo.BaseMutationOptions<
    CloseVehicleForEmployeeInEmergencyMutation,
    CloseVehicleForEmployeeInEmergencyMutationVariables
  >
