import { CircularProgress } from '@mui/material'
import mime from 'mime'
import type { ChangeEvent, DragEvent } from 'react'
import { useRef, useState } from 'react'
import type { DropEvent, FileRejection } from 'react-dropzone'
import Dropzone from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { DownloadIcon, ErrorCircleSolidIcon } from '@moia-dev/pace-icons'
import { Text } from '@moia-dev/pace-web'
import { formatFileSize } from '@backoffice-frontend/common'
import { Caption } from '../../basics/Typography'
import { useTheme } from '../../basics/theme'
import { useMoiaErrorNotification } from '../../feedback/MoiaNotification/useMoiaErrorNotification'

type FileUploadFieldProps = {
  onDropChange: (files: File[]) => Promise<void>
  onInputChange: (event: ChangeEvent<HTMLInputElement>) => void
  disabled: boolean
  maxFiles?: number
  maxFileSizeBytes?: number
  accept: string[]
  title?: string
  subtitle?: string
  uploading?: boolean
  required?: boolean
  label?: string
}
export const FileUploadField = ({
  onDropChange,
  disabled,
  onInputChange,
  maxFiles,
  maxFileSizeBytes = 3 * 1000000, // 3MB
  accept,
  subtitle,
  title,
  uploading,
  required,
  label,
}: FileUploadFieldProps) => {
  const theme = useTheme()
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const { enqueueMoiaErrorNotification } = useMoiaErrorNotification()
  const { t } = useTranslation()
  const [dragOverError, setDragOverError] = useState<string | undefined>(
    undefined,
  )

  const fileTypesAllowed = (
    fileTypes: string[],
    acceptedFilesTypes: string[],
    isDragEvent = false,
  ) => {
    if (fileTypes.length === 0) return

    const allowedFileTypes = acceptedFilesTypes
    const notAllowedTypes = fileTypes.filter(
      type => !allowedFileTypes.includes(type),
    )

    if (notAllowedTypes.length > 0) {
      const errorMessage = t('patterns_fileTypesNotAllowed', {
        type: notAllowedTypes
          .map((type: string) => mime.getExtension(type))
          .join(', '),
      })

      if (isDragEvent) {
        setDragOverError(errorMessage)
        return false
      }
      enqueueMoiaErrorNotification(errorMessage)
      return false
    }
    return true
  }

  const onDropRejected = (fileRejs: FileRejection[], _: DropEvent) => {
    const files = fileRejs.map(it => it.file)
    const fileTypes = files.map(file => file.type)
    fileTypesAllowed(fileTypes, accept)
    setDragOverError(undefined)
  }

  const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files

    if (!files) return

    // Check for filesize
    const isFileTooLarge = Array.from(files).some(
      file => file.size > maxFileSizeBytes,
    )
    if (isFileTooLarge) {
      enqueueMoiaErrorNotification(
        `File is larger than ${formatFileSize(maxFileSizeBytes)}`,
      )
      return
    }

    const fileTypes = Array.from(files).map(file => file.type)
    const proceedUpload = fileTypesAllowed(fileTypes, accept)

    if (proceedUpload) onInputChange(event)
    hiddenFileInput.current!.value = ''
  }

  const disableDrop = () =>
    disabled ? { onDrop: (event: DropEvent) => event.preventDefault() } : {}

  const handleDragOver = (event: DragEvent<HTMLElement>) => {
    event.preventDefault()
    if (!maxFiles) return null

    const draggedOverFiles = Array.from(event.dataTransfer.items).length
    if (draggedOverFiles > maxFiles) {
      setDragOverError(
        t('patterns_maxFilesExceededError', {
          maxFiles: maxFiles,
        }),
      )
    }

    const fileTypes = Array.from(event.dataTransfer.items).map(
      item => item.type,
    )
    fileTypesAllowed(fileTypes, accept, true)

    return null
  }

  return (
    <div>
      {label && (
        <div className="fileUploadLabel">
          <Text size="lg" weight="bold">
            {label}
          </Text>
          {required && <Text color="critical">*</Text>}
        </div>
      )}
      {title && (
        <Caption
          css={theme => ({
            marginBottom: theme.space.Space1,
            color: theme.semantic.ColorContentReduced,
          })}
        >
          {title}
        </Caption>
      )}
      <Dropzone
        disabled={disabled}
        multiple
        onDropAccepted={onDropChange}
        maxFiles={maxFiles}
        maxSize={maxFileSizeBytes}
        noDrag={disabled}
        onDropRejected={onDropRejected}
        noKeyboard
        noClick
        accept={Object.fromEntries(accept.map(it => [it, []]))}
        onDragOver={handleDragOver}
        onDragLeave={() => setDragOverError(undefined)}
      >
        {({ getRootProps, isDragActive }) => {
          const dropZoneBackgroundColor = isDragActive
            ? theme.semantic.ColorControlsDefault
            : theme.semantic.ColorInputDefault

          return (
            <>
              <div
                {...getRootProps()}
                {...disableDrop()}
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: dragOverError
                    ? theme.semantic.ColorSurfaceCritical
                    : disabled
                      ? theme.semantic.ColorControlsDisabled
                      : dropZoneBackgroundColor,
                  border: `1px dashed ${
                    dragOverError
                      ? theme.semantic.ColorCriticalDefault
                      : theme.semantic.ColorBorderOnControlDefault
                  }`,
                  borderRadius: '4px',
                  alignItems: 'center',
                  padding: `${theme.spacing(3)}`,
                  ...(disabled && { cursor: 'no-drop' }),
                }}
              >
                <div
                  css={{
                    paddingBottom: `${theme.spacing(1)}`,
                    textAlign: 'center',
                    color: dragOverError
                      ? theme.semantic.ColorCriticalContrast
                      : disabled
                        ? theme.semantic.ColorContentDisabled
                        : theme.semantic.ColorContentDefault,
                  }}
                >
                  <div css={{ textAlign: 'center' }}>
                    {uploading ? (
                      <>
                        <CircularProgress
                          size={theme.space.Space5}
                          css={{
                            color: theme.semantic.ColorActionSecondaryDefault,
                          }}
                        />
                        <Caption>{t('common_uploading')}</Caption>
                      </>
                    ) : dragOverError ? (
                      <ErrorCircleSolidIcon />
                    ) : (
                      <DownloadIcon />
                    )}
                  </div>
                  {!dragOverError && !uploading
                    ? maxFiles === 1
                      ? t('patterns_dragAndDropFile')
                      : t('patterns_dragAndDropFiles')
                    : dragOverError}{' '}
                  {!dragOverError && !uploading && (
                    <span
                      role="button"
                      css={{
                        appearance: 'none',
                        border: 'none',
                        background: 'none',
                        cursor: disabled ? 'no-drop' : 'pointer',
                        textDecoration: 'underline',
                        color: disabled
                          ? theme.semantic.ColorContentDisabled
                          : theme.semantic.ColorContentDefault,
                      }}
                      onClick={e => {
                        e.preventDefault()
                        hiddenFileInput.current?.click()
                      }}
                    >
                      {maxFiles === 1
                        ? t('patterns_selectFileFromYourDevice_button')
                        : t('patterns_selectFilesFromYourDevice_button')}
                    </span>
                  )}
                </div>
              </div>
              <input
                disabled={disabled}
                ref={hiddenFileInput}
                id="fileUpload"
                type="file"
                aria-label={'fileUpload'}
                multiple
                accept={accept.join(',')}
                onChange={inputChangeHandler}
                hidden
              />
            </>
          )
        }}
      </Dropzone>
      <Caption
        css={theme => ({
          marginTop: theme.space.Space1,
          color: theme.semantic.ColorContentReduced,
        })}
      >
        {subtitle && `${subtitle} / `}
        {t('patterns_maxFileSize_caption', {
          fileSize: formatFileSize(maxFileSizeBytes),
        })}
      </Caption>
    </div>
  )
}
