import type { SnackbarKey } from 'notistack'
import { useSnackbar } from 'notistack'
import type { ReactNode } from 'react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Toast } from '@moia-dev/pace-web'

/**
 * A hook allows to display a success notification at the bottom center of
 * the screen.
 */
export const useMoiaSuccessNotification = (
  persist = false,
  duration = 2000,
): {
  enqueueMoiaSuccessNotification: (success: ReactNode) => SnackbarKey
} => {
  const { enqueueSnackbar } = useSnackbar()
  const enqueueMoiaSuccessNotification = useCallback(
    (message: ReactNode) =>
      enqueueSnackbar(message, {
        persist,
        autoHideDuration: duration,
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        content: function SnackbarContent(key) {
          const { closeSnackbar } = useSnackbar()
          const { t } = useTranslation()
          return (
            <div role="alert">
              <Toast
                variant="success"
                action={() => closeSnackbar(key)}
                actionLabel={t('common_ok_label')}
              >
                {message}
              </Toast>
            </div>
          )
        },
      }),
    [enqueueSnackbar, persist, duration],
  )

  return {
    enqueueMoiaSuccessNotification,
  }
}
