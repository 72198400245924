/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
export type AdPassengerRowCustomerFragment = {
  __typename?: 'Customer'
  firstName: string | null
  lastName: string | null
  extendedWalkingTime: boolean
  visualImpairment: boolean | null
}

export type AdPassengerRowSeatInfoFragment = {
  __typename?: 'FccWaypointSeatInfo'
  seats: number
  wheelchairs: number
  childSeats: number
  boosterSeats: number
}

export const AdPassengerRowCustomerFragmentDoc = gql`
    fragment AdPassengerRowCustomer on Customer {
  firstName
  lastName
  extendedWalkingTime
  visualImpairment
}
    `
export const AdPassengerRowSeatInfoFragmentDoc = gql`
    fragment AdPassengerRowSeatInfo on FccWaypointSeatInfo {
  seats
  wheelchairs
  childSeats
  boosterSeats
}
    `
