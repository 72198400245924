/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import { RioUserFragmentDoc } from './RioUserFragment.hook'
import { RioTemplateFragmentDoc } from './RioLatestTemplate.hook'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type RioReportQueryVariables = Types.Exact<{
  reportId: Types.Scalars['ID']['input']
}>

export type RioReportQuery = {
  __typename?: 'Query'
  rioReport: {
    __typename?: 'RioReportResponse'
    report: {
      __typename?: 'RioReport'
      reportId: string
      shortId: string
      version: number
      versionCreatedAt: string
      initialVersionCreatedAt: string
      serviceArea: { __typename?: 'ServiceArea'; id: string } | null
      versionCreatedBy: {
        __typename?: 'RioUser'
        backofficeUser: {
          __typename?: 'BackofficeUser'
          id: string
          firstName: string
          lastName: string
        } | null
        employee: {
          __typename?: 'Employee'
          id: string
          firstName: string | null
          lastName: string | null
        } | null
      }
      initialVersionCreatedBy: {
        __typename?: 'RioUser'
        backofficeUser: {
          __typename?: 'BackofficeUser'
          id: string
          firstName: string
          lastName: string
        } | null
        employee: {
          __typename?: 'Employee'
          id: string
          firstName: string | null
          lastName: string | null
        } | null
      }
      values: Array<{
        __typename?: 'RioValue'
        componentId: string
        value: string
      }>
      template: {
        __typename?: 'RioTemplate'
        id: string
        category: Types.RioCategory
        name: string
        tenant: string
        supportedFleets: Array<string> | null
        version: number
        immutable: boolean | null
        title: {
          __typename?: 'RioLabel'
          de: string | null
          en: string | null
        } | null
        newReportTitle: {
          __typename?: 'RioLabel'
          en: string | null
          de: string | null
        } | null
        editReportTitle: {
          __typename?: 'RioLabel'
          en: string | null
          de: string | null
        } | null
        viewReportTitle: {
          __typename?: 'RioLabel'
          en: string | null
          de: string | null
        } | null
        components: Array<
          | {
              __typename: 'RioComponentCheckbox'
              componentId: string
              componentType: Types.RioComponentType
              dependingComponentIds: Array<string> | null
              overrideFailValue: boolean | null
              topDivider: boolean | null
              label: {
                __typename?: 'RioLabel'
                de: string | null
                en: string | null
              }
              description: {
                __typename?: 'RioLabel'
                en: string | null
                de: string | null
              } | null
              checkboxLabel: {
                __typename?: 'RioLabel'
                en: string | null
                de: string | null
              } | null
              trueLabel: {
                __typename?: 'RioLabel'
                en: string | null
                de: string | null
              }
              falseLabel: {
                __typename?: 'RioLabel'
                en: string | null
                de: string | null
              }
              viewLabel: {
                __typename?: 'RioLabel'
                en: string | null
                de: string | null
              } | null
            }
          | {
              __typename: 'RioComponentDate'
              componentId: string
              componentType: Types.RioComponentType
              dependingComponentIds: Array<string> | null
              allowFutureDates: boolean | null
              label: {
                __typename?: 'RioLabel'
                de: string | null
                en: string | null
              }
            }
          | {
              __typename: 'RioComponentGeolocation'
              componentId: string
              componentType: Types.RioComponentType
              dependingComponentIds: Array<string> | null
              label: {
                __typename?: 'RioLabel'
                de: string | null
                en: string | null
              }
              latitudeLabel: {
                __typename?: 'RioLabel'
                en: string | null
                de: string | null
              } | null
              longitudeLabel: {
                __typename?: 'RioLabel'
                en: string | null
                de: string | null
              } | null
              latitudeTooltipLabel: {
                __typename?: 'RioLabel'
                en: string | null
                de: string | null
              } | null
              longitudeTooltipLabel: {
                __typename?: 'RioLabel'
                en: string | null
                de: string | null
              } | null
              viewLabel: {
                __typename?: 'RioLabel'
                en: string | null
                de: string | null
              } | null
            }
          | {
              __typename: 'RioComponentOptions'
              componentId: string
              componentType: Types.RioComponentType
              dependingComponentIds: Array<string> | null
              label: {
                __typename?: 'RioLabel'
                de: string | null
                en: string | null
              }
              options: Array<{
                __typename?: 'RioOptionValue'
                value: string
                label: {
                  __typename?: 'RioLabel'
                  en: string | null
                  de: string | null
                }
              }>
            }
          | {
              __typename: 'RioComponentPassFail'
              componentId: string
              componentType: Types.RioComponentType
              dependingComponentIds: Array<string> | null
              preselectedValue: Types.RioPassFailValue | null
              label: {
                __typename?: 'RioLabel'
                de: string | null
                en: string | null
              }
              passFailValues: {
                __typename?: 'RioPassFailValues'
                pass: Types.RioPassFailValue
                fail: Types.RioPassFailValue
              }
              passLabel: {
                __typename?: 'RioLabel'
                en: string | null
                de: string | null
              }
              failLabel: {
                __typename?: 'RioLabel'
                en: string | null
                de: string | null
              }
              viewLabel: {
                __typename?: 'RioLabel'
                en: string | null
                de: string | null
              } | null
              description: {
                __typename?: 'RioLabel'
                en: string | null
                de: string | null
              } | null
            }
          | {
              __typename: 'RioComponentSimple'
              componentId: string
              componentType: Types.RioComponentType
              dependingComponentIds: Array<string> | null
              label: {
                __typename?: 'RioLabel'
                de: string | null
                en: string | null
              }
            }
          | {
              __typename: 'RioComponentStaticValue'
              componentId: string
              componentType: Types.RioComponentType
              dependingComponentIds: Array<string> | null
              staticValue: Types.RioStaticValue
              label: {
                __typename?: 'RioLabel'
                de: string | null
                en: string | null
              }
            }
          | {
              __typename: 'RioComponentStatus'
              componentId: string
              componentType: Types.RioComponentType
              dependingComponentIds: Array<string> | null
              statusOptions: Array<Types.RioStatusOptions>
              label: {
                __typename?: 'RioLabel'
                de: string | null
                en: string | null
              }
            }
          | {
              __typename: 'RioComponentTextArea'
              componentId: string
              componentType: Types.RioComponentType
              dependingComponentIds: Array<string> | null
              label: {
                __typename?: 'RioLabel'
                de: string | null
                en: string | null
              }
              caption: {
                __typename?: 'RioLabel'
                de: string | null
                en: string | null
              } | null
              placeholder: {
                __typename?: 'RioLabel'
                de: string | null
                en: string | null
              } | null
            }
          | {
              __typename: 'RioComponentTroublemaker'
              componentId: string
              componentType: Types.RioComponentType
              dependingComponentIds: Array<string> | null
              troublemakerOptions: Array<Types.RioTroublemakerOptions>
              label: {
                __typename?: 'RioLabel'
                de: string | null
                en: string | null
              }
            }
        >
        editSections: Array<{
          __typename?: 'RioSection'
          sectionId: string
          componentIds: Array<string>
          label: {
            __typename?: 'RioLabel'
            de: string | null
            en: string | null
          } | null
        }>
        viewSections: Array<{
          __typename?: 'RioSection'
          sectionId: string
          componentIds: Array<string>
          width: Types.RioSectionWidth | null
          label: {
            __typename?: 'RioLabel'
            de: string | null
            en: string | null
          } | null
        }>
        rules: Array<{
          __typename?: 'RioRule'
          componentId: string | null
          sectionId: string | null
          rule: Types.RioRuleDefinition
          otherComponentId: string | null
          otherComponentValue: string | null
          label: {
            __typename?: 'RioLabel'
            de: string | null
            en: string | null
          } | null
        }> | null
      }
      attachments: Array<{
        __typename?: 'RioAttachment'
        attachmentId: string
        uploadedAt: string
        photoCategory: Types.RioPhotoCategory | null
        fileName: string
        attachmentType: Types.RioAttachmentType | null
        size: number
        uploadedBy: {
          __typename?: 'RioUser'
          backofficeUser: {
            __typename?: 'BackofficeUser'
            id: string
            firstName: string
            lastName: string
          } | null
          employee: {
            __typename?: 'Employee'
            id: string
            firstName: string | null
            lastName: string | null
          } | null
        } | null
      }> | null
    }
  }
}

export type RioReportFragment = {
  __typename?: 'RioReport'
  reportId: string
  shortId: string
  version: number
  versionCreatedAt: string
  initialVersionCreatedAt: string
  serviceArea: { __typename?: 'ServiceArea'; id: string } | null
  versionCreatedBy: {
    __typename?: 'RioUser'
    backofficeUser: {
      __typename?: 'BackofficeUser'
      id: string
      firstName: string
      lastName: string
    } | null
    employee: {
      __typename?: 'Employee'
      id: string
      firstName: string | null
      lastName: string | null
    } | null
  }
  initialVersionCreatedBy: {
    __typename?: 'RioUser'
    backofficeUser: {
      __typename?: 'BackofficeUser'
      id: string
      firstName: string
      lastName: string
    } | null
    employee: {
      __typename?: 'Employee'
      id: string
      firstName: string | null
      lastName: string | null
    } | null
  }
  values: Array<{ __typename?: 'RioValue'; componentId: string; value: string }>
  template: {
    __typename?: 'RioTemplate'
    id: string
    category: Types.RioCategory
    name: string
    tenant: string
    supportedFleets: Array<string> | null
    version: number
    immutable: boolean | null
    title: {
      __typename?: 'RioLabel'
      de: string | null
      en: string | null
    } | null
    newReportTitle: {
      __typename?: 'RioLabel'
      en: string | null
      de: string | null
    } | null
    editReportTitle: {
      __typename?: 'RioLabel'
      en: string | null
      de: string | null
    } | null
    viewReportTitle: {
      __typename?: 'RioLabel'
      en: string | null
      de: string | null
    } | null
    components: Array<
      | {
          __typename: 'RioComponentCheckbox'
          componentId: string
          componentType: Types.RioComponentType
          dependingComponentIds: Array<string> | null
          overrideFailValue: boolean | null
          topDivider: boolean | null
          label: {
            __typename?: 'RioLabel'
            de: string | null
            en: string | null
          }
          description: {
            __typename?: 'RioLabel'
            en: string | null
            de: string | null
          } | null
          checkboxLabel: {
            __typename?: 'RioLabel'
            en: string | null
            de: string | null
          } | null
          trueLabel: {
            __typename?: 'RioLabel'
            en: string | null
            de: string | null
          }
          falseLabel: {
            __typename?: 'RioLabel'
            en: string | null
            de: string | null
          }
          viewLabel: {
            __typename?: 'RioLabel'
            en: string | null
            de: string | null
          } | null
        }
      | {
          __typename: 'RioComponentDate'
          componentId: string
          componentType: Types.RioComponentType
          dependingComponentIds: Array<string> | null
          allowFutureDates: boolean | null
          label: {
            __typename?: 'RioLabel'
            de: string | null
            en: string | null
          }
        }
      | {
          __typename: 'RioComponentGeolocation'
          componentId: string
          componentType: Types.RioComponentType
          dependingComponentIds: Array<string> | null
          label: {
            __typename?: 'RioLabel'
            de: string | null
            en: string | null
          }
          latitudeLabel: {
            __typename?: 'RioLabel'
            en: string | null
            de: string | null
          } | null
          longitudeLabel: {
            __typename?: 'RioLabel'
            en: string | null
            de: string | null
          } | null
          latitudeTooltipLabel: {
            __typename?: 'RioLabel'
            en: string | null
            de: string | null
          } | null
          longitudeTooltipLabel: {
            __typename?: 'RioLabel'
            en: string | null
            de: string | null
          } | null
          viewLabel: {
            __typename?: 'RioLabel'
            en: string | null
            de: string | null
          } | null
        }
      | {
          __typename: 'RioComponentOptions'
          componentId: string
          componentType: Types.RioComponentType
          dependingComponentIds: Array<string> | null
          label: {
            __typename?: 'RioLabel'
            de: string | null
            en: string | null
          }
          options: Array<{
            __typename?: 'RioOptionValue'
            value: string
            label: {
              __typename?: 'RioLabel'
              en: string | null
              de: string | null
            }
          }>
        }
      | {
          __typename: 'RioComponentPassFail'
          componentId: string
          componentType: Types.RioComponentType
          dependingComponentIds: Array<string> | null
          preselectedValue: Types.RioPassFailValue | null
          label: {
            __typename?: 'RioLabel'
            de: string | null
            en: string | null
          }
          passFailValues: {
            __typename?: 'RioPassFailValues'
            pass: Types.RioPassFailValue
            fail: Types.RioPassFailValue
          }
          passLabel: {
            __typename?: 'RioLabel'
            en: string | null
            de: string | null
          }
          failLabel: {
            __typename?: 'RioLabel'
            en: string | null
            de: string | null
          }
          viewLabel: {
            __typename?: 'RioLabel'
            en: string | null
            de: string | null
          } | null
          description: {
            __typename?: 'RioLabel'
            en: string | null
            de: string | null
          } | null
        }
      | {
          __typename: 'RioComponentSimple'
          componentId: string
          componentType: Types.RioComponentType
          dependingComponentIds: Array<string> | null
          label: {
            __typename?: 'RioLabel'
            de: string | null
            en: string | null
          }
        }
      | {
          __typename: 'RioComponentStaticValue'
          componentId: string
          componentType: Types.RioComponentType
          dependingComponentIds: Array<string> | null
          staticValue: Types.RioStaticValue
          label: {
            __typename?: 'RioLabel'
            de: string | null
            en: string | null
          }
        }
      | {
          __typename: 'RioComponentStatus'
          componentId: string
          componentType: Types.RioComponentType
          dependingComponentIds: Array<string> | null
          statusOptions: Array<Types.RioStatusOptions>
          label: {
            __typename?: 'RioLabel'
            de: string | null
            en: string | null
          }
        }
      | {
          __typename: 'RioComponentTextArea'
          componentId: string
          componentType: Types.RioComponentType
          dependingComponentIds: Array<string> | null
          label: {
            __typename?: 'RioLabel'
            de: string | null
            en: string | null
          }
          caption: {
            __typename?: 'RioLabel'
            de: string | null
            en: string | null
          } | null
          placeholder: {
            __typename?: 'RioLabel'
            de: string | null
            en: string | null
          } | null
        }
      | {
          __typename: 'RioComponentTroublemaker'
          componentId: string
          componentType: Types.RioComponentType
          dependingComponentIds: Array<string> | null
          troublemakerOptions: Array<Types.RioTroublemakerOptions>
          label: {
            __typename?: 'RioLabel'
            de: string | null
            en: string | null
          }
        }
    >
    editSections: Array<{
      __typename?: 'RioSection'
      sectionId: string
      componentIds: Array<string>
      label: {
        __typename?: 'RioLabel'
        de: string | null
        en: string | null
      } | null
    }>
    viewSections: Array<{
      __typename?: 'RioSection'
      sectionId: string
      componentIds: Array<string>
      width: Types.RioSectionWidth | null
      label: {
        __typename?: 'RioLabel'
        de: string | null
        en: string | null
      } | null
    }>
    rules: Array<{
      __typename?: 'RioRule'
      componentId: string | null
      sectionId: string | null
      rule: Types.RioRuleDefinition
      otherComponentId: string | null
      otherComponentValue: string | null
      label: {
        __typename?: 'RioLabel'
        de: string | null
        en: string | null
      } | null
    }> | null
  }
  attachments: Array<{
    __typename?: 'RioAttachment'
    attachmentId: string
    uploadedAt: string
    photoCategory: Types.RioPhotoCategory | null
    fileName: string
    attachmentType: Types.RioAttachmentType | null
    size: number
    uploadedBy: {
      __typename?: 'RioUser'
      backofficeUser: {
        __typename?: 'BackofficeUser'
        id: string
        firstName: string
        lastName: string
      } | null
      employee: {
        __typename?: 'Employee'
        id: string
        firstName: string | null
        lastName: string | null
      } | null
    } | null
  }> | null
}

export type RioAttachmentFragment = {
  __typename?: 'RioAttachment'
  attachmentId: string
  uploadedAt: string
  photoCategory: Types.RioPhotoCategory | null
  fileName: string
  attachmentType: Types.RioAttachmentType | null
  size: number
  uploadedBy: {
    __typename?: 'RioUser'
    backofficeUser: {
      __typename?: 'BackofficeUser'
      id: string
      firstName: string
      lastName: string
    } | null
    employee: {
      __typename?: 'Employee'
      id: string
      firstName: string | null
      lastName: string | null
    } | null
  } | null
}

export const RioAttachmentFragmentDoc = gql`
    fragment rioAttachment on RioAttachment {
  attachmentId
  uploadedBy {
    ...rioUser
  }
  uploadedAt
  photoCategory
  fileName
  attachmentType
  size
}
    ${RioUserFragmentDoc}`
export const RioReportFragmentDoc = gql`
    fragment rioReport on RioReport {
  reportId
  shortId
  version
  serviceArea {
    id
  }
  versionCreatedAt
  versionCreatedBy {
    ...rioUser
  }
  initialVersionCreatedAt
  initialVersionCreatedBy {
    ...rioUser
  }
  values {
    componentId
    value
  }
  template {
    ...rioTemplate
  }
  attachments {
    ...rioAttachment
  }
}
    ${RioUserFragmentDoc}
${RioTemplateFragmentDoc}
${RioAttachmentFragmentDoc}`
export const RioReportDocument = gql`
    query RioReport($reportId: ID!) {
  rioReport(input: {reportId: $reportId}) {
    report {
      ...rioReport
    }
  }
}
    ${RioReportFragmentDoc}`

/**
 * __useRioReportQuery__
 *
 * To run a query within a React component, call `useRioReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useRioReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRioReportQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useRioReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    RioReportQuery,
    RioReportQueryVariables
  > &
    (
      | { variables: RioReportQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<RioReportQuery, RioReportQueryVariables>(
    RioReportDocument,
    options,
  )
}
export function useRioReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RioReportQuery,
    RioReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<RioReportQuery, RioReportQueryVariables>(
    RioReportDocument,
    options,
  )
}
export function useRioReportSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<RioReportQuery, RioReportQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<RioReportQuery, RioReportQueryVariables>(
    RioReportDocument,
    options,
  )
}
export type RioReportQueryHookResult = ReturnType<typeof useRioReportQuery>
export type RioReportLazyQueryHookResult = ReturnType<
  typeof useRioReportLazyQuery
>
export type RioReportSuspenseQueryHookResult = ReturnType<
  typeof useRioReportSuspenseQuery
>
export type RioReportQueryResult = Apollo.QueryResult<
  RioReportQuery,
  RioReportQueryVariables
>
