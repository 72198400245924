/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
export type AdVehiclePromptVehicleInfoVehicleFragment = {
  __typename?: 'Vehicle'
  id: string
  label: number | null
  isWheelchairAccessible: boolean | null
}

export const AdVehiclePromptVehicleInfoVehicleFragmentDoc = gql`
    fragment AdVehiclePromptVehicleInfoVehicle on Vehicle {
  id
  label
  isWheelchairAccessible
}
    `
