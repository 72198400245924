/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type ShiftOverrideScheduledHubReturnMutationVariables = Types.Exact<{
  shiftId: Types.Scalars['ID']['input']
}>

export type ShiftOverrideScheduledHubReturnMutation = {
  __typename?: 'Mutation'
  shiftOverrideScheduledHubReturn: {
    __typename?: 'ShiftScheduledHubReturn'
    id: string
    returnReason: Types.ShiftScheduledHubReturnReason
    source: Types.ShiftScheduledHubReturnSource
  }
}

export const ShiftOverrideScheduledHubReturnDocument = gql`
    mutation shiftOverrideScheduledHubReturn($shiftId: ID!) {
  shiftOverrideScheduledHubReturn(input: {shiftId: $shiftId}) {
    id
    returnReason
    source
  }
}
    `
export type ShiftOverrideScheduledHubReturnMutationFn = Apollo.MutationFunction<
  ShiftOverrideScheduledHubReturnMutation,
  ShiftOverrideScheduledHubReturnMutationVariables
>

/**
 * __useShiftOverrideScheduledHubReturnMutation__
 *
 * To run a mutation, you first call `useShiftOverrideScheduledHubReturnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShiftOverrideScheduledHubReturnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shiftOverrideScheduledHubReturnMutation, { data, loading, error }] = useShiftOverrideScheduledHubReturnMutation({
 *   variables: {
 *      shiftId: // value for 'shiftId'
 *   },
 * });
 */
export function useShiftOverrideScheduledHubReturnMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ShiftOverrideScheduledHubReturnMutation,
    ShiftOverrideScheduledHubReturnMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ShiftOverrideScheduledHubReturnMutation,
    ShiftOverrideScheduledHubReturnMutationVariables
  >(ShiftOverrideScheduledHubReturnDocument, options)
}
export type ShiftOverrideScheduledHubReturnMutationHookResult = ReturnType<
  typeof useShiftOverrideScheduledHubReturnMutation
>
export type ShiftOverrideScheduledHubReturnMutationResult =
  Apollo.MutationResult<ShiftOverrideScheduledHubReturnMutation>
export type ShiftOverrideScheduledHubReturnMutationOptions =
  Apollo.BaseMutationOptions<
    ShiftOverrideScheduledHubReturnMutation,
    ShiftOverrideScheduledHubReturnMutationVariables
  >
