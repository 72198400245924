import DOMPurify from 'dompurify'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import snarkdown from 'snarkdown'
import {
  useErrorHandlingMutation,
  useErrorHandlingQuery,
} from '@backoffice-frontend/data-fetching'
import { BackofficeUpdateType } from '@backoffice-frontend/graphql'
import {
  Body2,
  MoiaDrawer,
  MoiaDrawerContent,
  MoiaDrawerHeader,
  MoiaDrawerLoadingContent,
  MoiaDrawerLoadingHeader,
  Overline,
  Subtitle1,
  ImageThumbnail,
  MoiaDialog,
  MoiaDialogContent,
  MoiaDialogTitle,
  MoiaCard,
  MoiaCardContent,
  MoiaLink,
  MoiaGrid,
  Subtitle2,
  ImageGallery,
  useMoiaErrorNotification,
} from '@backoffice-frontend/patterns'
import type { BackofficeUpdateByIdQuery } from '@backoffice-frontend/updates-api'
import {
  useBackofficeUpdateByIdQuery,
  useBackofficeUpdatesGetMediaPresignedUrlByFileNameMutation,
} from '@backoffice-frontend/updates-api'
import {
  useGetTranslatedText,
  useGetTranslatedType,
} from './translateBackofficeUpdates'

type BackofficeUpdate = BackofficeUpdateByIdQuery['backofficeUpdateById']

export const BackofficeUpdatesDrawer = ({
  id,
  onClose,
  className,
}: {
  id: string
  onClose: () => void
  className?: string
}) => {
  const [getMediaPresignedUrl] = useErrorHandlingMutation(
    useBackofficeUpdatesGetMediaPresignedUrlByFileNameMutation,
  )
  const { data, loading } = useErrorHandlingQuery(
    useBackofficeUpdateByIdQuery,
    { variables: { id }, skip: !id },
  )
  const { t } = useTranslation()

  const { enqueueMoiaErrorNotification } = useMoiaErrorNotification()

  const emptyBackofficeUpdate: BackofficeUpdate = useMemo(() => {
    return {
      creationDate: '',
      id: '',
      rights: [],
      type: BackofficeUpdateType.Bug,
      titleDe: '',
      titleEn: '',
      notesDe: '',
      notesEn: '',
      images: [],
      videoEn: '',
      videoDe: '',
    }
  }, [])

  const [backOfficeUpdate, setBackofficeUpdate] = useState<BackofficeUpdate>(
    emptyBackofficeUpdate,
  )

  useEffect(() => {
    setBackofficeUpdate(data?.backofficeUpdateById ?? emptyBackofficeUpdate)
  }, [data?.backofficeUpdateById, emptyBackofficeUpdate])

  const translatedType = useGetTranslatedType(backOfficeUpdate?.type)
  const translatedTitle = useGetTranslatedText({
    de: backOfficeUpdate?.titleDe,
    en: backOfficeUpdate?.titleEn,
  })
  const translatedNotesHtml = snarkdown(
    useGetTranslatedText({
      de: backOfficeUpdate?.notesDe,
      en: backOfficeUpdate?.notesEn,
    }),
  )

  const sanitizedTranslatedNotesHtml = DOMPurify.sanitize(translatedNotesHtml, {
    ALLOWED_TAGS: ['strong', 'ol', 'ul', 'li', 's', 'br'],
  })

  const [imagePreviewOpen, setImagePreviewOpen] = useState(false)
  const [selectedThumbnail, setSelectedThumbnail] = useState(0)
  const [imagesUrls, setImagesUrls] = useState<string[]>([])

  const onSelectThumbnail = (selectedUrl?: string) => {
    if (selectedUrl) {
      setSelectedThumbnail(imagesUrls.indexOf(selectedUrl))
      setImagePreviewOpen(true)
    }
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: getMediaPresignedUrl is not stable
  useEffect(() => {
    async function fetchInitialImages() {
      const promises = (backOfficeUpdate?.images ?? []).map(async fileName => {
        if (fileName) {
          return getMediaPresignedUrl({ variables: { input: { fileName } } })
            .then(({ data }) =>
              fetch(
                data?.backofficeUpdatesGetMediaPresignedUrlByFileName
                  ?.preSignedUrl ?? '',
              ),
            )
            .then(response => response?.blob())
            .then(blob => URL.createObjectURL(blob))
        } else {
          return Promise.resolve('')
        }
      })

      const imageUrls = promises ? await Promise.all(promises) : []

      setImagesUrls(imageUrls)
    }
    fetchInitialImages().catch(error => enqueueMoiaErrorNotification(error))
  }, [backOfficeUpdate])

  return (
    <>
      <MoiaDrawer className={className}>
        {loading ? (
          <MoiaDrawerLoadingHeader />
        ) : (
          <MoiaDrawerHeader onClose={onClose}>
            {translatedType}
          </MoiaDrawerHeader>
        )}

        {loading ? (
          <MoiaDrawerLoadingContent />
        ) : (
          <MoiaDrawerContent>
            <Subtitle1
              paragraph
              css={theme => ({
                marginTop: theme.spacing(3),
              })}
            >
              {translatedTitle}
            </Subtitle1>
            <MoiaGrid>
              <Overline paragraph>{t('updates_drawerNews_header')}</Overline>
              <Body2 paragraph>
                <span
                  // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
                  dangerouslySetInnerHTML={{
                    __html: sanitizedTranslatedNotesHtml,
                  }}
                />
              </Body2>
              {imagesUrls.length > 0 && (
                <MoiaCard compact variant="outlined">
                  <MoiaCardContent>
                    <Overline paragraph>
                      {t('updates_drawerImages_header')}
                    </Overline>
                    {imagesUrls.map(url => (
                      <ImageThumbnail
                        key={url}
                        handleSelectFile={onSelectThumbnail}
                        fileUrl={url}
                      />
                    ))}
                  </MoiaCardContent>
                </MoiaCard>
              )}
              {(!!backOfficeUpdate?.videoDe || !!backOfficeUpdate?.videoEn) && (
                <MoiaCard compact variant="outlined">
                  <MoiaCardContent>
                    <Overline paragraph>
                      {t('updates_drawerVideo_header')}
                    </Overline>
                    <ul css={theme => ({ paddingLeft: theme.spacing(2) })}>
                      {backOfficeUpdate?.videoEn ? (
                        <li>
                          <MoiaLink
                            href={backOfficeUpdate?.videoEn}
                            target="_blank"
                          >
                            <Subtitle2>
                              {t('updates_drawerVideoEnglish_textlink')}
                            </Subtitle2>
                          </MoiaLink>
                        </li>
                      ) : null}
                      {backOfficeUpdate?.videoDe ? (
                        <li>
                          <MoiaLink
                            href={backOfficeUpdate?.videoDe}
                            target="_blank"
                          >
                            <Subtitle2>
                              {t('updates_drawerVideoGerman_textlink')}
                            </Subtitle2>
                          </MoiaLink>
                        </li>
                      ) : null}
                    </ul>
                  </MoiaCardContent>
                </MoiaCard>
              )}
            </MoiaGrid>
          </MoiaDrawerContent>
        )}
      </MoiaDrawer>
      <MoiaDialog
        maxWidth="xl"
        fullWidth
        open={imagePreviewOpen}
        onClose={() => setImagePreviewOpen(false)}
      >
        <MoiaDialogTitle onClose={() => setImagePreviewOpen(false)} />
        <MoiaDialogContent css={{ height: '70vh' }}>
          <ImageGallery
            imageUrls={imagesUrls}
            selectedIndex={selectedThumbnail}
          />
        </MoiaDialogContent>
      </MoiaDialog>
    </>
  )
}
