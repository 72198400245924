/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type QueryCustomerQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input']
}>

export type QueryCustomerQuery = {
  __typename?: 'Query'
  customer: {
    __typename?: 'CustomerResponse'
    customer: {
      __typename?: 'Customer'
      createdIpAddress: string | null
      creationDate: any | null
      deletionScheduledAt: any | null
      disqualifiedFromMarketingPermissionCoupon: boolean | null
      email: string | null
      emailVerified: boolean
      extendedWalkingTime: boolean
      firstName: string | null
      hvvPartnershipValidUntil: any | null
      id: string
      isEnabled: boolean
      lastModifiedDate: any | null
      lastName: string | null
      locale: string
      phoneNumber: string | null
      phoneVerified: boolean
      tenant: string
      visualImpairment: boolean | null
      wheelchairAccess: boolean
      zipCode: string | null
      marketingPermissions: {
        __typename?: 'MarketingPermissions'
        advertisement: boolean
        email: boolean
        lastAskedForMarketingPermissionsAt: any | null
        lastModified: any | null
        pushNotification: boolean
        textMessage: boolean
        version: string
      } | null
      severelyDisabled: {
        __typename?: 'SeverelyDisabled'
        eligibility: Types.Eligibility | null
        validUntil: any | null
      } | null
      termsAndConditions: {
        __typename?: 'TermsAndConditions'
        confirmationDate: any | null
        version: string
      } | null
    } | null
  }
}

export const QueryCustomerDocument = gql`
    query queryCustomer($id: ID!) {
  customer(id: $id) {
    customer {
      createdIpAddress
      creationDate
      deletionScheduledAt
      disqualifiedFromMarketingPermissionCoupon
      email
      emailVerified
      extendedWalkingTime
      firstName
      hvvPartnershipValidUntil
      id
      isEnabled
      lastModifiedDate
      lastName
      locale
      marketingPermissions {
        advertisement
        email
        lastAskedForMarketingPermissionsAt
        lastModified
        pushNotification
        textMessage
        version
      }
      phoneNumber
      phoneVerified
      severelyDisabled {
        eligibility
        validUntil
      }
      tenant
      termsAndConditions {
        confirmationDate
        version
      }
      visualImpairment
      wheelchairAccess
      zipCode
    }
  }
}
    `

/**
 * __useQueryCustomerQuery__
 *
 * To run a query within a React component, call `useQueryCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQueryCustomerQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryCustomerQuery,
    QueryCustomerQueryVariables
  > &
    (
      | { variables: QueryCustomerQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<QueryCustomerQuery, QueryCustomerQueryVariables>(
    QueryCustomerDocument,
    options,
  )
}
export function useQueryCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryCustomerQuery,
    QueryCustomerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<QueryCustomerQuery, QueryCustomerQueryVariables>(
    QueryCustomerDocument,
    options,
  )
}
export function useQueryCustomerSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        QueryCustomerQuery,
        QueryCustomerQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    QueryCustomerQuery,
    QueryCustomerQueryVariables
  >(QueryCustomerDocument, options)
}
export type QueryCustomerQueryHookResult = ReturnType<
  typeof useQueryCustomerQuery
>
export type QueryCustomerLazyQueryHookResult = ReturnType<
  typeof useQueryCustomerLazyQuery
>
export type QueryCustomerSuspenseQueryHookResult = ReturnType<
  typeof useQueryCustomerSuspenseQuery
>
export type QueryCustomerQueryResult = Apollo.QueryResult<
  QueryCustomerQuery,
  QueryCustomerQueryVariables
>
