/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type ShiftConfirmTaskNotificationMutationVariables = Types.Exact<{
  foreignId: Types.Scalars['ID']['input']
}>

export type ShiftConfirmTaskNotificationMutation = {
  __typename?: 'Mutation'
  shiftConfirmTaskNotification: {
    __typename?: 'ShiftConfirmTaskNotificationResponse'
    confirmed: boolean
    foreignId: string
  }
}

export const ShiftConfirmTaskNotificationDocument = gql`
    mutation shiftConfirmTaskNotification($foreignId: ID!) {
  shiftConfirmTaskNotification(input: {foreignId: $foreignId}) {
    confirmed
    foreignId
  }
}
    `
export type ShiftConfirmTaskNotificationMutationFn = Apollo.MutationFunction<
  ShiftConfirmTaskNotificationMutation,
  ShiftConfirmTaskNotificationMutationVariables
>

/**
 * __useShiftConfirmTaskNotificationMutation__
 *
 * To run a mutation, you first call `useShiftConfirmTaskNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShiftConfirmTaskNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shiftConfirmTaskNotificationMutation, { data, loading, error }] = useShiftConfirmTaskNotificationMutation({
 *   variables: {
 *      foreignId: // value for 'foreignId'
 *   },
 * });
 */
export function useShiftConfirmTaskNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ShiftConfirmTaskNotificationMutation,
    ShiftConfirmTaskNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ShiftConfirmTaskNotificationMutation,
    ShiftConfirmTaskNotificationMutationVariables
  >(ShiftConfirmTaskNotificationDocument, options)
}
export type ShiftConfirmTaskNotificationMutationHookResult = ReturnType<
  typeof useShiftConfirmTaskNotificationMutation
>
export type ShiftConfirmTaskNotificationMutationResult =
  Apollo.MutationResult<ShiftConfirmTaskNotificationMutation>
export type ShiftConfirmTaskNotificationMutationOptions =
  Apollo.BaseMutationOptions<
    ShiftConfirmTaskNotificationMutation,
    ShiftConfirmTaskNotificationMutationVariables
  >
