/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type BackofficeUpdateDeleteMutationVariables = Types.Exact<{
  input: Types.BackofficeUpdateDelete
}>

export type BackofficeUpdateDeleteMutation = {
  __typename?: 'Mutation'
  backofficeUpdateDelete: {
    __typename?: 'BackofficeUpdateDeleteResponse'
    success: boolean
  }
}

export const BackofficeUpdateDeleteDocument = gql`
    mutation backofficeUpdateDelete($input: BackofficeUpdateDelete!) {
  backofficeUpdateDelete(input: $input) {
    success
  }
}
    `
export type BackofficeUpdateDeleteMutationFn = Apollo.MutationFunction<
  BackofficeUpdateDeleteMutation,
  BackofficeUpdateDeleteMutationVariables
>

/**
 * __useBackofficeUpdateDeleteMutation__
 *
 * To run a mutation, you first call `useBackofficeUpdateDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackofficeUpdateDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backofficeUpdateDeleteMutation, { data, loading, error }] = useBackofficeUpdateDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBackofficeUpdateDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BackofficeUpdateDeleteMutation,
    BackofficeUpdateDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BackofficeUpdateDeleteMutation,
    BackofficeUpdateDeleteMutationVariables
  >(BackofficeUpdateDeleteDocument, options)
}
export type BackofficeUpdateDeleteMutationHookResult = ReturnType<
  typeof useBackofficeUpdateDeleteMutation
>
export type BackofficeUpdateDeleteMutationResult =
  Apollo.MutationResult<BackofficeUpdateDeleteMutation>
export type BackofficeUpdateDeleteMutationOptions = Apollo.BaseMutationOptions<
  BackofficeUpdateDeleteMutation,
  BackofficeUpdateDeleteMutationVariables
>
