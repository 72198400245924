import { transformRoutes } from '../utils'

const baseRoute = '/pattern-library'

export const PatternLibraryBaseRoutes = {
  route: baseRoute,
  Patterns: {
    route: '/patterns',
  },
}
export const PatternLibraryAreaRoutes = transformRoutes(
  PatternLibraryBaseRoutes,
)
