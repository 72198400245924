/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type FccAdTelematicsCardTelemetrySubscriptionVariables = Types.Exact<{
  vehicleId: Types.Scalars['ID']['input']
}>

export type FccAdTelematicsCardTelemetrySubscription = {
  __typename?: 'Subscription'
  fccVehicleTelemetry: {
    __typename?: 'FccVehicleTelemetry'
    interiorTemperatureCelsius: number | null
    stateOfChargePercentage: number | null
    remainingCruisingRangeKilometer: number | null
    ignitionOn: boolean | null
    centralLockLocked: boolean | null
    rightSlidingDoorOpen: boolean | null
    consumedEnergy: number | null
    mileageKilometer: number | null
  }
}

export const FccAdTelematicsCardTelemetryDocument = gql`
    subscription fccAdTelematicsCardTelemetry($vehicleId: ID!) {
  fccVehicleTelemetry(vehicleId: $vehicleId) {
    interiorTemperatureCelsius
    stateOfChargePercentage
    remainingCruisingRangeKilometer
    ignitionOn
    centralLockLocked
    rightSlidingDoorOpen
    consumedEnergy
    mileageKilometer
  }
}
    `

/**
 * __useFccAdTelematicsCardTelemetrySubscription__
 *
 * To run a query within a React component, call `useFccAdTelematicsCardTelemetrySubscription` and pass it any options that fit your needs.
 * When your component renders, `useFccAdTelematicsCardTelemetrySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFccAdTelematicsCardTelemetrySubscription({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useFccAdTelematicsCardTelemetrySubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    FccAdTelematicsCardTelemetrySubscription,
    FccAdTelematicsCardTelemetrySubscriptionVariables
  > &
    (
      | {
          variables: FccAdTelematicsCardTelemetrySubscriptionVariables
          skip?: boolean
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    FccAdTelematicsCardTelemetrySubscription,
    FccAdTelematicsCardTelemetrySubscriptionVariables
  >(FccAdTelematicsCardTelemetryDocument, options)
}
export type FccAdTelematicsCardTelemetrySubscriptionHookResult = ReturnType<
  typeof useFccAdTelematicsCardTelemetrySubscription
>
export type FccAdTelematicsCardTelemetrySubscriptionResult =
  Apollo.SubscriptionResult<FccAdTelematicsCardTelemetrySubscription>
