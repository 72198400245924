/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type ShiftPlannedHubReturnSectionOptionsQueryVariables = Types.Exact<{
  shiftId: Types.Scalars['ID']['input']
}>

export type ShiftPlannedHubReturnSectionOptionsQuery = {
  __typename?: 'Query'
  shiftPlannedHubReturnSectionOptions: {
    __typename?: 'ShiftPlannedHubReturnSectionOptionsResponse'
    comment: {
      __typename?: 'ShiftComment'
      author: string | null
      comment: string
      updatedAt: string
    } | null
    options: Array<{
      __typename?: 'ShiftPlannedHubReturnSectionOption'
      section: Types.ShiftPlannedHubReturnSectionCategory
      availableStartTimes: Array<string>
      earliestSectionStart: string | null
      latestSectionEnd: string | null
      plannedReturnTime: string | null
      providedReason: Types.ShiftPlannedHubReturnProvidedReason | null
      availableAreas: Array<{
        __typename?: 'Area'
        id: string
        name: string | null
        details:
          | { __typename?: 'CooperationDetails' }
          | { __typename?: 'HubDetails'; displayName: string }
          | { __typename?: 'InFieldBreakDetails' }
          | { __typename?: 'ShortBreakDetails' }
          | null
        areaCapacityConfiguration: {
          __typename?: 'AreaCapacityConfiguration'
          parkingCapacity: number
        } | null
      }>
      availableBreakLocations: Array<{
        __typename?: 'Area'
        id: string
        name: string | null
        areaCapacityConfiguration: {
          __typename?: 'AreaCapacityConfiguration'
          parkingCapacity: number
        } | null
      }>
      plannedArea: {
        __typename?: 'Area'
        id: string
        name: string | null
      } | null
    }>
  } | null
}

export type ShiftPlannedHubReturnSectionOptionFragment = {
  __typename?: 'ShiftPlannedHubReturnSectionOption'
  section: Types.ShiftPlannedHubReturnSectionCategory
  availableStartTimes: Array<string>
  earliestSectionStart: string | null
  latestSectionEnd: string | null
  plannedReturnTime: string | null
  providedReason: Types.ShiftPlannedHubReturnProvidedReason | null
  availableAreas: Array<{
    __typename?: 'Area'
    id: string
    name: string | null
    details:
      | { __typename?: 'CooperationDetails' }
      | { __typename?: 'HubDetails'; displayName: string }
      | { __typename?: 'InFieldBreakDetails' }
      | { __typename?: 'ShortBreakDetails' }
      | null
    areaCapacityConfiguration: {
      __typename?: 'AreaCapacityConfiguration'
      parkingCapacity: number
    } | null
  }>
  availableBreakLocations: Array<{
    __typename?: 'Area'
    id: string
    name: string | null
    areaCapacityConfiguration: {
      __typename?: 'AreaCapacityConfiguration'
      parkingCapacity: number
    } | null
  }>
  plannedArea: { __typename?: 'Area'; id: string; name: string | null } | null
}

export const ShiftPlannedHubReturnSectionOptionFragmentDoc = gql`
    fragment shiftPlannedHubReturnSectionOption on ShiftPlannedHubReturnSectionOption {
  section
  availableAreas {
    id
    details {
      ... on HubDetails {
        displayName
      }
    }
    areaCapacityConfiguration {
      parkingCapacity
    }
    name
  }
  availableBreakLocations {
    id
    name
    areaCapacityConfiguration {
      parkingCapacity
    }
  }
  availableStartTimes
  earliestSectionStart
  latestSectionEnd
  plannedArea {
    id
    name
  }
  plannedReturnTime
  providedReason
}
    `
export const ShiftPlannedHubReturnSectionOptionsDocument = gql`
    query shiftPlannedHubReturnSectionOptions($shiftId: ID!) {
  shiftPlannedHubReturnSectionOptions(shiftId: $shiftId) {
    comment {
      author
      comment
      updatedAt
    }
    options {
      ...shiftPlannedHubReturnSectionOption
    }
  }
}
    ${ShiftPlannedHubReturnSectionOptionFragmentDoc}`

/**
 * __useShiftPlannedHubReturnSectionOptionsQuery__
 *
 * To run a query within a React component, call `useShiftPlannedHubReturnSectionOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShiftPlannedHubReturnSectionOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShiftPlannedHubReturnSectionOptionsQuery({
 *   variables: {
 *      shiftId: // value for 'shiftId'
 *   },
 * });
 */
export function useShiftPlannedHubReturnSectionOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ShiftPlannedHubReturnSectionOptionsQuery,
    ShiftPlannedHubReturnSectionOptionsQueryVariables
  > &
    (
      | {
          variables: ShiftPlannedHubReturnSectionOptionsQueryVariables
          skip?: boolean
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ShiftPlannedHubReturnSectionOptionsQuery,
    ShiftPlannedHubReturnSectionOptionsQueryVariables
  >(ShiftPlannedHubReturnSectionOptionsDocument, options)
}
export function useShiftPlannedHubReturnSectionOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ShiftPlannedHubReturnSectionOptionsQuery,
    ShiftPlannedHubReturnSectionOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ShiftPlannedHubReturnSectionOptionsQuery,
    ShiftPlannedHubReturnSectionOptionsQueryVariables
  >(ShiftPlannedHubReturnSectionOptionsDocument, options)
}
export function useShiftPlannedHubReturnSectionOptionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ShiftPlannedHubReturnSectionOptionsQuery,
        ShiftPlannedHubReturnSectionOptionsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ShiftPlannedHubReturnSectionOptionsQuery,
    ShiftPlannedHubReturnSectionOptionsQueryVariables
  >(ShiftPlannedHubReturnSectionOptionsDocument, options)
}
export type ShiftPlannedHubReturnSectionOptionsQueryHookResult = ReturnType<
  typeof useShiftPlannedHubReturnSectionOptionsQuery
>
export type ShiftPlannedHubReturnSectionOptionsLazyQueryHookResult = ReturnType<
  typeof useShiftPlannedHubReturnSectionOptionsLazyQuery
>
export type ShiftPlannedHubReturnSectionOptionsSuspenseQueryHookResult =
  ReturnType<typeof useShiftPlannedHubReturnSectionOptionsSuspenseQuery>
export type ShiftPlannedHubReturnSectionOptionsQueryResult = Apollo.QueryResult<
  ShiftPlannedHubReturnSectionOptionsQuery,
  ShiftPlannedHubReturnSectionOptionsQueryVariables
>
