/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type QueryCustomerGdprSelfAssessmentQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input']
}>

export type QueryCustomerGdprSelfAssessmentQuery = {
  __typename?: 'Query'
  customer: {
    __typename?: 'CustomerResponse'
    customer: {
      __typename?: 'Customer'
      firstName: string | null
      lastName: string | null
      email: string | null
      phoneNumber: string | null
      creationDate: any | null
      lastModifiedDate: any | null
      createdIpAddress: string | null
    } | null
  }
}

export type QueryTripGdprSelfAssessmentQueryVariables = Types.Exact<{
  customerId: Types.Scalars['ID']['input']
}>

export type QueryTripGdprSelfAssessmentQuery = {
  __typename?: 'Query'
  tripHistoryEntries: {
    __typename?: 'TripHistoryEntriesResponse'
    tripHistoryEntries: Array<{
      __typename?: 'TripHistoryEntry'
      orderTimestamp: any | null
      cancellationType: Types.CancellationType | null
      completionTimestamp: any | null
      pickupTimestamp: any | null
      invoiceIdShort: string | null
      passengers: {
        __typename?: 'Passengers'
        adults: number | null
        infants: number | null
      } | null
      origin: {
        __typename?: 'NamedLocation'
        primaryAddress: string | null
        secondaryAddress: string | null
      } | null
      destination: {
        __typename?: 'NamedLocation'
        primaryAddress: string | null
        secondaryAddress: string | null
      } | null
      price: {
        __typename?: 'TripHistoryEntryPrice'
        amount: string | null
        currency: string | null
      } | null
      offer: {
        __typename?: 'Offer'
        price: {
          __typename?: 'TripHistoryEntryPrice'
          amount: string | null
          currency: string | null
        } | null
      } | null
      cardData: {
        __typename?: 'CardData'
        cardType: string | null
        last4Digits: string | null
      } | null
    }>
  }
}

export const QueryCustomerGdprSelfAssessmentDocument = gql`
    query queryCustomerGDPRSelfAssessment($id: ID!) {
  customer(id: $id) {
    customer {
      firstName
      lastName
      email
      phoneNumber
      creationDate
      lastModifiedDate
      createdIpAddress
    }
  }
}
    `

/**
 * __useQueryCustomerGdprSelfAssessmentQuery__
 *
 * To run a query within a React component, call `useQueryCustomerGdprSelfAssessmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCustomerGdprSelfAssessmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCustomerGdprSelfAssessmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQueryCustomerGdprSelfAssessmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryCustomerGdprSelfAssessmentQuery,
    QueryCustomerGdprSelfAssessmentQueryVariables
  > &
    (
      | {
          variables: QueryCustomerGdprSelfAssessmentQueryVariables
          skip?: boolean
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    QueryCustomerGdprSelfAssessmentQuery,
    QueryCustomerGdprSelfAssessmentQueryVariables
  >(QueryCustomerGdprSelfAssessmentDocument, options)
}
export function useQueryCustomerGdprSelfAssessmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryCustomerGdprSelfAssessmentQuery,
    QueryCustomerGdprSelfAssessmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    QueryCustomerGdprSelfAssessmentQuery,
    QueryCustomerGdprSelfAssessmentQueryVariables
  >(QueryCustomerGdprSelfAssessmentDocument, options)
}
export function useQueryCustomerGdprSelfAssessmentSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        QueryCustomerGdprSelfAssessmentQuery,
        QueryCustomerGdprSelfAssessmentQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    QueryCustomerGdprSelfAssessmentQuery,
    QueryCustomerGdprSelfAssessmentQueryVariables
  >(QueryCustomerGdprSelfAssessmentDocument, options)
}
export type QueryCustomerGdprSelfAssessmentQueryHookResult = ReturnType<
  typeof useQueryCustomerGdprSelfAssessmentQuery
>
export type QueryCustomerGdprSelfAssessmentLazyQueryHookResult = ReturnType<
  typeof useQueryCustomerGdprSelfAssessmentLazyQuery
>
export type QueryCustomerGdprSelfAssessmentSuspenseQueryHookResult = ReturnType<
  typeof useQueryCustomerGdprSelfAssessmentSuspenseQuery
>
export type QueryCustomerGdprSelfAssessmentQueryResult = Apollo.QueryResult<
  QueryCustomerGdprSelfAssessmentQuery,
  QueryCustomerGdprSelfAssessmentQueryVariables
>
export const QueryTripGdprSelfAssessmentDocument = gql`
    query queryTripGDPRSelfAssessment($customerId: ID!) {
  tripHistoryEntries(customerId: $customerId) {
    tripHistoryEntries {
      orderTimestamp
      cancellationType
      passengers {
        adults
        infants
      }
      origin {
        primaryAddress
        secondaryAddress
      }
      destination {
        primaryAddress
        secondaryAddress
      }
      completionTimestamp
      pickupTimestamp
      price {
        amount
        currency
      }
      offer {
        price {
          amount
          currency
        }
      }
      invoiceIdShort
      cardData {
        cardType
        last4Digits
      }
    }
  }
}
    `

/**
 * __useQueryTripGdprSelfAssessmentQuery__
 *
 * To run a query within a React component, call `useQueryTripGdprSelfAssessmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryTripGdprSelfAssessmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryTripGdprSelfAssessmentQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useQueryTripGdprSelfAssessmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryTripGdprSelfAssessmentQuery,
    QueryTripGdprSelfAssessmentQueryVariables
  > &
    (
      | { variables: QueryTripGdprSelfAssessmentQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    QueryTripGdprSelfAssessmentQuery,
    QueryTripGdprSelfAssessmentQueryVariables
  >(QueryTripGdprSelfAssessmentDocument, options)
}
export function useQueryTripGdprSelfAssessmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryTripGdprSelfAssessmentQuery,
    QueryTripGdprSelfAssessmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    QueryTripGdprSelfAssessmentQuery,
    QueryTripGdprSelfAssessmentQueryVariables
  >(QueryTripGdprSelfAssessmentDocument, options)
}
export function useQueryTripGdprSelfAssessmentSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        QueryTripGdprSelfAssessmentQuery,
        QueryTripGdprSelfAssessmentQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    QueryTripGdprSelfAssessmentQuery,
    QueryTripGdprSelfAssessmentQueryVariables
  >(QueryTripGdprSelfAssessmentDocument, options)
}
export type QueryTripGdprSelfAssessmentQueryHookResult = ReturnType<
  typeof useQueryTripGdprSelfAssessmentQuery
>
export type QueryTripGdprSelfAssessmentLazyQueryHookResult = ReturnType<
  typeof useQueryTripGdprSelfAssessmentLazyQuery
>
export type QueryTripGdprSelfAssessmentSuspenseQueryHookResult = ReturnType<
  typeof useQueryTripGdprSelfAssessmentSuspenseQuery
>
export type QueryTripGdprSelfAssessmentQueryResult = Apollo.QueryResult<
  QueryTripGdprSelfAssessmentQuery,
  QueryTripGdprSelfAssessmentQueryVariables
>
