/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import { RioTemplateFragmentDoc } from './RioLatestTemplate.hook'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type RioTemplateSelectionQueryVariables = Types.Exact<{
  tenant: Types.Scalars['String']['input']
  categories?: Types.InputMaybe<Array<Types.RioCategory> | Types.RioCategory>
  fleetId?: Types.InputMaybe<Types.Scalars['String']['input']>
}>

export type RioTemplateSelectionQuery = {
  __typename?: 'Query'
  rioTemplateSelection: {
    __typename?: 'RioTemplateSelectionResponse'
    templates: Array<{
      __typename?: 'RioTemplate'
      id: string
      category: Types.RioCategory
      name: string
      tenant: string
      supportedFleets: Array<string> | null
      version: number
      immutable: boolean | null
      title: {
        __typename?: 'RioLabel'
        de: string | null
        en: string | null
      } | null
      newReportTitle: {
        __typename?: 'RioLabel'
        en: string | null
        de: string | null
      } | null
      editReportTitle: {
        __typename?: 'RioLabel'
        en: string | null
        de: string | null
      } | null
      viewReportTitle: {
        __typename?: 'RioLabel'
        en: string | null
        de: string | null
      } | null
      components: Array<
        | {
            __typename: 'RioComponentCheckbox'
            componentId: string
            componentType: Types.RioComponentType
            dependingComponentIds: Array<string> | null
            overrideFailValue: boolean | null
            topDivider: boolean | null
            label: {
              __typename?: 'RioLabel'
              de: string | null
              en: string | null
            }
            description: {
              __typename?: 'RioLabel'
              en: string | null
              de: string | null
            } | null
            checkboxLabel: {
              __typename?: 'RioLabel'
              en: string | null
              de: string | null
            } | null
            trueLabel: {
              __typename?: 'RioLabel'
              en: string | null
              de: string | null
            }
            falseLabel: {
              __typename?: 'RioLabel'
              en: string | null
              de: string | null
            }
            viewLabel: {
              __typename?: 'RioLabel'
              en: string | null
              de: string | null
            } | null
          }
        | {
            __typename: 'RioComponentDate'
            componentId: string
            componentType: Types.RioComponentType
            dependingComponentIds: Array<string> | null
            allowFutureDates: boolean | null
            label: {
              __typename?: 'RioLabel'
              de: string | null
              en: string | null
            }
          }
        | {
            __typename: 'RioComponentGeolocation'
            componentId: string
            componentType: Types.RioComponentType
            dependingComponentIds: Array<string> | null
            label: {
              __typename?: 'RioLabel'
              de: string | null
              en: string | null
            }
            latitudeLabel: {
              __typename?: 'RioLabel'
              en: string | null
              de: string | null
            } | null
            longitudeLabel: {
              __typename?: 'RioLabel'
              en: string | null
              de: string | null
            } | null
            latitudeTooltipLabel: {
              __typename?: 'RioLabel'
              en: string | null
              de: string | null
            } | null
            longitudeTooltipLabel: {
              __typename?: 'RioLabel'
              en: string | null
              de: string | null
            } | null
            viewLabel: {
              __typename?: 'RioLabel'
              en: string | null
              de: string | null
            } | null
          }
        | {
            __typename: 'RioComponentOptions'
            componentId: string
            componentType: Types.RioComponentType
            dependingComponentIds: Array<string> | null
            label: {
              __typename?: 'RioLabel'
              de: string | null
              en: string | null
            }
            options: Array<{
              __typename?: 'RioOptionValue'
              value: string
              label: {
                __typename?: 'RioLabel'
                en: string | null
                de: string | null
              }
            }>
          }
        | {
            __typename: 'RioComponentPassFail'
            componentId: string
            componentType: Types.RioComponentType
            dependingComponentIds: Array<string> | null
            preselectedValue: Types.RioPassFailValue | null
            label: {
              __typename?: 'RioLabel'
              de: string | null
              en: string | null
            }
            passFailValues: {
              __typename?: 'RioPassFailValues'
              pass: Types.RioPassFailValue
              fail: Types.RioPassFailValue
            }
            passLabel: {
              __typename?: 'RioLabel'
              en: string | null
              de: string | null
            }
            failLabel: {
              __typename?: 'RioLabel'
              en: string | null
              de: string | null
            }
            viewLabel: {
              __typename?: 'RioLabel'
              en: string | null
              de: string | null
            } | null
            description: {
              __typename?: 'RioLabel'
              en: string | null
              de: string | null
            } | null
          }
        | {
            __typename: 'RioComponentSimple'
            componentId: string
            componentType: Types.RioComponentType
            dependingComponentIds: Array<string> | null
            label: {
              __typename?: 'RioLabel'
              de: string | null
              en: string | null
            }
          }
        | {
            __typename: 'RioComponentStaticValue'
            componentId: string
            componentType: Types.RioComponentType
            dependingComponentIds: Array<string> | null
            staticValue: Types.RioStaticValue
            label: {
              __typename?: 'RioLabel'
              de: string | null
              en: string | null
            }
          }
        | {
            __typename: 'RioComponentStatus'
            componentId: string
            componentType: Types.RioComponentType
            dependingComponentIds: Array<string> | null
            statusOptions: Array<Types.RioStatusOptions>
            label: {
              __typename?: 'RioLabel'
              de: string | null
              en: string | null
            }
          }
        | {
            __typename: 'RioComponentTextArea'
            componentId: string
            componentType: Types.RioComponentType
            dependingComponentIds: Array<string> | null
            label: {
              __typename?: 'RioLabel'
              de: string | null
              en: string | null
            }
            caption: {
              __typename?: 'RioLabel'
              de: string | null
              en: string | null
            } | null
            placeholder: {
              __typename?: 'RioLabel'
              de: string | null
              en: string | null
            } | null
          }
        | {
            __typename: 'RioComponentTroublemaker'
            componentId: string
            componentType: Types.RioComponentType
            dependingComponentIds: Array<string> | null
            troublemakerOptions: Array<Types.RioTroublemakerOptions>
            label: {
              __typename?: 'RioLabel'
              de: string | null
              en: string | null
            }
          }
      >
      editSections: Array<{
        __typename?: 'RioSection'
        sectionId: string
        componentIds: Array<string>
        label: {
          __typename?: 'RioLabel'
          de: string | null
          en: string | null
        } | null
      }>
      viewSections: Array<{
        __typename?: 'RioSection'
        sectionId: string
        componentIds: Array<string>
        width: Types.RioSectionWidth | null
        label: {
          __typename?: 'RioLabel'
          de: string | null
          en: string | null
        } | null
      }>
      rules: Array<{
        __typename?: 'RioRule'
        componentId: string | null
        sectionId: string | null
        rule: Types.RioRuleDefinition
        otherComponentId: string | null
        otherComponentValue: string | null
        label: {
          __typename?: 'RioLabel'
          de: string | null
          en: string | null
        } | null
      }> | null
    }>
  }
}

export const RioTemplateSelectionDocument = gql`
    query rioTemplateSelection($tenant: String!, $categories: [RioCategory!], $fleetId: String) {
  rioTemplateSelection(
    input: {categories: $categories, fleetId: $fleetId, tenant: $tenant}
  ) {
    templates {
      ...rioTemplate
    }
  }
}
    ${RioTemplateFragmentDoc}`

/**
 * __useRioTemplateSelectionQuery__
 *
 * To run a query within a React component, call `useRioTemplateSelectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useRioTemplateSelectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRioTemplateSelectionQuery({
 *   variables: {
 *      tenant: // value for 'tenant'
 *      categories: // value for 'categories'
 *      fleetId: // value for 'fleetId'
 *   },
 * });
 */
export function useRioTemplateSelectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    RioTemplateSelectionQuery,
    RioTemplateSelectionQueryVariables
  > &
    (
      | { variables: RioTemplateSelectionQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    RioTemplateSelectionQuery,
    RioTemplateSelectionQueryVariables
  >(RioTemplateSelectionDocument, options)
}
export function useRioTemplateSelectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RioTemplateSelectionQuery,
    RioTemplateSelectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    RioTemplateSelectionQuery,
    RioTemplateSelectionQueryVariables
  >(RioTemplateSelectionDocument, options)
}
export function useRioTemplateSelectionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RioTemplateSelectionQuery,
        RioTemplateSelectionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    RioTemplateSelectionQuery,
    RioTemplateSelectionQueryVariables
  >(RioTemplateSelectionDocument, options)
}
export type RioTemplateSelectionQueryHookResult = ReturnType<
  typeof useRioTemplateSelectionQuery
>
export type RioTemplateSelectionLazyQueryHookResult = ReturnType<
  typeof useRioTemplateSelectionLazyQuery
>
export type RioTemplateSelectionSuspenseQueryHookResult = ReturnType<
  typeof useRioTemplateSelectionSuspenseQuery
>
export type RioTemplateSelectionQueryResult = Apollo.QueryResult<
  RioTemplateSelectionQuery,
  RioTemplateSelectionQueryVariables
>
