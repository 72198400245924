/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type VehicleInteractionSubscriptionVariables = Types.Exact<{
  id: Types.Scalars['ID']['input']
}>

export type VehicleInteractionSubscription = {
  __typename?: 'Subscription'
  vehicleInteraction: {
    __typename?: 'VehicleInteractionResponse'
    id: string
    success: boolean
    errors: Array<Types.VehicleInteractionError>
  }
}

export const VehicleInteractionDocument = gql`
    subscription vehicleInteraction($id: ID!) {
  vehicleInteraction(id: $id) {
    id
    success
    errors
  }
}
    `

/**
 * __useVehicleInteractionSubscription__
 *
 * To run a query within a React component, call `useVehicleInteractionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useVehicleInteractionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleInteractionSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVehicleInteractionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    VehicleInteractionSubscription,
    VehicleInteractionSubscriptionVariables
  > &
    (
      | { variables: VehicleInteractionSubscriptionVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    VehicleInteractionSubscription,
    VehicleInteractionSubscriptionVariables
  >(VehicleInteractionDocument, options)
}
export type VehicleInteractionSubscriptionHookResult = ReturnType<
  typeof useVehicleInteractionSubscription
>
export type VehicleInteractionSubscriptionResult =
  Apollo.SubscriptionResult<VehicleInteractionSubscription>
