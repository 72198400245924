import type { ReactNode } from 'react'
import { createContext, memo, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import type { MOIACognitoUser } from '@backoffice-frontend/common'
import {
  getOktaSamlRedirectTo,
  isAuthenticated,
  logout,
  resetPersistedUserSpecificValues,
} from '@backoffice-frontend/common'
import { useRedirect } from './useRedirect'

type AuthContextProps = {
  user: MOIACognitoUser | null
  setUser: (user: MOIACognitoUser | null) => void
}
export const AuthContext = createContext<AuthContextProps | null>(null)

export const AuthContextProvider = memo(
  ({ children }: { children: ReactNode }) => {
    const [user, setUser] = useState<MOIACognitoUser | null>(null)
    const setUserAndCheckForReset = (user: MOIACognitoUser | null) => {
      setUser(user)
      if (user) {
        resetPersistedUserSpecificValues(user.username)
      }
    }
    const navigate = useNavigate()
    const redirect = useRedirect()
    // biome-ignore lint/correctness/useExhaustiveDependencies: only execute this once initially
    useEffect(() => {
      isAuthenticated()
        .then(
          user => {
            setUserAndCheckForReset(user)
            if (user === null) {
              logout().finally(() => {
                navigate('/login')
              })
            } else {
              const url = getOktaSamlRedirectTo()
              if (url) {
                redirect({ url })
              }
              if (!url && location.pathname.includes('login')) {
                redirect({ url: 'welcome' })
              }
            }
          },
          () => {
            setUser(null)
            logout().finally(() => {
              navigate('/login')
            })
          },
        )
        .catch(() => {
          setUser(null)
          logout().finally(() => {
            navigate('/login')
          })
        })
    }, [])
    return (
      <AuthContext.Provider value={{ user, setUser: setUserAndCheckForReset }}>
        {children}
      </AuthContext.Provider>
    )
  },
)

export const useIsAuthenticated = () => {
  const { user } = useContext(AuthContext) ?? { user: null }
  return user
}

export const useSetCognitoUser = () => {
  const { setUser } = useContext(AuthContext) ?? { setUser: null }
  return setUser
}
