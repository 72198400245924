/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type ShiftSetPlannedHubReturnSectionMutationVariables = Types.Exact<{
  shiftId: Types.Scalars['ID']['input']
  plannedAreaId: Types.Scalars['ID']['input']
  plannedReturnTime: Types.Scalars['DateTime']['input']
  section: Types.ShiftPlannedHubReturnSectionCategory
  comment?: Types.InputMaybe<Types.Scalars['String']['input']>
  providedReason?: Types.InputMaybe<Types.ShiftPlannedHubReturnProvidedReason>
}>

export type ShiftSetPlannedHubReturnSectionMutation = {
  __typename?: 'Mutation'
  shiftSetPlannedHubReturnSection: {
    __typename?: 'ShiftSetPlannedHubReturnSectionResponse'
    shiftComment: {
      __typename: 'ShiftComment'
      author: string | null
      comment: string
      updatedAt: string
      backofficeUser: {
        __typename?: 'BackofficeUser'
        id: string
        firstName: string
        lastName: string
      } | null
    } | null
    planned: Array<{
      __typename?: 'ShiftPlannedHubReturnSection'
      plannedReturnTime: string
      section: Types.ShiftPlannedHubReturnSectionCategory
      providedReason: Types.ShiftPlannedHubReturnProvidedReason | null
      plannedArea: {
        __typename?: 'Area'
        id: string
        name: string | null
        details:
          | { __typename?: 'CooperationDetails' }
          | { __typename?: 'HubDetails'; displayName: string }
          | { __typename?: 'InFieldBreakDetails' }
          | { __typename?: 'ShortBreakDetails' }
          | null
      } | null
    }>
  }
}

export type PlannedHubReturnShiftSectionStateFragment = {
  __typename: 'Shift'
  id: string
  state: {
    __typename: 'ShiftState'
    comment: {
      __typename: 'ShiftComment'
      author: string | null
      comment: string
      updatedAt: string
      backofficeUser: {
        __typename?: 'BackofficeUser'
        id: string
        firstName: string
        lastName: string
      } | null
    } | null
    plannedHubReturnSections: {
      __typename: 'ShiftPlannedHubReturnSections'
      planningPossibleFor: Array<Types.ShiftPlannedHubReturnSectionCategory>
      deletePossibleFor: Array<Types.ShiftPlannedHubReturnSectionCategory>
      arrivals: {
        __typename?: 'ShiftPlannedHubReturnArrivals'
        sectionBreakArrivalAt: string | null
        sectionFirstArrivalAt: string | null
        sectionSecondArrivalAt: string | null
        sectionShiftEndArrivalAt: string | null
      }
      planned: Array<{
        __typename?: 'ShiftPlannedHubReturnSection'
        plannedReturnTime: string
        section: Types.ShiftPlannedHubReturnSectionCategory
        providedReason: Types.ShiftPlannedHubReturnProvidedReason | null
        plannedArea: {
          __typename?: 'Area'
          id: string
          name: string | null
          details:
            | { __typename?: 'CooperationDetails' }
            | { __typename?: 'HubDetails'; displayName: string }
            | { __typename?: 'InFieldBreakDetails' }
            | { __typename?: 'ShortBreakDetails' }
            | null
        } | null
      }>
    }
  }
}

export const PlannedHubReturnShiftSectionStateFragmentDoc = gql`
    fragment plannedHubReturnShiftSectionState on Shift {
  id
  state {
    comment {
      author
      comment
      updatedAt
      backofficeUser {
        id
        firstName
        lastName
      }
      __typename
    }
    plannedHubReturnSections {
      arrivals {
        sectionBreakArrivalAt
        sectionFirstArrivalAt
        sectionSecondArrivalAt
        sectionShiftEndArrivalAt
      }
      planningPossibleFor
      deletePossibleFor
      planned {
        plannedArea {
          id
          details {
            ... on HubDetails {
              displayName
            }
          }
          name
        }
        plannedReturnTime
        section
        providedReason
      }
      __typename
    }
    __typename
  }
  __typename
}
    `
export const ShiftSetPlannedHubReturnSectionDocument = gql`
    mutation shiftSetPlannedHubReturnSection($shiftId: ID!, $plannedAreaId: ID!, $plannedReturnTime: DateTime!, $section: ShiftPlannedHubReturnSectionCategory!, $comment: String, $providedReason: ShiftPlannedHubReturnProvidedReason) {
  shiftSetPlannedHubReturnSection(
    input: {shiftId: $shiftId, plannedAreaId: $plannedAreaId, plannedReturnTime: $plannedReturnTime, section: $section, comment: $comment, providedReason: $providedReason}
  ) {
    shiftComment {
      author
      comment
      updatedAt
      backofficeUser {
        id
        firstName
        lastName
      }
      __typename
    }
    planned {
      plannedArea {
        id
        details {
          ... on HubDetails {
            displayName
          }
        }
        name
      }
      plannedReturnTime
      section
      providedReason
    }
  }
}
    `
export type ShiftSetPlannedHubReturnSectionMutationFn = Apollo.MutationFunction<
  ShiftSetPlannedHubReturnSectionMutation,
  ShiftSetPlannedHubReturnSectionMutationVariables
>

/**
 * __useShiftSetPlannedHubReturnSectionMutation__
 *
 * To run a mutation, you first call `useShiftSetPlannedHubReturnSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShiftSetPlannedHubReturnSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shiftSetPlannedHubReturnSectionMutation, { data, loading, error }] = useShiftSetPlannedHubReturnSectionMutation({
 *   variables: {
 *      shiftId: // value for 'shiftId'
 *      plannedAreaId: // value for 'plannedAreaId'
 *      plannedReturnTime: // value for 'plannedReturnTime'
 *      section: // value for 'section'
 *      comment: // value for 'comment'
 *      providedReason: // value for 'providedReason'
 *   },
 * });
 */
export function useShiftSetPlannedHubReturnSectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ShiftSetPlannedHubReturnSectionMutation,
    ShiftSetPlannedHubReturnSectionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ShiftSetPlannedHubReturnSectionMutation,
    ShiftSetPlannedHubReturnSectionMutationVariables
  >(ShiftSetPlannedHubReturnSectionDocument, options)
}
export type ShiftSetPlannedHubReturnSectionMutationHookResult = ReturnType<
  typeof useShiftSetPlannedHubReturnSectionMutation
>
export type ShiftSetPlannedHubReturnSectionMutationResult =
  Apollo.MutationResult<ShiftSetPlannedHubReturnSectionMutation>
export type ShiftSetPlannedHubReturnSectionMutationOptions =
  Apollo.BaseMutationOptions<
    ShiftSetPlannedHubReturnSectionMutation,
    ShiftSetPlannedHubReturnSectionMutationVariables
  >
