/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type UpdateTaskNotificationStatusMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input']
  status: Types.TaskNotificationStatus
}>

export type UpdateTaskNotificationStatusMutation = {
  __typename?: 'Mutation'
  updateTaskNotificationStatus:
    | {
        __typename?: 'HubServiceNotification'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        pended: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
    | {
        __typename?: 'IvcTaskNotification'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        pended: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
    | {
        __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        pended: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
    | {
        __typename?: 'PassengerUnbuckled'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        pended: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
    | {
        __typename?: 'RoadBlockageTaskNotification'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        pended: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
    | {
        __typename?: 'ShiftDeviationNotification'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        pended: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
    | {
        __typename?: 'StopAccessibilityDownvotedByDrivers'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        pended: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
    | {
        __typename?: 'StopActivationFailedNotification'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        pended: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
    | {
        __typename?: 'StopAreaMissingActiveStopsNotification'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        pended: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
    | {
        __typename?: 'StopDownVotedByDrivers'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        pended: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
    | {
        __typename?: 'StopPotentiallyUnreachable'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        pended: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
    | {
        __typename?: 'StopValidationFailed'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        pended: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
    | {
        __typename?: 'TimeManagementNotification'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        pended: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
    | {
        __typename?: 'TripPrebookingNoAssignment'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        pended: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
    | {
        __typename?: 'VehicleAvailabilityNotification'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        pended: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
    | {
        __typename?: 'VehicleIncidentNotification'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        pended: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
    | {
        __typename?: 'VehicleSurveillanceNotification'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        pended: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
    | {
        __typename?: 'VehicleWaypointAttendance'
        id: string
        status: Types.TaskNotificationStatus
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          backofficeUserId: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
        pended: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
        } | null
        resolved: {
          __typename?: 'TaskStateChangeDetails'
          timestamp: string | null
          to: Types.TaskNotificationStatus
          initiatedBySystem: boolean
          backofficeUser: {
            __typename?: 'BackofficeUser'
            username: string
          } | null
        } | null
      }
}

export const UpdateTaskNotificationStatusDocument = gql`
    mutation updateTaskNotificationStatus($id: ID!, $status: TaskNotificationStatus!) {
  updateTaskNotificationStatus(id: $id, status: $status) {
    id
    status
    assigned {
      timestamp
      backofficeUserId
      backofficeUser {
        username
      }
      to
      initiatedBySystem
    }
    pended {
      timestamp
      to
      initiatedBySystem
    }
    resolved {
      timestamp
      backofficeUser {
        username
      }
      to
      initiatedBySystem
    }
  }
}
    `
export type UpdateTaskNotificationStatusMutationFn = Apollo.MutationFunction<
  UpdateTaskNotificationStatusMutation,
  UpdateTaskNotificationStatusMutationVariables
>

/**
 * __useUpdateTaskNotificationStatusMutation__
 *
 * To run a mutation, you first call `useUpdateTaskNotificationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskNotificationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskNotificationStatusMutation, { data, loading, error }] = useUpdateTaskNotificationStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateTaskNotificationStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTaskNotificationStatusMutation,
    UpdateTaskNotificationStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateTaskNotificationStatusMutation,
    UpdateTaskNotificationStatusMutationVariables
  >(UpdateTaskNotificationStatusDocument, options)
}
export type UpdateTaskNotificationStatusMutationHookResult = ReturnType<
  typeof useUpdateTaskNotificationStatusMutation
>
export type UpdateTaskNotificationStatusMutationResult =
  Apollo.MutationResult<UpdateTaskNotificationStatusMutation>
export type UpdateTaskNotificationStatusMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateTaskNotificationStatusMutation,
    UpdateTaskNotificationStatusMutationVariables
  >
