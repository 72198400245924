/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type QueryEmployeesQueryVariables = Types.Exact<{
  employeesFilter?: Types.InputMaybe<Types.EmployeesFilterInput>
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>
}>

export type QueryEmployeesQuery = {
  __typename?: 'Query'
  employees: {
    __typename?: 'Employees'
    employees: Array<{
      __typename?: 'Employee'
      firstName: string | null
      lastName: string | null
      roles: Array<Types.Role>
      id: string
      employeeVehicleInteraction: {
        __typename?: 'EmployeeVehicleInteraction'
        status: Types.EmployeeVehicleInteractionStatus
        token: string | null
      }
    }>
  }
}

export const QueryEmployeesDocument = gql`
    query queryEmployees($employeesFilter: EmployeesFilterInput, $limit: Int) {
  employees(employeesFilter: $employeesFilter, pagination: {limit: $limit}) {
    employees {
      firstName
      lastName
      roles
      id
      employeeVehicleInteraction {
        status
        token
      }
    }
  }
}
    `

/**
 * __useQueryEmployeesQuery__
 *
 * To run a query within a React component, call `useQueryEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryEmployeesQuery({
 *   variables: {
 *      employeesFilter: // value for 'employeesFilter'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useQueryEmployeesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryEmployeesQuery,
    QueryEmployeesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<QueryEmployeesQuery, QueryEmployeesQueryVariables>(
    QueryEmployeesDocument,
    options,
  )
}
export function useQueryEmployeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryEmployeesQuery,
    QueryEmployeesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<QueryEmployeesQuery, QueryEmployeesQueryVariables>(
    QueryEmployeesDocument,
    options,
  )
}
export function useQueryEmployeesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        QueryEmployeesQuery,
        QueryEmployeesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    QueryEmployeesQuery,
    QueryEmployeesQueryVariables
  >(QueryEmployeesDocument, options)
}
export type QueryEmployeesQueryHookResult = ReturnType<
  typeof useQueryEmployeesQuery
>
export type QueryEmployeesLazyQueryHookResult = ReturnType<
  typeof useQueryEmployeesLazyQuery
>
export type QueryEmployeesSuspenseQueryHookResult = ReturnType<
  typeof useQueryEmployeesSuspenseQuery
>
export type QueryEmployeesQueryResult = Apollo.QueryResult<
  QueryEmployeesQuery,
  QueryEmployeesQueryVariables
>
