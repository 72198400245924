/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
export type RioUserFragment = {
  __typename?: 'RioUser'
  backofficeUser: {
    __typename?: 'BackofficeUser'
    id: string
    firstName: string
    lastName: string
  } | null
  employee: {
    __typename?: 'Employee'
    id: string
    firstName: string | null
    lastName: string | null
  } | null
}

export type RioBackofficeUserFragment = {
  __typename?: 'BackofficeUser'
  id: string
  firstName: string
  lastName: string
}

export type RioEmployeeFragment = {
  __typename?: 'Employee'
  id: string
  firstName: string | null
  lastName: string | null
}

export const RioBackofficeUserFragmentDoc = gql`
    fragment rioBackofficeUser on BackofficeUser {
  id
  firstName
  lastName
}
    `
export const RioEmployeeFragmentDoc = gql`
    fragment rioEmployee on Employee {
  id
  firstName
  lastName
}
    `
export const RioUserFragmentDoc = gql`
    fragment rioUser on RioUser {
  backofficeUser {
    ...rioBackofficeUser
  }
  employee {
    ...rioEmployee
  }
}
    ${RioBackofficeUserFragmentDoc}
${RioEmployeeFragmentDoc}`
