/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import { AdVehicleSeatingVehicleFragmentDoc } from '../utils/seating/seating.hook'
import { GetPassengersWaypointFragmentDoc } from '../../../../ad-passenger-list/src/lib/utils/passenger-filters.hook'
import { AlertsModalSkeletonVehicleFragmentDoc } from '../components/AlertsModalSkeleton/AlertsModalSkeleton.hook'
import { AdOnBoardPassengersVehicleFragmentDoc } from '../../../../ad-passenger-list/src/lib/AdOnBoardPassengers/AdOnBoardPassengers.hook'
import {
  AlertVehicleMapVehicleFragmentDoc,
  AlertVehicleMapWaypointFragmentDoc,
} from '../AlertVehicleMap/AlertVehicleMap.hook'
import { EmergencyStopResolutionVehicleFragmentDoc } from './EmergencyStopResolution/emergencyStopResolution.hook'
import { CabinSafetyCheckVehicleFragmentDoc } from '../components/CabinSafetyCheck/CabinSafetyCheck.hook'
import { DepartureVehicleFragmentDoc } from '../components/Departure/Departure.hook'
import { ServiceCancellationActionCardVehicleFragmentDoc } from '../ServiceCancellationModal/serviceCancellationModal.hook'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type EmergencyStopModalQueryVariables = Types.Exact<{
  alertId: Types.Scalars['ID']['input']
  taskNotificationTypes:
    | Array<Types.TaskNotificationType>
    | Types.TaskNotificationType
  taskNotificationStatus:
    | Array<Types.TaskNotificationStatus>
    | Types.TaskNotificationStatus
}>

export type EmergencyStopModalQuery = {
  __typename?: 'Query'
  adAlert: {
    __typename?: 'FccAdAlert'
    status: Types.FccAdAlertStatus
    vehicle: {
      __typename?: 'Vehicle'
      id: string
      isWheelchairAccessible: boolean | null
      isSelfDriving: boolean
      label: number | null
      fccUpcomingWaypoints: Array<{
        __typename?: 'FccWaypoint'
        id: string
        actions: Array<
          | {
              __typename: 'FccWaypointDeliveryAction'
              estimatedAt: string
              id: string
              trip: { __typename?: 'Trip'; id: string } | null
              customer: {
                __typename?: 'Customer'
                firstName: string | null
                lastName: string | null
                extendedWalkingTime: boolean
                visualImpairment: boolean | null
              } | null
              seats: {
                __typename?: 'FccWaypointSeatInfo'
                seats: number
                wheelchairs: number
                childSeats: number
                boosterSeats: number
              }
            }
          | { __typename?: 'FccWaypointHubReturnAction'; estimatedAt: string }
          | {
              __typename: 'FccWaypointPickupAction'
              estimatedAt: string
              id: string
              adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
              trip: { __typename?: 'Trip'; id: string } | null
              customer: {
                __typename?: 'Customer'
                firstName: string | null
                lastName: string | null
                extendedWalkingTime: boolean
                visualImpairment: boolean | null
              } | null
              seats: {
                __typename?: 'FccWaypointSeatInfo'
                seats: number
                wheelchairs: number
                childSeats: number
                boosterSeats: number
              }
            }
          | {
              __typename?: 'FccWaypointReallocationAction'
              estimatedAt: string
            }
        >
        stop: {
          __typename?: 'Stop'
          routingLocation: {
            __typename?: 'Location'
            latitude: number
            longitude: number
          }
        } | null
      }> | null
      adSeatsLayout: {
        __typename?: 'FccSeatLayout'
        thirdRowRight: {
          __typename?: 'FccSeat'
          occupancy: Types.FccSeatOccupancy
          seatbelt: Types.FccSeatbelt
        }
        thirdRowMiddle: {
          __typename?: 'FccSeat'
          occupancy: Types.FccSeatOccupancy
          seatbelt: Types.FccSeatbelt
        }
        thirdRowLeft: {
          __typename?: 'FccSeat'
          occupancy: Types.FccSeatOccupancy
          seatbelt: Types.FccSeatbelt
        }
        secondRowRight: {
          __typename?: 'FccSeat'
          occupancy: Types.FccSeatOccupancy
          seatbelt: Types.FccSeatbelt
        }
        secondRowMiddle: {
          __typename?: 'FccSeat'
          occupancy: Types.FccSeatOccupancy
          seatbelt: Types.FccSeatbelt
        }
        secondRowLeft: {
          __typename?: 'FccSeat'
          occupancy: Types.FccSeatOccupancy
          seatbelt: Types.FccSeatbelt
        }
      } | null
      latestLocation: {
        __typename?: 'VehicleLocation'
        heading: number | null
        location: {
          __typename?: 'Location'
          latitude: number
          longitude: number
        } | null
      } | null
      latestADTelemetry: {
        __typename?: 'ADTelemetry'
        rightSlidingDoorOpen: {
          __typename?: 'ADTelemetryBooleanWithTimestamp'
          value: boolean
          timestamp: string
        } | null
        centralLockLocked: {
          __typename?: 'ADTelemetryBooleanWithTimestamp'
          value: boolean
        } | null
        speed: {
          __typename?: 'ADTelemetryFloatWithTimestamp'
          value: number
          timestamp: string
        } | null
      } | null
      fccCurrentAdAlerts: {
        __typename?: 'FccAdAlerts'
        alerts: Array<{
          __typename: 'FccAdAlert'
          id: string
          type: Types.FccAdAlertType
          createdAt: string
        }>
      } | null
      taskNotifications: {
        __typename?: 'TaskNotificationList'
        tasks: Array<
          | {
              __typename: 'HubServiceNotification'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
          | {
              __typename: 'IvcTaskNotification'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
          | {
              __typename: 'PassengerAuthentificationFallbackRequestedNotification'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
          | {
              __typename: 'PassengerUnbuckled'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
          | {
              __typename: 'RoadBlockageTaskNotification'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
          | {
              __typename: 'ShiftDeviationNotification'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
          | {
              __typename: 'StopAccessibilityDownvotedByDrivers'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
          | {
              __typename: 'StopActivationFailedNotification'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
          | {
              __typename: 'StopAreaMissingActiveStopsNotification'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
          | {
              __typename: 'StopDownVotedByDrivers'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
          | {
              __typename: 'StopPotentiallyUnreachable'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
          | {
              __typename: 'StopValidationFailed'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
          | {
              __typename: 'TimeManagementNotification'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
          | {
              __typename: 'TripPrebookingNoAssignment'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
          | {
              __typename: 'VehicleAvailabilityNotification'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
          | {
              __typename: 'VehicleIncidentNotification'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
          | {
              __typename: 'VehicleSurveillanceNotification'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
          | {
              __typename: 'VehicleWaypointAttendance'
              id: string
              createdAt: string
              severity: Types.TaskNotificationSeverity
              type: Types.TaskNotificationType
            }
        >
      } | null
      pooling: { __typename?: 'Pooling'; enabled: boolean } | null
    } | null
  } | null
}

export const EmergencyStopModalDocument = gql`
    query EmergencyStopModal($alertId: ID!, $taskNotificationTypes: [TaskNotificationType!]!, $taskNotificationStatus: [TaskNotificationStatus!]!) {
  adAlert: fccAdAlertById(id: $alertId) {
    status
    vehicle {
      id
      ...AdVehicleSeatingVehicle
      fccUpcomingWaypoints {
        ...GetPassengersWaypoint
      }
      ...AlertsModalSkeletonVehicle
      ...AdOnBoardPassengersVehicle
      ...AlertVehicleMapVehicle
      ...EmergencyStopResolutionVehicle
      ...CabinSafetyCheckVehicle
      ...DepartureVehicle
      ...ServiceCancellationActionCardVehicle
    }
  }
}
    ${AdVehicleSeatingVehicleFragmentDoc}
${GetPassengersWaypointFragmentDoc}
${AlertsModalSkeletonVehicleFragmentDoc}
${AdOnBoardPassengersVehicleFragmentDoc}
${AlertVehicleMapVehicleFragmentDoc}
${EmergencyStopResolutionVehicleFragmentDoc}
${CabinSafetyCheckVehicleFragmentDoc}
${DepartureVehicleFragmentDoc}
${ServiceCancellationActionCardVehicleFragmentDoc}`

/**
 * __useEmergencyStopModalQuery__
 *
 * To run a query within a React component, call `useEmergencyStopModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmergencyStopModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmergencyStopModalQuery({
 *   variables: {
 *      alertId: // value for 'alertId'
 *      taskNotificationTypes: // value for 'taskNotificationTypes'
 *      taskNotificationStatus: // value for 'taskNotificationStatus'
 *   },
 * });
 */
export function useEmergencyStopModalQuery(
  baseOptions: Apollo.QueryHookOptions<
    EmergencyStopModalQuery,
    EmergencyStopModalQueryVariables
  > &
    (
      | { variables: EmergencyStopModalQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    EmergencyStopModalQuery,
    EmergencyStopModalQueryVariables
  >(EmergencyStopModalDocument, options)
}
export function useEmergencyStopModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmergencyStopModalQuery,
    EmergencyStopModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    EmergencyStopModalQuery,
    EmergencyStopModalQueryVariables
  >(EmergencyStopModalDocument, options)
}
export function useEmergencyStopModalSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        EmergencyStopModalQuery,
        EmergencyStopModalQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    EmergencyStopModalQuery,
    EmergencyStopModalQueryVariables
  >(EmergencyStopModalDocument, options)
}
export type EmergencyStopModalQueryHookResult = ReturnType<
  typeof useEmergencyStopModalQuery
>
export type EmergencyStopModalLazyQueryHookResult = ReturnType<
  typeof useEmergencyStopModalLazyQuery
>
export type EmergencyStopModalSuspenseQueryHookResult = ReturnType<
  typeof useEmergencyStopModalSuspenseQuery
>
export type EmergencyStopModalQueryResult = Apollo.QueryResult<
  EmergencyStopModalQuery,
  EmergencyStopModalQueryVariables
>
