import { ChartBarSquareIcon } from '@moia-dev/pace-icons'
import type { AreaNavigationProps } from '@backoffice-frontend/patterns'
import { NavigationItem } from '@backoffice-frontend/patterns'
import { KpiDashboardAreaRoutes } from './KpiDashboardAreaRoutes'

export const KpiDashboardAreaNavigation = ({
  onClick,
  open,
  label,
}: AreaNavigationProps) => {
  return (
    <NavigationItem
      onClick={onClick}
      open={open}
      label={label}
      to={KpiDashboardAreaRoutes.route}
      Icon={ChartBarSquareIcon}
    />
  )
}
