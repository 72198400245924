import { getJwtString } from './auth'
import { UUID } from './uuid-v4'

export type JsonResponse =
  | Array<{
      code: string
      details: unknown
      message: string
      path: string
    }>
  | {
      message?: string
      error?: string
      errorCode?: number | string
      errorMessage?: string
      translatableError?: {
        errorCode?: string
        errorMessage?: string
      }
    }
  | {
      message: string
    }[]

export class FetchError extends Error {
  constructor(
    public message: string,
    public response: Response,
    public jsonResponse: JsonResponse | unknown,
  ) {
    super(message)

    this.name = this.constructor.name

    // Workaround to make `instanceof FetchError` work in transpiled ES5
    Object.setPrototypeOf(this, FetchError.prototype)
  }
}

export type MoiaFetchOptions = {
  addTraceIdHeader?: boolean
  authHeaderKey?: string
}

const AUTHORIZATION_HEADER = 'Authorization'

export const fetchWithAuthHeaders = (
  url: string,
  options: RequestInit & MoiaFetchOptions = {
    addTraceIdHeader: false,
  },
): Promise<Response> => {
  const { addTraceIdHeader, ...restOptions } = options
  const authHeaderKey = options.authHeaderKey ?? AUTHORIZATION_HEADER

  // we need this to be able to trace requests across several services in Kibana.
  const traceId = UUID()

  return getJwtString().then(jwt => {
    const authHeaders = { [authHeaderKey]: jwt }
    return fetch(url, {
      ...restOptions,
      headers: {
        ...authHeaders,
        ...options.headers,
        ...(addTraceIdHeader ? { 'trace-id': `backoffice_${traceId}` } : {}),
      },
    })
  })
}

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
}

export const fetchWithDefaultHeaders = (
  url: string,
  options: RequestInit & MoiaFetchOptions = {
    addTraceIdHeader: false,
  },
): Promise<Response> => {
  const defaultHeaders = { ...DEFAULT_HEADERS }

  return fetchWithAuthHeaders(url, {
    ...options,
    headers: { ...defaultHeaders, ...options.headers },
  })
}

const stringifyBody = (body: string | unknown) => {
  if (
    typeof body === 'string' ||
    body instanceof FormData ||
    body instanceof File
  ) {
    return body
  }
  return JSON.stringify(body)
}

const assertOkResponse = <T>(response: Response, bodyOrUndefined: T): T => {
  if (response.ok) {
    return bodyOrUndefined
  }
  if (response.status === 403) {
    throw new FetchError(
      `Server responded with 403 unauthorized status, try updating your rights and permissions in
    your account page and refresh. If this error persists, please contact the backoffice platform team`,
      response,
      bodyOrUndefined,
    )
  }
  throw new FetchError(
    `Server responded with status ${response.status} ${response.statusText}`,
    response,
    bodyOrUndefined,
  )
}

const parseJsonResponse = <T>(
  response: Response,
  url: string,
): Promise<T | undefined> =>
  response.text().then(bodyText => {
    if (bodyText) {
      try {
        return JSON.parse(bodyText)
      } catch (jsonParseError: unknown) {
        console.warn('Could not parse HTTP response body as JSON', {
          url,
          bodyText,
          jsonParseError,
        })
      }
    } else {
      console.warn('Could not parse empty HTTP response body as JSON', {
        url,
        status: response.status,
      })
    }
    return undefined
  })

const DEFAULT_OPTIONS: Partial<RequestInit> = {
  mode: 'cors',
}

export const fetchJson = <TResult = undefined, TBody = undefined>(
  url: string,
  options: Omit<RequestInit, 'body'> &
    MoiaFetchOptions & {
      body?: TBody
    } = {
    addTraceIdHeader: false,
  },
): Promise<TResult> => {
  const stringifiedBody = options.body && stringifyBody(options.body)
  const extendedOptions = {
    ...DEFAULT_OPTIONS,
    ...options,
    body: stringifiedBody,
  }

  return fetchWithDefaultHeaders(url, extendedOptions).then(response => {
    if (response.status === 204) {
      // We trust what the generic the caller specifies as TResult
      // since we don't want to force every caller to use a non-null assertion
      // or unnecessarily check for undefined for endpoints that will always return a type,
      // we sacrifice some type-safety for the 204 use-case.
      // biome-ignore lint/suspicious/noExplicitAny: see above
      return undefined as unknown as any
    }

    return parseJsonResponse<TResult>(response, url).then(bodyOrUndefined =>
      assertOkResponse(response, bodyOrUndefined),
    )
  })
}
