/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
export type AdVehicleSeatingVehicleFragment = {
  __typename?: 'Vehicle'
  adSeatsLayout: {
    __typename?: 'FccSeatLayout'
    thirdRowRight: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
    thirdRowMiddle: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
    thirdRowLeft: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
    secondRowRight: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
    secondRowMiddle: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
    secondRowLeft: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
  } | null
}

export const AdVehicleSeatingVehicleFragmentDoc = gql`
    fragment AdVehicleSeatingVehicle on Vehicle {
  adSeatsLayout {
    thirdRowRight {
      occupancy
      seatbelt
    }
    thirdRowMiddle {
      occupancy
      seatbelt
    }
    thirdRowLeft {
      occupancy
      seatbelt
    }
    secondRowRight {
      occupancy
      seatbelt
    }
    secondRowMiddle {
      occupancy
      seatbelt
    }
    secondRowLeft {
      occupancy
      seatbelt
    }
  }
}
    `
