// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts

import { lazy } from 'react'

import {
  FleetManagementTestingAreaId,
  FleetManagementTestingAreaRoutes,
  FleetManagementTestingAreaDataTestIds,
} from '@backoffice-frontend/common'

import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { BoumRight } from '@moia-dev/moia-token-claims'

import { FleetManagementTestingAreaNavigation } from './FleetManagementTestingAreaNavigation'

const LazyFleetManagementTestingComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./FleetManagementTestingArea')),
)

const FleetManagementTestingComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyFleetManagementTestingComponent />
  </SuspenseLoadingWrapper>
)

export const FleetManagementTestingArea = {
  id: FleetManagementTestingAreaId,
  dataTestIds: FleetManagementTestingAreaDataTestIds,
  requiredRight: BoumRight.AREA_VEHICLE,
  NavigationComponent: FleetManagementTestingAreaNavigation,
  route: {
    path: FleetManagementTestingAreaRoutes.route,
    component: FleetManagementTestingComponent,
  },
}
