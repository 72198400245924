import { alpha, useMediaQuery } from '@mui/material'
import classNames from 'clsx'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import type {
  TableProps,
  SortDirectionType,
  ColumnProps,
  TableCellProps,
  RowMouseEventHandlerParams,
} from 'react-virtualized'
import { AutoSizer, Column, Table } from 'react-virtualized'
import { useTheme } from '../../basics/theme'
import { tableActionRowStyle } from '../TableActions'
import type { ColumnData } from './components'
import { headerRenderer } from './components'
import { DISABLE_HEADER, HEADER_HEIGHT, ROW_HEIGHT } from './constants'
import type { SortListArgs } from './useSorting'
import { useSorting } from './useSorting'
import { getColumnWidth } from './utils'

export type CellRendererArgs<T> = Omit<TableCellProps, 'rowData'> & {
  rowData: T
}

export type MoiaColumnProps<T> = Omit<
  ColumnProps,
  'columnData' | 'cellRenderer' | 'width'
> & {
  width?: number | ((width: number, columnCount: number) => number)
  columnData?: ColumnData & { columnHeaderTitle?: string }
  cellRenderer: (args: CellRendererArgs<T>) => React.ReactNode
}

export type RowMouseEvent<T> = {
  rowData: T
} & Pick<RowMouseEventHandlerParams, 'event' | 'index'>

export type VirtualizedTableProps<T> = {
  activeRow?: string
  columns: MoiaColumnProps<T>[]
  dataTestId?: string
  getId?: (args: T) => string
  hideOddRowStyle?: boolean
  initialSortDirection: SortDirectionType
  initialSortKey: string
  noRows?: string
  onRowClick?: (args: RowMouseEvent<T>) => void
  onRowMouseOver?: (args: RowMouseEvent<T>) => void
  rows: T[]
  rowHeight?: number
  shouldScrollToTop?: boolean
  showActiveRowHovering?: boolean
  showPointerOnRowHover?: boolean
  sortList: (args: SortListArgs<T>) => T[]
  onRowsRendered?: TableProps['onRowsRendered']
  registerChild?: TableProps['registerChild']
  /**
   * enables [row click concept](https://moia-dev.atlassian.net/browse/MOIA-37410)
   */
  hasActions?: boolean
  minWidth?: string
  className?: string
  scrollToAlignment?: 'start' | 'end' | 'center' | 'auto'
  renderEmptyRowsState?: JSX.Element
  disableRowHoverForMobileAndTablet?: boolean
}

/**
 * @deprecated use VirtualizedTable from @backoffice-frontend/tables instead
 */
export const VirtualizedTable = function VirtualizedTable<T>({
  activeRow,
  columns,
  dataTestId,
  getId,
  initialSortDirection,
  initialSortKey,
  noRows,
  onRowClick,
  onRowMouseOver,
  rows,
  rowHeight,
  shouldScrollToTop = false,
  scrollToAlignment,
  showActiveRowHovering = false,
  showPointerOnRowHover = false,
  sortList,
  hasActions,
  minWidth,
  className,
  onRowsRendered,
  registerChild,
  renderEmptyRowsState,
  disableRowHoverForMobileAndTablet = false,
}: VirtualizedTableProps<T>) {
  const { t } = useTranslation()
  const theme = useTheme()
  const { sortBy, sortDirection, sortedList, sort } = useSorting(
    {
      sortBy: initialSortKey,
      sortDirection: initialSortDirection,
      sortedList: [],
    },
    sortList,
    rows,
  )
  const isMobileOrTablet = useMediaQuery('(max-width:1180px)')
  const isRowHoverDisabled =
    disableRowHoverForMobileAndTablet && isMobileOrTablet

  const getActiveRow = () =>
    activeRow
      ? sortedList.findIndex(row => getId?.(row) === activeRow)
      : undefined

  const rowClassName: TableProps['rowClassName'] = ({ index }) => {
    const isHeaderRow = index < 0

    return classNames('row', {
      actionRow: hasActions,
      pointer: showPointerOnRowHover || Boolean(onRowClick),
      activeHover: showActiveRowHovering,
      activeRow: getActiveRow() === index,
      header: isHeaderRow,
    })
  }

  const allCustomWidths = columns
    .map(({ width }) => width)
    .filter(w => w !== undefined)
  return (
    <div
      css={{
        height: '100%',
        minWidth,
      }}
      className={className}
    >
      <AutoSizer>
        {({ height, width }) => {
          const allCustomWidthSum = allCustomWidths.reduce(
            (sum: number, nextWidth = 0) => {
              if (typeof nextWidth === 'number') {
                return sum + nextWidth
              }

              return sum + nextWidth(width, columns.length)
            },
            0,
          )
          const autoColumnCount = columns.length - allCustomWidths.length
          const autoWidth = (width - allCustomWidthSum) / autoColumnCount
          const tableRowHoverColor = alpha(
            theme.semantic.ColorSurfaceHovered,
            0.5,
          )
          return (
            <div data-testid={dataTestId}>
              <Table
                css={{
                  '.header > div > div': {
                    color: theme.semantic.ColorContentDefault,
                  },
                  '.ReactVirtualized__Table__headerColumn': {
                    overflowX: 'auto',
                  },
                  '.row': {
                    alignItems: 'center',
                    boxShadow: `inset 0 -1px 0 ${theme.semantic.ColorAccessoryDefault}`,
                    color: theme.semantic.ColorContentDefault,
                    display: 'flex',
                    flexDirection: 'row',
                    outline: 'none',
                  },
                  ...(!isRowHoverDisabled && {
                    '.pointer': {
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    },
                    '.actionRow': tableActionRowStyle(theme),
                    '.activeHover': {
                      backgroundColor: tableRowHoverColor,
                    },
                    '.activeRow': {
                      backgroundColor: theme.semantic.ColorSurfacePressed,
                      activeRowModifierHover: {
                        '&:hover': {
                          backgroundColor: tableRowHoverColor,
                        },
                      },
                    },
                    '.row ': {
                      '&:hover': {
                        backgroundColor: tableRowHoverColor,
                      },
                    },
                    '.header.actionRow': {
                      '&:hover': {
                        background: 'none',
                        cursor: 'default',
                      },
                    },
                    '.header': {
                      '&:hover': {
                        background: 'none',
                      },
                    },
                  }),
                }}
                scrollToAlignment={scrollToAlignment}
                disableHeader={DISABLE_HEADER}
                headerHeight={HEADER_HEIGHT}
                onRowMouseOver={onRowMouseOver}
                onRowClick={onRowClick}
                onRowsRendered={onRowsRendered}
                ref={registerChild}
                height={height}
                rowHeight={rowHeight ?? ROW_HEIGHT}
                rowCount={sortedList.length}
                rowClassName={rowClassName}
                noRowsRenderer={() =>
                  renderEmptyRowsState ? (
                    renderEmptyRowsState
                  ) : (
                    <div
                      css={{
                        color: theme.semantic.ColorContentDefault,
                        padding: theme.spacing(1),
                      }}
                    >
                      {noRows ?? t('patterns_table_noResults')}
                    </div>
                  )
                }
                sort={sort}
                rowGetter={({ index }) => ({
                  ...sortedList[index % sortedList.length],
                  index,
                })}
                sortBy={sortBy}
                sortDirection={sortDirection}
                scrollToIndex={shouldScrollToTop ? 0 : getActiveRow()}
                width={width}
              >
                {columns.map(column => {
                  const columnWidth = column.width
                    ? Number(
                        getColumnWidth(width, column.width, columns.length),
                      )
                    : autoWidth
                  return (
                    <Column
                      cellDataGetter={column.cellDataGetter}
                      cellRenderer={column.cellRenderer}
                      className={classNames(column.className)}
                      columnData={column.columnData}
                      dataKey={column.dataKey}
                      defaultSortDirection={column.defaultSortDirection}
                      disableSort={column.disableSort}
                      flexGrow={column.flexGrow}
                      flexShrink={column.flexShrink}
                      headerClassName={column.headerClassName}
                      headerRenderer={headerRenderer}
                      headerStyle={column.headerStyle}
                      id={column.id}
                      key={column.dataKey}
                      label={column.label}
                      maxWidth={column.maxWidth}
                      minWidth={column.minWidth}
                      style={column.style}
                      width={columnWidth}
                    />
                  )
                })}
              </Table>
            </div>
          )
        }}
      </AutoSizer>
    </div>
  )
}
