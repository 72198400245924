import { CircularProgress, InputAdornment } from '@mui/material'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import * as React from 'react'
import { ChevronDownIcon } from '../../basics/Icons'
import { MoiaCheckbox } from '../MoiaCheckbox'
import type { MoiaDropdownFlexProps } from '../MoiaDropdownFlex/MoiaDropdownFlex'
import { MoiaDropdownFlex } from '../MoiaDropdownFlex/MoiaDropdownFlex'

export type MoiaDropDownOption<T> = {
  value: T
  label: React.ReactNode
  'data-testid'?: string
}

export type MoiaDropdownProps = Omit<MoiaDropdownFlexProps, 'children'> & {
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  options?: MoiaDropDownOption<any>[]
}
export const MoiaDropdown = ({
  options = [],
  emptyOption,
  emptyPlaceholder,
  disabled,
  loading,
  value,
  SelectProps,
  ...otherProps
}: MoiaDropdownProps) => {
  const isDisabled = disabled || loading || options.length === 0

  const valueIsInOptions =
    options.findIndex(option => value === option.value) >= 0
  const sanitizedValue = valueIsInOptions ? value : ''

  return (
    <MoiaDropdownFlex
      fullWidth
      css={theme => ({
        '& .Mui-disabled svg': {
          stroke: theme.semantic.ColorActionSecondaryDisabled,
        },
      })}
      role="listbox"
      disabled={isDisabled}
      value={sanitizedValue}
      InputLabelProps={{ shrink: emptyOption ? true : undefined }}
      InputProps={{
        endAdornment: loading && (
          <InputAdornment position="end">
            <CircularProgress size={24} />
          </InputAdornment>
        ),
      }}
      SelectProps={{
        displayEmpty: !!emptyOption || !!emptyPlaceholder,
        renderValue: selected => {
          if (Array.isArray(selected)) {
            const selectedOptions = options
              .filter(option => selected.includes(option.value))
              .map(option => option.label)
              .join(', ')

            return selectedOptions.length > 0
              ? selectedOptions
              : (emptyPlaceholder ?? emptyOption)
          }
          if (typeof selected === 'string') {
            return (
              options.find(option => option.value === selected)?.label ??
              emptyOption ??
              emptyPlaceholder
            )
          }
          return ''
        },
        IconComponent: ChevronDownIcon,
        ...SelectProps,
      }}
      {...otherProps}
      select
    >
      {options.map(option => (
        <MenuItem
          key={option.value}
          value={option.value}
          data-testid={option['data-testid']}
          css={theme => ({
            '&.Mui-selected': {
              backgroundColor: SelectProps?.multiple
                ? theme.semantic.ColorSurfaceDefault
                : theme.semantic.ColorSurfacePressed,
            },
          })}
        >
          {SelectProps?.multiple && (
            <MoiaCheckbox
              checked={
                Array.isArray(SelectProps?.value) &&
                SelectProps?.value.includes(option.value)
              }
            />
          )}
          <ListItemText primary={option.label} />
        </MenuItem>
      ))}
    </MoiaDropdownFlex>
  )
}
