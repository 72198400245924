/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type ActivateVehicleMutationVariables = Types.Exact<{
  input: Types.ActivateVehicleInput
}>

export type ActivateVehicleMutation = {
  __typename?: 'Mutation'
  activateVehicle: {
    __typename?: 'StateChangeResponse'
    state: Types.VehicleState
    success: boolean
  }
}

export const ActivateVehicleDocument = gql`
    mutation activateVehicle($input: ActivateVehicleInput!) {
  activateVehicle(input: $input) {
    state
    success
  }
}
    `
export type ActivateVehicleMutationFn = Apollo.MutationFunction<
  ActivateVehicleMutation,
  ActivateVehicleMutationVariables
>

/**
 * __useActivateVehicleMutation__
 *
 * To run a mutation, you first call `useActivateVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateVehicleMutation, { data, loading, error }] = useActivateVehicleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateVehicleMutation,
    ActivateVehicleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ActivateVehicleMutation,
    ActivateVehicleMutationVariables
  >(ActivateVehicleDocument, options)
}
export type ActivateVehicleMutationHookResult = ReturnType<
  typeof useActivateVehicleMutation
>
export type ActivateVehicleMutationResult =
  Apollo.MutationResult<ActivateVehicleMutation>
export type ActivateVehicleMutationOptions = Apollo.BaseMutationOptions<
  ActivateVehicleMutation,
  ActivateVehicleMutationVariables
>
