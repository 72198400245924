import { useState, useCallback, useEffect, useRef } from 'react'
import type { SortDirectionType } from 'react-virtualized'
import { trackAnalyticsEvent } from '@backoffice-frontend/tracking'

export type LowerCaseSortDirection = 'asc' | 'desc'
export const lowerCaseSortDirections = (
  sortDirection: SortDirectionType,
): LowerCaseSortDirection => (sortDirection === 'ASC' ? 'asc' : 'desc')

type SortingArgs = {
  sortBy: string
  sortDirection: SortDirectionType
}
type SortingState<T> = {
  sortedList: T[]
} & SortingArgs

export type SortListArgs<T> = {
  rows: T[]
  sortBy: string
  sortDirection: LowerCaseSortDirection
}

export const useSorting = <T>(
  initialState: SortingState<T>,
  sortList: (args: SortListArgs<T>) => T[],
  rows: T[],
): {
  sort(args: SortingArgs): void
} & SortingState<T> => {
  const [state, setState] = useState(initialState)
  const sort = useCallback(
    ({ sortBy, sortDirection }: SortingArgs) => {
      const sortedList = sortList({
        rows,
        sortBy,
        sortDirection: lowerCaseSortDirections(sortDirection),
      })
      setState({
        sortBy,
        sortDirection,
        sortedList,
      })
    },
    [sortList, rows],
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    sort({ sortBy: state.sortBy, sortDirection: state.sortDirection })
  }, [rows, sort, state.sortBy, state.sortDirection])

  return {
    ...state,
    sort,
  }
}

type SortTrackingProps = Pick<SortListArgs<unknown>, 'sortBy' | 'sortDirection'>

type UseSortTracking = (name: string) => (args: SortTrackingProps) => void

export const useSortTracking: UseSortTracking = name => {
  const argsRef = useRef<SortTrackingProps | undefined>()
  const trackSorting = useCallback(
    ({ sortBy, sortDirection }: SortTrackingProps) => {
      // skip initial run as we don't want to track this
      if (argsRef.current === undefined) {
        argsRef.current = { sortBy, sortDirection }
        return
      }

      if (
        argsRef.current.sortBy !== sortBy ||
        argsRef.current.sortDirection !== sortDirection
      ) {
        trackAnalyticsEvent({
          action: 'select',
          label: `${name}_${sortBy}_${sortDirection}`,
        })
        argsRef.current = {
          sortBy,
          sortDirection,
        }
      }
    },
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    [name],
  )

  return trackSorting
}
