// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts

import { lazy } from 'react'

import {
  ShiftsAreaId,
  ShiftsAreaRoutes,
  ShiftsAreaDataTestIds,
} from '@backoffice-frontend/common'

import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { BoumRight } from '@moia-dev/moia-token-claims'

import { ShiftsAreaNavigation } from './ShiftsAreaNavigation'

const LazyShiftsComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./ShiftsArea')),
)

const ShiftsComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyShiftsComponent />
  </SuspenseLoadingWrapper>
)

export const ShiftsArea = {
  id: ShiftsAreaId,
  dataTestIds: ShiftsAreaDataTestIds,
  requiredRight: BoumRight.AREA_SHIFTS,
  NavigationComponent: ShiftsAreaNavigation,
  route: {
    path: ShiftsAreaRoutes.route,
    component: ShiftsComponent,
  },
}
