/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type CurrentVehicleRouteSubscriptionVariables = Types.Exact<{
  vehicleId: Types.Scalars['ID']['input']
}>

export type CurrentVehicleRouteSubscription = {
  __typename?: 'Subscription'
  fccVehicleRoute: {
    __typename?: 'FccVehicleRoute'
    encodedPath: string | null
  }
}

export const CurrentVehicleRouteDocument = gql`
    subscription currentVehicleRoute($vehicleId: ID!) {
  fccVehicleRoute(vehicleId: $vehicleId) {
    encodedPath
  }
}
    `

/**
 * __useCurrentVehicleRouteSubscription__
 *
 * To run a query within a React component, call `useCurrentVehicleRouteSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCurrentVehicleRouteSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentVehicleRouteSubscription({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useCurrentVehicleRouteSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    CurrentVehicleRouteSubscription,
    CurrentVehicleRouteSubscriptionVariables
  > &
    (
      | { variables: CurrentVehicleRouteSubscriptionVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    CurrentVehicleRouteSubscription,
    CurrentVehicleRouteSubscriptionVariables
  >(CurrentVehicleRouteDocument, options)
}
export type CurrentVehicleRouteSubscriptionHookResult = ReturnType<
  typeof useCurrentVehicleRouteSubscription
>
export type CurrentVehicleRouteSubscriptionResult =
  Apollo.SubscriptionResult<CurrentVehicleRouteSubscription>
