import { transformDataTestIds } from '../utils'

const DataTestIds = {
  Base: 'data-testid',
  // Buttons
  CreateButton: 'create-button',
  CancelButton: 'cancel-button',
  NoButton: 'no-button',
  YesButton: 'yes-button',
  CloseButton: 'close-button',
  MoiaChipButton: 'moia-chip-button',
}

export const PatternLibraryDataTestIds = transformDataTestIds(DataTestIds)
