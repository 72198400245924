/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import { GetAlertSeverityAdAlertFragmentDoc } from '../../../../alerts-common/src/lib/utils/getAlertSeverity.hook'
export type AdAlertListVehicleFragment = {
  __typename?: 'Vehicle'
  fccOperatorAdAlerts: {
    __typename?: 'FccAdAlerts'
    alerts: Array<{
      __typename: 'FccAdAlert'
      id: string
      createdAt: string
      type: Types.FccAdAlertType
      status: Types.FccAdAlertStatus
    }>
  } | null
}

export type AdAlertListAdAlertFragment = {
  __typename: 'FccAdAlert'
  id: string
  createdAt: string
  type: Types.FccAdAlertType
  status: Types.FccAdAlertStatus
}

export const AdAlertListAdAlertFragmentDoc = gql`
    fragment AdAlertListAdAlert on FccAdAlert {
  __typename
  id
  createdAt
  type
  status
  ...GetAlertSeverityAdAlert
}
    ${GetAlertSeverityAdAlertFragmentDoc}`
export const AdAlertListVehicleFragmentDoc = gql`
    fragment AdAlertListVehicle on Vehicle {
  fccOperatorAdAlerts {
    alerts {
      ...AdAlertListAdAlert
    }
  }
}
    ${AdAlertListAdAlertFragmentDoc}`
