/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type ConfirmCancelAllTripsMutationVariables = Types.Exact<{
  vehicleId: Types.Scalars['ID']['input']
}>

export type ConfirmCancelAllTripsMutation = {
  __typename?: 'Mutation'
  fccConfirmCancelAllTrips: any | null
}

export const ConfirmCancelAllTripsDocument = gql`
    mutation confirmCancelAllTrips($vehicleId: ID!) {
  fccConfirmCancelAllTrips(input: {vehicleId: $vehicleId})
}
    `
export type ConfirmCancelAllTripsMutationFn = Apollo.MutationFunction<
  ConfirmCancelAllTripsMutation,
  ConfirmCancelAllTripsMutationVariables
>

/**
 * __useConfirmCancelAllTripsMutation__
 *
 * To run a mutation, you first call `useConfirmCancelAllTripsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmCancelAllTripsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmCancelAllTripsMutation, { data, loading, error }] = useConfirmCancelAllTripsMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useConfirmCancelAllTripsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmCancelAllTripsMutation,
    ConfirmCancelAllTripsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ConfirmCancelAllTripsMutation,
    ConfirmCancelAllTripsMutationVariables
  >(ConfirmCancelAllTripsDocument, options)
}
export type ConfirmCancelAllTripsMutationHookResult = ReturnType<
  typeof useConfirmCancelAllTripsMutation
>
export type ConfirmCancelAllTripsMutationResult =
  Apollo.MutationResult<ConfirmCancelAllTripsMutation>
export type ConfirmCancelAllTripsMutationOptions = Apollo.BaseMutationOptions<
  ConfirmCancelAllTripsMutation,
  ConfirmCancelAllTripsMutationVariables
>
