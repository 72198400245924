import { useTranslation } from 'react-i18next'
import { Button, Callout } from '@moia-dev/pace-web'
import {
  getIsOktaSamlOngoing,
  loginWithOkta,
} from '@backoffice-frontend/common'
import {
  MoiaGrid,
  useMediaQuery,
  useTheme,
} from '@backoffice-frontend/patterns'
import { GetSupportChannelByEmail } from '../components/GetSupportChannelByEmail'
import { PublicAppViewContentLayout } from '../components/PublicAppViewContentLayout'
import { useLoginType } from './useLoginType'

export const SingleSignOn = () => {
  const isLoggingInWithOkta = getIsOktaSamlOngoing()
  const { t } = useTranslation()
  const { enableCognitoLogin } = useLoginType()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <PublicAppViewContentLayout
      title={t('loginView_ssoTitle')}
      isMobile={isMobile}
    >
      <div
        css={theme => ({
          marginTop: isMobile ? theme.space.Space2 : theme.space.Space6,
        })}
      >
        {isLoggingInWithOkta === 'ONGOING' && (
          <Callout variant="warning">
            {t('loginView_ssoWaitingForOkta')}
          </Callout>
        )}
        {isLoggingInWithOkta === 'ERROR' && (
          <Callout variant="critical" title={t('loginView_generalError')}>
            <p>{t('loginView_ssoErrorHint')}</p>
            <p>
              <GetSupportChannelByEmail email={'@moia-operations.io'} />
            </p>
            <p>
              <GetSupportChannelByEmail email={'@moia.io'} />
            </p>
          </Callout>
        )}
      </div>
      <MoiaGrid gridGap={isMobile ? 1 : 2} mt={isMobile ? 1 : 3}>
        <Button
          label={t('loginView_ssoLoginWithOkta_button')}
          variant="primary"
          size="default"
          onClick={async () => {
            await loginWithOkta()
          }}
        />
        <Button
          label={t('loginView_notOktaLoginButton')}
          onClick={enableCognitoLogin}
          size="default"
          variant="secondary"
        ></Button>
      </MoiaGrid>
    </PublicAppViewContentLayout>
  )
}
