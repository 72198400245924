/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import {
  IsOpenTaskFragmentDoc,
  IsAssignedTaskFragmentDoc,
  IsAssignedAdAlertFragmentDoc,
  IsOpenAdAlertFragmentDoc,
} from '../../../../alerts-common/src/lib/utils/alertConditions.hook'
import {
  UseAlertTypeTranslationTaskFragmentDoc,
  UseAlertTypeTranslationAdAlertFragmentDoc,
} from '../../../../alerts-common/src/lib/hooks/useAlertTypeTranslation.hook'
export type AlertAssignmentIconTask_HubServiceNotification_Fragment = {
  __typename?: 'HubServiceNotification'
  id: string
  status: Types.TaskNotificationStatus
  notificationType: Types.TaskNotificationType
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type AlertAssignmentIconTask_IvcTaskNotification_Fragment = {
  __typename?: 'IvcTaskNotification'
  id: string
  status: Types.TaskNotificationStatus
  notificationType: Types.TaskNotificationType
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type AlertAssignmentIconTask_PassengerAuthentificationFallbackRequestedNotification_Fragment =
  {
    __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
    id: string
    status: Types.TaskNotificationStatus
    notificationType: Types.TaskNotificationType
    assigned: {
      __typename?: 'TaskStateChangeDetails'
      backofficeUserId: string | null
    } | null
  }

export type AlertAssignmentIconTask_PassengerUnbuckled_Fragment = {
  __typename?: 'PassengerUnbuckled'
  id: string
  status: Types.TaskNotificationStatus
  notificationType: Types.TaskNotificationType
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type AlertAssignmentIconTask_RoadBlockageTaskNotification_Fragment = {
  __typename?: 'RoadBlockageTaskNotification'
  id: string
  status: Types.TaskNotificationStatus
  notificationType: Types.TaskNotificationType
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type AlertAssignmentIconTask_ShiftDeviationNotification_Fragment = {
  __typename?: 'ShiftDeviationNotification'
  id: string
  status: Types.TaskNotificationStatus
  notificationType: Types.TaskNotificationType
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type AlertAssignmentIconTask_StopAccessibilityDownvotedByDrivers_Fragment =
  {
    __typename?: 'StopAccessibilityDownvotedByDrivers'
    id: string
    status: Types.TaskNotificationStatus
    notificationType: Types.TaskNotificationType
    assigned: {
      __typename?: 'TaskStateChangeDetails'
      backofficeUserId: string | null
    } | null
  }

export type AlertAssignmentIconTask_StopActivationFailedNotification_Fragment =
  {
    __typename?: 'StopActivationFailedNotification'
    id: string
    status: Types.TaskNotificationStatus
    notificationType: Types.TaskNotificationType
    assigned: {
      __typename?: 'TaskStateChangeDetails'
      backofficeUserId: string | null
    } | null
  }

export type AlertAssignmentIconTask_StopAreaMissingActiveStopsNotification_Fragment =
  {
    __typename?: 'StopAreaMissingActiveStopsNotification'
    id: string
    status: Types.TaskNotificationStatus
    notificationType: Types.TaskNotificationType
    assigned: {
      __typename?: 'TaskStateChangeDetails'
      backofficeUserId: string | null
    } | null
  }

export type AlertAssignmentIconTask_StopDownVotedByDrivers_Fragment = {
  __typename?: 'StopDownVotedByDrivers'
  id: string
  status: Types.TaskNotificationStatus
  notificationType: Types.TaskNotificationType
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type AlertAssignmentIconTask_StopPotentiallyUnreachable_Fragment = {
  __typename?: 'StopPotentiallyUnreachable'
  id: string
  status: Types.TaskNotificationStatus
  notificationType: Types.TaskNotificationType
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type AlertAssignmentIconTask_StopValidationFailed_Fragment = {
  __typename?: 'StopValidationFailed'
  id: string
  status: Types.TaskNotificationStatus
  notificationType: Types.TaskNotificationType
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type AlertAssignmentIconTask_TimeManagementNotification_Fragment = {
  __typename?: 'TimeManagementNotification'
  id: string
  status: Types.TaskNotificationStatus
  notificationType: Types.TaskNotificationType
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type AlertAssignmentIconTask_TripPrebookingNoAssignment_Fragment = {
  __typename?: 'TripPrebookingNoAssignment'
  id: string
  status: Types.TaskNotificationStatus
  notificationType: Types.TaskNotificationType
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type AlertAssignmentIconTask_VehicleAvailabilityNotification_Fragment = {
  __typename?: 'VehicleAvailabilityNotification'
  id: string
  status: Types.TaskNotificationStatus
  notificationType: Types.TaskNotificationType
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type AlertAssignmentIconTask_VehicleIncidentNotification_Fragment = {
  __typename?: 'VehicleIncidentNotification'
  id: string
  status: Types.TaskNotificationStatus
  notificationType: Types.TaskNotificationType
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type AlertAssignmentIconTask_VehicleSurveillanceNotification_Fragment = {
  __typename?: 'VehicleSurveillanceNotification'
  id: string
  status: Types.TaskNotificationStatus
  notificationType: Types.TaskNotificationType
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type AlertAssignmentIconTask_VehicleWaypointAttendance_Fragment = {
  __typename?: 'VehicleWaypointAttendance'
  id: string
  status: Types.TaskNotificationStatus
  notificationType: Types.TaskNotificationType
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type AlertAssignmentIconTaskFragment =
  | AlertAssignmentIconTask_HubServiceNotification_Fragment
  | AlertAssignmentIconTask_IvcTaskNotification_Fragment
  | AlertAssignmentIconTask_PassengerAuthentificationFallbackRequestedNotification_Fragment
  | AlertAssignmentIconTask_PassengerUnbuckled_Fragment
  | AlertAssignmentIconTask_RoadBlockageTaskNotification_Fragment
  | AlertAssignmentIconTask_ShiftDeviationNotification_Fragment
  | AlertAssignmentIconTask_StopAccessibilityDownvotedByDrivers_Fragment
  | AlertAssignmentIconTask_StopActivationFailedNotification_Fragment
  | AlertAssignmentIconTask_StopAreaMissingActiveStopsNotification_Fragment
  | AlertAssignmentIconTask_StopDownVotedByDrivers_Fragment
  | AlertAssignmentIconTask_StopPotentiallyUnreachable_Fragment
  | AlertAssignmentIconTask_StopValidationFailed_Fragment
  | AlertAssignmentIconTask_TimeManagementNotification_Fragment
  | AlertAssignmentIconTask_TripPrebookingNoAssignment_Fragment
  | AlertAssignmentIconTask_VehicleAvailabilityNotification_Fragment
  | AlertAssignmentIconTask_VehicleIncidentNotification_Fragment
  | AlertAssignmentIconTask_VehicleSurveillanceNotification_Fragment
  | AlertAssignmentIconTask_VehicleWaypointAttendance_Fragment

export type AlertAssignmentIconAdAlertFragment = {
  __typename?: 'FccAdAlert'
  id: string
  status: Types.FccAdAlertStatus
  type: Types.FccAdAlertType
  assignee: { __typename?: 'BackofficeUser'; id: string } | null
}

export const AlertAssignmentIconTaskFragmentDoc = gql`
    fragment AlertAssignmentIconTask on TaskNotification {
  id
  ...IsOpenTask
  ...IsAssignedTask
  ...UseAlertTypeTranslationTask
}
    ${IsOpenTaskFragmentDoc}
${IsAssignedTaskFragmentDoc}
${UseAlertTypeTranslationTaskFragmentDoc}`
export const AlertAssignmentIconAdAlertFragmentDoc = gql`
    fragment AlertAssignmentIconAdAlert on FccAdAlert {
  id
  ...IsOpenAdAlert
  ...IsAssignedAdAlert
  ...UseAlertTypeTranslationAdAlert
}
    ${IsOpenAdAlertFragmentDoc}
${IsAssignedAdAlertFragmentDoc}
${UseAlertTypeTranslationAdAlertFragmentDoc}`
