/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type VehicleInteractionsQueryVariables = Types.Exact<{
  vehicleInteractionsQueryInput: Types.VehicleInteractionsQueryInput
}>

export type VehicleInteractionsQuery = {
  __typename?: 'Query'
  vehicleInteractions: {
    __typename?: 'VehicleInteractionsResponse'
    count: number
    vehicleInteractions: Array<{
      __typename?: 'VehicleInteraction'
      hardwareKeyUsed: boolean
      highVoltageBatteryLevel: number | null
      id: string
      isBackofficeInteraction: boolean
      mileage: number | null
      reason: string | null
      shiftId: string | null
      timestamp: string
      type: Types.VehicleInteractionType
      backofficeUser: {
        __typename?: 'BackofficeUser'
        firstName: string
        lastName: string
      } | null
      employee: {
        __typename?: 'Employee'
        id: string
        firstName: string | null
        lastName: string | null
        roles: Array<Types.Role>
      } | null
      hub: {
        __typename?: 'Hub'
        displayName: string
        location: {
          __typename?: 'Location'
          latitude: number
          longitude: number
        }
      } | null
    }>
  }
}

export const VehicleInteractionsDocument = gql`
    query vehicleInteractions($vehicleInteractionsQueryInput: VehicleInteractionsQueryInput!) {
  vehicleInteractions(
    vehicleInteractionsQueryInput: $vehicleInteractionsQueryInput
  ) {
    vehicleInteractions {
      backofficeUser {
        firstName
        lastName
      }
      employee {
        id
        firstName
        lastName
        roles
      }
      hardwareKeyUsed
      highVoltageBatteryLevel
      hub {
        displayName
        location {
          latitude
          longitude
        }
      }
      id
      isBackofficeInteraction
      mileage
      reason
      shiftId
      timestamp
      type
    }
    count
  }
}
    `

/**
 * __useVehicleInteractionsQuery__
 *
 * To run a query within a React component, call `useVehicleInteractionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleInteractionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleInteractionsQuery({
 *   variables: {
 *      vehicleInteractionsQueryInput: // value for 'vehicleInteractionsQueryInput'
 *   },
 * });
 */
export function useVehicleInteractionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    VehicleInteractionsQuery,
    VehicleInteractionsQueryVariables
  > &
    (
      | { variables: VehicleInteractionsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    VehicleInteractionsQuery,
    VehicleInteractionsQueryVariables
  >(VehicleInteractionsDocument, options)
}
export function useVehicleInteractionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VehicleInteractionsQuery,
    VehicleInteractionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    VehicleInteractionsQuery,
    VehicleInteractionsQueryVariables
  >(VehicleInteractionsDocument, options)
}
export function useVehicleInteractionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        VehicleInteractionsQuery,
        VehicleInteractionsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    VehicleInteractionsQuery,
    VehicleInteractionsQueryVariables
  >(VehicleInteractionsDocument, options)
}
export type VehicleInteractionsQueryHookResult = ReturnType<
  typeof useVehicleInteractionsQuery
>
export type VehicleInteractionsLazyQueryHookResult = ReturnType<
  typeof useVehicleInteractionsLazyQuery
>
export type VehicleInteractionsSuspenseQueryHookResult = ReturnType<
  typeof useVehicleInteractionsSuspenseQuery
>
export type VehicleInteractionsQueryResult = Apollo.QueryResult<
  VehicleInteractionsQuery,
  VehicleInteractionsQueryVariables
>
