import { useSnackbar } from 'notistack'
import type { SnackbarKey } from 'notistack'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Toast } from '@moia-dev/pace-web'

type MoiaErrorNotificationProps = {
  message: string
  notificationKey: SnackbarKey
}

export const MoiaErrorNotification = forwardRef<
  HTMLDivElement,
  MoiaErrorNotificationProps
>(({ notificationKey, message }, ref) => {
  const { closeSnackbar } = useSnackbar()
  const { t } = useTranslation()

  return (
    <div ref={ref} role="alert">
      <Toast
        variant="critical"
        action={() => closeSnackbar(notificationKey)}
        actionLabel={t('common_ok_label')}
      >
        {message}
      </Toast>
    </div>
  )
})
