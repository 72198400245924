/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
export type UpdateCommentFragment = {
  __typename: 'Shift'
  id: string
  state: {
    __typename: 'ShiftState'
    comment: {
      __typename: 'ShiftComment'
      author: string | null
      comment: string
      updatedAt: string
      backofficeUser: { __typename: 'BackofficeUser'; id: string } | null
    } | null
  }
}

export const UpdateCommentFragmentDoc = gql`
    fragment updateComment on Shift {
  id
  state {
    comment {
      author
      backofficeUser {
        id
        __typename
      }
      comment
      updatedAt
      __typename
    }
    __typename
  }
  __typename
}
    `
