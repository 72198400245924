/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type BackofficeStatusUpdateMutationVariables = Types.Exact<{
  input: Types.BackofficeStatusUpdate
}>

export type BackofficeStatusUpdateMutation = {
  __typename?: 'Mutation'
  backofficeStatusUpdate: {
    __typename?: 'BackofficeStatus'
    id: string
    creationDate: string
    title: string
    type: Types.BackofficeStatusType
    notes: string
  }
}

export const BackofficeStatusUpdateDocument = gql`
    mutation backofficeStatusUpdate($input: BackofficeStatusUpdate!) {
  backofficeStatusUpdate(input: $input) {
    id
    creationDate
    title
    type
    notes
  }
}
    `
export type BackofficeStatusUpdateMutationFn = Apollo.MutationFunction<
  BackofficeStatusUpdateMutation,
  BackofficeStatusUpdateMutationVariables
>

/**
 * __useBackofficeStatusUpdateMutation__
 *
 * To run a mutation, you first call `useBackofficeStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackofficeStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backofficeStatusUpdateMutation, { data, loading, error }] = useBackofficeStatusUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBackofficeStatusUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BackofficeStatusUpdateMutation,
    BackofficeStatusUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BackofficeStatusUpdateMutation,
    BackofficeStatusUpdateMutationVariables
  >(BackofficeStatusUpdateDocument, options)
}
export type BackofficeStatusUpdateMutationHookResult = ReturnType<
  typeof useBackofficeStatusUpdateMutation
>
export type BackofficeStatusUpdateMutationResult =
  Apollo.MutationResult<BackofficeStatusUpdateMutation>
export type BackofficeStatusUpdateMutationOptions = Apollo.BaseMutationOptions<
  BackofficeStatusUpdateMutation,
  BackofficeStatusUpdateMutationVariables
>
