// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts

import { lazy } from 'react'

import {
  HubsAreaId,
  HubsAreaRoutes,
  HubsAreaDataTestIds,
} from '@backoffice-frontend/common'

import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { BoumRight } from '@moia-dev/moia-token-claims'

import { HubsAreaNavigation } from './HubsAreaNavigation'

const LazyHubsComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./HubsArea')),
)

const HubsComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyHubsComponent />
  </SuspenseLoadingWrapper>
)

export const HubsArea = {
  id: HubsAreaId,
  dataTestIds: HubsAreaDataTestIds,
  requiredRight: BoumRight.AREA_HUBS,
  NavigationComponent: HubsAreaNavigation,
  route: {
    path: HubsAreaRoutes.route,
    component: HubsComponent,
  },
}
