/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
export type AlertVehicleMapVehicleFragment = {
  __typename?: 'Vehicle'
  id: string
  latestLocation: {
    __typename?: 'VehicleLocation'
    heading: number | null
    location: {
      __typename?: 'Location'
      latitude: number
      longitude: number
    } | null
  } | null
  fccUpcomingWaypoints: Array<{
    __typename?: 'FccWaypoint'
    id: string
    stop: {
      __typename?: 'Stop'
      routingLocation: {
        __typename?: 'Location'
        latitude: number
        longitude: number
      }
    } | null
    actions: Array<
      | { __typename?: 'FccWaypointDeliveryAction'; estimatedAt: string }
      | { __typename?: 'FccWaypointHubReturnAction'; estimatedAt: string }
      | { __typename?: 'FccWaypointPickupAction'; estimatedAt: string }
      | { __typename?: 'FccWaypointReallocationAction'; estimatedAt: string }
    >
  }> | null
}

export type AlertVehicleMapWaypointFragment = {
  __typename?: 'FccWaypoint'
  id: string
  stop: {
    __typename?: 'Stop'
    routingLocation: {
      __typename?: 'Location'
      latitude: number
      longitude: number
    }
  } | null
  actions: Array<
    | { __typename?: 'FccWaypointDeliveryAction'; estimatedAt: string }
    | { __typename?: 'FccWaypointHubReturnAction'; estimatedAt: string }
    | { __typename?: 'FccWaypointPickupAction'; estimatedAt: string }
    | { __typename?: 'FccWaypointReallocationAction'; estimatedAt: string }
  >
}

export type AlertVehicleMapLocationFragment = {
  __typename?: 'Location'
  latitude: number
  longitude: number
}

export const AlertVehicleMapLocationFragmentDoc = gql`
    fragment AlertVehicleMapLocation on Location {
  latitude
  longitude
}
    `
export const AlertVehicleMapWaypointFragmentDoc = gql`
    fragment AlertVehicleMapWaypoint on FccWaypoint {
  id
  stop {
    routingLocation {
      ...AlertVehicleMapLocation
    }
  }
  actions {
    ... on FccWaypointDeliveryAction {
      estimatedAt
    }
    ... on FccWaypointPickupAction {
      estimatedAt
    }
    ... on FccWaypointHubReturnAction {
      estimatedAt
    }
    ... on FccWaypointReallocationAction {
      estimatedAt
    }
  }
}
    ${AlertVehicleMapLocationFragmentDoc}`
export const AlertVehicleMapVehicleFragmentDoc = gql`
    fragment AlertVehicleMapVehicle on Vehicle {
  id
  latestLocation {
    location {
      ...AlertVehicleMapLocation
    }
    heading
  }
  fccUpcomingWaypoints {
    ...AlertVehicleMapWaypoint
  }
}
    ${AlertVehicleMapLocationFragmentDoc}
${AlertVehicleMapWaypointFragmentDoc}`
