/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
export type AdVehicleParkingStatusFragment = {
  __typename?: 'Vehicle'
  id: string
  latestADTelemetry: {
    __typename?: 'ADTelemetry'
    speed: {
      __typename?: 'ADTelemetryFloatWithTimestamp'
      value: number
    } | null
    rightSlidingDoorOpen: {
      __typename?: 'ADTelemetryBooleanWithTimestamp'
      value: boolean
    } | null
  } | null
}

export const AdVehicleParkingStatusFragmentDoc = gql`
    fragment AdVehicleParkingStatus on Vehicle {
  id
  latestADTelemetry {
    speed {
      value
    }
    rightSlidingDoorOpen {
      value
    }
  }
}
    `
