/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type UnderAndOverSupplyQueryVariables = Types.Exact<{
  from: Types.Scalars['DateTime']['input']
  to: Types.Scalars['DateTime']['input']
  serviceAreaId: Types.Scalars['ID']['input']
}>

export type UnderAndOverSupplyQuery = {
  __typename?: 'Query'
  shiftsDemandSupply: Array<{
    __typename?: 'ShiftsDemandSupplyData'
    timestamp: string
    demandInShifts: number
    supplyInShifts: number
    inServiceHours: boolean
    updatedAt: string
  }>
}

export const UnderAndOverSupplyDocument = gql`
    query underAndOverSupply($from: DateTime!, $to: DateTime!, $serviceAreaId: ID!) {
  shiftsDemandSupply(from: $from, to: $to, serviceAreaId: $serviceAreaId) {
    timestamp
    demandInShifts
    supplyInShifts
    inServiceHours
    updatedAt
  }
}
    `

/**
 * __useUnderAndOverSupplyQuery__
 *
 * To run a query within a React component, call `useUnderAndOverSupplyQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnderAndOverSupplyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnderAndOverSupplyQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      serviceAreaId: // value for 'serviceAreaId'
 *   },
 * });
 */
export function useUnderAndOverSupplyQuery(
  baseOptions: Apollo.QueryHookOptions<
    UnderAndOverSupplyQuery,
    UnderAndOverSupplyQueryVariables
  > &
    (
      | { variables: UnderAndOverSupplyQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UnderAndOverSupplyQuery,
    UnderAndOverSupplyQueryVariables
  >(UnderAndOverSupplyDocument, options)
}
export function useUnderAndOverSupplyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UnderAndOverSupplyQuery,
    UnderAndOverSupplyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UnderAndOverSupplyQuery,
    UnderAndOverSupplyQueryVariables
  >(UnderAndOverSupplyDocument, options)
}
export function useUnderAndOverSupplySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        UnderAndOverSupplyQuery,
        UnderAndOverSupplyQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    UnderAndOverSupplyQuery,
    UnderAndOverSupplyQueryVariables
  >(UnderAndOverSupplyDocument, options)
}
export type UnderAndOverSupplyQueryHookResult = ReturnType<
  typeof useUnderAndOverSupplyQuery
>
export type UnderAndOverSupplyLazyQueryHookResult = ReturnType<
  typeof useUnderAndOverSupplyLazyQuery
>
export type UnderAndOverSupplySuspenseQueryHookResult = ReturnType<
  typeof useUnderAndOverSupplySuspenseQuery
>
export type UnderAndOverSupplyQueryResult = Apollo.QueryResult<
  UnderAndOverSupplyQuery,
  UnderAndOverSupplyQueryVariables
>
