/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
export type UseCurrentSortedAlertsTask_HubServiceNotification_Fragment = {
  __typename?: 'HubServiceNotification'
  status: Types.TaskNotificationStatus
  createdAt: string
}

export type UseCurrentSortedAlertsTask_IvcTaskNotification_Fragment = {
  __typename?: 'IvcTaskNotification'
  status: Types.TaskNotificationStatus
  createdAt: string
}

export type UseCurrentSortedAlertsTask_PassengerAuthentificationFallbackRequestedNotification_Fragment =
  {
    __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
    status: Types.TaskNotificationStatus
    createdAt: string
  }

export type UseCurrentSortedAlertsTask_PassengerUnbuckled_Fragment = {
  __typename?: 'PassengerUnbuckled'
  status: Types.TaskNotificationStatus
  createdAt: string
}

export type UseCurrentSortedAlertsTask_RoadBlockageTaskNotification_Fragment = {
  __typename?: 'RoadBlockageTaskNotification'
  status: Types.TaskNotificationStatus
  createdAt: string
}

export type UseCurrentSortedAlertsTask_ShiftDeviationNotification_Fragment = {
  __typename?: 'ShiftDeviationNotification'
  status: Types.TaskNotificationStatus
  createdAt: string
}

export type UseCurrentSortedAlertsTask_StopAccessibilityDownvotedByDrivers_Fragment =
  {
    __typename?: 'StopAccessibilityDownvotedByDrivers'
    status: Types.TaskNotificationStatus
    createdAt: string
  }

export type UseCurrentSortedAlertsTask_StopActivationFailedNotification_Fragment =
  {
    __typename?: 'StopActivationFailedNotification'
    status: Types.TaskNotificationStatus
    createdAt: string
  }

export type UseCurrentSortedAlertsTask_StopAreaMissingActiveStopsNotification_Fragment =
  {
    __typename?: 'StopAreaMissingActiveStopsNotification'
    status: Types.TaskNotificationStatus
    createdAt: string
  }

export type UseCurrentSortedAlertsTask_StopDownVotedByDrivers_Fragment = {
  __typename?: 'StopDownVotedByDrivers'
  status: Types.TaskNotificationStatus
  createdAt: string
}

export type UseCurrentSortedAlertsTask_StopPotentiallyUnreachable_Fragment = {
  __typename?: 'StopPotentiallyUnreachable'
  status: Types.TaskNotificationStatus
  createdAt: string
}

export type UseCurrentSortedAlertsTask_StopValidationFailed_Fragment = {
  __typename?: 'StopValidationFailed'
  status: Types.TaskNotificationStatus
  createdAt: string
}

export type UseCurrentSortedAlertsTask_TimeManagementNotification_Fragment = {
  __typename?: 'TimeManagementNotification'
  status: Types.TaskNotificationStatus
  createdAt: string
}

export type UseCurrentSortedAlertsTask_TripPrebookingNoAssignment_Fragment = {
  __typename?: 'TripPrebookingNoAssignment'
  status: Types.TaskNotificationStatus
  createdAt: string
}

export type UseCurrentSortedAlertsTask_VehicleAvailabilityNotification_Fragment =
  {
    __typename?: 'VehicleAvailabilityNotification'
    status: Types.TaskNotificationStatus
    createdAt: string
  }

export type UseCurrentSortedAlertsTask_VehicleIncidentNotification_Fragment = {
  __typename?: 'VehicleIncidentNotification'
  status: Types.TaskNotificationStatus
  createdAt: string
}

export type UseCurrentSortedAlertsTask_VehicleSurveillanceNotification_Fragment =
  {
    __typename?: 'VehicleSurveillanceNotification'
    status: Types.TaskNotificationStatus
    createdAt: string
  }

export type UseCurrentSortedAlertsTask_VehicleWaypointAttendance_Fragment = {
  __typename?: 'VehicleWaypointAttendance'
  status: Types.TaskNotificationStatus
  createdAt: string
}

export type UseCurrentSortedAlertsTaskFragment =
  | UseCurrentSortedAlertsTask_HubServiceNotification_Fragment
  | UseCurrentSortedAlertsTask_IvcTaskNotification_Fragment
  | UseCurrentSortedAlertsTask_PassengerAuthentificationFallbackRequestedNotification_Fragment
  | UseCurrentSortedAlertsTask_PassengerUnbuckled_Fragment
  | UseCurrentSortedAlertsTask_RoadBlockageTaskNotification_Fragment
  | UseCurrentSortedAlertsTask_ShiftDeviationNotification_Fragment
  | UseCurrentSortedAlertsTask_StopAccessibilityDownvotedByDrivers_Fragment
  | UseCurrentSortedAlertsTask_StopActivationFailedNotification_Fragment
  | UseCurrentSortedAlertsTask_StopAreaMissingActiveStopsNotification_Fragment
  | UseCurrentSortedAlertsTask_StopDownVotedByDrivers_Fragment
  | UseCurrentSortedAlertsTask_StopPotentiallyUnreachable_Fragment
  | UseCurrentSortedAlertsTask_StopValidationFailed_Fragment
  | UseCurrentSortedAlertsTask_TimeManagementNotification_Fragment
  | UseCurrentSortedAlertsTask_TripPrebookingNoAssignment_Fragment
  | UseCurrentSortedAlertsTask_VehicleAvailabilityNotification_Fragment
  | UseCurrentSortedAlertsTask_VehicleIncidentNotification_Fragment
  | UseCurrentSortedAlertsTask_VehicleSurveillanceNotification_Fragment
  | UseCurrentSortedAlertsTask_VehicleWaypointAttendance_Fragment

export type UseCurrentSortedAlertsAdAlertFragment = {
  __typename?: 'FccAdAlert'
  createdAt: string
}

export const UseCurrentSortedAlertsTaskFragmentDoc = gql`
    fragment UseCurrentSortedAlertsTask on TaskNotification {
  status
  createdAt
}
    `
export const UseCurrentSortedAlertsAdAlertFragmentDoc = gql`
    fragment UseCurrentSortedAlertsAdAlert on FccAdAlert {
  createdAt
}
    `
