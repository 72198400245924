/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type FindVehicleByLabelQueryVariables = Types.Exact<{
  serviceArea: Types.Scalars['ID']['input']
  search: Types.Scalars['String']['input']
}>

export type FindVehicleByLabelQuery = {
  __typename?: 'Query'
  fleetVehicles: {
    __typename?: 'VehiclesResponse'
    vehicles: Array<{
      __typename?: 'Vehicle'
      id: string
      state: Types.VehicleState
    }>
  }
}

export const FindVehicleByLabelDocument = gql`
    query findVehicleByLabel($serviceArea: ID!, $search: String!) {
  fleetVehicles(input: {serviceAreaUUID: $serviceArea, search: $search}) {
    vehicles {
      id
      state
    }
  }
}
    `

/**
 * __useFindVehicleByLabelQuery__
 *
 * To run a query within a React component, call `useFindVehicleByLabelQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindVehicleByLabelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindVehicleByLabelQuery({
 *   variables: {
 *      serviceArea: // value for 'serviceArea'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useFindVehicleByLabelQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindVehicleByLabelQuery,
    FindVehicleByLabelQueryVariables
  > &
    (
      | { variables: FindVehicleByLabelQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    FindVehicleByLabelQuery,
    FindVehicleByLabelQueryVariables
  >(FindVehicleByLabelDocument, options)
}
export function useFindVehicleByLabelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindVehicleByLabelQuery,
    FindVehicleByLabelQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    FindVehicleByLabelQuery,
    FindVehicleByLabelQueryVariables
  >(FindVehicleByLabelDocument, options)
}
export function useFindVehicleByLabelSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindVehicleByLabelQuery,
        FindVehicleByLabelQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    FindVehicleByLabelQuery,
    FindVehicleByLabelQueryVariables
  >(FindVehicleByLabelDocument, options)
}
export type FindVehicleByLabelQueryHookResult = ReturnType<
  typeof useFindVehicleByLabelQuery
>
export type FindVehicleByLabelLazyQueryHookResult = ReturnType<
  typeof useFindVehicleByLabelLazyQuery
>
export type FindVehicleByLabelSuspenseQueryHookResult = ReturnType<
  typeof useFindVehicleByLabelSuspenseQuery
>
export type FindVehicleByLabelQueryResult = Apollo.QueryResult<
  FindVehicleByLabelQuery,
  FindVehicleByLabelQueryVariables
>
