/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import { HasHubReturnNextFragmentDoc } from '../../../../../vehicle-common/src/lib/utils/vehicleValidators.hook'
import { UseFollowVehicleFragmentDoc } from './hooks/useFollowVehicle.hook'
import { UseRebalancingCircleVehicleFragmentDoc } from './hooks/useRebalancingCircle.hook'
import { UseLocationSelectionVehicleFragmentDoc } from './hooks/useLocationSelection.hook'
import { VehicleMarkerVehicleFragmentDoc } from '../MapMarkers/VehicleMarker.hook'
import { AdVehicleMarkerVehicleFragmentDoc } from '../MapMarkers/AdVehicleMarker.hook'
import { VehicleListOverlayVehicleFragmentDoc } from '../VehicleListOverlay.hook'
import { FilterByNotBookableOptionVehicleFragmentDoc } from './utils/filterByNotBookableOption.hook'
import { FilterByBookableOptionVehicleFragmentDoc } from './utils/filterByBookableOption.hook'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type HereMapViewAdPolyDataQueryVariables = Types.Exact<{
  serviceAreaUuid: Types.Scalars['ID']['input']
}>

export type HereMapViewAdPolyDataQuery = {
  __typename?: 'Query'
  areas: Array<{ __typename?: 'Area'; geometry: string }>
}

export type HereMapViewServiceAreaDataQueryVariables = Types.Exact<{
  serviceAreaId: Types.Scalars['ID']['input']
}>

export type HereMapViewServiceAreaDataQuery = {
  __typename?: 'Query'
  serviceAreaById: {
    __typename?: 'ServiceArea'
    uuid: string
    locationAttributes: {
      __typename?: 'LocationAttributes'
      area: {
        __typename?: 'Shape'
        geoJson: string
        points: Array<{
          __typename?: 'Location'
          latitude: number
          longitude: number
        }>
      }
    }
  } | null
}

export type HereMapViewVehicleFragment = {
  __typename?: 'Vehicle'
  id: string
  openedBy: Types.VehicleInteractionUserType | null
  inService: boolean | null
  isWheelchairAccessible: boolean | null
  label: number | null
  totalWheelchairUsersInVehicle: number | null
  engineType: Types.VehicleEngineType | null
  highVoltageBatteryLevel: number | null
  interiorTemperature: number | null
  nextHubReturn: {
    __typename?: 'HubReturn'
    type: Types.HubReturnType
    scheduledArrival: string
    area: { __typename?: 'Area'; id: string } | null
  } | null
  latestLocation: {
    __typename?: 'VehicleLocation'
    heading: number | null
    location: {
      __typename?: 'Location'
      latitude: number
      longitude: number
    } | null
  } | null
  nextWaypoint: {
    __typename?: 'Waypoint'
    visible: boolean
    waypointActions: Array<{
      __typename?: 'WaypointAction'
      kind: Types.WaypointActionKind
      reallocationRadius: number | null
    }>
    stop: {
      __typename?: 'Stop'
      routingLocation: {
        __typename?: 'Location'
        latitude: number
        longitude: number
      }
    } | null
  } | null
  currentlyLocatedInHub: { __typename?: 'Hub'; id: string } | null
  fccUpcomingWaypoints: Array<{
    __typename?: 'FccWaypoint'
    actions: Array<
      | {
          __typename: 'FccWaypointDeliveryAction'
          estimatedAt: string
          seats: { __typename?: 'FccWaypointSeatInfo'; wheelchairs: number }
        }
      | {
          __typename: 'FccWaypointHubReturnAction'
          isPlannedHubReturn: boolean
          reason: Types.FccWaypointHubReturnReason
          estimatedAt: string
        }
      | {
          __typename: 'FccWaypointPickupAction'
          estimatedAt: string
          seats: { __typename?: 'FccWaypointSeatInfo'; wheelchairs: number }
        }
      | { __typename: 'FccWaypointReallocationAction'; estimatedAt: string }
    >
  }> | null
  pooling: {
    __typename?: 'Pooling'
    reason: Types.PoolingReason | null
    connected: boolean | null
  } | null
  taskNotifications: {
    __typename?: 'TaskNotificationList'
    tasks: Array<
      | {
          __typename?: 'HubServiceNotification'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'IvcTaskNotification'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'PassengerUnbuckled'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'RoadBlockageTaskNotification'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'ShiftDeviationNotification'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'StopAccessibilityDownvotedByDrivers'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'StopActivationFailedNotification'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'StopAreaMissingActiveStopsNotification'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'StopDownVotedByDrivers'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'StopPotentiallyUnreachable'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'StopValidationFailed'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'TimeManagementNotification'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'TripPrebookingNoAssignment'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'VehicleAvailabilityNotification'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'VehicleIncidentNotification'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'VehicleSurveillanceNotification'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
      | {
          __typename?: 'VehicleWaypointAttendance'
          createdAt: string
          status: Types.TaskNotificationStatus
          severity: Types.TaskNotificationSeverity
          id: string
          notificationType: Types.TaskNotificationType
          assigned: {
            __typename?: 'TaskStateChangeDetails'
            backofficeUserId: string | null
          } | null
        }
    >
  } | null
  fccCurrentAdAlerts?: {
    __typename?: 'FccAdAlerts'
    alerts: Array<{
      __typename?: 'FccAdAlert'
      type: Types.FccAdAlertType
      status: Types.FccAdAlertStatus
      createdAt: string
      id: string
      assignee: { __typename?: 'BackofficeUser'; id: string } | null
    }>
  } | null
  ignition: {
    __typename?: 'Ignition'
    ignitionState: Types.IgnitionState | null
    updatedAt: string | null
  } | null
}

export const HereMapViewVehicleFragmentDoc = gql`
    fragment HereMapViewVehicle on Vehicle {
  id
  nextHubReturn {
    area {
      id
    }
  }
  latestLocation {
    location {
      latitude
      longitude
    }
  }
  ...HasHubReturnNext
  ...UseFollowVehicle
  ...UseRebalancingCircleVehicle
  ...UseLocationSelectionVehicle
  ...VehicleMarkerVehicle
  ...AdVehicleMarkerVehicle
  ...VehicleListOverlayVehicle
  ...FilterByNotBookableOptionVehicle
  ...FilterByBookableOptionVehicle
}
    ${HasHubReturnNextFragmentDoc}
${UseFollowVehicleFragmentDoc}
${UseRebalancingCircleVehicleFragmentDoc}
${UseLocationSelectionVehicleFragmentDoc}
${VehicleMarkerVehicleFragmentDoc}
${AdVehicleMarkerVehicleFragmentDoc}
${VehicleListOverlayVehicleFragmentDoc}
${FilterByNotBookableOptionVehicleFragmentDoc}
${FilterByBookableOptionVehicleFragmentDoc}`
export const HereMapViewAdPolyDataDocument = gql`
    query hereMapViewAdPolyData($serviceAreaUuid: ID!) {
  areas(areaTypes: [AD], serviceAreaID: $serviceAreaUuid) {
    geometry
  }
}
    `

/**
 * __useHereMapViewAdPolyDataQuery__
 *
 * To run a query within a React component, call `useHereMapViewAdPolyDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useHereMapViewAdPolyDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHereMapViewAdPolyDataQuery({
 *   variables: {
 *      serviceAreaUuid: // value for 'serviceAreaUuid'
 *   },
 * });
 */
export function useHereMapViewAdPolyDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    HereMapViewAdPolyDataQuery,
    HereMapViewAdPolyDataQueryVariables
  > &
    (
      | { variables: HereMapViewAdPolyDataQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    HereMapViewAdPolyDataQuery,
    HereMapViewAdPolyDataQueryVariables
  >(HereMapViewAdPolyDataDocument, options)
}
export function useHereMapViewAdPolyDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HereMapViewAdPolyDataQuery,
    HereMapViewAdPolyDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    HereMapViewAdPolyDataQuery,
    HereMapViewAdPolyDataQueryVariables
  >(HereMapViewAdPolyDataDocument, options)
}
export function useHereMapViewAdPolyDataSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        HereMapViewAdPolyDataQuery,
        HereMapViewAdPolyDataQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    HereMapViewAdPolyDataQuery,
    HereMapViewAdPolyDataQueryVariables
  >(HereMapViewAdPolyDataDocument, options)
}
export type HereMapViewAdPolyDataQueryHookResult = ReturnType<
  typeof useHereMapViewAdPolyDataQuery
>
export type HereMapViewAdPolyDataLazyQueryHookResult = ReturnType<
  typeof useHereMapViewAdPolyDataLazyQuery
>
export type HereMapViewAdPolyDataSuspenseQueryHookResult = ReturnType<
  typeof useHereMapViewAdPolyDataSuspenseQuery
>
export type HereMapViewAdPolyDataQueryResult = Apollo.QueryResult<
  HereMapViewAdPolyDataQuery,
  HereMapViewAdPolyDataQueryVariables
>
export const HereMapViewServiceAreaDataDocument = gql`
    query hereMapViewServiceAreaData($serviceAreaId: ID!) {
  serviceAreaById(id: $serviceAreaId) {
    uuid
    locationAttributes {
      area {
        points {
          latitude
          longitude
        }
        geoJson
      }
    }
  }
}
    `

/**
 * __useHereMapViewServiceAreaDataQuery__
 *
 * To run a query within a React component, call `useHereMapViewServiceAreaDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useHereMapViewServiceAreaDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHereMapViewServiceAreaDataQuery({
 *   variables: {
 *      serviceAreaId: // value for 'serviceAreaId'
 *   },
 * });
 */
export function useHereMapViewServiceAreaDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    HereMapViewServiceAreaDataQuery,
    HereMapViewServiceAreaDataQueryVariables
  > &
    (
      | { variables: HereMapViewServiceAreaDataQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    HereMapViewServiceAreaDataQuery,
    HereMapViewServiceAreaDataQueryVariables
  >(HereMapViewServiceAreaDataDocument, options)
}
export function useHereMapViewServiceAreaDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HereMapViewServiceAreaDataQuery,
    HereMapViewServiceAreaDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    HereMapViewServiceAreaDataQuery,
    HereMapViewServiceAreaDataQueryVariables
  >(HereMapViewServiceAreaDataDocument, options)
}
export function useHereMapViewServiceAreaDataSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        HereMapViewServiceAreaDataQuery,
        HereMapViewServiceAreaDataQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    HereMapViewServiceAreaDataQuery,
    HereMapViewServiceAreaDataQueryVariables
  >(HereMapViewServiceAreaDataDocument, options)
}
export type HereMapViewServiceAreaDataQueryHookResult = ReturnType<
  typeof useHereMapViewServiceAreaDataQuery
>
export type HereMapViewServiceAreaDataLazyQueryHookResult = ReturnType<
  typeof useHereMapViewServiceAreaDataLazyQuery
>
export type HereMapViewServiceAreaDataSuspenseQueryHookResult = ReturnType<
  typeof useHereMapViewServiceAreaDataSuspenseQuery
>
export type HereMapViewServiceAreaDataQueryResult = Apollo.QueryResult<
  HereMapViewServiceAreaDataQuery,
  HereMapViewServiceAreaDataQueryVariables
>
