import { BoumRight } from '@moia-dev/moia-token-claims'
import {
  AccidentsReportsAreaRoutes,
  DisruptionsAreaRoutes,
  VehicleAreaRoutes,
} from '@backoffice-frontend/common'
import { RioCategory } from '@backoffice-frontend/graphql'

export type CategoryConfig = {
  rights: { write: BoumRight[]; read: BoumRight[] }
  routes: { list: string; view: string }
}

const accidentConfig = {
  rights: {
    write: [BoumRight.ACCIDENT_REPORT_W],
    read: [BoumRight.ACCIDENT_REPORT_R],
  },
  routes: {
    list: AccidentsReportsAreaRoutes.route,
    view: AccidentsReportsAreaRoutes.ViewReport.route,
  },
}

const vehicleChecksConfig = {
  rights: {
    write: [BoumRight.VEHICLE_CHECKS_W],
    read: [BoumRight.VEHICLE_CHECKS_R],
  },
  routes: {
    list: VehicleAreaRoutes.RideChecks.route,
    view: VehicleAreaRoutes.RideChecksView.route,
  },
}

const disruptionConfig = {
  rights: {
    write: [BoumRight.RIO_DISRUPTION_CRUD],
    read: [BoumRight.RIO_DISRUPTION_R, BoumRight.RIO_DISRUPTION_CRUD],
  },
  routes: {
    list: DisruptionsAreaRoutes.route,
    view: DisruptionsAreaRoutes.ViewReport.route,
  },
}

export const getCategoryConfig: (category: RioCategory) => CategoryConfig = (
  category: RioCategory,
) => {
  switch (category) {
    case RioCategory.Accident:
      return accidentConfig
    case RioCategory.VehicleChecks:
      return vehicleChecksConfig
    case RioCategory.Disruption:
      return disruptionConfig

    default:
      throw new Error(`Unknown category ${category}`)
  }
}
