/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type EmergencyStopResolutionVehicleFragment = {
  __typename?: 'Vehicle'
  id: string
  latestADTelemetry: {
    __typename?: 'ADTelemetry'
    rightSlidingDoorOpen: {
      __typename?: 'ADTelemetryBooleanWithTimestamp'
      value: boolean
    } | null
  } | null
}

export type InitiateServiceCancellationWithIncidentMutationVariables =
  Types.Exact<{
    vehicleId: Types.Scalars['ID']['input']
    incidentId: Types.Scalars['ID']['input']
  }>

export type InitiateServiceCancellationWithIncidentMutation = {
  __typename?: 'Mutation'
  fccInitiateCancelAllTrips: any | null
}

export type ResolveServiceCancellationToContinueTripsMutationVariables =
  Types.Exact<{
    vehicleId: Types.Scalars['ID']['input']
    incidentId: Types.Scalars['ID']['input']
  }>

export type ResolveServiceCancellationToContinueTripsMutation = {
  __typename?: 'Mutation'
  fccContinueTrips: any | null
}

export const EmergencyStopResolutionVehicleFragmentDoc = gql`
    fragment EmergencyStopResolutionVehicle on Vehicle {
  id
  latestADTelemetry {
    rightSlidingDoorOpen {
      value
    }
  }
}
    `
export const InitiateServiceCancellationWithIncidentDocument = gql`
    mutation initiateServiceCancellationWithIncident($vehicleId: ID!, $incidentId: ID!) {
  fccInitiateCancelAllTrips(
    input: {vehicleId: $vehicleId, incidentId: $incidentId}
  )
}
    `
export type InitiateServiceCancellationWithIncidentMutationFn =
  Apollo.MutationFunction<
    InitiateServiceCancellationWithIncidentMutation,
    InitiateServiceCancellationWithIncidentMutationVariables
  >

/**
 * __useInitiateServiceCancellationWithIncidentMutation__
 *
 * To run a mutation, you first call `useInitiateServiceCancellationWithIncidentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateServiceCancellationWithIncidentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateServiceCancellationWithIncidentMutation, { data, loading, error }] = useInitiateServiceCancellationWithIncidentMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      incidentId: // value for 'incidentId'
 *   },
 * });
 */
export function useInitiateServiceCancellationWithIncidentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitiateServiceCancellationWithIncidentMutation,
    InitiateServiceCancellationWithIncidentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InitiateServiceCancellationWithIncidentMutation,
    InitiateServiceCancellationWithIncidentMutationVariables
  >(InitiateServiceCancellationWithIncidentDocument, options)
}
export type InitiateServiceCancellationWithIncidentMutationHookResult =
  ReturnType<typeof useInitiateServiceCancellationWithIncidentMutation>
export type InitiateServiceCancellationWithIncidentMutationResult =
  Apollo.MutationResult<InitiateServiceCancellationWithIncidentMutation>
export type InitiateServiceCancellationWithIncidentMutationOptions =
  Apollo.BaseMutationOptions<
    InitiateServiceCancellationWithIncidentMutation,
    InitiateServiceCancellationWithIncidentMutationVariables
  >
export const ResolveServiceCancellationToContinueTripsDocument = gql`
    mutation resolveServiceCancellationToContinueTrips($vehicleId: ID!, $incidentId: ID!) {
  fccContinueTrips(input: {vehicleId: $vehicleId, incidentId: $incidentId})
}
    `
export type ResolveServiceCancellationToContinueTripsMutationFn =
  Apollo.MutationFunction<
    ResolveServiceCancellationToContinueTripsMutation,
    ResolveServiceCancellationToContinueTripsMutationVariables
  >

/**
 * __useResolveServiceCancellationToContinueTripsMutation__
 *
 * To run a mutation, you first call `useResolveServiceCancellationToContinueTripsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveServiceCancellationToContinueTripsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveServiceCancellationToContinueTripsMutation, { data, loading, error }] = useResolveServiceCancellationToContinueTripsMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      incidentId: // value for 'incidentId'
 *   },
 * });
 */
export function useResolveServiceCancellationToContinueTripsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResolveServiceCancellationToContinueTripsMutation,
    ResolveServiceCancellationToContinueTripsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ResolveServiceCancellationToContinueTripsMutation,
    ResolveServiceCancellationToContinueTripsMutationVariables
  >(ResolveServiceCancellationToContinueTripsDocument, options)
}
export type ResolveServiceCancellationToContinueTripsMutationHookResult =
  ReturnType<typeof useResolveServiceCancellationToContinueTripsMutation>
export type ResolveServiceCancellationToContinueTripsMutationResult =
  Apollo.MutationResult<ResolveServiceCancellationToContinueTripsMutation>
export type ResolveServiceCancellationToContinueTripsMutationOptions =
  Apollo.BaseMutationOptions<
    ResolveServiceCancellationToContinueTripsMutation,
    ResolveServiceCancellationToContinueTripsMutationVariables
  >
