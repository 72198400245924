/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
export type UseAlertCountAdAlertFragment = {
  __typename?: 'FccAdAlert'
  type: Types.FccAdAlertType
  status: Types.FccAdAlertStatus
  assignee: { __typename?: 'BackofficeUser'; id: string } | null
}

export type UseAlertCountAlert_HubServiceNotification_Fragment = {
  __typename?: 'HubServiceNotification'
  status: Types.TaskNotificationStatus
  severity: Types.TaskNotificationSeverity
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type UseAlertCountAlert_IvcTaskNotification_Fragment = {
  __typename?: 'IvcTaskNotification'
  status: Types.TaskNotificationStatus
  severity: Types.TaskNotificationSeverity
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type UseAlertCountAlert_PassengerAuthentificationFallbackRequestedNotification_Fragment =
  {
    __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
    status: Types.TaskNotificationStatus
    severity: Types.TaskNotificationSeverity
    assigned: {
      __typename?: 'TaskStateChangeDetails'
      backofficeUserId: string | null
    } | null
  }

export type UseAlertCountAlert_PassengerUnbuckled_Fragment = {
  __typename?: 'PassengerUnbuckled'
  status: Types.TaskNotificationStatus
  severity: Types.TaskNotificationSeverity
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type UseAlertCountAlert_RoadBlockageTaskNotification_Fragment = {
  __typename?: 'RoadBlockageTaskNotification'
  status: Types.TaskNotificationStatus
  severity: Types.TaskNotificationSeverity
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type UseAlertCountAlert_ShiftDeviationNotification_Fragment = {
  __typename?: 'ShiftDeviationNotification'
  status: Types.TaskNotificationStatus
  severity: Types.TaskNotificationSeverity
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type UseAlertCountAlert_StopAccessibilityDownvotedByDrivers_Fragment = {
  __typename?: 'StopAccessibilityDownvotedByDrivers'
  status: Types.TaskNotificationStatus
  severity: Types.TaskNotificationSeverity
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type UseAlertCountAlert_StopActivationFailedNotification_Fragment = {
  __typename?: 'StopActivationFailedNotification'
  status: Types.TaskNotificationStatus
  severity: Types.TaskNotificationSeverity
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type UseAlertCountAlert_StopAreaMissingActiveStopsNotification_Fragment =
  {
    __typename?: 'StopAreaMissingActiveStopsNotification'
    status: Types.TaskNotificationStatus
    severity: Types.TaskNotificationSeverity
    assigned: {
      __typename?: 'TaskStateChangeDetails'
      backofficeUserId: string | null
    } | null
  }

export type UseAlertCountAlert_StopDownVotedByDrivers_Fragment = {
  __typename?: 'StopDownVotedByDrivers'
  status: Types.TaskNotificationStatus
  severity: Types.TaskNotificationSeverity
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type UseAlertCountAlert_StopPotentiallyUnreachable_Fragment = {
  __typename?: 'StopPotentiallyUnreachable'
  status: Types.TaskNotificationStatus
  severity: Types.TaskNotificationSeverity
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type UseAlertCountAlert_StopValidationFailed_Fragment = {
  __typename?: 'StopValidationFailed'
  status: Types.TaskNotificationStatus
  severity: Types.TaskNotificationSeverity
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type UseAlertCountAlert_TimeManagementNotification_Fragment = {
  __typename?: 'TimeManagementNotification'
  status: Types.TaskNotificationStatus
  severity: Types.TaskNotificationSeverity
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type UseAlertCountAlert_TripPrebookingNoAssignment_Fragment = {
  __typename?: 'TripPrebookingNoAssignment'
  status: Types.TaskNotificationStatus
  severity: Types.TaskNotificationSeverity
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type UseAlertCountAlert_VehicleAvailabilityNotification_Fragment = {
  __typename?: 'VehicleAvailabilityNotification'
  status: Types.TaskNotificationStatus
  severity: Types.TaskNotificationSeverity
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type UseAlertCountAlert_VehicleIncidentNotification_Fragment = {
  __typename?: 'VehicleIncidentNotification'
  status: Types.TaskNotificationStatus
  severity: Types.TaskNotificationSeverity
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type UseAlertCountAlert_VehicleSurveillanceNotification_Fragment = {
  __typename?: 'VehicleSurveillanceNotification'
  status: Types.TaskNotificationStatus
  severity: Types.TaskNotificationSeverity
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type UseAlertCountAlert_VehicleWaypointAttendance_Fragment = {
  __typename?: 'VehicleWaypointAttendance'
  status: Types.TaskNotificationStatus
  severity: Types.TaskNotificationSeverity
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type UseAlertCountAlertFragment =
  | UseAlertCountAlert_HubServiceNotification_Fragment
  | UseAlertCountAlert_IvcTaskNotification_Fragment
  | UseAlertCountAlert_PassengerAuthentificationFallbackRequestedNotification_Fragment
  | UseAlertCountAlert_PassengerUnbuckled_Fragment
  | UseAlertCountAlert_RoadBlockageTaskNotification_Fragment
  | UseAlertCountAlert_ShiftDeviationNotification_Fragment
  | UseAlertCountAlert_StopAccessibilityDownvotedByDrivers_Fragment
  | UseAlertCountAlert_StopActivationFailedNotification_Fragment
  | UseAlertCountAlert_StopAreaMissingActiveStopsNotification_Fragment
  | UseAlertCountAlert_StopDownVotedByDrivers_Fragment
  | UseAlertCountAlert_StopPotentiallyUnreachable_Fragment
  | UseAlertCountAlert_StopValidationFailed_Fragment
  | UseAlertCountAlert_TimeManagementNotification_Fragment
  | UseAlertCountAlert_TripPrebookingNoAssignment_Fragment
  | UseAlertCountAlert_VehicleAvailabilityNotification_Fragment
  | UseAlertCountAlert_VehicleIncidentNotification_Fragment
  | UseAlertCountAlert_VehicleSurveillanceNotification_Fragment
  | UseAlertCountAlert_VehicleWaypointAttendance_Fragment

export const UseAlertCountAdAlertFragmentDoc = gql`
    fragment UseAlertCountAdAlert on FccAdAlert {
  type
  status
  assignee {
    id
  }
}
    `
export const UseAlertCountAlertFragmentDoc = gql`
    fragment UseAlertCountAlert on TaskNotification {
  status
  assigned {
    backofficeUserId
  }
  severity
}
    `
