/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type AssignVehicleToAlertMutationVariables = Types.Exact<{
  vehicleId: Types.Scalars['ID']['input']
  alertId: Types.Scalars['ID']['input']
}>

export type AssignVehicleToAlertMutation = {
  __typename?: 'Mutation'
  updateTaskNotificationVehicleId:
    | { __typename?: 'HubServiceNotification'; id: string }
    | { __typename?: 'IvcTaskNotification'; id: string }
    | {
        __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
        id: string
      }
    | { __typename?: 'PassengerUnbuckled'; id: string }
    | { __typename?: 'RoadBlockageTaskNotification'; id: string }
    | { __typename?: 'ShiftDeviationNotification'; id: string }
    | { __typename?: 'StopAccessibilityDownvotedByDrivers'; id: string }
    | { __typename?: 'StopActivationFailedNotification'; id: string }
    | { __typename?: 'StopAreaMissingActiveStopsNotification'; id: string }
    | { __typename?: 'StopDownVotedByDrivers'; id: string }
    | { __typename?: 'StopPotentiallyUnreachable'; id: string }
    | { __typename?: 'StopValidationFailed'; id: string }
    | { __typename?: 'TimeManagementNotification'; id: string }
    | { __typename?: 'TripPrebookingNoAssignment'; id: string }
    | { __typename?: 'VehicleAvailabilityNotification'; id: string }
    | { __typename?: 'VehicleIncidentNotification'; id: string }
    | { __typename?: 'VehicleSurveillanceNotification'; id: string }
    | { __typename?: 'VehicleWaypointAttendance'; id: string }
}

export const AssignVehicleToAlertDocument = gql`
    mutation assignVehicleToAlert($vehicleId: ID!, $alertId: ID!) {
  updateTaskNotificationVehicleId(id: $alertId, vehicleId: $vehicleId) {
    id
  }
}
    `
export type AssignVehicleToAlertMutationFn = Apollo.MutationFunction<
  AssignVehicleToAlertMutation,
  AssignVehicleToAlertMutationVariables
>

/**
 * __useAssignVehicleToAlertMutation__
 *
 * To run a mutation, you first call `useAssignVehicleToAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignVehicleToAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignVehicleToAlertMutation, { data, loading, error }] = useAssignVehicleToAlertMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      alertId: // value for 'alertId'
 *   },
 * });
 */
export function useAssignVehicleToAlertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignVehicleToAlertMutation,
    AssignVehicleToAlertMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AssignVehicleToAlertMutation,
    AssignVehicleToAlertMutationVariables
  >(AssignVehicleToAlertDocument, options)
}
export type AssignVehicleToAlertMutationHookResult = ReturnType<
  typeof useAssignVehicleToAlertMutation
>
export type AssignVehicleToAlertMutationResult =
  Apollo.MutationResult<AssignVehicleToAlertMutation>
export type AssignVehicleToAlertMutationOptions = Apollo.BaseMutationOptions<
  AssignVehicleToAlertMutation,
  AssignVehicleToAlertMutationVariables
>
