import React from 'react'
import { Portals } from '@backoffice-frontend/common'
import { currentEnvironmentNotBuildEnvironment } from '@backoffice-frontend/environment'
import {
  SwitchEnvironmentDropdown,
  useTheme,
  useMediaQuery,
} from '@backoffice-frontend/patterns'
import { AccountBadge } from './AccountBadge'

type MoiaHeaderProps = {
  /**
   * is already set as a default
   * should only be overwritten for tests or stories
   */
  accountBadge?: React.ReactNode
}

/**
 * This is just a container for portals that render the specific area content to the header
 * to set any content use the MoiaTopBar component
 */
export const MoiaHeader = ({
  accountBadge = <AccountBadge />,
}: MoiaHeaderProps) => {
  const theme = useTheme()
  const envMismatch = currentEnvironmentNotBuildEnvironment()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <div
      css={{
        overflow: 'hidden',
        position: 'sticky',
        top: 0,
        zIndex: 100,
        paddingTop: theme.space.Space2,
        paddingBottom: theme.space.Space2,
        paddingLeft: theme.space.Space4,
        paddingRight: theme.space.Space4,
        background: theme.semantic.ColorSurfaceDefault,
        width: isMobile ? `auto` : `calc(100% - ${theme.spacing(4)})`,
        ...(isMobile
          ? {}
          : {
              marginLeft: theme.spacing(2),
              marginRight: theme.spacing(2),
              borderBottomRightRadius: theme.radius.RadiusMedium,
              borderBottomLeftRadius: theme.radius.RadiusMedium,
            }),
        boxShadow:
          '0px 1px 3px 0px rgba(0, 12, 21, 0.12), 0px 1px 2px 0px rgba(0, 12, 21, 0.08)',
        [theme.breakpoints.down('md')]: {
          paddingTop: theme.space.Space1,
          paddingBottom: theme.space.Space1,
          paddingLeft: theme.space.Space2,
          paddingRight: theme.space.Space2,
        },
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          justifyContent: 'space-between',
        }}
      >
        <div
          id={Portals.DedicatedHeader}
          css={{
            height: '100%',
            width: '100%',
          }}
        />

        {envMismatch && (
          <div>
            <SwitchEnvironmentDropdown />
          </div>
        )}
        {accountBadge}
      </div>
      <div id={Portals.DedicatedHeaderTabs} />
    </div>
  )
}
