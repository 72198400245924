// This file was once generated when you created this area
// but you can edit it freely
import { transformRoutes } from '@backoffice-frontend/common'

const baseRoute = '/data-dashboard'

export const BaseKpiDashboardAreaRoutes = {
  route: baseRoute,
  Overview: {
    route: '/overview',
  },
  DemandEstimation: {
    route: '/demand-estimation',
  },
  ScLabs: {
    route: '/sc-labs',
  },
  MobilityMetrics: {
    route: '/mobility-metrics',
  },
  ADDashboard: {
    route: '/ad-dashboard',
  },
}

export const KpiDashboardAreaRoutes = transformRoutes(
  BaseKpiDashboardAreaRoutes,
)
