/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import { AdVehicleSeatingVehicleFragmentDoc } from '../../utils/seating/seating.hook'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type CabinSafetyCheckVehicleFragment = {
  __typename?: 'Vehicle'
  id: string
  fccCurrentAdAlerts: {
    __typename?: 'FccAdAlerts'
    alerts: Array<{
      __typename?: 'FccAdAlert'
      id: string
      type: Types.FccAdAlertType
    }>
  } | null
  adSeatsLayout: {
    __typename?: 'FccSeatLayout'
    thirdRowRight: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
    thirdRowMiddle: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
    thirdRowLeft: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
    secondRowRight: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
    secondRowMiddle: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
    secondRowLeft: {
      __typename?: 'FccSeat'
      occupancy: Types.FccSeatOccupancy
      seatbelt: Types.FccSeatbelt
    }
  } | null
}

export type ConfirmCabinSafetyMutationVariables = Types.Exact<{
  vehicleId: Types.Scalars['ID']['input']
}>

export type ConfirmCabinSafetyMutation = {
  __typename?: 'Mutation'
  fccConfirmCabinSafety: any | null
}

export const CabinSafetyCheckVehicleFragmentDoc = gql`
    fragment CabinSafetyCheckVehicle on Vehicle {
  id
  fccCurrentAdAlerts {
    alerts {
      id
      type
    }
  }
  ...AdVehicleSeatingVehicle
}
    ${AdVehicleSeatingVehicleFragmentDoc}`
export const ConfirmCabinSafetyDocument = gql`
    mutation confirmCabinSafety($vehicleId: ID!) {
  fccConfirmCabinSafety(input: {vehicleId: $vehicleId})
}
    `
export type ConfirmCabinSafetyMutationFn = Apollo.MutationFunction<
  ConfirmCabinSafetyMutation,
  ConfirmCabinSafetyMutationVariables
>

/**
 * __useConfirmCabinSafetyMutation__
 *
 * To run a mutation, you first call `useConfirmCabinSafetyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmCabinSafetyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmCabinSafetyMutation, { data, loading, error }] = useConfirmCabinSafetyMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useConfirmCabinSafetyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmCabinSafetyMutation,
    ConfirmCabinSafetyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ConfirmCabinSafetyMutation,
    ConfirmCabinSafetyMutationVariables
  >(ConfirmCabinSafetyDocument, options)
}
export type ConfirmCabinSafetyMutationHookResult = ReturnType<
  typeof useConfirmCabinSafetyMutation
>
export type ConfirmCabinSafetyMutationResult =
  Apollo.MutationResult<ConfirmCabinSafetyMutation>
export type ConfirmCabinSafetyMutationOptions = Apollo.BaseMutationOptions<
  ConfirmCabinSafetyMutation,
  ConfirmCabinSafetyMutationVariables
>
