import { Button, Card, Collapse, useMediaQuery } from '@mui/material'
import type { ReactNode } from 'react'
import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { AutoSizer } from 'react-virtualized'
import { XIcon } from '@moia-dev/pace-icons'
import { useTheme } from '@backoffice-frontend/patterns'
import { MoiaGrid } from '../../basics/MoiaGrid'
import { H5 } from '../../basics/Typography'
import type { Theme } from '../../basics/theme'
import { RenderToPortal } from '../../navigation/RenderToPortal'
import { drawerCardStyle } from './drawerCardStyles'

const PortalDrawerId = 'portal-drawer'
const getDrawerHeaderColor = (theme: Theme, color = '') => {
  switch (color) {
    case 'info':
      return theme.semantic.ColorSurfaceReducedAlternative
    case 'warning':
      return theme.semantic.ColorWarningDefault
    case 'error':
      return theme.semantic.ColorCriticalDefault
    case 'success':
      return theme.semantic.ColorSuccessDefault
    default:
      return theme.semantic.ColorSurfaceDefault
  }
}

export type MoiaDrawerHeaderProps = {
  icon?: ReactNode
  children: ReactNode
  onClose: VoidFunction
  /**
   * use this slot to display sticky tabs in your header
   */
  tabs?: ReactNode
  states?: ReactNode
  color?: 'info' | 'warning' | 'error' | 'success'
  noShadow?: boolean
}

/**
 * Can be used if a closing action is needed in a MoiaDrawer
 * Else use a regular MUI CardHeader component
 * @example
 * ```
 *   <MoiaDrawer>
      <MoiaInfoDrawerClosableHeader onClose={toggleOpen}>
        {shift?.driver?.firstName} {shift?.driver?.lastName}
      </MoiaInfoDrawerClosableHeader>
      <CardContent>
        ...
      ```
 */
export const MoiaDrawerHeader = ({
  children,
  onClose,
  tabs,
  states,
  icon,
  color,
  noShadow = false,
}: MoiaDrawerHeaderProps) => (
  <div
    css={theme => ({
      borderBottom: `1px solid ${theme.semantic.ColorAccessoryReduced}`,
      boxShadow: noShadow ? undefined : '0px 0px 12px rgba(0, 0, 0, 0.06)',
      position: 'relative',
      zIndex: 1,
      '&:after': {
        backgroundColor: getDrawerHeaderColor(theme, color),
        content: '""',
        height: '8px',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100%',
      },
    })}
  >
    <div
      css={theme => ({
        background: theme.semantic.ColorSurfaceDefault,
        padding: theme.spacing(3, 2),
        color: theme.semantic.ColorContentDefault,
      })}
    >
      <MoiaGrid
        css={{
          alignItems: 'center',
          alignContent: 'center',
          gridTemplateColumns: `
            ${icon ? 'min-content' : ''}
            1fr
            ${'min-content'}
            ${states ? 'min-content' : ''}`,
        }}
      >
        {icon}
        <H5
          noWrap
          css={theme => ({
            flexGrow: 1,
            color: theme.semantic.ColorContentDefault,
          })}
        >
          {children}
        </H5>

        {states}

        <Button
          css={theme => ({
            minWidth: 0,
            color: theme.semantic.ColorActionSecondaryDefault,
          })}
          size="small"
          variant="text"
          onClick={onClose}
          color="secondary"
          aria-label="close-button"
        >
          <XIcon data-testid={'XIcon'} />
        </Button>
      </MoiaGrid>
    </div>
    {tabs && (
      <div
        css={theme => ({
          background: theme.semantic.ColorSurfaceDefault,
          display: 'grid',
          gridAutoColumns: 'min-content',
          gridAutoFlow: 'column',
          padding: theme.spacing(2),
        })}
      >
        {tabs}
      </div>
    )}
  </div>
)

/**
 * Absolute positioned Overlay that can be used inside any relative positioned parent.
 * It should be used with `useInfoDrawer` the hook will handle close on esc automatically
 */
export const MoiaDrawer = ({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const responsiveProps = isMobile
    ? {
        position: 'fixed' as const,
        top: 0,
        left: 0,
        zIndex: 100,
      }
    : { minWidth: '444px', maxWidth: '480px' }

  return (
    <Card
      style={isMobile ? { minWidth: '100vw', maxWidth: '100vw' } : {}}
      role="dialog"
      className={className}
      css={theme => ({
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.06)',
        background: theme.semantic.ColorSurfaceDefault,
        ...drawerCardStyle(theme),
        ...responsiveProps,
      })}
    >
      {children}
    </Card>
  )
}

export const MoiaDrawerContent = ({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) => (
  <div
    className={className}
    css={theme => ({
      backgroundColor: theme.semantic.ColorSurfaceDefault,
      color: theme.semantic.ColorContentDefault,
      height: '100%',
      overflowY: 'auto',
      padding: theme.spacing(0, 2, 2, 2),
    })}
  >
    {children}
  </div>
)

export const MoiaDrawerFooter = ({ children }: { children: ReactNode }) => (
  <div
    css={theme => ({
      alignItems: 'center',
      backgroundColor: theme.semantic.ColorSurfaceDefault,
      boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.06)',
      color: theme.semantic.ColorContentDefault,
      display: 'flex',
      gap: theme.spacing(2),
      justifyContent: 'space-between',
      padding: theme.spacing(3, 2),
      borderTop: `1px solid ${theme.semantic.ColorAccessoryReduced}`,
    })}
  >
    {children}
  </div>
)

export function useDrawer<Item>(
  initialValue?: Item,
): [Item | undefined, React.Dispatch<React.SetStateAction<Item | undefined>>] {
  const [drawerItem, setDrawerItem] = useState<Item | undefined>(initialValue)
  const handleClose = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      setDrawerItem(undefined)
    }
  }, [])
  useEffect(() => {
    document.addEventListener('keydown', handleClose)
    return () => {
      document.removeEventListener('keydown', handleClose)
    }
  }, [handleClose])
  return [drawerItem, setDrawerItem]
}

export type DrawerLayoutProps = {
  open: boolean
  drawer?: ReactNode
  children: ReactNode
}

export const MoiaDrawerLayout = ({
  open,
  drawer,
  children,
}: DrawerLayoutProps) => {
  return (
    <div
      css={theme => ({
        display: 'grid',
        gap: theme.spacing(2),
        gridTemplateColumns: open ? '1fr min-content' : '1fr',
        height: '100%',
        width: '100%',
        [theme.breakpoints.down('md')]: {
          gap: 0,
        },
      })}
    >
      <div css={{ height: '100%', width: '100%', overflow: 'hidden' }}>
        {children}
      </div>
      <Collapse orientation="horizontal" in={open} mountOnEnter unmountOnExit>
        <AutoSizer disableWidth>
          {({ height }) => (
            <div
              id={PortalDrawerId}
              css={{
                height,
                overflow: 'hidden',
              }}
            >
              {drawer}
            </div>
          )}
        </AutoSizer>
      </Collapse>
    </div>
  )
}

type ActiveDrawerItem = Record<string, unknown>
type DrawerContextProps = {
  item?: ActiveDrawerItem
  setItem?: (item?: ActiveDrawerItem) => void
}
const DrawerContext = React.createContext<DrawerContextProps>({})

export const useDrawerContext = () => {
  const context = React.useContext(DrawerContext)
  if (!context) {
    throw new Error('useDrawerContext must be used within a DrawerProvider')
  }
  return context
}

export const DrawerPortal = ({ children }: { children: ReactNode }) => {
  return <RenderToPortal id={PortalDrawerId}>{children}</RenderToPortal>
}

export function usePortalDrawer<Item>(
  id: string,
): [Item | undefined, (item?: Item) => void] {
  const { setItem, item } = useDrawerContext()
  const handleClose = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setItem?.(undefined)
      }
    },
    [setItem],
  )
  const setNewItem = (item?: Item) => {
    // make sure to unset the whole item not only the id: item map, else the outer drawer stays visible
    if (item === undefined) {
      setItem?.(undefined)
    } else {
      setItem?.({ [id]: item })
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', handleClose)
    return () => {
      document.removeEventListener('keydown', handleClose)
    }
  }, [handleClose])

  // as there is no easy way to define a fully dynamic and generic context casting is the most pragmatic solution
  return [item?.[id] as Item | undefined, setNewItem as (item?: Item) => void]
}

export const PortalDrawerLayout = ({ children }: { children: ReactNode }) => {
  const [item, setItem] = useState<ActiveDrawerItem>()
  return (
    <DrawerContext.Provider value={{ item, setItem }}>
      <MoiaDrawerLayout open={Boolean(item)}>{children}</MoiaDrawerLayout>
    </DrawerContext.Provider>
  )
}
