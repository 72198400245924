/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
export type UseLocationSelectionVehicleFragment = {
  __typename?: 'Vehicle'
  id: string
  latestLocation: {
    __typename?: 'VehicleLocation'
    location: {
      __typename?: 'Location'
      latitude: number
      longitude: number
    } | null
  } | null
}

export const UseLocationSelectionVehicleFragmentDoc = gql`
    fragment UseLocationSelectionVehicle on Vehicle {
  id
  latestLocation {
    location {
      latitude
      longitude
    }
  }
}
    `
