import type { Provider } from 'leaflet-geosearch/dist/providers'
import type {
  ProviderOptions,
  SearchResult,
  ProviderParams,
  EndpointArgument,
  ParseArgument,
  SearchArgument,
} from 'leaflet-geosearch/dist/providers/provider'

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export abstract class AbstractProvider<TRequestResult = any, TRawResult = any>
  implements Provider<TRequestResult, TRawResult>
{
  // @ts-expect-error Constructor implementation is missing because it's an abstract class.
  constructor(options?: ProviderOptions)

  abstract getParamString(params?: ProviderParams): string
  abstract getUrl(url: string, params?: ProviderParams): string
  abstract search(options: SearchArgument): Promise<SearchResult<TRawResult>[]>
  abstract endpoint(options: EndpointArgument): string
  abstract parse(
    response: ParseArgument<TRequestResult>,
  ): SearchResult<TRawResult>[]

  options!: ProviderOptions
}
