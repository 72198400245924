/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import { IsNotBookableFragmentDoc } from '../../../../../../vehicle-common/src/lib/utils/vehicleValidators.hook'
export type FilterByNotBookableOptionVehicleFragment = {
  __typename?: 'Vehicle'
  inService: boolean | null
  openedBy: Types.VehicleInteractionUserType | null
  pooling: {
    __typename?: 'Pooling'
    reason: Types.PoolingReason | null
    connected: boolean | null
  } | null
}

export const FilterByNotBookableOptionVehicleFragmentDoc = gql`
    fragment FilterByNotBookableOptionVehicle on Vehicle {
  ...IsNotBookable
  pooling {
    reason
    connected
  }
}
    ${IsNotBookableFragmentDoc}`
