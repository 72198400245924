import { useAppearance } from '@backoffice-frontend/dark-mode'

export const PictureConstructionSite = () => {
  const { darkMode } = useAppearance()
  return darkMode ? (
    <PictureConstructionSiteDark />
  ) : (
    <PictureConstructionSiteLight />
  )
}

const PictureConstructionSiteDark = () => {
  return (
    <svg
      aria-hidden="true"
      width="280"
      height="117"
      viewBox="0 0 280 117"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="280" height="116.667" />
      <path
        d="M198.659 100.115L224.364 103.12C224.978 103.191 225.597 103.117 226.18 102.901L242.043 97.619C242.043 96.5997 241.273 95.7443 240.258 95.6385L219.924 93.5357C219.255 93.4895 218.585 93.5342 217.928 93.6668L200.573 97.7055C199.453 97.9663 198.662 98.9648 198.662 100.114L198.659 100.115Z"
        fill="#29333A"
      />
      <path
        d="M217.776 59.0327L205.81 96.6222C205.81 96.6222 218.934 102.387 236.264 96.1587L223.683 58.8956C223.485 58.3099 222.993 57.8673 222.387 57.7466C221.646 57.5991 220.543 57.4977 219.223 57.7466C218.54 57.8748 217.986 58.371 217.774 59.0327H217.776Z"
        fill="#475056"
      />
      <path
        d="M224.963 62.6855L223.683 58.8972C223.485 58.3115 222.993 57.8689 222.387 57.7482C221.647 57.6006 220.543 57.4993 219.223 57.7482C218.54 57.8763 217.986 58.3726 217.775 59.0343L216.615 62.6751C219.333 63.3979 222.239 63.3934 224.961 62.6855H224.963Z"
        fill="#29333A"
      />
      <path
        d="M214.351 69.7925L211.866 77.5972C216.745 78.8356 221.871 79.0994 226.85 78.3542C227.913 78.1948 228.997 77.9757 229.991 77.5793L227.413 69.9446C226.517 70.1651 225.59 70.2948 224.669 70.3827C221.226 70.7121 217.734 70.5109 214.351 69.7925Z"
        fill="#29333A"
      />
      <path
        d="M234.587 91.1887L232.014 83.5689C228.641 84.9936 224.948 85.6583 221.278 85.6643C217.417 85.6702 213.579 84.9683 209.888 83.8148L207.486 91.3586C216.304 93.7982 225.8 93.7371 234.587 91.1887Z"
        fill="#29333A"
      />
      <path
        d="M37.8 81.1411L57.1227 83.3422C57.583 83.3944 58.0493 83.3392 58.4873 83.1813L70.4116 79.314C70.4116 78.5673 69.8321 77.9414 69.0693 77.8639L53.784 76.3244C53.282 76.2902 52.7769 76.3244 52.2838 76.4213L39.2377 79.378C38.396 79.5688 37.8 80.299 37.8 81.1425V81.1411Z"
        fill="#29333A"
      />
      <path
        d="M52.1707 51.0582L43.1753 78.5838C43.1753 78.5838 53.0407 82.8057 66.069 78.2455L56.6117 50.9583C56.4628 50.5291 56.0933 50.2057 55.6374 50.1178C55.0817 50.0105 54.2504 49.936 53.2582 50.1178C52.7457 50.2117 52.3286 50.5753 52.1707 51.0597V51.0582Z"
        fill="#475056"
      />
      <path
        d="M57.5726 53.7317L56.6117 50.9583C56.4627 50.5291 56.0932 50.2057 55.6374 50.1178C55.0817 50.0105 54.2504 49.936 53.2582 50.1178C52.7457 50.2117 52.3285 50.5753 52.1706 51.0597L51.2991 53.7258C53.3416 54.2548 55.5271 54.2518 57.5726 53.7332V53.7317Z"
        fill="#29333A"
      />
      <path
        d="M49.5962 58.9356L47.728 64.6509C51.3959 65.5585 55.2485 65.7507 58.9924 65.2053C59.7909 65.089 60.6058 64.9281 61.3522 64.6375L59.414 59.0459C58.7391 59.2084 58.0434 59.3022 57.3521 59.3663C54.7643 59.6078 52.1393 59.4602 49.5962 58.9341V58.9356Z"
        fill="#29333A"
      />
      <path
        d="M64.807 74.6047L62.8732 69.0251C60.3376 70.0683 57.5606 70.5556 54.803 70.56C51.8994 70.5645 49.0152 70.0504 46.2412 69.2054L44.4355 74.7299C51.0636 76.5167 58.2012 76.472 64.807 74.6047Z"
        fill="#29333A"
      />
      <path
        d="M78.2152 81.7727L94.8681 83.6714L103.701 80.8935L89.6032 79.3898L78.2152 81.7727Z"
        fill="#475056"
      />
      <path
        d="M94.8681 83.6714L97.9803 88.2152L73.8635 85.3539L78.2152 81.7727L94.8681 83.6714Z"
        fill="#475056"
      />
      <path
        d="M94.8682 83.6715L97.9803 88.2154L107.683 84.6521L103.701 80.8936L94.8682 83.6715Z"
        fill="#475056"
      />
      <path
        d="M160.954 91.4744L180.08 93.5906L191.158 89.543L174.509 87.8411L160.954 91.4744Z"
        fill="#475056"
      />
      <path
        d="M180.08 93.5906L182.66 98.3371L156.517 95.37L160.954 91.4744L180.08 93.5906Z"
        fill="#475056"
      />
      <path
        d="M180.08 93.5909L182.66 98.3374L194.495 93.2183L191.158 89.5432L180.08 93.5909Z"
        fill="#475056"
      />
      <path
        d="M168.827 72.0009L169.03 51.8925L166.155 51.8359L165.96 71.7863L157.335 71.1365L157.506 51.2159L154.642 51.1608L154.478 70.9219L146.436 70.3154L146.58 50.5512L143.724 50.4961L143.587 70.1023L135.581 69.4987L135.7 49.4663L132.852 49.4112L132.742 69.2856L124.485 68.6642L124.575 49.2532L121.737 49.1995L121.655 68.451L113.528 67.8385L113.589 48.821L110.76 48.7674L110.706 67.6269L103.063 67.0517L103.098 48.6198L100.279 48.5662L100.251 66.8401L94.3005 66.3915L94.3139 49.3992L91.2956 48.7524L91.2866 68.612L174.615 75.9502L175.159 72.4778L168.827 72.0009Z"
        fill="#475056"
      />
      <path
        d="M92.5931 21.27L94.3362 22.1448L94.287 81.7458L92.5574 82.163L92.5931 21.27Z"
        fill="#475056"
      />
      <path
        d="M178.012 21.5444L179.783 22.0422L180.089 92.8695L178.012 93.5372V21.5444Z"
        fill="#475056"
      />
      <path
        d="M169.538 22.5353C169.541 22.4295 169.587 22.3312 169.63 22.2343C170.621 20.0257 170.514 17.449 168.89 15.5563C167.725 14.1972 166.001 13.5087 164.245 13.534C162.034 13.5653 160.202 15.1808 159.866 17.3983C159.612 19.0853 160.23 20.8468 161.331 22.1464C161.237 22.3103 160.741 24.3207 160.64 24.3058C162.158 24.5398 163.71 24.4429 165.233 24.5174C166.152 24.5621 167.077 24.7797 168.001 24.8468C168.868 24.9094 169.744 24.8006 170.611 24.7916C170.247 24.1568 169.915 23.5025 169.619 22.8334C169.578 22.744 169.54 22.6501 169.538 22.5532C169.538 22.5472 169.538 22.5428 169.538 22.5368V22.5353Z"
        fill="#475056"
      />
      <path
        d="M167.397 22.4565C168.269 21.537 168.814 20.2732 168.829 18.8694C168.857 16.0095 166.676 13.6593 163.957 13.6221C161.241 13.5848 159.02 15.8709 158.995 18.7278C158.984 19.9215 159.36 21.0243 159.996 21.9081C159.72 22.6994 159.466 23.4892 159.177 24.2821C160.987 24.5608 166.982 24.4639 168.114 24.9378C168.08 24.9438 168.121 25.0034 168.15 24.9825C168.178 24.9602 168.169 24.9169 168.159 24.8827C167.904 24.0749 167.651 23.2657 167.396 22.4565H167.397Z"
        fill="#475056"
      />
      <path
        d="M167.16 18.7872C167.143 20.6128 165.627 22.0703 163.776 22.042C161.924 22.0136 160.44 20.5144 160.456 18.6903C160.473 16.8662 161.986 15.4072 163.837 15.4325C165.688 15.4579 167.177 16.9601 167.159 18.7872H167.16Z"
        fill="#29333A"
      />
      <path
        d="M134.219 22.233C134.222 22.1317 134.263 22.0378 134.3 21.9454C135.17 19.8307 135.066 17.3657 133.621 15.5565C132.584 14.257 131.057 13.5998 129.501 13.6251C127.544 13.6564 125.927 15.2048 125.638 17.327C125.418 18.9425 125.972 20.6265 126.951 21.8694C126.869 22.0274 126.435 23.9513 126.346 23.9364C127.691 24.1585 129.065 24.0646 130.413 24.1346C131.226 24.1764 132.047 24.3835 132.865 24.4461C133.634 24.5057 134.407 24.3999 135.176 24.391C134.851 23.7844 134.556 23.1585 134.291 22.5177C134.255 22.4312 134.219 22.3433 134.218 22.2494C134.218 22.2449 134.218 22.239 134.218 22.2345L134.219 22.233Z"
        fill="#475056"
      />
      <path
        d="M132.324 22.1602C133.093 21.2794 133.571 20.0693 133.579 18.7265C133.594 15.9904 131.655 13.743 129.248 13.7102C126.84 13.6774 124.884 15.8682 124.872 18.6014C124.866 19.7429 125.203 20.798 125.771 21.6445C125.529 22.4031 125.306 23.1586 125.054 23.9172C126.659 24.181 131.964 24.0826 132.969 24.5342C132.938 24.5401 132.977 24.5968 133.001 24.5759C133.025 24.555 133.019 24.5133 133.008 24.4805C132.78 23.7071 132.552 22.9336 132.324 22.1602Z"
        fill="#475056"
      />
      <path
        d="M132.103 18.3464C132.094 20.093 130.756 21.4894 129.117 21.4655C127.478 21.4417 126.158 20.0066 126.167 18.2614C126.176 16.5163 127.511 15.1169 129.15 15.1408C130.789 15.1646 132.111 16.5998 132.103 18.3479V18.3464Z"
        fill="#29333A"
      />
      <path
        d="M104.723 22.1196C104.725 22.0242 104.763 21.9333 104.798 21.8469C105.613 19.8395 105.505 17.4997 104.135 15.78C103.15 14.546 101.705 13.9231 100.234 13.9454C98.3841 13.9752 96.8616 15.4461 96.5949 17.4595C96.3923 18.993 96.9212 20.5921 97.8508 21.7724C97.7733 21.9214 97.3711 23.7485 97.2862 23.7351C98.5584 23.9452 99.8575 23.8558 101.131 23.9229C101.902 23.9631 102.676 24.1583 103.449 24.2194C104.176 24.2761 104.908 24.1747 105.633 24.1673C105.325 23.5905 105.044 22.9974 104.79 22.3894C104.756 22.3074 104.723 22.2239 104.722 22.1345C104.722 22.1301 104.722 22.1256 104.722 22.1196H104.723Z"
        fill="#475056"
      />
      <path
        d="M102.932 22.0511C103.657 21.215 104.103 20.066 104.106 18.7903C104.112 16.1913 102.271 14.0587 99.9961 14.0274C97.7212 13.9961 95.8798 16.075 95.8768 18.6711C95.8768 19.756 96.1971 20.7575 96.7364 21.5608C96.5115 22.2806 96.3029 22.9974 96.066 23.7202C97.5826 23.972 102.599 23.8767 103.551 24.3059C103.522 24.3103 103.558 24.3655 103.581 24.3461C103.603 24.3267 103.597 24.2865 103.588 24.2552C103.37 23.5205 103.151 22.7858 102.934 22.0525L102.932 22.0511Z"
        fill="#475056"
      />
      <path
        d="M102.71 18.4161C102.707 20.0748 101.449 21.4012 99.8992 21.3773C98.3513 21.3535 97.0984 19.9928 97.0999 18.3341C97.1013 16.6755 98.3587 15.3491 99.9081 15.37C101.458 15.3908 102.713 16.7559 102.71 18.4146V18.4161Z"
        fill="#29333A"
      />
      <path
        d="M173.048 21.6293L173.027 23.7172L92.5918 23.5845V21.2686L87.7827 21.1971V81.4628L92.556 82.1632L92.562 71.4942L172.743 78.7504V92.9752L178.009 93.5356V21.5413L173.047 21.6278L173.048 21.6293ZM98.2634 68.2663L92.5649 67.7879L92.5754 49.514L98.2858 49.7495L98.2619 68.2663H98.2634ZM108.76 69.147L101.206 68.5137L101.237 49.8717L108.81 50.1832L108.76 69.1485V69.147ZM119.584 70.0561L111.712 69.3959L111.768 50.3054L119.66 50.6302L119.582 70.0576L119.584 70.0561ZM130.371 70.9622L122.546 70.305L122.63 50.751L130.477 51.0728L130.371 70.9607V70.9622ZM141.114 71.8638L133.482 71.223L133.595 51.2025L141.248 51.517L141.113 71.8638H141.114ZM152.039 72.7804L144.235 72.1246L144.38 51.6436L152.207 51.9655L152.04 72.7789L152.039 72.7804ZM163.445 73.7371L155.169 73.0426L155.345 52.0952L163.646 52.4365L163.445 73.7371ZM172.263 74.4778L166.585 74.0009L166.795 52.5661L172.491 52.8001L172.263 74.4778Z"
        fill="#475056"
      />
      <path
        d="M126.855 27.1254L135.633 27.264L135.513 47.4798L126.76 47.143L126.855 27.1254Z"
        fill="#29333A"
      />
      <path
        d="M101.277 26.7215L101.244 46.1638L92.5782 45.8315L92.5902 26.5844L101.277 26.7215Z"
        fill="#29333A"
      />
      <path
        d="M109.088 26.8452L117.818 26.9823L117.744 46.7972L109.036 46.4634L109.088 26.8452Z"
        fill="#29333A"
      />
      <path
        d="M153.973 48.1876L145.171 47.8508L145.317 27.416L154.143 27.5561L153.973 48.1876Z"
        fill="#29333A"
      />
      <path
        d="M172.532 48.9013L163.683 48.5616L163.88 27.7095L172.754 27.8496L172.532 48.9013Z"
        fill="#29333A"
      />
    </svg>
  )
}

const PictureConstructionSiteLight = () => {
  return (
    <svg
      aria-hidden="true"
      width="280"
      height="117"
      viewBox="0 0 280 117"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M198.659 100.115L224.364 103.12C224.978 103.191 225.597 103.117 226.18 102.901L242.043 97.619C242.043 96.5997 241.273 95.7443 240.258 95.6385L219.924 93.5357C219.255 93.4895 218.585 93.5342 217.928 93.6668L200.573 97.7055C199.453 97.9663 198.662 98.9648 198.662 100.114L198.659 100.115Z"
        fill="#B3B6B9"
      />
      <path
        d="M217.776 59.0327L205.81 96.6221C205.81 96.6221 218.934 102.387 236.264 96.1587L223.683 58.8956C223.485 58.3099 222.993 57.8673 222.387 57.7465C221.646 57.599 220.543 57.4977 219.223 57.7465C218.54 57.8747 217.986 58.371 217.774 59.0327H217.776Z"
        fill="#E6E7E8"
      />
      <path
        d="M224.963 62.6855L223.683 58.8972C223.485 58.3115 222.993 57.8689 222.387 57.7481C221.647 57.6006 220.543 57.4993 219.223 57.7481C218.54 57.8763 217.986 58.3726 217.775 59.0343L216.615 62.675C219.333 63.3978 222.239 63.3933 224.961 62.6855H224.963Z"
        fill="#B3B6B9"
      />
      <path
        d="M214.351 69.7925L211.866 77.5971C216.745 78.8356 221.871 79.0994 226.85 78.3542C227.913 78.1948 228.997 77.9757 229.991 77.5793L227.413 69.9445C226.517 70.1651 225.59 70.2947 224.669 70.3827C221.226 70.712 217.734 70.5108 214.351 69.7925Z"
        fill="#B3B6B9"
      />
      <path
        d="M234.587 91.1887L232.014 83.5689C228.641 84.9936 224.948 85.6583 221.278 85.6642C217.417 85.6702 213.579 84.9683 209.888 83.8148L207.486 91.3586C216.304 93.7982 225.8 93.7371 234.587 91.1887Z"
        fill="#B3B6B9"
      />
      <path
        d="M37.8 81.1411L57.1227 83.3422C57.583 83.3944 58.0493 83.3392 58.4873 83.1813L70.4116 79.314C70.4116 78.5673 69.8321 77.9414 69.0693 77.8639L53.784 76.3244C53.282 76.2902 52.7769 76.3244 52.2838 76.4213L39.2377 79.378C38.396 79.5688 37.8 80.299 37.8 81.1425V81.1411Z"
        fill="#B3B6B9"
      />
      <path
        d="M52.1707 51.0581L43.1753 78.5837C43.1753 78.5837 53.0407 82.8057 66.069 78.2454L56.6117 50.9583C56.4628 50.5291 56.0933 50.2057 55.6374 50.1178C55.0817 50.0105 54.2504 49.936 53.2582 50.1178C52.7457 50.2117 52.3286 50.5753 52.1707 51.0596V51.0581Z"
        fill="#E6E7E8"
      />
      <path
        d="M57.5726 53.7317L56.6117 50.9583C56.4627 50.5291 56.0932 50.2057 55.6374 50.1178C55.0817 50.0105 54.2504 49.936 53.2582 50.1178C52.7457 50.2117 52.3285 50.5753 52.1706 51.0596L51.2991 53.7258C53.3416 54.2548 55.5271 54.2518 57.5726 53.7332V53.7317Z"
        fill="#B3B6B9"
      />
      <path
        d="M49.5962 58.9356L47.728 64.6509C51.3959 65.5584 55.2485 65.7507 58.9924 65.2052C59.7909 65.089 60.6058 64.928 61.3522 64.6374L59.414 59.0459C58.7391 59.2083 58.0434 59.3022 57.3521 59.3663C54.7643 59.6077 52.1393 59.4602 49.5962 58.9341V58.9356Z"
        fill="#B3B6B9"
      />
      <path
        d="M64.807 74.6047L62.8732 69.025C60.3376 70.0682 57.5606 70.5555 54.803 70.56C51.8994 70.5645 49.0152 70.0503 46.2412 69.2053L44.4355 74.7298C51.0636 76.5167 58.2012 76.472 64.807 74.6047Z"
        fill="#B3B6B9"
      />
      <path
        d="M78.2152 81.7727L94.8681 83.6714L103.701 80.8935L89.6032 79.3898L78.2152 81.7727Z"
        fill="#E6E7E8"
      />
      <path
        d="M94.8681 83.6714L97.9803 88.2152L73.8635 85.3539L78.2152 81.7727L94.8681 83.6714Z"
        fill="#E6E7E8"
      />
      <path
        d="M94.8682 83.6715L97.9803 88.2154L107.683 84.6521L103.701 80.8936L94.8682 83.6715Z"
        fill="#E6E7E8"
      />
      <path
        d="M160.954 91.4744L180.08 93.5906L191.158 89.543L174.509 87.8411L160.954 91.4744Z"
        fill="#E6E7E8"
      />
      <path
        d="M180.08 93.5906L182.66 98.3371L156.517 95.37L160.954 91.4744L180.08 93.5906Z"
        fill="#E6E7E8"
      />
      <path
        d="M180.08 93.5909L182.66 98.3374L194.495 93.2183L191.158 89.5432L180.08 93.5909Z"
        fill="#E6E7E8"
      />
      <path
        d="M168.827 72.0009L169.03 51.8925L166.155 51.8359L165.96 71.7863L157.335 71.1366L157.506 51.2159L154.642 51.1608L154.478 70.922L146.436 70.3154L146.58 50.5512L143.724 50.4961L143.587 70.1023L135.581 69.4987L135.7 49.4663L132.852 49.4112L132.742 69.2856L124.485 68.6642L124.575 49.2532L121.737 49.1995L121.655 68.4511L113.528 67.8386L113.589 48.821L110.76 48.7674L110.706 67.6269L103.063 67.0517L103.098 48.6198L100.279 48.5662L100.251 66.8401L94.3005 66.3915L94.3139 49.3993L91.2956 48.7525L91.2866 68.612L174.615 75.9502L175.159 72.4778L168.827 72.0009Z"
        fill="#E6E7E8"
      />
      <path
        d="M92.5931 21.27L94.3362 22.1448L94.287 81.7458L92.5574 82.163L92.5931 21.27Z"
        fill="#E6E7E8"
      />
      <path
        d="M178.012 21.5445L179.783 22.0422L180.089 92.8696L178.012 93.5372V21.5445Z"
        fill="#E6E7E8"
      />
      <path
        d="M169.538 22.5354C169.541 22.4296 169.587 22.3312 169.63 22.2343C170.621 20.0257 170.514 17.449 168.89 15.5564C167.725 14.1972 166.001 13.5087 164.245 13.534C162.034 13.5653 160.202 15.1808 159.866 17.3984C159.612 19.0854 160.23 20.8469 161.331 22.1464C161.237 22.3103 160.741 24.3207 160.64 24.3058C162.158 24.5398 163.71 24.4429 165.233 24.5175C166.152 24.5622 167.077 24.7797 168.001 24.8468C168.868 24.9094 169.744 24.8006 170.611 24.7917C170.247 24.1568 169.915 23.5026 169.619 22.8334C169.578 22.744 169.54 22.6501 169.538 22.5533C169.538 22.5473 169.538 22.5428 169.538 22.5369V22.5354Z"
        fill="#E6E7E8"
      />
      <path
        d="M167.397 22.4565C168.269 21.537 168.814 20.2732 168.829 18.8694C168.857 16.0095 166.676 13.6593 163.957 13.6221C161.241 13.5848 159.02 15.8709 158.995 18.7278C158.984 19.9215 159.36 21.0243 159.996 21.9081C159.72 22.6994 159.466 23.4893 159.177 24.2821C160.987 24.5608 166.982 24.4639 168.114 24.9378C168.08 24.9438 168.121 25.0034 168.15 24.9825C168.178 24.9602 168.169 24.917 168.159 24.8827C167.904 24.0749 167.651 23.2657 167.396 22.4565H167.397Z"
        fill="#E6E7E8"
      />
      <path
        d="M167.16 18.7872C167.143 20.6128 165.627 22.0703 163.776 22.042C161.924 22.0137 160.44 20.5145 160.456 18.6903C160.473 16.8662 161.986 15.4072 163.837 15.4326C165.688 15.4579 167.177 16.9601 167.159 18.7872H167.16Z"
        fill="#B3B6B9"
      />
      <path
        d="M134.219 22.233C134.222 22.1317 134.263 22.0378 134.3 21.9454C135.17 19.8307 135.066 17.3658 133.621 15.5566C132.584 14.257 131.057 13.5998 129.501 13.6251C127.544 13.6564 125.927 15.2048 125.638 17.327C125.418 18.9425 125.972 20.6265 126.951 21.8694C126.869 22.0274 126.435 23.9513 126.346 23.9364C127.691 24.1585 129.065 24.0646 130.413 24.1346C131.226 24.1764 132.047 24.3835 132.865 24.4461C133.634 24.5057 134.407 24.3999 135.176 24.391C134.851 23.7844 134.556 23.1585 134.291 22.5177C134.255 22.4312 134.219 22.3433 134.218 22.2494C134.218 22.245 134.218 22.239 134.218 22.2345L134.219 22.233Z"
        fill="#E6E7E8"
      />
      <path
        d="M132.324 22.1602C133.093 21.2794 133.571 20.0693 133.579 18.7266C133.594 15.9904 131.655 13.743 129.248 13.7102C126.84 13.6775 124.884 15.8682 124.872 18.6014C124.866 19.7429 125.203 20.798 125.771 21.6445C125.529 22.4031 125.306 23.1587 125.054 23.9172C126.659 24.181 131.964 24.0826 132.969 24.5342C132.938 24.5402 132.977 24.5968 133.001 24.5759C133.025 24.5551 133.019 24.5133 133.008 24.4805C132.78 23.7071 132.552 22.9336 132.324 22.1602Z"
        fill="#E6E7E8"
      />
      <path
        d="M132.103 18.3464C132.094 20.093 130.756 21.4894 129.117 21.4656C127.478 21.4417 126.158 20.0066 126.167 18.2614C126.176 16.5163 127.511 15.1169 129.15 15.1408C130.789 15.1646 132.111 16.5998 132.103 18.3479V18.3464Z"
        fill="#B3B6B9"
      />
      <path
        d="M104.723 22.1197C104.725 22.0243 104.763 21.9334 104.798 21.8469C105.613 19.8395 105.505 17.4998 104.135 15.78C103.15 14.546 101.705 13.9231 100.234 13.9454C98.3841 13.9752 96.8616 15.4462 96.5949 17.4595C96.3923 18.993 96.9212 20.5921 97.8508 21.7724C97.7733 21.9215 97.3711 23.7485 97.2862 23.7351C98.5584 23.9453 99.8575 23.8558 101.131 23.9229C101.902 23.9631 102.676 24.1584 103.449 24.2195C104.176 24.2761 104.908 24.1748 105.633 24.1673C105.325 23.5906 105.044 22.9974 104.79 22.3894C104.756 22.3074 104.723 22.224 104.722 22.1346C104.722 22.1301 104.722 22.1256 104.722 22.1197H104.723Z"
        fill="#E6E7E8"
      />
      <path
        d="M102.932 22.0511C103.657 21.215 104.103 20.066 104.106 18.7903C104.112 16.1913 102.271 14.0587 99.9961 14.0274C97.7212 13.9961 95.8798 16.075 95.8768 18.6711C95.8768 19.756 96.1971 20.7575 96.7364 21.5608C96.5115 22.2806 96.3029 22.9974 96.066 23.7202C97.5826 23.9721 102.599 23.8767 103.551 24.3059C103.522 24.3104 103.558 24.3655 103.581 24.3461C103.603 24.3268 103.597 24.2865 103.588 24.2552C103.37 23.5205 103.151 22.7858 102.934 22.0526L102.932 22.0511Z"
        fill="#E6E7E8"
      />
      <path
        d="M102.71 18.4161C102.707 20.0748 101.449 21.4012 99.8992 21.3773C98.3513 21.3535 97.0984 19.9929 97.0999 18.3342C97.1013 16.6755 98.3587 15.3491 99.9081 15.37C101.458 15.3909 102.713 16.756 102.71 18.4146V18.4161Z"
        fill="#B3B6B9"
      />
      <path
        d="M173.048 21.6293L173.027 23.7172L92.5918 23.5845V21.2686L87.7827 21.1971V81.4628L92.556 82.1632L92.562 71.4942L172.743 78.7504V92.9752L178.009 93.5356V21.5413L173.047 21.6278L173.048 21.6293ZM98.2634 68.2663L92.5649 67.7879L92.5754 49.514L98.2858 49.7495L98.2619 68.2663H98.2634ZM108.76 69.147L101.206 68.5137L101.237 49.8717L108.81 50.1832L108.76 69.1485V69.147ZM119.584 70.0561L111.712 69.3959L111.768 50.3054L119.66 50.6302L119.582 70.0576L119.584 70.0561ZM130.371 70.9622L122.546 70.305L122.63 50.751L130.477 51.0728L130.371 70.9607V70.9622ZM141.114 71.8638L133.482 71.223L133.595 51.2025L141.248 51.517L141.113 71.8638H141.114ZM152.039 72.7804L144.235 72.1246L144.38 51.6436L152.207 51.9655L152.04 72.7789L152.039 72.7804ZM163.445 73.7371L155.169 73.0426L155.345 52.0952L163.646 52.4365L163.445 73.7371ZM172.263 74.4778L166.585 74.0009L166.795 52.5661L172.491 52.8001L172.263 74.4778Z"
        fill="#E6E7E8"
      />
      <path
        d="M126.855 27.1254L135.633 27.264L135.513 47.4798L126.76 47.143L126.855 27.1254Z"
        fill="#B3B6B9"
      />
      <path
        d="M101.277 26.7216L101.244 46.1638L92.5782 45.8315L92.5902 26.5845L101.277 26.7216Z"
        fill="#B3B6B9"
      />
      <path
        d="M109.088 26.8453L117.818 26.9824L117.744 46.7972L109.036 46.4634L109.088 26.8453Z"
        fill="#B3B6B9"
      />
      <path
        d="M153.973 48.1877L145.171 47.8509L145.317 27.416L154.143 27.5561L153.973 48.1877Z"
        fill="#B3B6B9"
      />
      <path
        d="M172.532 48.9014L163.683 48.5616L163.88 27.7095L172.754 27.8496L172.532 48.9014Z"
        fill="#B3B6B9"
      />
    </svg>
  )
}
