// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts

import { lazy } from 'react'

import { ReportIoAreaId, ReportIoAreaRoutes } from '@backoffice-frontend/common'

import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'

import { ReportIoAreaNavigation } from './ReportIoAreaNavigation'

const LazyReportIoComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./ReportIoArea')),
)

const ReportIoComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyReportIoComponent />
  </SuspenseLoadingWrapper>
)

export const ReportIoArea = {
  id: ReportIoAreaId,
  NavigationComponent: ReportIoAreaNavigation,
  route: {
    path: ReportIoAreaRoutes.route,
    component: ReportIoComponent,
  },
}
