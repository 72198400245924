import type { BoumClaims, BoumRight } from '@moia-dev/moia-token-claims'

/**
 * This method is used to check if a user has access to something
 * @param rights the rights you own
 * @param requiredRights if you have ALL these rights, you can pass
 * @param allowedRights if you have ANY of these rights, you can pass
 * @returns {boolean|*}
 */
export const hasAccess = ({
  boumClaim,
  allowedRights,
  requiredRights,
}: {
  boumClaim: BoumClaims
  allowedRights?: BoumRight[]
  requiredRights?: BoumRight[]
}): boolean => {
  const hasEveryRequiredRight =
    !requiredRights ||
    requiredRights.length === 0 ||
    requiredRights.every(r => boumClaim.userRights().has(r))
  const hasSomeAllowedRight =
    !allowedRights ||
    allowedRights.length === 0 ||
    allowedRights.some(r => boumClaim.userRights().has(r))

  return hasEveryRequiredRight && hasSomeAllowedRight
}
