/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import {
  GetAlertSeverityTaskNotificationFragmentDoc,
  GetAlertSeverityAdAlertFragmentDoc,
} from '../../../../../../alerts-common/src/lib/utils/getAlertSeverity.hook'
export type AlertsModalSkeletonIncidentsListVehicleFragment = {
  __typename?: 'Vehicle'
  taskNotifications: {
    __typename?: 'TaskNotificationList'
    tasks: Array<
      | {
          __typename: 'HubServiceNotification'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'IvcTaskNotification'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'PassengerAuthentificationFallbackRequestedNotification'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'PassengerUnbuckled'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'RoadBlockageTaskNotification'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'ShiftDeviationNotification'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'StopAccessibilityDownvotedByDrivers'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'StopActivationFailedNotification'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'StopAreaMissingActiveStopsNotification'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'StopDownVotedByDrivers'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'StopPotentiallyUnreachable'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'StopValidationFailed'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'TimeManagementNotification'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'TripPrebookingNoAssignment'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'VehicleAvailabilityNotification'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'VehicleIncidentNotification'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'VehicleSurveillanceNotification'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
      | {
          __typename: 'VehicleWaypointAttendance'
          id: string
          createdAt: string
          severity: Types.TaskNotificationSeverity
          type: Types.TaskNotificationType
        }
    >
  } | null
  fccCurrentAdAlerts: {
    __typename?: 'FccAdAlerts'
    alerts: Array<{
      __typename: 'FccAdAlert'
      id: string
      createdAt: string
      type: Types.FccAdAlertType
    }>
  } | null
}

export type AlertsModalSkeletonIncidentsListAlert_HubServiceNotification_Fragment =
  {
    __typename: 'HubServiceNotification'
    id: string
    createdAt: string
    severity: Types.TaskNotificationSeverity
    type: Types.TaskNotificationType
  }

export type AlertsModalSkeletonIncidentsListAlert_IvcTaskNotification_Fragment =
  {
    __typename: 'IvcTaskNotification'
    id: string
    createdAt: string
    severity: Types.TaskNotificationSeverity
    type: Types.TaskNotificationType
  }

export type AlertsModalSkeletonIncidentsListAlert_PassengerAuthentificationFallbackRequestedNotification_Fragment =
  {
    __typename: 'PassengerAuthentificationFallbackRequestedNotification'
    id: string
    createdAt: string
    severity: Types.TaskNotificationSeverity
    type: Types.TaskNotificationType
  }

export type AlertsModalSkeletonIncidentsListAlert_PassengerUnbuckled_Fragment =
  {
    __typename: 'PassengerUnbuckled'
    id: string
    createdAt: string
    severity: Types.TaskNotificationSeverity
    type: Types.TaskNotificationType
  }

export type AlertsModalSkeletonIncidentsListAlert_RoadBlockageTaskNotification_Fragment =
  {
    __typename: 'RoadBlockageTaskNotification'
    id: string
    createdAt: string
    severity: Types.TaskNotificationSeverity
    type: Types.TaskNotificationType
  }

export type AlertsModalSkeletonIncidentsListAlert_ShiftDeviationNotification_Fragment =
  {
    __typename: 'ShiftDeviationNotification'
    id: string
    createdAt: string
    severity: Types.TaskNotificationSeverity
    type: Types.TaskNotificationType
  }

export type AlertsModalSkeletonIncidentsListAlert_StopAccessibilityDownvotedByDrivers_Fragment =
  {
    __typename: 'StopAccessibilityDownvotedByDrivers'
    id: string
    createdAt: string
    severity: Types.TaskNotificationSeverity
    type: Types.TaskNotificationType
  }

export type AlertsModalSkeletonIncidentsListAlert_StopActivationFailedNotification_Fragment =
  {
    __typename: 'StopActivationFailedNotification'
    id: string
    createdAt: string
    severity: Types.TaskNotificationSeverity
    type: Types.TaskNotificationType
  }

export type AlertsModalSkeletonIncidentsListAlert_StopAreaMissingActiveStopsNotification_Fragment =
  {
    __typename: 'StopAreaMissingActiveStopsNotification'
    id: string
    createdAt: string
    severity: Types.TaskNotificationSeverity
    type: Types.TaskNotificationType
  }

export type AlertsModalSkeletonIncidentsListAlert_StopDownVotedByDrivers_Fragment =
  {
    __typename: 'StopDownVotedByDrivers'
    id: string
    createdAt: string
    severity: Types.TaskNotificationSeverity
    type: Types.TaskNotificationType
  }

export type AlertsModalSkeletonIncidentsListAlert_StopPotentiallyUnreachable_Fragment =
  {
    __typename: 'StopPotentiallyUnreachable'
    id: string
    createdAt: string
    severity: Types.TaskNotificationSeverity
    type: Types.TaskNotificationType
  }

export type AlertsModalSkeletonIncidentsListAlert_StopValidationFailed_Fragment =
  {
    __typename: 'StopValidationFailed'
    id: string
    createdAt: string
    severity: Types.TaskNotificationSeverity
    type: Types.TaskNotificationType
  }

export type AlertsModalSkeletonIncidentsListAlert_TimeManagementNotification_Fragment =
  {
    __typename: 'TimeManagementNotification'
    id: string
    createdAt: string
    severity: Types.TaskNotificationSeverity
    type: Types.TaskNotificationType
  }

export type AlertsModalSkeletonIncidentsListAlert_TripPrebookingNoAssignment_Fragment =
  {
    __typename: 'TripPrebookingNoAssignment'
    id: string
    createdAt: string
    severity: Types.TaskNotificationSeverity
    type: Types.TaskNotificationType
  }

export type AlertsModalSkeletonIncidentsListAlert_VehicleAvailabilityNotification_Fragment =
  {
    __typename: 'VehicleAvailabilityNotification'
    id: string
    createdAt: string
    severity: Types.TaskNotificationSeverity
    type: Types.TaskNotificationType
  }

export type AlertsModalSkeletonIncidentsListAlert_VehicleIncidentNotification_Fragment =
  {
    __typename: 'VehicleIncidentNotification'
    id: string
    createdAt: string
    severity: Types.TaskNotificationSeverity
    type: Types.TaskNotificationType
  }

export type AlertsModalSkeletonIncidentsListAlert_VehicleSurveillanceNotification_Fragment =
  {
    __typename: 'VehicleSurveillanceNotification'
    id: string
    createdAt: string
    severity: Types.TaskNotificationSeverity
    type: Types.TaskNotificationType
  }

export type AlertsModalSkeletonIncidentsListAlert_VehicleWaypointAttendance_Fragment =
  {
    __typename: 'VehicleWaypointAttendance'
    id: string
    createdAt: string
    severity: Types.TaskNotificationSeverity
    type: Types.TaskNotificationType
  }

export type AlertsModalSkeletonIncidentsListAlertFragment =
  | AlertsModalSkeletonIncidentsListAlert_HubServiceNotification_Fragment
  | AlertsModalSkeletonIncidentsListAlert_IvcTaskNotification_Fragment
  | AlertsModalSkeletonIncidentsListAlert_PassengerAuthentificationFallbackRequestedNotification_Fragment
  | AlertsModalSkeletonIncidentsListAlert_PassengerUnbuckled_Fragment
  | AlertsModalSkeletonIncidentsListAlert_RoadBlockageTaskNotification_Fragment
  | AlertsModalSkeletonIncidentsListAlert_ShiftDeviationNotification_Fragment
  | AlertsModalSkeletonIncidentsListAlert_StopAccessibilityDownvotedByDrivers_Fragment
  | AlertsModalSkeletonIncidentsListAlert_StopActivationFailedNotification_Fragment
  | AlertsModalSkeletonIncidentsListAlert_StopAreaMissingActiveStopsNotification_Fragment
  | AlertsModalSkeletonIncidentsListAlert_StopDownVotedByDrivers_Fragment
  | AlertsModalSkeletonIncidentsListAlert_StopPotentiallyUnreachable_Fragment
  | AlertsModalSkeletonIncidentsListAlert_StopValidationFailed_Fragment
  | AlertsModalSkeletonIncidentsListAlert_TimeManagementNotification_Fragment
  | AlertsModalSkeletonIncidentsListAlert_TripPrebookingNoAssignment_Fragment
  | AlertsModalSkeletonIncidentsListAlert_VehicleAvailabilityNotification_Fragment
  | AlertsModalSkeletonIncidentsListAlert_VehicleIncidentNotification_Fragment
  | AlertsModalSkeletonIncidentsListAlert_VehicleSurveillanceNotification_Fragment
  | AlertsModalSkeletonIncidentsListAlert_VehicleWaypointAttendance_Fragment

export type AlertsModalSkeletonIncidentsListAdAlertFragment = {
  __typename: 'FccAdAlert'
  id: string
  createdAt: string
  type: Types.FccAdAlertType
}

export const AlertsModalSkeletonIncidentsListAlertFragmentDoc = gql`
    fragment AlertsModalSkeletonIncidentsListAlert on TaskNotification {
  __typename
  id
  createdAt
  type: notificationType
  ...GetAlertSeverityTaskNotification
}
    ${GetAlertSeverityTaskNotificationFragmentDoc}`
export const AlertsModalSkeletonIncidentsListAdAlertFragmentDoc = gql`
    fragment AlertsModalSkeletonIncidentsListAdAlert on FccAdAlert {
  __typename
  id
  createdAt
  type
  ...GetAlertSeverityAdAlert
}
    ${GetAlertSeverityAdAlertFragmentDoc}`
export const AlertsModalSkeletonIncidentsListVehicleFragmentDoc = gql`
    fragment AlertsModalSkeletonIncidentsListVehicle on Vehicle {
  taskNotifications(
    status: $taskNotificationStatus
    types: $taskNotificationTypes
  ) {
    tasks {
      ...AlertsModalSkeletonIncidentsListAlert
    }
  }
  fccCurrentAdAlerts {
    alerts {
      ...AlertsModalSkeletonIncidentsListAdAlert
    }
  }
}
    ${AlertsModalSkeletonIncidentsListAlertFragmentDoc}
${AlertsModalSkeletonIncidentsListAdAlertFragmentDoc}`
