/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type GetTripCustomerByTripIdQueryVariables = Types.Exact<{
  tripId: Types.Scalars['ID']['input']
}>

export type GetTripCustomerByTripIdQuery = {
  __typename?: 'Query'
  tripByTripId: {
    __typename?: 'Trip'
    customerId: string
    customer: {
      __typename?: 'Customer'
      firstName: string | null
      lastName: string | null
      phoneNumber: string | null
      email: string | null
    } | null
  } | null
}

export const GetTripCustomerByTripIdDocument = gql`
    query getTripCustomerByTripId($tripId: ID!) {
  tripByTripId(id: $tripId) {
    customerId
    customer {
      firstName
      lastName
      phoneNumber
      email
    }
  }
}
    `

/**
 * __useGetTripCustomerByTripIdQuery__
 *
 * To run a query within a React component, call `useGetTripCustomerByTripIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTripCustomerByTripIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTripCustomerByTripIdQuery({
 *   variables: {
 *      tripId: // value for 'tripId'
 *   },
 * });
 */
export function useGetTripCustomerByTripIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTripCustomerByTripIdQuery,
    GetTripCustomerByTripIdQueryVariables
  > &
    (
      | { variables: GetTripCustomerByTripIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetTripCustomerByTripIdQuery,
    GetTripCustomerByTripIdQueryVariables
  >(GetTripCustomerByTripIdDocument, options)
}
export function useGetTripCustomerByTripIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTripCustomerByTripIdQuery,
    GetTripCustomerByTripIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetTripCustomerByTripIdQuery,
    GetTripCustomerByTripIdQueryVariables
  >(GetTripCustomerByTripIdDocument, options)
}
export function useGetTripCustomerByTripIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetTripCustomerByTripIdQuery,
        GetTripCustomerByTripIdQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetTripCustomerByTripIdQuery,
    GetTripCustomerByTripIdQueryVariables
  >(GetTripCustomerByTripIdDocument, options)
}
export type GetTripCustomerByTripIdQueryHookResult = ReturnType<
  typeof useGetTripCustomerByTripIdQuery
>
export type GetTripCustomerByTripIdLazyQueryHookResult = ReturnType<
  typeof useGetTripCustomerByTripIdLazyQuery
>
export type GetTripCustomerByTripIdSuspenseQueryHookResult = ReturnType<
  typeof useGetTripCustomerByTripIdSuspenseQuery
>
export type GetTripCustomerByTripIdQueryResult = Apollo.QueryResult<
  GetTripCustomerByTripIdQuery,
  GetTripCustomerByTripIdQueryVariables
>
