/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import { VehicleGroupFragmentDoc } from '../../../../../vehicle-common/src/lib/graphql/fragment/vehicleGroup.hook'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type CreateVehicleGroupMutationVariables = Types.Exact<{
  input: Types.VehicleGroupConfigurationInput
}>

export type CreateVehicleGroupMutation = {
  __typename?: 'Mutation'
  createVehicleGroup: {
    __typename?: 'VehicleGroupResponse'
    success: boolean
    vehicleGroup: {
      __typename?: 'VehicleGroup'
      id: string
      groupType: Types.VehicleGroupType
      boxProvider: Types.BoxProvider
      engineType: Types.VehicleEngineType
      displayName: string
      minStateOfCharge: number
      tenant: string
      seats: number
      wheelchairSeats: number
      sdsProvider: Types.SdsProvider
      serviceAreaUUIDs: Array<string>
      isWheelchairAccessible: boolean
      childSeatSpecs: Array<{
        __typename?: 'ChildSeatSpec'
        seatNumber: number
        isBoosterSeat: boolean
        isChildSeat: boolean
        isAvailable: boolean
      }>
    }
  }
}

export const CreateVehicleGroupDocument = gql`
    mutation createVehicleGroup($input: VehicleGroupConfigurationInput!) {
  createVehicleGroup(input: $input) {
    success
    vehicleGroup {
      ...VehicleGroup
    }
  }
}
    ${VehicleGroupFragmentDoc}`
export type CreateVehicleGroupMutationFn = Apollo.MutationFunction<
  CreateVehicleGroupMutation,
  CreateVehicleGroupMutationVariables
>

/**
 * __useCreateVehicleGroupMutation__
 *
 * To run a mutation, you first call `useCreateVehicleGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVehicleGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVehicleGroupMutation, { data, loading, error }] = useCreateVehicleGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVehicleGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateVehicleGroupMutation,
    CreateVehicleGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateVehicleGroupMutation,
    CreateVehicleGroupMutationVariables
  >(CreateVehicleGroupDocument, options)
}
export type CreateVehicleGroupMutationHookResult = ReturnType<
  typeof useCreateVehicleGroupMutation
>
export type CreateVehicleGroupMutationResult =
  Apollo.MutationResult<CreateVehicleGroupMutation>
export type CreateVehicleGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateVehicleGroupMutation,
  CreateVehicleGroupMutationVariables
>
