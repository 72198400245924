import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Callout } from '@moia-dev/pace-web'
import {
  useRedirect,
  useSetCognitoUser,
} from '@backoffice-frontend/authentication'
import type {
  LoginCredentials,
  MOIACognitoUser,
} from '@backoffice-frontend/common'
import {
  AccountViewRoute,
  MfaMethods,
  Routes,
  login,
} from '@backoffice-frontend/common'
import { useMediaQuery, useTheme } from '@backoffice-frontend/patterns'
import { GetSupportChannelByEmail } from '../components/GetSupportChannelByEmail'
import { PublicAppViewContentLayout } from '../components/PublicAppViewContentLayout'
import { LoginForm } from './LoginForm'
import { MultiFactorAuthenticationForm } from './MultiFactorAuthenticationForm'
import { MultiFactorAuthenticationInformation } from './MultiFactorAuthenticationInformation'
import { MultiFactorRegistrationForm } from './MultiFactorRegistrationForm'

const loginSteps = Object.freeze({
  CREDENTIALS: 'CREDENTIALS',
  MULTI_FACTOR_AUTHENTICATION_INFORMATION:
    'MULTI_FACTOR_AUTHENTICATION_INFORMATION',
  MULTI_FACTOR_AUTHENTICATION: 'MULTI_FACTOR_AUTHENTICATION',
  MULTI_FACTOR_REGISTRATION: 'MULTI_FACTOR_REGISTRATION',
})

export const CognitoLoginView = () => {
  const setUser = useSetCognitoUser()
  const authenticatedUser = useRef<MOIACognitoUser>()
  const navigate = useNavigate()
  const [loginStep, setLoginStep] = useState<string>(loginSteps.CREDENTIALS)
  const [email, setEmail] = useState<string>('')
  const [validationError, setValidationError] = useState('')

  const { t } = useTranslation()
  const redirectTo = useRedirect()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleValidationError = (errorMessage: string) => {
    if (errorMessage === 'User is disabled.') {
      setValidationError(t('loginView_userDisabledError'))
      return
    }

    setValidationError('default')
  }
  const handleCredentialsSubmit = async (credentials: LoginCredentials) => {
    setValidationError('')
    setEmail(credentials.username)
    try {
      const user = await login(credentials)
      authenticatedUser.current = user
      if (!user.challengeName) {
        if (user.preferredMFA === MfaMethods.SOFTWARE_TOKEN_MFA) {
          setLoginStep(loginSteps.MULTI_FACTOR_AUTHENTICATION)
        } else {
          setLoginStep(loginSteps.MULTI_FACTOR_AUTHENTICATION_INFORMATION)
        }
      }

      if (user.challengeName === MfaMethods.SOFTWARE_TOKEN_MFA) {
        setLoginStep(loginSteps.MULTI_FACTOR_AUTHENTICATION)
      } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        navigate(Routes.Auth.PasswordChallenge.url)
      } else {
        console.error('Unknown user.challengeName:', user.challengeName)
      }
    } catch (error) {
      // @ts-expect-error there might be a code or message
      if (error?.code === 'PasswordResetRequiredException') {
        navigate(Routes.Auth.SetNewPassword.url)
      } else {
        // @ts-expect-error there might be a message
        handleValidationError(error?.message)
      }
    }
  }

  const getTitle = () => {
    switch (loginStep) {
      case loginSteps.MULTI_FACTOR_AUTHENTICATION:
        return t('loginView_multiFactorAuthentication_header')

      case loginSteps.MULTI_FACTOR_AUTHENTICATION_INFORMATION:
        return t('loginView_multiFactorAuthenticationInformation_header')

      case loginSteps.MULTI_FACTOR_REGISTRATION:
        return t('loginView_multiFactorRegistrationForm_header')

      case loginSteps.CREDENTIALS:
      default:
        return t('loginView_loginForm_header')
    }
  }

  return (
    <PublicAppViewContentLayout title={getTitle()} isMobile={isMobile}>
      {validationError && (
        <div
          css={theme => ({
            marginTop: isMobile ? theme.space.Space2 : theme.space.Space6,
          })}
        >
          <Callout variant="critical" title={t('loginView_generalError')}>
            {validationError === 'default' ? (
              <>
                {t('loginView_validationErrorMessage')}{' '}
                <GetSupportChannelByEmail email={email} />
              </>
            ) : (
              validationError
            )}
          </Callout>
        </div>
      )}
      {loginStep === loginSteps.CREDENTIALS && (
        <LoginForm onSubmit={handleCredentialsSubmit} isMobile={isMobile} />
      )}
      {loginStep === loginSteps.MULTI_FACTOR_AUTHENTICATION && (
        <MultiFactorAuthenticationForm
          email={email}
          authenticatedUserRef={authenticatedUser}
          onRedirect={() => {
            if (authenticatedUser.current) {
              setUser?.(authenticatedUser.current)
              redirectTo({
                url: '/',
              })
            }
          }}
          isMobile={isMobile}
        />
      )}
      {loginStep === loginSteps.MULTI_FACTOR_AUTHENTICATION_INFORMATION && (
        <MultiFactorAuthenticationInformation
          onNextClick={() => {
            setLoginStep(loginSteps.MULTI_FACTOR_REGISTRATION)
          }}
          isMobile={isMobile}
        />
      )}
      {loginStep === loginSteps.MULTI_FACTOR_REGISTRATION && (
        <MultiFactorRegistrationForm
          onRedirect={() => {
            if (authenticatedUser.current) {
              setUser?.(authenticatedUser.current)
              redirectTo({
                url: AccountViewRoute.route,
              })
            }
          }}
          isMobile={isMobile}
        />
      )}
    </PublicAppViewContentLayout>
  )
}
