/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type ReferrerInfoQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input']
}>

export type ReferrerInfoQuery = {
  __typename?: 'Query'
  referrerInfo: {
    __typename?: 'ReferrerInfo'
    referralCode: string | null
    referredBy: {
      __typename?: 'ReferrerInfo'
      referralCode: string | null
      customer: {
        __typename?: 'Customer'
        id: string
        firstName: string | null
        lastName: string | null
      }
    } | null
  } | null
}

export const ReferrerInfoDocument = gql`
    query ReferrerInfo($id: String!) {
  referrerInfo(customerId: $id) {
    referralCode
    referredBy {
      customer {
        id
        firstName
        lastName
      }
      referralCode
    }
  }
}
    `

/**
 * __useReferrerInfoQuery__
 *
 * To run a query within a React component, call `useReferrerInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferrerInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferrerInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReferrerInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReferrerInfoQuery,
    ReferrerInfoQueryVariables
  > &
    (
      | { variables: ReferrerInfoQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ReferrerInfoQuery, ReferrerInfoQueryVariables>(
    ReferrerInfoDocument,
    options,
  )
}
export function useReferrerInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReferrerInfoQuery,
    ReferrerInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ReferrerInfoQuery, ReferrerInfoQueryVariables>(
    ReferrerInfoDocument,
    options,
  )
}
export function useReferrerInfoSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ReferrerInfoQuery,
        ReferrerInfoQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ReferrerInfoQuery, ReferrerInfoQueryVariables>(
    ReferrerInfoDocument,
    options,
  )
}
export type ReferrerInfoQueryHookResult = ReturnType<
  typeof useReferrerInfoQuery
>
export type ReferrerInfoLazyQueryHookResult = ReturnType<
  typeof useReferrerInfoLazyQuery
>
export type ReferrerInfoSuspenseQueryHookResult = ReturnType<
  typeof useReferrerInfoSuspenseQuery
>
export type ReferrerInfoQueryResult = Apollo.QueryResult<
  ReferrerInfoQuery,
  ReferrerInfoQueryVariables
>
