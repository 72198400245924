/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type OperatorAvailabilityQueryVariables = Types.Exact<{
  serviceAreaUUID: Types.Scalars['ID']['input']
}>

export type OperatorAvailabilityQuery = {
  __typename?: 'Query'
  fccOperatorState: {
    __typename?: 'FccOperatorState'
    availability: Types.FccOperatorAvailability
  } | null
}

export const OperatorAvailabilityDocument = gql`
    query OperatorAvailability($serviceAreaUUID: ID!) {
  fccOperatorState(serviceAreaUUID: $serviceAreaUUID) {
    availability
  }
}
    `

/**
 * __useOperatorAvailabilityQuery__
 *
 * To run a query within a React component, call `useOperatorAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperatorAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperatorAvailabilityQuery({
 *   variables: {
 *      serviceAreaUUID: // value for 'serviceAreaUUID'
 *   },
 * });
 */
export function useOperatorAvailabilityQuery(
  baseOptions: Apollo.QueryHookOptions<
    OperatorAvailabilityQuery,
    OperatorAvailabilityQueryVariables
  > &
    (
      | { variables: OperatorAvailabilityQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    OperatorAvailabilityQuery,
    OperatorAvailabilityQueryVariables
  >(OperatorAvailabilityDocument, options)
}
export function useOperatorAvailabilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OperatorAvailabilityQuery,
    OperatorAvailabilityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    OperatorAvailabilityQuery,
    OperatorAvailabilityQueryVariables
  >(OperatorAvailabilityDocument, options)
}
export function useOperatorAvailabilitySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OperatorAvailabilityQuery,
        OperatorAvailabilityQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    OperatorAvailabilityQuery,
    OperatorAvailabilityQueryVariables
  >(OperatorAvailabilityDocument, options)
}
export type OperatorAvailabilityQueryHookResult = ReturnType<
  typeof useOperatorAvailabilityQuery
>
export type OperatorAvailabilityLazyQueryHookResult = ReturnType<
  typeof useOperatorAvailabilityLazyQuery
>
export type OperatorAvailabilitySuspenseQueryHookResult = ReturnType<
  typeof useOperatorAvailabilitySuspenseQuery
>
export type OperatorAvailabilityQueryResult = Apollo.QueryResult<
  OperatorAvailabilityQuery,
  OperatorAvailabilityQueryVariables
>
