/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
export type AdVehicleSpeedStatusFragment = {
  __typename?: 'Vehicle'
  id: string
  latestADTelemetry: {
    __typename?: 'ADTelemetry'
    speed: {
      __typename?: 'ADTelemetryFloatWithTimestamp'
      value: number
      timestamp: string
    } | null
  } | null
}

export const AdVehicleSpeedStatusFragmentDoc = gql`
    fragment AdVehicleSpeedStatus on Vehicle {
  id
  latestADTelemetry {
    speed {
      value
      timestamp
    }
  }
}
    `
