/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type RequestCabinVideoStreamCredentialsMutationVariables = Types.Exact<{
  request: Types.RequestCabinVideoStreamCredentials
}>

export type RequestCabinVideoStreamCredentialsMutation = {
  __typename?: 'Mutation'
  requestCabinVideoStreamCredentials: {
    __typename?: 'CabinVideoStreamCredentials'
    accessKeyId: string
    secretAccessKey: string
    sessionToken: string
    channelName: string
    validUntil: string
  }
}

export const RequestCabinVideoStreamCredentialsDocument = gql`
    mutation requestCabinVideoStreamCredentials($request: RequestCabinVideoStreamCredentials!) {
  requestCabinVideoStreamCredentials(request: $request) {
    accessKeyId
    secretAccessKey
    sessionToken
    channelName
    validUntil
  }
}
    `
export type RequestCabinVideoStreamCredentialsMutationFn =
  Apollo.MutationFunction<
    RequestCabinVideoStreamCredentialsMutation,
    RequestCabinVideoStreamCredentialsMutationVariables
  >

/**
 * __useRequestCabinVideoStreamCredentialsMutation__
 *
 * To run a mutation, you first call `useRequestCabinVideoStreamCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestCabinVideoStreamCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestCabinVideoStreamCredentialsMutation, { data, loading, error }] = useRequestCabinVideoStreamCredentialsMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useRequestCabinVideoStreamCredentialsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestCabinVideoStreamCredentialsMutation,
    RequestCabinVideoStreamCredentialsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RequestCabinVideoStreamCredentialsMutation,
    RequestCabinVideoStreamCredentialsMutationVariables
  >(RequestCabinVideoStreamCredentialsDocument, options)
}
export type RequestCabinVideoStreamCredentialsMutationHookResult = ReturnType<
  typeof useRequestCabinVideoStreamCredentialsMutation
>
export type RequestCabinVideoStreamCredentialsMutationResult =
  Apollo.MutationResult<RequestCabinVideoStreamCredentialsMutation>
export type RequestCabinVideoStreamCredentialsMutationOptions =
  Apollo.BaseMutationOptions<
    RequestCabinVideoStreamCredentialsMutation,
    RequestCabinVideoStreamCredentialsMutationVariables
  >
