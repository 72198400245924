import type { StandardTextFieldProps, TextFieldProps } from '@mui/material'
import TextField from '@mui/material/TextField'
import clsx from 'clsx'
import * as React from 'react'
import { useRef } from 'react'
import { UUID, getIsoDateFormatWithoutTime } from '@backoffice-frontend/common'
import { useTheme } from '../../basics/theme'
import type { DatePickerProps } from '../../inputs/DateTimePickers/DatePicker'
import { DatePicker } from '../../inputs/DateTimePickers/DatePicker'
import type { MoiaDropdownProps } from '../../inputs/MoiaDropdown/MoiaDropdown'
import { MoiaDropdown } from '../../inputs/MoiaDropdown/MoiaDropdown'

export type MoiaCardFilterProps = {
  filters?: React.ReactNode
  filterColumns?: string
  action?: React.ReactNode
  children?: React.ReactNode
}

/**
 * A wrapper component that can be used to display filters alongside tables
 */
export const MoiaCardFilter = ({
  filters,
  filterColumns,
  action,
  children,
}: MoiaCardFilterProps) => {
  const theme = useTheme()
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        padding: theme.spacing(2, 3, 1, 3),
      }}
    >
      {(filters || action) && (
        <div
          css={{
            [theme.breakpoints.down('md')]: {
              overflow: 'scroll',
              display: 'grid',
              gridAutoFlow: 'column',
            },
            [theme.breakpoints.up('md')]: {
              display: 'flex',
              justifyContent: 'space-between',
            },
          }}
        >
          {filters && (
            <div
              css={{
                display: 'grid',
                gridAutoFlow: 'column',
                alignItems: 'center',
                gridAutoColumns: filterColumns,
                // ensures content is not cut of for the last item
                paddingRight: theme.spacing(1),
                [theme.breakpoints.up('md')]: {
                  overflowX: 'auto',
                  gridGap: theme.spacing(4),
                },
              }}
            >
              {filters}
            </div>
          )}
          {action && (
            <div
              css={{
                display: 'grid',
                justifyContent: 'end',
                alignItems: 'center',
              }}
            >
              {action}
            </div>
          )}
        </div>
      )}
      {children && <div>{children}</div>}
    </div>
  )
}

export const MoiaFilterDropDown = (props: MoiaDropdownProps) => {
  return <MoiaDropdown InputProps={{ disableUnderline: true }} {...props} />
}

type MoiaFilterTextFieldProps = {
  disableUnderline?: boolean
} & TextFieldProps

export const MoiaFilterTextField = ({
  InputProps,
  disableUnderline = true,
  ...props
}: MoiaFilterTextFieldProps) => {
  const theme = useTheme()

  return (
    <TextField
      {...(props as StandardTextFieldProps)}
      css={{
        '& .MuiInput-root': {
          color: props.error ? theme.palette.error.main : undefined,
        },
      }}
      InputProps={{ ...InputProps, disableUnderline: disableUnderline }}
    />
  )
}

type Placement =
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'left'
  | 'left-start'
  | 'left-end'

type MoiaFilterDatePickerProps = {
  offset?: Array<number>
  placement?: Placement
  disableUnderline?: boolean
} & DatePickerProps<Date>

export const MoiaFilterDatePicker = ({
  offset = [0, 8],
  placement = 'bottom-start',
  slotProps,
  ...props
}: MoiaFilterDatePickerProps) => {
  const idRef = useRef(UUID())
  return (
    <DatePicker
      {...props}
      format={getIsoDateFormatWithoutTime()}
      slotProps={{
        textField: { id: idRef.current },
        popper: {
          placement: placement,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: offset,
              },
            },
          ],
        },
        ...slotProps,
      }}
    />
  )
}

export const MoiaTableDropdown = ({
  className,
  ...props
}: MoiaDropdownProps) => {
  return (
    <MoiaFilterDropDown
      {...props}
      className={clsx(className, 'tableDropdown')}
      css={{
        '& .MuiSvgIcon-root': {
          opacity: 0,
        },
      }}
    />
  )
}
