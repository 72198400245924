/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type RioLatestTemplateQueryVariables = Types.Exact<{
  input: Types.RioLatestTemplateInput
}>

export type RioLatestTemplateQuery = {
  __typename?: 'Query'
  rioLatestTemplate: {
    __typename?: 'RioLatestTemplateResponse'
    template: {
      __typename?: 'RioTemplate'
      id: string
      category: Types.RioCategory
      name: string
      tenant: string
      supportedFleets: Array<string> | null
      version: number
      immutable: boolean | null
      title: {
        __typename?: 'RioLabel'
        de: string | null
        en: string | null
      } | null
      newReportTitle: {
        __typename?: 'RioLabel'
        en: string | null
        de: string | null
      } | null
      editReportTitle: {
        __typename?: 'RioLabel'
        en: string | null
        de: string | null
      } | null
      viewReportTitle: {
        __typename?: 'RioLabel'
        en: string | null
        de: string | null
      } | null
      components: Array<
        | {
            __typename: 'RioComponentCheckbox'
            componentId: string
            componentType: Types.RioComponentType
            dependingComponentIds: Array<string> | null
            overrideFailValue: boolean | null
            topDivider: boolean | null
            label: {
              __typename?: 'RioLabel'
              de: string | null
              en: string | null
            }
            description: {
              __typename?: 'RioLabel'
              en: string | null
              de: string | null
            } | null
            checkboxLabel: {
              __typename?: 'RioLabel'
              en: string | null
              de: string | null
            } | null
            trueLabel: {
              __typename?: 'RioLabel'
              en: string | null
              de: string | null
            }
            falseLabel: {
              __typename?: 'RioLabel'
              en: string | null
              de: string | null
            }
            viewLabel: {
              __typename?: 'RioLabel'
              en: string | null
              de: string | null
            } | null
          }
        | {
            __typename: 'RioComponentDate'
            componentId: string
            componentType: Types.RioComponentType
            dependingComponentIds: Array<string> | null
            allowFutureDates: boolean | null
            label: {
              __typename?: 'RioLabel'
              de: string | null
              en: string | null
            }
          }
        | {
            __typename: 'RioComponentGeolocation'
            componentId: string
            componentType: Types.RioComponentType
            dependingComponentIds: Array<string> | null
            label: {
              __typename?: 'RioLabel'
              de: string | null
              en: string | null
            }
            latitudeLabel: {
              __typename?: 'RioLabel'
              en: string | null
              de: string | null
            } | null
            longitudeLabel: {
              __typename?: 'RioLabel'
              en: string | null
              de: string | null
            } | null
            latitudeTooltipLabel: {
              __typename?: 'RioLabel'
              en: string | null
              de: string | null
            } | null
            longitudeTooltipLabel: {
              __typename?: 'RioLabel'
              en: string | null
              de: string | null
            } | null
            viewLabel: {
              __typename?: 'RioLabel'
              en: string | null
              de: string | null
            } | null
          }
        | {
            __typename: 'RioComponentOptions'
            componentId: string
            componentType: Types.RioComponentType
            dependingComponentIds: Array<string> | null
            label: {
              __typename?: 'RioLabel'
              de: string | null
              en: string | null
            }
            options: Array<{
              __typename?: 'RioOptionValue'
              value: string
              label: {
                __typename?: 'RioLabel'
                en: string | null
                de: string | null
              }
            }>
          }
        | {
            __typename: 'RioComponentPassFail'
            componentId: string
            componentType: Types.RioComponentType
            dependingComponentIds: Array<string> | null
            preselectedValue: Types.RioPassFailValue | null
            label: {
              __typename?: 'RioLabel'
              de: string | null
              en: string | null
            }
            passFailValues: {
              __typename?: 'RioPassFailValues'
              pass: Types.RioPassFailValue
              fail: Types.RioPassFailValue
            }
            passLabel: {
              __typename?: 'RioLabel'
              en: string | null
              de: string | null
            }
            failLabel: {
              __typename?: 'RioLabel'
              en: string | null
              de: string | null
            }
            viewLabel: {
              __typename?: 'RioLabel'
              en: string | null
              de: string | null
            } | null
            description: {
              __typename?: 'RioLabel'
              en: string | null
              de: string | null
            } | null
          }
        | {
            __typename: 'RioComponentSimple'
            componentId: string
            componentType: Types.RioComponentType
            dependingComponentIds: Array<string> | null
            label: {
              __typename?: 'RioLabel'
              de: string | null
              en: string | null
            }
          }
        | {
            __typename: 'RioComponentStaticValue'
            componentId: string
            componentType: Types.RioComponentType
            dependingComponentIds: Array<string> | null
            staticValue: Types.RioStaticValue
            label: {
              __typename?: 'RioLabel'
              de: string | null
              en: string | null
            }
          }
        | {
            __typename: 'RioComponentStatus'
            componentId: string
            componentType: Types.RioComponentType
            dependingComponentIds: Array<string> | null
            statusOptions: Array<Types.RioStatusOptions>
            label: {
              __typename?: 'RioLabel'
              de: string | null
              en: string | null
            }
          }
        | {
            __typename: 'RioComponentTextArea'
            componentId: string
            componentType: Types.RioComponentType
            dependingComponentIds: Array<string> | null
            label: {
              __typename?: 'RioLabel'
              de: string | null
              en: string | null
            }
            caption: {
              __typename?: 'RioLabel'
              de: string | null
              en: string | null
            } | null
            placeholder: {
              __typename?: 'RioLabel'
              de: string | null
              en: string | null
            } | null
          }
        | {
            __typename: 'RioComponentTroublemaker'
            componentId: string
            componentType: Types.RioComponentType
            dependingComponentIds: Array<string> | null
            troublemakerOptions: Array<Types.RioTroublemakerOptions>
            label: {
              __typename?: 'RioLabel'
              de: string | null
              en: string | null
            }
          }
      >
      editSections: Array<{
        __typename?: 'RioSection'
        sectionId: string
        componentIds: Array<string>
        label: {
          __typename?: 'RioLabel'
          de: string | null
          en: string | null
        } | null
      }>
      viewSections: Array<{
        __typename?: 'RioSection'
        sectionId: string
        componentIds: Array<string>
        width: Types.RioSectionWidth | null
        label: {
          __typename?: 'RioLabel'
          de: string | null
          en: string | null
        } | null
      }>
      rules: Array<{
        __typename?: 'RioRule'
        componentId: string | null
        sectionId: string | null
        rule: Types.RioRuleDefinition
        otherComponentId: string | null
        otherComponentValue: string | null
        label: {
          __typename?: 'RioLabel'
          de: string | null
          en: string | null
        } | null
      }> | null
    } | null
  }
}

export type RioTemplateFragment = {
  __typename?: 'RioTemplate'
  id: string
  category: Types.RioCategory
  name: string
  tenant: string
  supportedFleets: Array<string> | null
  version: number
  immutable: boolean | null
  title: {
    __typename?: 'RioLabel'
    de: string | null
    en: string | null
  } | null
  newReportTitle: {
    __typename?: 'RioLabel'
    en: string | null
    de: string | null
  } | null
  editReportTitle: {
    __typename?: 'RioLabel'
    en: string | null
    de: string | null
  } | null
  viewReportTitle: {
    __typename?: 'RioLabel'
    en: string | null
    de: string | null
  } | null
  components: Array<
    | {
        __typename: 'RioComponentCheckbox'
        componentId: string
        componentType: Types.RioComponentType
        dependingComponentIds: Array<string> | null
        overrideFailValue: boolean | null
        topDivider: boolean | null
        label: { __typename?: 'RioLabel'; de: string | null; en: string | null }
        description: {
          __typename?: 'RioLabel'
          en: string | null
          de: string | null
        } | null
        checkboxLabel: {
          __typename?: 'RioLabel'
          en: string | null
          de: string | null
        } | null
        trueLabel: {
          __typename?: 'RioLabel'
          en: string | null
          de: string | null
        }
        falseLabel: {
          __typename?: 'RioLabel'
          en: string | null
          de: string | null
        }
        viewLabel: {
          __typename?: 'RioLabel'
          en: string | null
          de: string | null
        } | null
      }
    | {
        __typename: 'RioComponentDate'
        componentId: string
        componentType: Types.RioComponentType
        dependingComponentIds: Array<string> | null
        allowFutureDates: boolean | null
        label: { __typename?: 'RioLabel'; de: string | null; en: string | null }
      }
    | {
        __typename: 'RioComponentGeolocation'
        componentId: string
        componentType: Types.RioComponentType
        dependingComponentIds: Array<string> | null
        label: { __typename?: 'RioLabel'; de: string | null; en: string | null }
        latitudeLabel: {
          __typename?: 'RioLabel'
          en: string | null
          de: string | null
        } | null
        longitudeLabel: {
          __typename?: 'RioLabel'
          en: string | null
          de: string | null
        } | null
        latitudeTooltipLabel: {
          __typename?: 'RioLabel'
          en: string | null
          de: string | null
        } | null
        longitudeTooltipLabel: {
          __typename?: 'RioLabel'
          en: string | null
          de: string | null
        } | null
        viewLabel: {
          __typename?: 'RioLabel'
          en: string | null
          de: string | null
        } | null
      }
    | {
        __typename: 'RioComponentOptions'
        componentId: string
        componentType: Types.RioComponentType
        dependingComponentIds: Array<string> | null
        label: { __typename?: 'RioLabel'; de: string | null; en: string | null }
        options: Array<{
          __typename?: 'RioOptionValue'
          value: string
          label: {
            __typename?: 'RioLabel'
            en: string | null
            de: string | null
          }
        }>
      }
    | {
        __typename: 'RioComponentPassFail'
        componentId: string
        componentType: Types.RioComponentType
        dependingComponentIds: Array<string> | null
        preselectedValue: Types.RioPassFailValue | null
        label: { __typename?: 'RioLabel'; de: string | null; en: string | null }
        passFailValues: {
          __typename?: 'RioPassFailValues'
          pass: Types.RioPassFailValue
          fail: Types.RioPassFailValue
        }
        passLabel: {
          __typename?: 'RioLabel'
          en: string | null
          de: string | null
        }
        failLabel: {
          __typename?: 'RioLabel'
          en: string | null
          de: string | null
        }
        viewLabel: {
          __typename?: 'RioLabel'
          en: string | null
          de: string | null
        } | null
        description: {
          __typename?: 'RioLabel'
          en: string | null
          de: string | null
        } | null
      }
    | {
        __typename: 'RioComponentSimple'
        componentId: string
        componentType: Types.RioComponentType
        dependingComponentIds: Array<string> | null
        label: { __typename?: 'RioLabel'; de: string | null; en: string | null }
      }
    | {
        __typename: 'RioComponentStaticValue'
        componentId: string
        componentType: Types.RioComponentType
        dependingComponentIds: Array<string> | null
        staticValue: Types.RioStaticValue
        label: { __typename?: 'RioLabel'; de: string | null; en: string | null }
      }
    | {
        __typename: 'RioComponentStatus'
        componentId: string
        componentType: Types.RioComponentType
        dependingComponentIds: Array<string> | null
        statusOptions: Array<Types.RioStatusOptions>
        label: { __typename?: 'RioLabel'; de: string | null; en: string | null }
      }
    | {
        __typename: 'RioComponentTextArea'
        componentId: string
        componentType: Types.RioComponentType
        dependingComponentIds: Array<string> | null
        label: { __typename?: 'RioLabel'; de: string | null; en: string | null }
        caption: {
          __typename?: 'RioLabel'
          de: string | null
          en: string | null
        } | null
        placeholder: {
          __typename?: 'RioLabel'
          de: string | null
          en: string | null
        } | null
      }
    | {
        __typename: 'RioComponentTroublemaker'
        componentId: string
        componentType: Types.RioComponentType
        dependingComponentIds: Array<string> | null
        troublemakerOptions: Array<Types.RioTroublemakerOptions>
        label: { __typename?: 'RioLabel'; de: string | null; en: string | null }
      }
  >
  editSections: Array<{
    __typename?: 'RioSection'
    sectionId: string
    componentIds: Array<string>
    label: {
      __typename?: 'RioLabel'
      de: string | null
      en: string | null
    } | null
  }>
  viewSections: Array<{
    __typename?: 'RioSection'
    sectionId: string
    componentIds: Array<string>
    width: Types.RioSectionWidth | null
    label: {
      __typename?: 'RioLabel'
      de: string | null
      en: string | null
    } | null
  }>
  rules: Array<{
    __typename?: 'RioRule'
    componentId: string | null
    sectionId: string | null
    rule: Types.RioRuleDefinition
    otherComponentId: string | null
    otherComponentValue: string | null
    label: {
      __typename?: 'RioLabel'
      de: string | null
      en: string | null
    } | null
  }> | null
}

export type RioComponentGeolocationFragment = {
  __typename?: 'RioComponentGeolocation'
  latitudeLabel: {
    __typename?: 'RioLabel'
    en: string | null
    de: string | null
  } | null
  longitudeLabel: {
    __typename?: 'RioLabel'
    en: string | null
    de: string | null
  } | null
  latitudeTooltipLabel: {
    __typename?: 'RioLabel'
    en: string | null
    de: string | null
  } | null
  longitudeTooltipLabel: {
    __typename?: 'RioLabel'
    en: string | null
    de: string | null
  } | null
  viewLabel: {
    __typename?: 'RioLabel'
    en: string | null
    de: string | null
  } | null
}

export type RioComponentTextAreaFragment = {
  __typename?: 'RioComponentTextArea'
  caption: {
    __typename?: 'RioLabel'
    de: string | null
    en: string | null
  } | null
  placeholder: {
    __typename?: 'RioLabel'
    de: string | null
    en: string | null
  } | null
}

export type RioComponentStatusFragment = {
  __typename?: 'RioComponentStatus'
  statusOptions: Array<Types.RioStatusOptions>
}

export type RioComponentDateFragment = {
  __typename?: 'RioComponentDate'
  allowFutureDates: boolean | null
}

export type RioComponentStaticValueFragment = {
  __typename?: 'RioComponentStaticValue'
  staticValue: Types.RioStaticValue
}

export type RioComponentTroublemakerFragment = {
  __typename?: 'RioComponentTroublemaker'
  troublemakerOptions: Array<Types.RioTroublemakerOptions>
}

export type RioComponentOptionsFragment = {
  __typename?: 'RioComponentOptions'
  options: Array<{
    __typename?: 'RioOptionValue'
    value: string
    label: { __typename?: 'RioLabel'; en: string | null; de: string | null }
  }>
}

export type RioComponentPassFailFragment = {
  __typename?: 'RioComponentPassFail'
  preselectedValue: Types.RioPassFailValue | null
  passFailValues: {
    __typename?: 'RioPassFailValues'
    pass: Types.RioPassFailValue
    fail: Types.RioPassFailValue
  }
  passLabel: { __typename?: 'RioLabel'; en: string | null; de: string | null }
  failLabel: { __typename?: 'RioLabel'; en: string | null; de: string | null }
  viewLabel: {
    __typename?: 'RioLabel'
    en: string | null
    de: string | null
  } | null
  description: {
    __typename?: 'RioLabel'
    en: string | null
    de: string | null
  } | null
}

export type RioComponentCheckboxFragment = {
  __typename?: 'RioComponentCheckbox'
  overrideFailValue: boolean | null
  topDivider: boolean | null
  description: {
    __typename?: 'RioLabel'
    en: string | null
    de: string | null
  } | null
  checkboxLabel: {
    __typename?: 'RioLabel'
    en: string | null
    de: string | null
  } | null
  trueLabel: { __typename?: 'RioLabel'; en: string | null; de: string | null }
  falseLabel: { __typename?: 'RioLabel'; en: string | null; de: string | null }
  viewLabel: {
    __typename?: 'RioLabel'
    en: string | null
    de: string | null
  } | null
}

export type RioRuleFragment = {
  __typename?: 'RioRule'
  componentId: string | null
  sectionId: string | null
  rule: Types.RioRuleDefinition
  otherComponentId: string | null
  otherComponentValue: string | null
  label: {
    __typename?: 'RioLabel'
    de: string | null
    en: string | null
  } | null
}

export const RioComponentDateFragmentDoc = gql`
    fragment rioComponentDate on RioComponentDate {
  allowFutureDates
}
    `
export const RioComponentTextAreaFragmentDoc = gql`
    fragment rioComponentTextArea on RioComponentTextArea {
  caption {
    de
    en
  }
  placeholder {
    de
    en
  }
}
    `
export const RioComponentStatusFragmentDoc = gql`
    fragment rioComponentStatus on RioComponentStatus {
  statusOptions
}
    `
export const RioComponentPassFailFragmentDoc = gql`
    fragment rioComponentPassFail on RioComponentPassFail {
  passFailValues {
    pass
    fail
  }
  passLabel {
    en
    de
  }
  failLabel {
    en
    de
  }
  viewLabel {
    en
    de
  }
  description {
    en
    de
  }
  preselectedValue
}
    `
export const RioComponentCheckboxFragmentDoc = gql`
    fragment rioComponentCheckbox on RioComponentCheckbox {
  description {
    en
    de
  }
  checkboxLabel {
    en
    de
  }
  trueLabel {
    en
    de
  }
  falseLabel {
    en
    de
  }
  viewLabel {
    en
    de
  }
  overrideFailValue
  topDivider
}
    `
export const RioComponentStaticValueFragmentDoc = gql`
    fragment rioComponentStaticValue on RioComponentStaticValue {
  staticValue
}
    `
export const RioComponentTroublemakerFragmentDoc = gql`
    fragment rioComponentTroublemaker on RioComponentTroublemaker {
  troublemakerOptions
}
    `
export const RioComponentOptionsFragmentDoc = gql`
    fragment rioComponentOptions on RioComponentOptions {
  options {
    value
    label {
      en
      de
    }
  }
}
    `
export const RioComponentGeolocationFragmentDoc = gql`
    fragment rioComponentGeolocation on RioComponentGeolocation {
  latitudeLabel {
    en
    de
  }
  longitudeLabel {
    en
    de
  }
  latitudeTooltipLabel {
    en
    de
  }
  longitudeTooltipLabel {
    en
    de
  }
  viewLabel {
    en
    de
  }
}
    `
export const RioRuleFragmentDoc = gql`
    fragment rioRule on RioRule {
  componentId
  sectionId
  rule
  otherComponentId
  otherComponentValue
  label {
    de
    en
  }
}
    `
export const RioTemplateFragmentDoc = gql`
    fragment rioTemplate on RioTemplate {
  id
  category
  name
  tenant
  title {
    de
    en
  }
  supportedFleets
  version
  newReportTitle {
    en
    de
  }
  editReportTitle {
    en
    de
  }
  viewReportTitle {
    en
    de
  }
  components {
    __typename
    componentId
    componentType
    label {
      de
      en
    }
    dependingComponentIds
    ...rioComponentDate
    ...rioComponentTextArea
    ...rioComponentStatus
    ...rioComponentPassFail
    ...rioComponentCheckbox
    ...rioComponentStaticValue
    ...rioComponentTroublemaker
    ...rioComponentOptions
    ...rioComponentGeolocation
  }
  editSections {
    sectionId
    label {
      de
      en
    }
    componentIds
  }
  viewSections {
    sectionId
    label {
      de
      en
    }
    componentIds
    width
  }
  immutable
  rules {
    ...rioRule
  }
}
    ${RioComponentDateFragmentDoc}
${RioComponentTextAreaFragmentDoc}
${RioComponentStatusFragmentDoc}
${RioComponentPassFailFragmentDoc}
${RioComponentCheckboxFragmentDoc}
${RioComponentStaticValueFragmentDoc}
${RioComponentTroublemakerFragmentDoc}
${RioComponentOptionsFragmentDoc}
${RioComponentGeolocationFragmentDoc}
${RioRuleFragmentDoc}`
export const RioLatestTemplateDocument = gql`
    query RioLatestTemplate($input: RioLatestTemplateInput!) {
  rioLatestTemplate(input: $input) {
    template {
      ...rioTemplate
    }
  }
}
    ${RioTemplateFragmentDoc}`

/**
 * __useRioLatestTemplateQuery__
 *
 * To run a query within a React component, call `useRioLatestTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useRioLatestTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRioLatestTemplateQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRioLatestTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    RioLatestTemplateQuery,
    RioLatestTemplateQueryVariables
  > &
    (
      | { variables: RioLatestTemplateQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    RioLatestTemplateQuery,
    RioLatestTemplateQueryVariables
  >(RioLatestTemplateDocument, options)
}
export function useRioLatestTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RioLatestTemplateQuery,
    RioLatestTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    RioLatestTemplateQuery,
    RioLatestTemplateQueryVariables
  >(RioLatestTemplateDocument, options)
}
export function useRioLatestTemplateSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RioLatestTemplateQuery,
        RioLatestTemplateQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    RioLatestTemplateQuery,
    RioLatestTemplateQueryVariables
  >(RioLatestTemplateDocument, options)
}
export type RioLatestTemplateQueryHookResult = ReturnType<
  typeof useRioLatestTemplateQuery
>
export type RioLatestTemplateLazyQueryHookResult = ReturnType<
  typeof useRioLatestTemplateLazyQuery
>
export type RioLatestTemplateSuspenseQueryHookResult = ReturnType<
  typeof useRioLatestTemplateSuspenseQuery
>
export type RioLatestTemplateQueryResult = Apollo.QueryResult<
  RioLatestTemplateQuery,
  RioLatestTemplateQueryVariables
>
