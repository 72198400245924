import { useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { getCommitHash } from '@backoffice-frontend/environment'

let shouldRefresh = false

// this event will be broadcasted to all tabs as soon a servive worker has an update
// with this we make sure every tab has the latest version running
navigator?.serviceWorker?.addEventListener('controllerchange', () => {
  console.info('controller changed')
  console.info(`current git commit hash ${getCommitHash()}`)
  shouldRefresh = true
})

export const AppUpdate = () => {
  const location = useLocation()

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useLayoutEffect(() => {
    if (shouldRefresh) {
      window.location.reload()
    }
  }, [location])

  return null
}

// some debug info te be sure what version we are currently running
console.info(`new version setup ${getCommitHash()}`)
