/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type AdSeatStatusSubscriptionVariables = Types.Exact<{
  vehicleId: Types.Scalars['ID']['input']
}>

export type AdSeatStatusSubscription = {
  __typename?: 'Subscription'
  fccVehicleTelemetry: {
    __typename?: 'FccVehicleTelemetry'
    rightSlidingDoorOpen: boolean | null
    seatStatus: {
      __typename?: 'FccSeatStatus'
      thirdRowRight: Types.FccSeatStatusType
      thirdRowMiddle: Types.FccSeatStatusType
      thirdRowLeft: Types.FccSeatStatusType
      secondRowRight: Types.FccSeatStatusType
      secondRowMiddle: Types.FccSeatStatusType
      secondRowLeft: Types.FccSeatStatusType
    } | null
  }
}

export const AdSeatStatusDocument = gql`
    subscription AdSeatStatus($vehicleId: ID!) {
  fccVehicleTelemetry(vehicleId: $vehicleId) {
    rightSlidingDoorOpen
    seatStatus {
      thirdRowRight
      thirdRowMiddle
      thirdRowLeft
      secondRowRight
      secondRowMiddle
      secondRowLeft
    }
  }
}
    `

/**
 * __useAdSeatStatusSubscription__
 *
 * To run a query within a React component, call `useAdSeatStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAdSeatStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdSeatStatusSubscription({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useAdSeatStatusSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    AdSeatStatusSubscription,
    AdSeatStatusSubscriptionVariables
  > &
    (
      | { variables: AdSeatStatusSubscriptionVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    AdSeatStatusSubscription,
    AdSeatStatusSubscriptionVariables
  >(AdSeatStatusDocument, options)
}
export type AdSeatStatusSubscriptionHookResult = ReturnType<
  typeof useAdSeatStatusSubscription
>
export type AdSeatStatusSubscriptionResult =
  Apollo.SubscriptionResult<AdSeatStatusSubscription>
