/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type WorkOrderDeletionMutationVariables = Types.Exact<{
  workOrderID: Types.Scalars['ID']['input']
}>

export type WorkOrderDeletionMutation = {
  __typename?: 'Mutation'
  deleteWorkOrder: boolean
}

export const WorkOrderDeletionDocument = gql`
    mutation WorkOrderDeletion($workOrderID: ID!) {
  deleteWorkOrder(workOrderID: $workOrderID)
}
    `
export type WorkOrderDeletionMutationFn = Apollo.MutationFunction<
  WorkOrderDeletionMutation,
  WorkOrderDeletionMutationVariables
>

/**
 * __useWorkOrderDeletionMutation__
 *
 * To run a mutation, you first call `useWorkOrderDeletionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkOrderDeletionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workOrderDeletionMutation, { data, loading, error }] = useWorkOrderDeletionMutation({
 *   variables: {
 *      workOrderID: // value for 'workOrderID'
 *   },
 * });
 */
export function useWorkOrderDeletionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WorkOrderDeletionMutation,
    WorkOrderDeletionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    WorkOrderDeletionMutation,
    WorkOrderDeletionMutationVariables
  >(WorkOrderDeletionDocument, options)
}
export type WorkOrderDeletionMutationHookResult = ReturnType<
  typeof useWorkOrderDeletionMutation
>
export type WorkOrderDeletionMutationResult =
  Apollo.MutationResult<WorkOrderDeletionMutation>
export type WorkOrderDeletionMutationOptions = Apollo.BaseMutationOptions<
  WorkOrderDeletionMutation,
  WorkOrderDeletionMutationVariables
>
