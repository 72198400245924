/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type RioTempAttachmentDownloadUrlQueryVariables = Types.Exact<{
  attachmentId: Types.Scalars['String']['input']
  category: Types.RioCategory
}>

export type RioTempAttachmentDownloadUrlQuery = {
  __typename?: 'Query'
  rioTempAttachmentDownloadUrl: {
    __typename?: 'RioTempAttachmentDownloadUrlResponse'
    url: {
      __typename?: 'RioAttachmentDownloadUrl'
      attachmentId: string
      url: string
    }
  }
}

export const RioTempAttachmentDownloadUrlDocument = gql`
    query RioTempAttachmentDownloadUrl($attachmentId: String!, $category: RioCategory!) {
  rioTempAttachmentDownloadUrl(
    input: {attachmentId: $attachmentId, category: $category}
  ) {
    url {
      attachmentId
      url
    }
  }
}
    `

/**
 * __useRioTempAttachmentDownloadUrlQuery__
 *
 * To run a query within a React component, call `useRioTempAttachmentDownloadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useRioTempAttachmentDownloadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRioTempAttachmentDownloadUrlQuery({
 *   variables: {
 *      attachmentId: // value for 'attachmentId'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useRioTempAttachmentDownloadUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    RioTempAttachmentDownloadUrlQuery,
    RioTempAttachmentDownloadUrlQueryVariables
  > &
    (
      | {
          variables: RioTempAttachmentDownloadUrlQueryVariables
          skip?: boolean
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    RioTempAttachmentDownloadUrlQuery,
    RioTempAttachmentDownloadUrlQueryVariables
  >(RioTempAttachmentDownloadUrlDocument, options)
}
export function useRioTempAttachmentDownloadUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RioTempAttachmentDownloadUrlQuery,
    RioTempAttachmentDownloadUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    RioTempAttachmentDownloadUrlQuery,
    RioTempAttachmentDownloadUrlQueryVariables
  >(RioTempAttachmentDownloadUrlDocument, options)
}
export function useRioTempAttachmentDownloadUrlSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RioTempAttachmentDownloadUrlQuery,
        RioTempAttachmentDownloadUrlQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    RioTempAttachmentDownloadUrlQuery,
    RioTempAttachmentDownloadUrlQueryVariables
  >(RioTempAttachmentDownloadUrlDocument, options)
}
export type RioTempAttachmentDownloadUrlQueryHookResult = ReturnType<
  typeof useRioTempAttachmentDownloadUrlQuery
>
export type RioTempAttachmentDownloadUrlLazyQueryHookResult = ReturnType<
  typeof useRioTempAttachmentDownloadUrlLazyQuery
>
export type RioTempAttachmentDownloadUrlSuspenseQueryHookResult = ReturnType<
  typeof useRioTempAttachmentDownloadUrlSuspenseQuery
>
export type RioTempAttachmentDownloadUrlQueryResult = Apollo.QueryResult<
  RioTempAttachmentDownloadUrlQuery,
  RioTempAttachmentDownloadUrlQueryVariables
>
