import { Outlet } from 'react-router-dom'
import { AuthContextProvider } from '@backoffice-frontend/authentication'
import { loadTranslations } from '@backoffice-frontend/login-view'
import {
  MoiaNotificationProvider,
  PickersUtilsProvider,
  SuspenseLoadingWrapper,
} from '@backoffice-frontend/patterns'
import { AppUpdate } from './AppUpdater'

export const Layout = () => {
  loadTranslations()

  return (
    <AuthContextProvider>
      <PickersUtilsProvider>
        <AppUpdate />
        <MoiaNotificationProvider>
          <SuspenseLoadingWrapper>
            <Outlet />
          </SuspenseLoadingWrapper>
        </MoiaNotificationProvider>
      </PickersUtilsProvider>
    </AuthContextProvider>
  )
}
