/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type DownloadWorkOrdersAsCsvQueryVariables = Types.Exact<{
  serviceAreaUUID: Types.Scalars['ID']['input']
  pagination: Types.PaginationInput
  search?: Types.InputMaybe<Types.Scalars['String']['input']>
  currentLocation?: Types.InputMaybe<Types.Scalars['String']['input']>
  status?: Types.InputMaybe<Types.WorkOrderStatus>
  baseHub?: Types.InputMaybe<Types.Scalars['ID']['input']>
  sorting?: Types.InputMaybe<Types.WorkOrderSorting>
  vehicleId?: Types.InputMaybe<Types.Scalars['ID']['input']>
  fromDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>
  toDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>
  serviceItem?: Types.InputMaybe<Types.WorkOrderServiceItem>
}>

export type DownloadWorkOrdersAsCsvQuery = {
  __typename?: 'Query'
  workOrders: {
    __typename?: 'WorkOrdersQueryResponse'
    workOrders: Array<{
      __typename?: 'WorkOrder'
      id: string
      createdAt: string
      statusChangedAt: string | null
      status: Types.WorkOrderStatus
      mileageAtClosing: number | null
      serviceItem: Types.WorkOrderServiceItem | null
      vehicle: {
        __typename?: 'Vehicle'
        id: string
        label: number | null
        mileage: number | null
        baseHub: { __typename?: 'Hub'; displayName: string } | null
      } | null
    }>
  }
}

export const DownloadWorkOrdersAsCsvDocument = gql`
    query DownloadWorkOrdersAsCsv($serviceAreaUUID: ID!, $pagination: PaginationInput!, $search: String, $currentLocation: String, $status: WorkOrderStatus, $baseHub: ID, $sorting: WorkOrderSorting, $vehicleId: ID, $fromDate: DateTime, $toDate: DateTime, $serviceItem: WorkOrderServiceItem) {
  workOrders(
    input: {serviceAreaUUID: $serviceAreaUUID, pagination: $pagination, search: $search, currentLocation: $currentLocation, status: $status, baseHub: $baseHub, sorting: $sorting, vehicleID: $vehicleId, after: $fromDate, before: $toDate, serviceItem: $serviceItem}
  ) {
    workOrders {
      id
      createdAt
      statusChangedAt
      status
      mileageAtClosing
      serviceItem
      vehicle {
        id
        label
        mileage
        baseHub {
          displayName
        }
      }
    }
  }
}
    `

/**
 * __useDownloadWorkOrdersAsCsvQuery__
 *
 * To run a query within a React component, call `useDownloadWorkOrdersAsCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadWorkOrdersAsCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadWorkOrdersAsCsvQuery({
 *   variables: {
 *      serviceAreaUUID: // value for 'serviceAreaUUID'
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *      currentLocation: // value for 'currentLocation'
 *      status: // value for 'status'
 *      baseHub: // value for 'baseHub'
 *      sorting: // value for 'sorting'
 *      vehicleId: // value for 'vehicleId'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      serviceItem: // value for 'serviceItem'
 *   },
 * });
 */
export function useDownloadWorkOrdersAsCsvQuery(
  baseOptions: Apollo.QueryHookOptions<
    DownloadWorkOrdersAsCsvQuery,
    DownloadWorkOrdersAsCsvQueryVariables
  > &
    (
      | { variables: DownloadWorkOrdersAsCsvQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    DownloadWorkOrdersAsCsvQuery,
    DownloadWorkOrdersAsCsvQueryVariables
  >(DownloadWorkOrdersAsCsvDocument, options)
}
export function useDownloadWorkOrdersAsCsvLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DownloadWorkOrdersAsCsvQuery,
    DownloadWorkOrdersAsCsvQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    DownloadWorkOrdersAsCsvQuery,
    DownloadWorkOrdersAsCsvQueryVariables
  >(DownloadWorkOrdersAsCsvDocument, options)
}
export function useDownloadWorkOrdersAsCsvSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        DownloadWorkOrdersAsCsvQuery,
        DownloadWorkOrdersAsCsvQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    DownloadWorkOrdersAsCsvQuery,
    DownloadWorkOrdersAsCsvQueryVariables
  >(DownloadWorkOrdersAsCsvDocument, options)
}
export type DownloadWorkOrdersAsCsvQueryHookResult = ReturnType<
  typeof useDownloadWorkOrdersAsCsvQuery
>
export type DownloadWorkOrdersAsCsvLazyQueryHookResult = ReturnType<
  typeof useDownloadWorkOrdersAsCsvLazyQuery
>
export type DownloadWorkOrdersAsCsvSuspenseQueryHookResult = ReturnType<
  typeof useDownloadWorkOrdersAsCsvSuspenseQuery
>
export type DownloadWorkOrdersAsCsvQueryResult = Apollo.QueryResult<
  DownloadWorkOrdersAsCsvQuery,
  DownloadWorkOrdersAsCsvQueryVariables
>
