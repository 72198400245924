/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import {
  AdPassengerRowCustomerFragmentDoc,
  AdPassengerRowSeatInfoFragmentDoc,
} from '../components/AdPassengerRow/AdPassengerRow.hook'
export type GetPassengersWaypointFragment = {
  __typename?: 'FccWaypoint'
  actions: Array<
    | {
        __typename: 'FccWaypointDeliveryAction'
        id: string
        estimatedAt: string
        trip: { __typename?: 'Trip'; id: string } | null
        customer: {
          __typename?: 'Customer'
          firstName: string | null
          lastName: string | null
          extendedWalkingTime: boolean
          visualImpairment: boolean | null
        } | null
        seats: {
          __typename?: 'FccWaypointSeatInfo'
          seats: number
          wheelchairs: number
          childSeats: number
          boosterSeats: number
        }
      }
    | { __typename?: 'FccWaypointHubReturnAction' }
    | {
        __typename: 'FccWaypointPickupAction'
        id: string
        estimatedAt: string
        adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
        trip: { __typename?: 'Trip'; id: string } | null
        customer: {
          __typename?: 'Customer'
          firstName: string | null
          lastName: string | null
          extendedWalkingTime: boolean
          visualImpairment: boolean | null
        } | null
        seats: {
          __typename?: 'FccWaypointSeatInfo'
          seats: number
          wheelchairs: number
          childSeats: number
          boosterSeats: number
        }
      }
    | { __typename?: 'FccWaypointReallocationAction' }
  >
}

export type GetPassengersPickupActionFragment = {
  __typename: 'FccWaypointPickupAction'
  id: string
  estimatedAt: string
  adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
  trip: { __typename?: 'Trip'; id: string } | null
  customer: {
    __typename?: 'Customer'
    firstName: string | null
    lastName: string | null
    extendedWalkingTime: boolean
    visualImpairment: boolean | null
  } | null
  seats: {
    __typename?: 'FccWaypointSeatInfo'
    seats: number
    wheelchairs: number
    childSeats: number
    boosterSeats: number
  }
}

export type GetPassengersDeliveryActionFragment = {
  __typename: 'FccWaypointDeliveryAction'
  id: string
  estimatedAt: string
  trip: { __typename?: 'Trip'; id: string } | null
  customer: {
    __typename?: 'Customer'
    firstName: string | null
    lastName: string | null
    extendedWalkingTime: boolean
    visualImpairment: boolean | null
  } | null
  seats: {
    __typename?: 'FccWaypointSeatInfo'
    seats: number
    wheelchairs: number
    childSeats: number
    boosterSeats: number
  }
}

export const GetPassengersPickupActionFragmentDoc = gql`
    fragment GetPassengersPickupAction on FccWaypointPickupAction {
  __typename
  id
  estimatedAt
  adPassengerAuthenticationState
  trip {
    id
  }
  customer {
    ...AdPassengerRowCustomer
  }
  seats {
    seats
    ...AdPassengerRowSeatInfo
  }
}
    ${AdPassengerRowCustomerFragmentDoc}
${AdPassengerRowSeatInfoFragmentDoc}`
export const GetPassengersDeliveryActionFragmentDoc = gql`
    fragment GetPassengersDeliveryAction on FccWaypointDeliveryAction {
  __typename
  id
  estimatedAt
  trip {
    id
  }
  customer {
    ...AdPassengerRowCustomer
  }
  seats {
    seats
    ...AdPassengerRowSeatInfo
  }
}
    ${AdPassengerRowCustomerFragmentDoc}
${AdPassengerRowSeatInfoFragmentDoc}`
export const GetPassengersWaypointFragmentDoc = gql`
    fragment GetPassengersWaypoint on FccWaypoint {
  actions {
    ... on FccWaypointPickupAction {
      ...GetPassengersPickupAction
    }
    ... on FccWaypointDeliveryAction {
      ...GetPassengersDeliveryAction
    }
  }
}
    ${GetPassengersPickupActionFragmentDoc}
${GetPassengersDeliveryActionFragmentDoc}`
