/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type AvoidanceZonesByServiceAreaQueryVariables = Types.Exact<{
  serviceAreaId: Types.Scalars['ID']['input']
}>

export type AvoidanceZonesByServiceAreaQuery = {
  __typename?: 'Query'
  areas: Array<{
    __typename?: 'Area'
    id: string
    status: Types.AreaStatus | null
    type: Types.AreaType
    keepOutZone: boolean | null
    geometry: string
  }>
}

export type ServiceAreaGeoDataQueryVariables = Types.Exact<{
  serviceAreaId: Types.Scalars['ID']['input']
}>

export type ServiceAreaGeoDataQuery = {
  __typename?: 'Query'
  serviceAreaById: {
    __typename?: 'ServiceArea'
    uuid: string
    locationAttributes: {
      __typename?: 'LocationAttributes'
      area: { __typename?: 'Shape'; geoJson: string }
    }
  } | null
}

export const AvoidanceZonesByServiceAreaDocument = gql`
    query avoidanceZonesByServiceArea($serviceAreaId: ID!) {
  areas(
    areaTypes: [ConstructionSite, Event, Other]
    serviceAreaID: $serviceAreaId
  ) {
    id
    status
    type
    keepOutZone
    geometry
  }
}
    `

/**
 * __useAvoidanceZonesByServiceAreaQuery__
 *
 * To run a query within a React component, call `useAvoidanceZonesByServiceAreaQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvoidanceZonesByServiceAreaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvoidanceZonesByServiceAreaQuery({
 *   variables: {
 *      serviceAreaId: // value for 'serviceAreaId'
 *   },
 * });
 */
export function useAvoidanceZonesByServiceAreaQuery(
  baseOptions: Apollo.QueryHookOptions<
    AvoidanceZonesByServiceAreaQuery,
    AvoidanceZonesByServiceAreaQueryVariables
  > &
    (
      | { variables: AvoidanceZonesByServiceAreaQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AvoidanceZonesByServiceAreaQuery,
    AvoidanceZonesByServiceAreaQueryVariables
  >(AvoidanceZonesByServiceAreaDocument, options)
}
export function useAvoidanceZonesByServiceAreaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvoidanceZonesByServiceAreaQuery,
    AvoidanceZonesByServiceAreaQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AvoidanceZonesByServiceAreaQuery,
    AvoidanceZonesByServiceAreaQueryVariables
  >(AvoidanceZonesByServiceAreaDocument, options)
}
export function useAvoidanceZonesByServiceAreaSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AvoidanceZonesByServiceAreaQuery,
        AvoidanceZonesByServiceAreaQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    AvoidanceZonesByServiceAreaQuery,
    AvoidanceZonesByServiceAreaQueryVariables
  >(AvoidanceZonesByServiceAreaDocument, options)
}
export type AvoidanceZonesByServiceAreaQueryHookResult = ReturnType<
  typeof useAvoidanceZonesByServiceAreaQuery
>
export type AvoidanceZonesByServiceAreaLazyQueryHookResult = ReturnType<
  typeof useAvoidanceZonesByServiceAreaLazyQuery
>
export type AvoidanceZonesByServiceAreaSuspenseQueryHookResult = ReturnType<
  typeof useAvoidanceZonesByServiceAreaSuspenseQuery
>
export type AvoidanceZonesByServiceAreaQueryResult = Apollo.QueryResult<
  AvoidanceZonesByServiceAreaQuery,
  AvoidanceZonesByServiceAreaQueryVariables
>
export const ServiceAreaGeoDataDocument = gql`
    query serviceAreaGeoData($serviceAreaId: ID!) {
  serviceAreaById(id: $serviceAreaId) {
    uuid
    locationAttributes {
      area {
        geoJson
      }
    }
  }
}
    `

/**
 * __useServiceAreaGeoDataQuery__
 *
 * To run a query within a React component, call `useServiceAreaGeoDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceAreaGeoDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceAreaGeoDataQuery({
 *   variables: {
 *      serviceAreaId: // value for 'serviceAreaId'
 *   },
 * });
 */
export function useServiceAreaGeoDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    ServiceAreaGeoDataQuery,
    ServiceAreaGeoDataQueryVariables
  > &
    (
      | { variables: ServiceAreaGeoDataQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ServiceAreaGeoDataQuery,
    ServiceAreaGeoDataQueryVariables
  >(ServiceAreaGeoDataDocument, options)
}
export function useServiceAreaGeoDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ServiceAreaGeoDataQuery,
    ServiceAreaGeoDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ServiceAreaGeoDataQuery,
    ServiceAreaGeoDataQueryVariables
  >(ServiceAreaGeoDataDocument, options)
}
export function useServiceAreaGeoDataSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ServiceAreaGeoDataQuery,
        ServiceAreaGeoDataQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ServiceAreaGeoDataQuery,
    ServiceAreaGeoDataQueryVariables
  >(ServiceAreaGeoDataDocument, options)
}
export type ServiceAreaGeoDataQueryHookResult = ReturnType<
  typeof useServiceAreaGeoDataQuery
>
export type ServiceAreaGeoDataLazyQueryHookResult = ReturnType<
  typeof useServiceAreaGeoDataLazyQuery
>
export type ServiceAreaGeoDataSuspenseQueryHookResult = ReturnType<
  typeof useServiceAreaGeoDataSuspenseQuery
>
export type ServiceAreaGeoDataQueryResult = Apollo.QueryResult<
  ServiceAreaGeoDataQuery,
  ServiceAreaGeoDataQueryVariables
>
