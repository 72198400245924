/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type ServiceAreaByIdWithAreaGeometryQueryVariables = Types.Exact<{
  serviceAreaId: Types.Scalars['ID']['input']
}>

export type ServiceAreaByIdWithAreaGeometryQuery = {
  __typename?: 'Query'
  serviceAreaById: {
    __typename?: 'ServiceArea'
    id: string
    locationAttributes: {
      __typename?: 'LocationAttributes'
      area: {
        __typename?: 'Shape'
        geoJson: string
        points: Array<{
          __typename?: 'Location'
          latitude: number
          longitude: number
        }>
      }
      bottomRight: {
        __typename?: 'Location'
        latitude: number
        longitude: number
      }
      topLeft: { __typename?: 'Location'; latitude: number; longitude: number }
      defaultLocation: {
        __typename?: 'Location'
        latitude: number
        longitude: number
      }
    }
  } | null
}

export const ServiceAreaByIdWithAreaGeometryDocument = gql`
    query serviceAreaByIdWithAreaGeometry($serviceAreaId: ID!) {
  serviceAreaById(id: $serviceAreaId) {
    id
    locationAttributes {
      area {
        points {
          latitude
          longitude
        }
        geoJson
      }
      bottomRight {
        latitude
        longitude
      }
      topLeft {
        latitude
        longitude
      }
      defaultLocation {
        latitude
        longitude
      }
    }
  }
}
    `

/**
 * __useServiceAreaByIdWithAreaGeometryQuery__
 *
 * To run a query within a React component, call `useServiceAreaByIdWithAreaGeometryQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceAreaByIdWithAreaGeometryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceAreaByIdWithAreaGeometryQuery({
 *   variables: {
 *      serviceAreaId: // value for 'serviceAreaId'
 *   },
 * });
 */
export function useServiceAreaByIdWithAreaGeometryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ServiceAreaByIdWithAreaGeometryQuery,
    ServiceAreaByIdWithAreaGeometryQueryVariables
  > &
    (
      | {
          variables: ServiceAreaByIdWithAreaGeometryQueryVariables
          skip?: boolean
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ServiceAreaByIdWithAreaGeometryQuery,
    ServiceAreaByIdWithAreaGeometryQueryVariables
  >(ServiceAreaByIdWithAreaGeometryDocument, options)
}
export function useServiceAreaByIdWithAreaGeometryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ServiceAreaByIdWithAreaGeometryQuery,
    ServiceAreaByIdWithAreaGeometryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ServiceAreaByIdWithAreaGeometryQuery,
    ServiceAreaByIdWithAreaGeometryQueryVariables
  >(ServiceAreaByIdWithAreaGeometryDocument, options)
}
export function useServiceAreaByIdWithAreaGeometrySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ServiceAreaByIdWithAreaGeometryQuery,
        ServiceAreaByIdWithAreaGeometryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    ServiceAreaByIdWithAreaGeometryQuery,
    ServiceAreaByIdWithAreaGeometryQueryVariables
  >(ServiceAreaByIdWithAreaGeometryDocument, options)
}
export type ServiceAreaByIdWithAreaGeometryQueryHookResult = ReturnType<
  typeof useServiceAreaByIdWithAreaGeometryQuery
>
export type ServiceAreaByIdWithAreaGeometryLazyQueryHookResult = ReturnType<
  typeof useServiceAreaByIdWithAreaGeometryLazyQuery
>
export type ServiceAreaByIdWithAreaGeometrySuspenseQueryHookResult = ReturnType<
  typeof useServiceAreaByIdWithAreaGeometrySuspenseQuery
>
export type ServiceAreaByIdWithAreaGeometryQueryResult = Apollo.QueryResult<
  ServiceAreaByIdWithAreaGeometryQuery,
  ServiceAreaByIdWithAreaGeometryQueryVariables
>
