/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
export type AdVehiclePromptHeaderFccVehicleFragment = {
  __typename?: 'Vehicle'
  id: string
  fccCurrentAdAlerts: {
    __typename?: 'FccAdAlerts'
    count: number
    alerts: Array<{
      __typename: 'FccAdAlert'
      id: string
      type: Types.FccAdAlertType
    }>
  } | null
}

export const AdVehiclePromptHeaderFccVehicleFragmentDoc = gql`
    fragment AdVehiclePromptHeaderFccVehicle on Vehicle {
  id
  fccCurrentAdAlerts {
    count
    alerts {
      __typename
      id
      type
    }
  }
}
    `
