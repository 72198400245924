// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts

import { lazy } from 'react'

import { AlertsTestingAreaId } from './AlertsTestingAreaId'
import { AlertsTestingAreaRoutes } from './AlertsTestingAreaRoutes'

import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { BoumRight } from '@moia-dev/moia-token-claims'

import { AlertsTestingAreaNavigation } from './AlertsTestingAreaNavigation'

const LazyAlertsTestingComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./AlertsTestingArea')),
)

const AlertsTestingComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyAlertsTestingComponent />
  </SuspenseLoadingWrapper>
)

export const AlertsTestingArea = {
  id: AlertsTestingAreaId,
  requiredRight: BoumRight.AREA_TEST,
  NavigationComponent: AlertsTestingAreaNavigation,
  route: {
    path: AlertsTestingAreaRoutes.route,
    component: AlertsTestingComponent,
  },
}
