/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type CloseVehicleByBackofficeUserMutationVariables = Types.Exact<{
  input: Types.CloseVehicleByBackofficeUserInput
}>

export type CloseVehicleByBackofficeUserMutation = {
  __typename?: 'Mutation'
  closeVehicleByBackofficeUser: {
    __typename?: 'VehicleInteractionResponse'
    interactionID: string | null
    errors: Array<Types.VehicleInteractionError>
  }
}

export const CloseVehicleByBackofficeUserDocument = gql`
    mutation closeVehicleByBackofficeUser($input: CloseVehicleByBackofficeUserInput!) {
  closeVehicleByBackofficeUser(input: $input) {
    interactionID
    errors
  }
}
    `
export type CloseVehicleByBackofficeUserMutationFn = Apollo.MutationFunction<
  CloseVehicleByBackofficeUserMutation,
  CloseVehicleByBackofficeUserMutationVariables
>

/**
 * __useCloseVehicleByBackofficeUserMutation__
 *
 * To run a mutation, you first call `useCloseVehicleByBackofficeUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseVehicleByBackofficeUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeVehicleByBackofficeUserMutation, { data, loading, error }] = useCloseVehicleByBackofficeUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloseVehicleByBackofficeUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloseVehicleByBackofficeUserMutation,
    CloseVehicleByBackofficeUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CloseVehicleByBackofficeUserMutation,
    CloseVehicleByBackofficeUserMutationVariables
  >(CloseVehicleByBackofficeUserDocument, options)
}
export type CloseVehicleByBackofficeUserMutationHookResult = ReturnType<
  typeof useCloseVehicleByBackofficeUserMutation
>
export type CloseVehicleByBackofficeUserMutationResult =
  Apollo.MutationResult<CloseVehicleByBackofficeUserMutation>
export type CloseVehicleByBackofficeUserMutationOptions =
  Apollo.BaseMutationOptions<
    CloseVehicleByBackofficeUserMutation,
    CloseVehicleByBackofficeUserMutationVariables
  >
