import { useEffect, useRef } from 'react'

/**
 * A hook to have access to whatever the previous value was.
 * For example:
 *  <pre>
 *  // State value and setter for our example
 *  const [count, setCount] = useState(0);
 *
 *  // Get the previous value (was passed into hook on last render)
 *  const prevCount = usePrevious(count);
 *
 *  // Display both current and previous count value
 *  return (
 *    <div>
 *      <h1>Now: {count}, before: {prevCount}</h1>
 *      <button onClick={() => setCount(count + 1)}>Increment</button>
 *    </div>
 *  );
 * </pre>
 *
 * Also, see:
 *   <a href="https://usehooks.com/usePrevious/">https://usehooks.com/usePrevious</a>
 *
 * @param value
 * @returns {*} previous value
 */
export const usePrevious = <T>(
  value: T,
  {
    ignoreFalsyValues,
  }: {
    ignoreFalsyValues: boolean
  } = {
    ignoreFalsyValues: false,
  },
): T | undefined => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>()

  // Store current value in ref
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!ignoreFalsyValues) {
      ref.current = value
    }

    if (ignoreFalsyValues && value) {
      ref.current = value
    }
  }, [value]) // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current
}
