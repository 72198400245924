// NOTE: Please do not use other HEX color values for charts.
// Do not add HEX color values without consulting the
// Designers and aligning with the Designer Pattern Libary.
export type ChartTheme = 'blue' | 'black' | 'green' | 'orange' | 'red'

export const chartColors = {
  black: {
    1: '#3B3F42',
    2: '#6C6E79',
    3: '#8A8E9B',
    4: '#A9ABB6',
    5: '#C4C7CF',
    6: '#D7D9E0',
    7: '#F4F5F9',
  },

  blue: {
    1: '#0026D6',
    2: '#0532FF',
    3: '#5070FF',
    4: '#389BFF',
    5: '#9BADFF',
    6: '#CDD6FF',
    7: '#F2F5FF',
  },

  green: {
    1: '#006D59',
    2: '#007C65',
    3: '#009F81',
    4: '#00C192',
    5: '#59DDAA',
    6: '#9EF2C9',
    7: '#DBFEE8',
  },

  orange: {
    1: '#AF3106',
    2: '#C33909',
    3: '#FF642D',
    4: '#FF8C43',
    5: '#FFB26E',
    6: '#FFDCA2',
    7: '#FFF3D9',
  },

  red: {
    1: '#B30028',
    2: '#D1002F',
    3: '#FF4953',
    4: '#FF8786',
    5: '#FFAEB5',
    6: '#FFD7DF',
    7: '#FFF0F7',
  },
}

export const backgroundColors = {
  black: 'rgba(196, 199, 207, 0.1)',
  blue: 'rgba(155, 173, 255, 0.1)',
  green: 'rgba(89, 221, 170, 0.1)',
  orange: 'rgba(255, 178, 110, 0.1)',
  red: 'rgba(255, 174, 181, 0.1)',
}

export const pickColors = (themeName: ChartTheme, count: number): string[] => {
  const availableColors = Object.values(chartColors[themeName])

  if (count > availableColors.length) {
    console.warn('Max allowed sections for chart exceeded. Expected <= 7')
    return []
  }

  switch (count) {
    case 1:
      return [availableColors[2]]
    case 2:
      return [availableColors[0], availableColors[2]]
    case 3:
      return [availableColors[0], availableColors[2], availableColors[4]]
    case 4:
      return [
        availableColors[0],
        availableColors[2],
        availableColors[4],
        availableColors[5],
      ]
    case 5:
      return [
        availableColors[0],
        availableColors[2],
        availableColors[3],
        availableColors[4],
        availableColors[5],
      ]
    case 6:
      return [
        availableColors[0],
        availableColors[1],
        availableColors[2],
        availableColors[3],
        availableColors[4],
        availableColors[5],
      ]
    default:
      return availableColors
  }
}
