const baseRoute = '/pricing'

export const PricingAreaRoutes = {
  route: baseRoute,
  path: '/',
  Overview: {
    route: `${baseRoute}/overview`,
    path: 'overview',
  },
  Simulator: {
    route: `${baseRoute}/simulator`,
    path: 'simulator',
  },
  Optimizer: {
    route: `${baseRoute}/optimizer`,
    path: 'optimizer',
  },
}
