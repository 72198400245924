/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type AlertModalQueryVariables = Types.Exact<{
  alertId: Types.Scalars['ID']['input']
}>

export type AlertModalQuery = {
  __typename?: 'Query'
  alert:
    | {
        __typename?: 'HubServiceNotification'
        id: string
        notificationType: Types.TaskNotificationType
      }
    | {
        __typename?: 'IvcTaskNotification'
        id: string
        notificationType: Types.TaskNotificationType
      }
    | {
        __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
        id: string
        notificationType: Types.TaskNotificationType
        vehicle: { __typename?: 'Vehicle'; id: string } | null
      }
    | {
        __typename?: 'PassengerUnbuckled'
        id: string
        notificationType: Types.TaskNotificationType
      }
    | {
        __typename?: 'RoadBlockageTaskNotification'
        id: string
        notificationType: Types.TaskNotificationType
      }
    | {
        __typename?: 'ShiftDeviationNotification'
        id: string
        notificationType: Types.TaskNotificationType
      }
    | {
        __typename?: 'StopAccessibilityDownvotedByDrivers'
        id: string
        notificationType: Types.TaskNotificationType
      }
    | {
        __typename?: 'StopActivationFailedNotification'
        id: string
        notificationType: Types.TaskNotificationType
      }
    | {
        __typename?: 'StopAreaMissingActiveStopsNotification'
        id: string
        notificationType: Types.TaskNotificationType
      }
    | {
        __typename?: 'StopDownVotedByDrivers'
        id: string
        notificationType: Types.TaskNotificationType
      }
    | {
        __typename?: 'StopPotentiallyUnreachable'
        id: string
        notificationType: Types.TaskNotificationType
      }
    | {
        __typename?: 'StopValidationFailed'
        id: string
        notificationType: Types.TaskNotificationType
      }
    | {
        __typename?: 'TimeManagementNotification'
        id: string
        notificationType: Types.TaskNotificationType
      }
    | {
        __typename?: 'TripPrebookingNoAssignment'
        id: string
        notificationType: Types.TaskNotificationType
      }
    | {
        __typename?: 'VehicleAvailabilityNotification'
        id: string
        notificationType: Types.TaskNotificationType
      }
    | {
        __typename?: 'VehicleIncidentNotification'
        foreignId: string
        id: string
        notificationType: Types.TaskNotificationType
        vehicle: { __typename?: 'Vehicle'; id: string } | null
      }
    | {
        __typename?: 'VehicleSurveillanceNotification'
        id: string
        notificationType: Types.TaskNotificationType
        vehicle: { __typename?: 'Vehicle'; id: string } | null
      }
    | {
        __typename?: 'VehicleWaypointAttendance'
        id: string
        notificationType: Types.TaskNotificationType
      }
    | null
  adAlert: {
    __typename?: 'FccAdAlert'
    id: string
    type: Types.FccAdAlertType
    vehicle: { __typename?: 'Vehicle'; id: string } | null
  } | null
}

export type AlertModalTask_HubServiceNotification_Fragment = {
  __typename?: 'HubServiceNotification'
  id: string
  notificationType: Types.TaskNotificationType
}

export type AlertModalTask_IvcTaskNotification_Fragment = {
  __typename?: 'IvcTaskNotification'
  id: string
  notificationType: Types.TaskNotificationType
}

export type AlertModalTask_PassengerAuthentificationFallbackRequestedNotification_Fragment =
  {
    __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
    id: string
    notificationType: Types.TaskNotificationType
    vehicle: { __typename?: 'Vehicle'; id: string } | null
  }

export type AlertModalTask_PassengerUnbuckled_Fragment = {
  __typename?: 'PassengerUnbuckled'
  id: string
  notificationType: Types.TaskNotificationType
}

export type AlertModalTask_RoadBlockageTaskNotification_Fragment = {
  __typename?: 'RoadBlockageTaskNotification'
  id: string
  notificationType: Types.TaskNotificationType
}

export type AlertModalTask_ShiftDeviationNotification_Fragment = {
  __typename?: 'ShiftDeviationNotification'
  id: string
  notificationType: Types.TaskNotificationType
}

export type AlertModalTask_StopAccessibilityDownvotedByDrivers_Fragment = {
  __typename?: 'StopAccessibilityDownvotedByDrivers'
  id: string
  notificationType: Types.TaskNotificationType
}

export type AlertModalTask_StopActivationFailedNotification_Fragment = {
  __typename?: 'StopActivationFailedNotification'
  id: string
  notificationType: Types.TaskNotificationType
}

export type AlertModalTask_StopAreaMissingActiveStopsNotification_Fragment = {
  __typename?: 'StopAreaMissingActiveStopsNotification'
  id: string
  notificationType: Types.TaskNotificationType
}

export type AlertModalTask_StopDownVotedByDrivers_Fragment = {
  __typename?: 'StopDownVotedByDrivers'
  id: string
  notificationType: Types.TaskNotificationType
}

export type AlertModalTask_StopPotentiallyUnreachable_Fragment = {
  __typename?: 'StopPotentiallyUnreachable'
  id: string
  notificationType: Types.TaskNotificationType
}

export type AlertModalTask_StopValidationFailed_Fragment = {
  __typename?: 'StopValidationFailed'
  id: string
  notificationType: Types.TaskNotificationType
}

export type AlertModalTask_TimeManagementNotification_Fragment = {
  __typename?: 'TimeManagementNotification'
  id: string
  notificationType: Types.TaskNotificationType
}

export type AlertModalTask_TripPrebookingNoAssignment_Fragment = {
  __typename?: 'TripPrebookingNoAssignment'
  id: string
  notificationType: Types.TaskNotificationType
}

export type AlertModalTask_VehicleAvailabilityNotification_Fragment = {
  __typename?: 'VehicleAvailabilityNotification'
  id: string
  notificationType: Types.TaskNotificationType
}

export type AlertModalTask_VehicleIncidentNotification_Fragment = {
  __typename?: 'VehicleIncidentNotification'
  foreignId: string
  id: string
  notificationType: Types.TaskNotificationType
  vehicle: { __typename?: 'Vehicle'; id: string } | null
}

export type AlertModalTask_VehicleSurveillanceNotification_Fragment = {
  __typename?: 'VehicleSurveillanceNotification'
  id: string
  notificationType: Types.TaskNotificationType
  vehicle: { __typename?: 'Vehicle'; id: string } | null
}

export type AlertModalTask_VehicleWaypointAttendance_Fragment = {
  __typename?: 'VehicleWaypointAttendance'
  id: string
  notificationType: Types.TaskNotificationType
}

export type AlertModalTaskFragment =
  | AlertModalTask_HubServiceNotification_Fragment
  | AlertModalTask_IvcTaskNotification_Fragment
  | AlertModalTask_PassengerAuthentificationFallbackRequestedNotification_Fragment
  | AlertModalTask_PassengerUnbuckled_Fragment
  | AlertModalTask_RoadBlockageTaskNotification_Fragment
  | AlertModalTask_ShiftDeviationNotification_Fragment
  | AlertModalTask_StopAccessibilityDownvotedByDrivers_Fragment
  | AlertModalTask_StopActivationFailedNotification_Fragment
  | AlertModalTask_StopAreaMissingActiveStopsNotification_Fragment
  | AlertModalTask_StopDownVotedByDrivers_Fragment
  | AlertModalTask_StopPotentiallyUnreachable_Fragment
  | AlertModalTask_StopValidationFailed_Fragment
  | AlertModalTask_TimeManagementNotification_Fragment
  | AlertModalTask_TripPrebookingNoAssignment_Fragment
  | AlertModalTask_VehicleAvailabilityNotification_Fragment
  | AlertModalTask_VehicleIncidentNotification_Fragment
  | AlertModalTask_VehicleSurveillanceNotification_Fragment
  | AlertModalTask_VehicleWaypointAttendance_Fragment

export type AlertModalAdAlertFragment = {
  __typename?: 'FccAdAlert'
  id: string
  type: Types.FccAdAlertType
  vehicle: { __typename?: 'Vehicle'; id: string } | null
}

export type FccStopHandlingVehicleMutationVariables = Types.Exact<{
  vehicleId: Types.Scalars['ID']['input']
}>

export type FccStopHandlingVehicleMutation = {
  __typename?: 'Mutation'
  fccStopHandlingVehicle: any | null
}

export const AlertModalTaskFragmentDoc = gql`
    fragment AlertModalTask on TaskNotification {
  id
  notificationType
  ... on VehicleIncidentNotification {
    foreignId
    vehicle {
      id
    }
  }
  ... on VehicleSurveillanceNotification {
    vehicle {
      id
    }
  }
  ... on PassengerAuthentificationFallbackRequestedNotification {
    vehicle {
      id
    }
  }
}
    `
export const AlertModalAdAlertFragmentDoc = gql`
    fragment AlertModalAdAlert on FccAdAlert {
  id
  type
  vehicle {
    id
  }
}
    `
export const AlertModalDocument = gql`
    query AlertModal($alertId: ID!) {
  alert: taskNotificationById(id: $alertId) {
    ...AlertModalTask
  }
  adAlert: fccAdAlertById(id: $alertId) {
    ...AlertModalAdAlert
  }
}
    ${AlertModalTaskFragmentDoc}
${AlertModalAdAlertFragmentDoc}`

/**
 * __useAlertModalQuery__
 *
 * To run a query within a React component, call `useAlertModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlertModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlertModalQuery({
 *   variables: {
 *      alertId: // value for 'alertId'
 *   },
 * });
 */
export function useAlertModalQuery(
  baseOptions: Apollo.QueryHookOptions<
    AlertModalQuery,
    AlertModalQueryVariables
  > &
    (
      | { variables: AlertModalQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AlertModalQuery, AlertModalQueryVariables>(
    AlertModalDocument,
    options,
  )
}
export function useAlertModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AlertModalQuery,
    AlertModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AlertModalQuery, AlertModalQueryVariables>(
    AlertModalDocument,
    options,
  )
}
export function useAlertModalSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AlertModalQuery,
        AlertModalQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<AlertModalQuery, AlertModalQueryVariables>(
    AlertModalDocument,
    options,
  )
}
export type AlertModalQueryHookResult = ReturnType<typeof useAlertModalQuery>
export type AlertModalLazyQueryHookResult = ReturnType<
  typeof useAlertModalLazyQuery
>
export type AlertModalSuspenseQueryHookResult = ReturnType<
  typeof useAlertModalSuspenseQuery
>
export type AlertModalQueryResult = Apollo.QueryResult<
  AlertModalQuery,
  AlertModalQueryVariables
>
export const FccStopHandlingVehicleDocument = gql`
    mutation fccStopHandlingVehicle($vehicleId: ID!) {
  fccStopHandlingVehicle(vehicleId: $vehicleId)
}
    `
export type FccStopHandlingVehicleMutationFn = Apollo.MutationFunction<
  FccStopHandlingVehicleMutation,
  FccStopHandlingVehicleMutationVariables
>

/**
 * __useFccStopHandlingVehicleMutation__
 *
 * To run a mutation, you first call `useFccStopHandlingVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFccStopHandlingVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fccStopHandlingVehicleMutation, { data, loading, error }] = useFccStopHandlingVehicleMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useFccStopHandlingVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FccStopHandlingVehicleMutation,
    FccStopHandlingVehicleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    FccStopHandlingVehicleMutation,
    FccStopHandlingVehicleMutationVariables
  >(FccStopHandlingVehicleDocument, options)
}
export type FccStopHandlingVehicleMutationHookResult = ReturnType<
  typeof useFccStopHandlingVehicleMutation
>
export type FccStopHandlingVehicleMutationResult =
  Apollo.MutationResult<FccStopHandlingVehicleMutation>
export type FccStopHandlingVehicleMutationOptions = Apollo.BaseMutationOptions<
  FccStopHandlingVehicleMutation,
  FccStopHandlingVehicleMutationVariables
>
