import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { BoumRight } from '@moia-dev/moia-token-claims'
import {
  CalendarScheduleIcon,
  PenIcon,
  ReloadIcon,
  PersonIcon,
} from '@moia-dev/pace-icons'
import { ActionMenu, useActionMenu, ActionItem } from '@moia-dev/pace-web'
import { AccountViewRoute } from '@backoffice-frontend/common'
import {
  useErrorHandlingMutation,
  useErrorHandlingQuery,
} from '@backoffice-frontend/data-fetching'
import { Environment, getEnvironment } from '@backoffice-frontend/environment'
import { useToggle } from '@backoffice-frontend/hooks'
import {
  Avatar,
  Badge,
  Box,
  NotificationBadge,
  UnstyledButton,
} from '@backoffice-frontend/patterns'
import { Restricted, useUserHasAccess } from '@backoffice-frontend/restricted'
import {
  BackofficeStatusRoutes,
  useBackofficeStatusesQuery,
} from '@backoffice-frontend/status-api'
import {
  BackofficeUpdatesRoutes,
  useBackofficeUpdatesByRightsQuery,
} from '@backoffice-frontend/updates-api'
import { useBackofficeLoggedInUser } from '@backoffice-frontend/user-management-ui'
import { AccountViewDataTestIds } from '../AccountViewDataTestIds'
import {
  useBackofficeStatusGetLastVisitForBadgeQuery,
  useBackofficeStatusSetLastVisitMutation,
  useBackofficeUpdateGetLastVisitForBadgeQuery,
  useBackofficeUpdateSetLastVisitMutation,
} from './AccountBadge.hook'
import { ListOfAvailabilityGroups } from './ListOfAvailabilityGroups'
import { LogoutButton } from './LogoutButton'

const getUserInitials = (userName: string): string => {
  const userNameSplit = userName ? userName.split('.') : ''
  const lastNameInitial =
    userNameSplit[1]?.charAt(0) || userNameSplit[0]?.charAt(1)
  return `${userNameSplit[0]?.charAt(0)}${lastNameInitial}`.toUpperCase()
}

export const AccountBadge = () => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const { user: loggedInUser } = useBackofficeLoggedInUser()
  const userNameInitials = loggedInUser
    ? getUserInitials(loggedInUser.username)
    : ''
  const navigate = useNavigate()

  const { data: updatesData } = useBackofficeUpdatesByRightsQuery()
  const hasAccessToStatuses = useUserHasAccess({
    requiredRights: [BoumRight.BACKOFFICE_STATUS_CRUD],
  })
  const hasAccessToUpdates = useUserHasAccess({
    requiredRights: [BoumRight.BACKOFFICE_UPDATES_R],
  })

  const { data: statusesData } = useErrorHandlingQuery(
    useBackofficeStatusesQuery,
    {
      skip: !hasAccessToStatuses,
    },
  )
  const { data: statusLastVisit, refetch: refetchStatusLastVisit } =
    useErrorHandlingQuery(useBackofficeStatusGetLastVisitForBadgeQuery, {
      skip: !hasAccessToStatuses,
    })
  const { data: updateLastVisit, refetch: refetchUpdateLastVisit } =
    useErrorHandlingQuery(useBackofficeUpdateGetLastVisitForBadgeQuery, {
      skip: !hasAccessToUpdates,
    })

  const [setUpdatesLastVisited] = useErrorHandlingMutation(
    useBackofficeUpdateSetLastVisitMutation,
  )
  const [setStatusLastVisited] = useErrorHandlingMutation(
    useBackofficeStatusSetLastVisitMutation,
  )

  const hasNotificationsUpdates =
    updatesData?.backofficeUpdatesByRights.updates.some(
      update =>
        update?.creationDate &&
        updateLastVisit?.lastVisit.lastVisitDate &&
        update.creationDate > updateLastVisit?.lastVisit.lastVisitDate,
    )

  const hasNotificationsStatuses =
    statusesData?.backofficeStatuses.statuses.some(
      status =>
        status?.creationDate &&
        statusLastVisit?.lastVisit.lastVisitDate &&
        status.creationDate > statusLastVisit?.lastVisit.lastVisitDate,
    )

  const hasNotifications = hasNotificationsUpdates || hasNotificationsStatuses

  const { getReferenceProps, ...props } = useActionMenu({
    isOpen,
    setIsOpen,
  })

  const [modalOpen, setModalOpen] = useToggle(false)

  return (
    <>
      <Box {...getReferenceProps()} ref={props.refs.setReference}>
        <UnstyledButton data-testid={AccountViewDataTestIds.AccountBadge}>
          <NotificationBadge active={hasNotifications}>
            <Avatar small active={isOpen}>
              {userNameInitials}
            </Avatar>
          </NotificationBadge>
        </UnstyledButton>
        <ActionMenu {...props}>
          <ActionItem
            leading={<PersonIcon />}
            label={t('authenticatedAppView_account_button')}
            onClick={() => {
              setIsOpen(false)
              navigate(AccountViewRoute.route)
            }}
          />
          <ActionItem
            leading={<CalendarScheduleIcon />}
            label={t('authenticatedAppView_releaseNotes_button')}
            onClick={async () => {
              await setUpdatesLastVisited()
              await refetchUpdateLastVisit()
              navigate(BackofficeUpdatesRoutes.route)
              setIsOpen(false)
            }}
            trailing={
              hasNotificationsUpdates && (
                <Badge
                  css={{
                    '& > span': {
                      backgroundColor: 'var(--color-accent-default)',
                      borderRadius: '4px',
                      color: 'var(--color-content-on-accent-default)',
                      fontSize: '12px',
                      height: '18px',
                      padding: '0 4px',
                      fontWeight: '400',
                      transform: 'none',
                      position: 'relative',
                    },
                  }}
                />
              )
            }
          />
          <Restricted requiredRights={[BoumRight.BACKOFFICE_STATUS_CRUD]}>
            <ActionItem
              leading={<ReloadIcon />}
              label={t('authenticatedAppView_statusPage_button')}
              onClick={async () => {
                await setStatusLastVisited()
                await refetchStatusLastVisit()
                navigate(BackofficeStatusRoutes.route)
                setIsOpen(false)
              }}
              trailing={
                hasNotificationsStatuses && (
                  <Badge
                    css={{
                      '& > span': {
                        backgroundColor: 'var(--color-accent-default)',
                        borderRadius: '4px',
                        color: 'var(--color-content-on-accent-default)',
                        fontSize: '12px',
                        height: '18px',
                        padding: '0 4px',
                        fontWeight: '400',
                        transform: 'none',
                        position: 'relative',
                      },
                    }}
                  />
                )
              }
            />
          </Restricted>
          {getEnvironment() !== Environment.production && (
            <ActionItem
              leading={<PenIcon />}
              label={t('authenticatedAppView_availableGroups_button')}
              onClick={() => {
                setModalOpen()
                setIsOpen(false)
              }}
            />
          )}
          <LogoutButton />
        </ActionMenu>
      </Box>
      {getEnvironment() !== Environment.production && (
        <ListOfAvailabilityGroups
          isOpen={modalOpen}
          toggleOpen={setModalOpen}
        />
      )}
    </>
  )
}
