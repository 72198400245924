import { memo, useEffect, useState } from 'react'
import {
  ActionItem,
  ActionItemDivider,
  ActionItemMultiSelect,
  ActionMenu,
  FilterButton,
  useActionMenu,
} from '@moia-dev/pace-web'
import type {
  RioComponentType,
  RioReportsFilter,
} from '@backoffice-frontend/graphql'
import { useLocalStorage } from '@backoffice-frontend/hooks'

type CustomFilterProps = {
  filterKey: string
  filterLabel: string
  label: string
  options: string[]
  getLabel: (option: string) => string
  componentType: RioComponentType
  onFilterChange: (filter: RioReportsFilter) => void
}
export const CustomFilter = memo(
  ({
    filterKey,
    filterLabel,
    label,
    options,
    getLabel,
    componentType,
    onFilterChange,
  }: CustomFilterProps) => {
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false)
    const { getReferenceProps, ...props } = useActionMenu({
      isOpen: isFilterOpen,
      setIsOpen: setIsFilterOpen,
    })

    const [firstRender, setFirstRender] = useState(true)

    const [filterValue, setFilterValue] = useLocalStorage<RioReportsFilter>(
      `${componentType}`,
      {
        componentType: componentType,
        values: [],
      },
    )
    const handleSelectOption = (option: string) => {
      let values: string[]
      if (filterValue.values.includes(option)) {
        values = filterValue.values.filter(item => item !== option)
      } else {
        values = [option].concat(filterValue.values)
      }
      const newFilterValue = { ...filterValue, values }
      setFilterValue({
        componentType: filterValue.componentType,
        values: values,
      })
      onFilterChange(newFilterValue)
    }

    const clearFilter = () => {
      const clearedFilterValue = { ...filterValue, values: [] }
      setFilterValue(clearedFilterValue)
      onFilterChange(clearedFilterValue)
    }

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
      if (firstRender && filterValue.values.length > 0) {
        onFilterChange(filterValue)
      }
      setFirstRender(false)
    }, [
      filterValue,
      filterValue.values,
      onFilterChange,
      firstRender,
      setFirstRender,
    ])

    return (
      <>
        <FilterButton
          {...getReferenceProps({
            onClick: () => {
              setIsFilterOpen(!isFilterOpen)
            },
          })}
          ref={props.refs.setReference}
          label={label}
          selectedOptions={filterValue.values.map(getLabel)}
          key={`${filterKey}FilterButton`}
        />
        <ActionMenu {...props} key={`${filterKey}ActionMenu`}>
          {options.map(option => (
            <ActionItemMultiSelect
              selected={filterValue.values.includes(option)}
              onClick={() => handleSelectOption(option)}
              label={getLabel(option)}
              key={`MultiSelectItem${option}`}
            />
          ))}
          <ActionItemDivider key={`Divider${componentType}${filterKey}`} />
          <ActionItem
            onClick={clearFilter}
            label={filterLabel}
            key={`ClearFilter${componentType}${filterKey}`}
          />
        </ActionMenu>
      </>
    )
  },
)
