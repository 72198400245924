/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type ShiftDeletePlannedHubReturnSectionMutationVariables = Types.Exact<{
  shiftId: Types.Scalars['ID']['input']
  section: Types.ShiftPlannedHubReturnSectionCategory
}>

export type ShiftDeletePlannedHubReturnSectionMutation = {
  __typename?: 'Mutation'
  shiftDeletePlannedHubReturnSection: {
    __typename?: 'ShiftDeletePlannedHubReturnSectionResponse'
    planned: Array<{
      __typename?: 'ShiftPlannedHubReturnSection'
      plannedReturnTime: string
      section: Types.ShiftPlannedHubReturnSectionCategory
      plannedArea: {
        __typename?: 'Area'
        id: string
        name: string | null
        details:
          | { __typename?: 'CooperationDetails' }
          | { __typename?: 'HubDetails'; displayName: string }
          | { __typename?: 'InFieldBreakDetails' }
          | { __typename?: 'ShortBreakDetails' }
          | null
      } | null
    }>
  }
}

export const ShiftDeletePlannedHubReturnSectionDocument = gql`
    mutation shiftDeletePlannedHubReturnSection($shiftId: ID!, $section: ShiftPlannedHubReturnSectionCategory!) {
  shiftDeletePlannedHubReturnSection(
    input: {shiftId: $shiftId, section: $section}
  ) {
    planned {
      plannedArea {
        id
        details {
          ... on HubDetails {
            displayName
          }
        }
        name
      }
      plannedReturnTime
      section
    }
  }
}
    `
export type ShiftDeletePlannedHubReturnSectionMutationFn =
  Apollo.MutationFunction<
    ShiftDeletePlannedHubReturnSectionMutation,
    ShiftDeletePlannedHubReturnSectionMutationVariables
  >

/**
 * __useShiftDeletePlannedHubReturnSectionMutation__
 *
 * To run a mutation, you first call `useShiftDeletePlannedHubReturnSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShiftDeletePlannedHubReturnSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shiftDeletePlannedHubReturnSectionMutation, { data, loading, error }] = useShiftDeletePlannedHubReturnSectionMutation({
 *   variables: {
 *      shiftId: // value for 'shiftId'
 *      section: // value for 'section'
 *   },
 * });
 */
export function useShiftDeletePlannedHubReturnSectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ShiftDeletePlannedHubReturnSectionMutation,
    ShiftDeletePlannedHubReturnSectionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ShiftDeletePlannedHubReturnSectionMutation,
    ShiftDeletePlannedHubReturnSectionMutationVariables
  >(ShiftDeletePlannedHubReturnSectionDocument, options)
}
export type ShiftDeletePlannedHubReturnSectionMutationHookResult = ReturnType<
  typeof useShiftDeletePlannedHubReturnSectionMutation
>
export type ShiftDeletePlannedHubReturnSectionMutationResult =
  Apollo.MutationResult<ShiftDeletePlannedHubReturnSectionMutation>
export type ShiftDeletePlannedHubReturnSectionMutationOptions =
  Apollo.BaseMutationOptions<
    ShiftDeletePlannedHubReturnSectionMutation,
    ShiftDeletePlannedHubReturnSectionMutationVariables
  >
