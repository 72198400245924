/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type EmployeeSearchQueryQueryVariables = Types.Exact<{
  employeesFilter?: Types.InputMaybe<Types.EmployeesFilterInput>
}>

export type EmployeeSearchQueryQuery = {
  __typename?: 'Query'
  employees: {
    __typename?: 'Employees'
    total: number
    employees: Array<{
      __typename?: 'Employee'
      confirmed: boolean
      externalId: string | null
      firstName: string | null
      id: string
      lastName: string | null
      roles: Array<Types.Role>
    }>
  }
}

export const EmployeeSearchQueryDocument = gql`
    query employeeSearchQuery($employeesFilter: EmployeesFilterInput) {
  employees(employeesFilter: $employeesFilter) {
    employees {
      confirmed
      externalId
      firstName
      id
      lastName
      roles
    }
    total
  }
}
    `

/**
 * __useEmployeeSearchQueryQuery__
 *
 * To run a query within a React component, call `useEmployeeSearchQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeSearchQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeSearchQueryQuery({
 *   variables: {
 *      employeesFilter: // value for 'employeesFilter'
 *   },
 * });
 */
export function useEmployeeSearchQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EmployeeSearchQueryQuery,
    EmployeeSearchQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    EmployeeSearchQueryQuery,
    EmployeeSearchQueryQueryVariables
  >(EmployeeSearchQueryDocument, options)
}
export function useEmployeeSearchQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmployeeSearchQueryQuery,
    EmployeeSearchQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    EmployeeSearchQueryQuery,
    EmployeeSearchQueryQueryVariables
  >(EmployeeSearchQueryDocument, options)
}
export function useEmployeeSearchQuerySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        EmployeeSearchQueryQuery,
        EmployeeSearchQueryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    EmployeeSearchQueryQuery,
    EmployeeSearchQueryQueryVariables
  >(EmployeeSearchQueryDocument, options)
}
export type EmployeeSearchQueryQueryHookResult = ReturnType<
  typeof useEmployeeSearchQueryQuery
>
export type EmployeeSearchQueryLazyQueryHookResult = ReturnType<
  typeof useEmployeeSearchQueryLazyQuery
>
export type EmployeeSearchQuerySuspenseQueryHookResult = ReturnType<
  typeof useEmployeeSearchQuerySuspenseQuery
>
export type EmployeeSearchQueryQueryResult = Apollo.QueryResult<
  EmployeeSearchQueryQuery,
  EmployeeSearchQueryQueryVariables
>
