// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts

import { lazy } from 'react'

import { DriverTippingAreaId } from './DriverTippingAreaId'
import { DriverTippingAreaRoutes } from '@backoffice-frontend/driver-tipping-api'

import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { BoumRight } from '@moia-dev/moia-token-claims'

import { DriverTippingAreaNavigation } from './DriverTippingAreaNavigation'

const LazyDriverTippingComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./DriverTippingArea')),
)

const DriverTippingComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyDriverTippingComponent />
  </SuspenseLoadingWrapper>
)

export const DriverTippingArea = {
  id: DriverTippingAreaId,
  requiredRight: BoumRight.AREA_DRIVER_TIPPING,
  NavigationComponent: DriverTippingAreaNavigation,
  route: {
    path: DriverTippingAreaRoutes.route,
    component: DriverTippingComponent,
  },
}
