/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import { VehicleGroupFragmentDoc } from '../../../../../vehicle-common/src/lib/graphql/fragment/vehicleGroup.hook'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type VehicleGroupsQueryVariables = Types.Exact<{
  tenant?: Types.InputMaybe<Types.Scalars['String']['input']>
  paginationInput?: Types.InputMaybe<Types.PaginationInput>
}>

export type VehicleGroupsQuery = {
  __typename?: 'Query'
  vehicleGroups: {
    __typename?: 'VehicleGroupsResponse'
    total: number
    vehicleGroups: Array<{
      __typename?: 'VehicleGroup'
      id: string
      groupType: Types.VehicleGroupType
      boxProvider: Types.BoxProvider
      engineType: Types.VehicleEngineType
      displayName: string
      minStateOfCharge: number
      tenant: string
      seats: number
      wheelchairSeats: number
      sdsProvider: Types.SdsProvider
      serviceAreaUUIDs: Array<string>
      isWheelchairAccessible: boolean
      childSeatSpecs: Array<{
        __typename?: 'ChildSeatSpec'
        seatNumber: number
        isBoosterSeat: boolean
        isChildSeat: boolean
        isAvailable: boolean
      }>
    }>
  }
}

export const VehicleGroupsDocument = gql`
    query vehicleGroups($tenant: String, $paginationInput: PaginationInput) {
  vehicleGroups(tenant: $tenant, paginationInput: $paginationInput) {
    total
    vehicleGroups {
      ...VehicleGroup
    }
  }
}
    ${VehicleGroupFragmentDoc}`

/**
 * __useVehicleGroupsQuery__
 *
 * To run a query within a React component, call `useVehicleGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleGroupsQuery({
 *   variables: {
 *      tenant: // value for 'tenant'
 *      paginationInput: // value for 'paginationInput'
 *   },
 * });
 */
export function useVehicleGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    VehicleGroupsQuery,
    VehicleGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<VehicleGroupsQuery, VehicleGroupsQueryVariables>(
    VehicleGroupsDocument,
    options,
  )
}
export function useVehicleGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VehicleGroupsQuery,
    VehicleGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<VehicleGroupsQuery, VehicleGroupsQueryVariables>(
    VehicleGroupsDocument,
    options,
  )
}
export function useVehicleGroupsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        VehicleGroupsQuery,
        VehicleGroupsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    VehicleGroupsQuery,
    VehicleGroupsQueryVariables
  >(VehicleGroupsDocument, options)
}
export type VehicleGroupsQueryHookResult = ReturnType<
  typeof useVehicleGroupsQuery
>
export type VehicleGroupsLazyQueryHookResult = ReturnType<
  typeof useVehicleGroupsLazyQuery
>
export type VehicleGroupsSuspenseQueryHookResult = ReturnType<
  typeof useVehicleGroupsSuspenseQuery
>
export type VehicleGroupsQueryResult = Apollo.QueryResult<
  VehicleGroupsQuery,
  VehicleGroupsQueryVariables
>
