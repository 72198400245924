// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts

import { lazy } from 'react'

import { UserManagementAreaId } from './UserManagementAreaId'
import { UserManagementAreaRoutes } from '@backoffice-frontend/user-management-api'

import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'

import { UserManagementAreaNavigation } from './UserManagementAreaNavigation'

const LazyUserManagementComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./UserManagementArea')),
)

const UserManagementComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyUserManagementComponent />
  </SuspenseLoadingWrapper>
)

export const UserManagementArea = {
  id: UserManagementAreaId,
  NavigationComponent: UserManagementAreaNavigation,
  route: {
    path: UserManagementAreaRoutes.route,
    component: UserManagementComponent,
  },
}
