/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type DepartureVehicleFragment = {
  __typename?: 'Vehicle'
  id: string
  latestADTelemetry: {
    __typename?: 'ADTelemetry'
    centralLockLocked: {
      __typename?: 'ADTelemetryBooleanWithTimestamp'
      value: boolean
    } | null
  } | null
}

export type ResolveAlertMutationVariables = Types.Exact<{
  alertId: Types.Scalars['ID']['input']
}>

export type ResolveAlertMutation = {
  __typename?: 'Mutation'
  updateTaskNotificationStatus:
    | { __typename?: 'HubServiceNotification'; id: string }
    | { __typename?: 'IvcTaskNotification'; id: string }
    | {
        __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
        id: string
      }
    | { __typename?: 'PassengerUnbuckled'; id: string }
    | { __typename?: 'RoadBlockageTaskNotification'; id: string }
    | { __typename?: 'ShiftDeviationNotification'; id: string }
    | { __typename?: 'StopAccessibilityDownvotedByDrivers'; id: string }
    | { __typename?: 'StopActivationFailedNotification'; id: string }
    | { __typename?: 'StopAreaMissingActiveStopsNotification'; id: string }
    | { __typename?: 'StopDownVotedByDrivers'; id: string }
    | { __typename?: 'StopPotentiallyUnreachable'; id: string }
    | { __typename?: 'StopValidationFailed'; id: string }
    | { __typename?: 'TimeManagementNotification'; id: string }
    | { __typename?: 'TripPrebookingNoAssignment'; id: string }
    | { __typename?: 'VehicleAvailabilityNotification'; id: string }
    | { __typename?: 'VehicleIncidentNotification'; id: string }
    | { __typename?: 'VehicleSurveillanceNotification'; id: string }
    | { __typename?: 'VehicleWaypointAttendance'; id: string }
}

export const DepartureVehicleFragmentDoc = gql`
    fragment DepartureVehicle on Vehicle {
  id
  latestADTelemetry {
    centralLockLocked {
      value
    }
  }
}
    `
export const ResolveAlertDocument = gql`
    mutation resolveAlert($alertId: ID!) {
  updateTaskNotificationStatus(id: $alertId, status: SOLVED) {
    id
  }
}
    `
export type ResolveAlertMutationFn = Apollo.MutationFunction<
  ResolveAlertMutation,
  ResolveAlertMutationVariables
>

/**
 * __useResolveAlertMutation__
 *
 * To run a mutation, you first call `useResolveAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveAlertMutation, { data, loading, error }] = useResolveAlertMutation({
 *   variables: {
 *      alertId: // value for 'alertId'
 *   },
 * });
 */
export function useResolveAlertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResolveAlertMutation,
    ResolveAlertMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ResolveAlertMutation,
    ResolveAlertMutationVariables
  >(ResolveAlertDocument, options)
}
export type ResolveAlertMutationHookResult = ReturnType<
  typeof useResolveAlertMutation
>
export type ResolveAlertMutationResult =
  Apollo.MutationResult<ResolveAlertMutation>
export type ResolveAlertMutationOptions = Apollo.BaseMutationOptions<
  ResolveAlertMutation,
  ResolveAlertMutationVariables
>
