/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type FlashVehicleMenuItemVehicleFragment = {
  __typename?: 'Vehicle'
  id: string
  openedBy: Types.VehicleInteractionUserType | null
  hardwareConfiguration: {
    __typename?: 'HardwareConfiguration'
    cdisBox: { __typename?: 'CdisBox'; imei: string }
  } | null
  vehicleGroup: {
    __typename?: 'VehicleGroup'
    boxProvider: Types.BoxProvider
  } | null
}

export type FlashVehicleMenuItemMutationMutationVariables = Types.Exact<{
  vehicleID: Types.Scalars['ID']['input']
}>

export type FlashVehicleMenuItemMutationMutation = {
  __typename?: 'Mutation'
  flashVehicle: boolean
}

export const FlashVehicleMenuItemVehicleFragmentDoc = gql`
    fragment FlashVehicleMenuItemVehicle on Vehicle {
  id
  hardwareConfiguration {
    cdisBox {
      imei
    }
  }
  vehicleGroup {
    boxProvider
  }
  openedBy
}
    `
export const FlashVehicleMenuItemMutationDocument = gql`
    mutation FlashVehicleMenuItemMutation($vehicleID: ID!) {
  flashVehicle(vehicleID: $vehicleID)
}
    `
export type FlashVehicleMenuItemMutationMutationFn = Apollo.MutationFunction<
  FlashVehicleMenuItemMutationMutation,
  FlashVehicleMenuItemMutationMutationVariables
>

/**
 * __useFlashVehicleMenuItemMutationMutation__
 *
 * To run a mutation, you first call `useFlashVehicleMenuItemMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFlashVehicleMenuItemMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [flashVehicleMenuItemMutationMutation, { data, loading, error }] = useFlashVehicleMenuItemMutationMutation({
 *   variables: {
 *      vehicleID: // value for 'vehicleID'
 *   },
 * });
 */
export function useFlashVehicleMenuItemMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FlashVehicleMenuItemMutationMutation,
    FlashVehicleMenuItemMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    FlashVehicleMenuItemMutationMutation,
    FlashVehicleMenuItemMutationMutationVariables
  >(FlashVehicleMenuItemMutationDocument, options)
}
export type FlashVehicleMenuItemMutationMutationHookResult = ReturnType<
  typeof useFlashVehicleMenuItemMutationMutation
>
export type FlashVehicleMenuItemMutationMutationResult =
  Apollo.MutationResult<FlashVehicleMenuItemMutationMutation>
export type FlashVehicleMenuItemMutationMutationOptions =
  Apollo.BaseMutationOptions<
    FlashVehicleMenuItemMutationMutation,
    FlashVehicleMenuItemMutationMutationVariables
  >
