import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Text, Heading, Callout } from '@moia-dev/pace-web'
import {
  currentEnvironmentNotBuildEnvironment,
  getEnvironment,
} from '@backoffice-frontend/environment'
import {
  SwitchEnvironmentDropdown,
  Box,
  backroundEnv,
} from '@backoffice-frontend/patterns'

export const PublicAppViewContentLayout = ({
  children,
  title,
  isMobile,
}: {
  children: React.ReactNode
  title: string
  isMobile: boolean
}) => {
  const { t } = useTranslation()
  const envMismatch = currentEnvironmentNotBuildEnvironment()
  const currentEnv = getEnvironment()

  const isBrowserSupported = React.useMemo(() => {
    const { userAgent } = navigator
    return userAgent.includes('Edg/') || userAgent.includes('Chrome/')
  }, [])

  return (
    <Card
      css={theme => ({
        padding: 0,
        width: isMobile ? '300px' : '400px',
        background: theme.semantic.ColorSurfaceDefault,
        color: theme.semantic.ColorContentDefault,
        overflow: 'hidden',
      })}
      cornerRadius="large"
    >
      {envMismatch && (
        <span
          css={theme => ({
            background: `linear-gradient(90deg, ${backroundEnv[currentEnv].dark} 0%, ${backroundEnv[currentEnv].bright} 100%)`,
            padding: `${theme.space.Space1} 0`,
            color:
              theme.semantic
                .ColorContentOnContrastCriticalSuccessDefaultAlternative,
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'center',
            letterSpacing: '0.75px',
          })}
        >
          {currentEnv.toUpperCase()}
        </span>
      )}
      <div
        css={theme => ({
          padding: isMobile ? theme.space.Space2 : theme.space.Space6,
        })}
      >
        <Heading css={{ textAlign: 'center' }} size={isMobile ? 'md' : 'xl'}>
          {title}
        </Heading>
        {!isBrowserSupported && (
          <Callout
            variant={envMismatch ? 'critical' : 'warning'}
            title={t('loginView_browserNotSupported_header')}
          >
            {t('loginView_browserNotSupported_caption')}
          </Callout>
        )}
        {children}
        {envMismatch && (
          <Box paddingTop={2}>
            <Text size="lg">{t('loginView_environmentMismatch')}</Text>
            <SwitchEnvironmentDropdown />
          </Box>
        )}
      </div>
    </Card>
  )
}
