/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type RequestOperatorCallMutationVariables = Types.Exact<{
  vehicleId: Types.Scalars['ID']['input']
  causedByAlertId?: Types.InputMaybe<Types.Scalars['ID']['input']>
}>

export type RequestOperatorCallMutation = {
  __typename?: 'Mutation'
  fccRequestOperatorCall: any | null
}

export const RequestOperatorCallDocument = gql`
    mutation requestOperatorCall($vehicleId: ID!, $causedByAlertId: ID) {
  fccRequestOperatorCall(
    input: {vehicleId: $vehicleId, causedByAlertId: $causedByAlertId}
  )
}
    `
export type RequestOperatorCallMutationFn = Apollo.MutationFunction<
  RequestOperatorCallMutation,
  RequestOperatorCallMutationVariables
>

/**
 * __useRequestOperatorCallMutation__
 *
 * To run a mutation, you first call `useRequestOperatorCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestOperatorCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestOperatorCallMutation, { data, loading, error }] = useRequestOperatorCallMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      causedByAlertId: // value for 'causedByAlertId'
 *   },
 * });
 */
export function useRequestOperatorCallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestOperatorCallMutation,
    RequestOperatorCallMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RequestOperatorCallMutation,
    RequestOperatorCallMutationVariables
  >(RequestOperatorCallDocument, options)
}
export type RequestOperatorCallMutationHookResult = ReturnType<
  typeof useRequestOperatorCallMutation
>
export type RequestOperatorCallMutationResult =
  Apollo.MutationResult<RequestOperatorCallMutation>
export type RequestOperatorCallMutationOptions = Apollo.BaseMutationOptions<
  RequestOperatorCallMutation,
  RequestOperatorCallMutationVariables
>
