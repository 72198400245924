/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
// @ts-nocheck
import * as Types from '../../../../../../graphql/src/lib/graphql/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type AccountViewCanaryQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type AccountViewCanaryQuery = {
  __typename?: 'Query'
  CANARY_headers: string
}

export const AccountViewCanaryDocument = gql`
    query accountViewCanary {
  CANARY_headers
}
    `

/**
 * __useAccountViewCanaryQuery__
 *
 * To run a query within a React component, call `useAccountViewCanaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountViewCanaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountViewCanaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountViewCanaryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AccountViewCanaryQuery,
    AccountViewCanaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AccountViewCanaryQuery,
    AccountViewCanaryQueryVariables
  >(AccountViewCanaryDocument, options)
}
export function useAccountViewCanaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountViewCanaryQuery,
    AccountViewCanaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AccountViewCanaryQuery,
    AccountViewCanaryQueryVariables
  >(AccountViewCanaryDocument, options)
}
export function useAccountViewCanarySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AccountViewCanaryQuery,
        AccountViewCanaryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    AccountViewCanaryQuery,
    AccountViewCanaryQueryVariables
  >(AccountViewCanaryDocument, options)
}
export type AccountViewCanaryQueryHookResult = ReturnType<
  typeof useAccountViewCanaryQuery
>
export type AccountViewCanaryLazyQueryHookResult = ReturnType<
  typeof useAccountViewCanaryLazyQuery
>
export type AccountViewCanarySuspenseQueryHookResult = ReturnType<
  typeof useAccountViewCanarySuspenseQuery
>
export type AccountViewCanaryQueryResult = Apollo.QueryResult<
  AccountViewCanaryQuery,
  AccountViewCanaryQueryVariables
>
